import React from 'react';
import Avatar from '@mui/material/Avatar';
import moment from 'moment/min/moment-with-locales';
import { renderWithEmoji } from '../helpers';
import './NewsFeedComments.css';
import withStyles from '@mui/styles/withStyles';
import $ from 'jquery';
import AutoScroll from '../general/AutoScroll';
import TaimerAvatar from '../general/TaimerAvatar';
import { Modal } from '@mui/material';
import { default as Saving } from '@mui/icons-material/AccessTime';
import { SettingsContext } from './../SettingsContext';
import Link from '../general/Link';

const styles = theme => ({
});

class NewsFeedComment extends React.Component {
    static contextType = SettingsContext;

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const { item: msg } = this.props;

        const time = moment.utc(msg.timestamp).local();

        let attach = null;
        
        const params = {
            id: msg.attachments_id,
            attachment: "newsfeed",
            is_comment: 'true',
            _auth: this.context.functions.getStorage().taimerToken,
        };
 
        const attachUrl = msg.attachments_id && this.context.functions.getDownloadPath() + $.param(params);

        const openModal = (e) => { 
            e.preventDefault();
            this.setState({modalOpen: true}); 
        }
        const closeModal = () => this.setState({modalOpen: false});

        let modal = undefined;

        if (msg.attachment_type && msg.attachment_type.startsWith("image/")) {
            attach = <div><a onClick={openModal} href={attachUrl} target="_blank" rel="noreferrer"><img className="attachment" src={attachUrl} alt={msg.attachment_name} /></a></div>
            modal = <Modal className="TeamChatImageModalOuter" open={this.state.modalOpen} onClose={closeModal}>
                <div className="TeamChatImageModal" onClick={closeModal}><img src={attachUrl} alt={msg.attachment_name} /></div>
            </Modal>
        }
        else if (msg.attachment_type && msg.attachment_type.startsWith("video/")) {
            attach = <div><video src={attachUrl} controls /></div>
        }
        else if (msg.attachments_id)
            attach = <div><a href={attachUrl} target="_blank" rel="noreferrer">{msg.attachment_name}</a></div>

        return (
            <div>
                {modal}
                <div className="NewsFeedUser">
                    <TaimerAvatar id={msg.creator_id} name={msg.creator} color={msg.creator_color} />
                </div>
                <div className="NewsFeedComment">
                    <div className="UserName">{msg.creator}</div>
                    <div>
                        <p>{renderWithEmoji(msg.comment)}</p>
                        {attach}
                    </div>
                </div>
                {msg.pending ? <div className="CommentTime"><Saving /></div> : <div className="CommentTime">{time.fromNow()}</div>}
            </div>
        );
    }
}

class NewsFeedComments extends React.Component {
    render() {
        const { comments, classes } = this.props;

        return (
            <AutoScroll ref={this.ref} className="TeamChatCommentsArea">
                {comments.map(c => <NewsFeedComment key={c.id} item={c} classes={classes} />)}
            </AutoScroll>
        );
    }
}

export default withStyles(styles)(NewsFeedComments);