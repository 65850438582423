import { Popover } from '@mui/material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Save from '@mui/icons-material/Save';
import React, { ReactNode, useState } from 'react';
import styles from './ProposalEditor.module.css';

interface AddContentMenuItem {
    type: string;
    icon?: ReactNode;
    title: string;
}

interface AddContentButtonProps {
    index: number;
    sectionIndex: number;
    title: string;
    onItemClick: (index: number, type: string, sectionIndex?: any, template?: any) => void;
    onAddTemplate?: (sectionIndex: number) => void;
    items: (AddContentMenuItem | undefined)[];
    icons: any;
    templateTitle?: string;
    showSeparatorLines?: boolean;
}

const AddContentButton = (props: AddContentButtonProps) => {
    const { index, title, onItemClick, showSeparatorLines, items, sectionIndex, icons, onAddTemplate, templateTitle } = props;
    const [popoverAnchor, setAnchor] = useState<null | HTMLElement>(null);
    return (
        <>
            <div className={styles.addContentButtonContainer}>
                {showSeparatorLines && <hr />}
                <button className={styles.addContentButton} onClick={(e) => setAnchor(e.currentTarget)}>
                    <AddCircleOutline />
                    <h2>{title}</h2>
                </button>
                {showSeparatorLines && <hr />}
            </div>
            <Popover
                elevation={1}
                anchorOrigin={{
                    vertical: 40,
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={popoverAnchor}
                onClose={() => setAnchor(null)}
                open={!!popoverAnchor}
            >
                <div className={styles.addContentMenu}>
                    {items.map((item, i) => {
                        if (!item) return null;
                        return (
                            <button
                                key={i}
                                className={styles.addContentItem}
                                onClick={() => {
                                    setAnchor(null);
                                    onItemClick(index, item.type, sectionIndex);
                                }}
                            >
                                {icons[item.type]}
                                {item.title}
                            </button>
                        );
                    })}
                    {!!templateTitle && onAddTemplate && (
                        <button
                            key={items.length}
                            className={styles.addContentItem}
                            onClick={() => {
                                setAnchor(null);
                                onAddTemplate(sectionIndex);
                            }}
                        >
                            {<Save />}
                            {templateTitle}
                        </button>
                    )}
                </div>
            </Popover>
        </>
    );
};

export default AddContentButton;
