/* local components */
import TaimerComponent from '../TaimerComponent';
import Category from './Category';

/* material ui */
import SettingsIcon from '@mui/icons-material/Settings';

/* context */
import { SettingsContext } from '../SettingsContext';
import { groupBy, map } from 'lodash';

import styles from './Notifications.module.scss';
import { getNotifications, NotifcationSeen, NotificationType, NotificationItem, getNotificationTypes, NotificationTypes, NotificationsCategory } from './api';
import Slider from '../general/Slider';

interface Props {
    onUnseenCount: (unread: number) => void;
    openTeamChat: () => void;
    full: boolean;
    updateFrequency: number;
}

interface State {
    open: boolean;
    typesLoaded: boolean;
    newCount: number;
    notifications: NotificationItem[];
    hasPoRights: boolean;
    types: NotificationTypes;
}


export class Notifications extends TaimerComponent<Props, State> {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "notifications/Notifications");

        this.state = {
            open: false,
            typesLoaded: false,
            newCount: 0,
            hasPoRights: false,
            notifications: [],
            types: {},
        }
    }

    isRefreshing = false;
    isPaused = false;
    interval?: NodeJS.Timer = undefined;

    componentDidMount() {
        super.componentDidMount();

        this.loadTypes();
        this.refresh(true);
        this.interval = setInterval(this.refresh, this.props.updateFrequency);
        const categoryVisibility = localStorage.getItem("openNotifications");
        if (!categoryVisibility) {
            localStorage.setItem("openNotifications", JSON.stringify({ 1: "closed", 2: "closed" }));
        }
    }

    componentWillUnmount = () => {
        super.componentWillUnmount();

        if (this.interval)
            clearInterval(this.interval);
    }

    open = (currentState) => {
        if (!this.state.open) {
            this.setState({ open: true });
        }
        this.refresh(true);
    }

    close = () => {
        this.setState({ open: false });
    }

    loadTypes = async () => {
        const { types } = await getNotificationTypes();

        this.setState({ typesLoaded: true, types });
    }

    refresh = async (forceRefresh = false) => {
        if (this.isPaused || (this.isRefreshing && !forceRefresh))
            return;

        this.isRefreshing = true;

        try {
            const data = await getNotifications({
                lang: this.lang,
            });
            const hasPoRights = data.has_po_rights;
            const newCount = data.notifications.filter(x => x.seen == NotifcationSeen.NotSeen).length;

            this.props.onUnseenCount && this.props.onUnseenCount(newCount);
            this.setState({
                notifications: data.notifications,
                newCount,
                hasPoRights
            });
        } catch (error) {
            console.log("notifications error: ", error);
        }

        this.isRefreshing = false;
    }

    updateNotification = (selector: (item: NotificationItem) => boolean, update: Partial<NotificationItem>) => {
        const { notifications } = this.state;

        this.setState({
            notifications: notifications.map(x => selector(x) ? { ...x, ...update } : x),
        });
    }

    settingsClick = () => {
        this.context.functions.updateView({ module: 'settings', action: 'index', group: 'my-profile', page: 'notifications', subpage: '' });
        this.close();
    }

    render() {
        const { tr } = this;
        const { openTeamChat } = this.props;
        const { open, notifications, typesLoaded, types } = this.state;
        const { functions: { hasPrivilege } } = this.context;

        const grouped = groupBy(notifications, x => x.type);
        const typesSorted: (NotificationsCategory & { type_id: string, sortOrder: number })[] = [];
        for (const i in types) {
            const type = types[i];
            typesSorted.push({ ...type, type_id: i });
        }
        typesSorted.sort((a, b) => a.sortOrder - b.sortOrder);

        return (
            <Slider
                title={tr("Notifications")} open={open} onClose={this.close}
                classes={{ paper: styles.notificationDrawer }}
                rightComponent={<button onClick={this.settingsClick}><SettingsIcon /></button>}>
                <div className={styles.notifications}>
                    {typesLoaded && map(typesSorted, (category) => {
                        const type = Number(category.type_id) as NotificationType;

                        let visible = true;

                        if (type === NotificationType.TimeTracker) {
                            visible = hasPrivilege("workhours", "write");
                        } else if (type === NotificationType.Invoice) {
                            visible = hasPrivilege("invoices", "write_simple") || hasPrivilege("invoices", "write_full");
                        } else if (type == NotificationType.PurchaseOrders) {
                            visible = !!this.context.addons?.bills;
                        } else if (type == NotificationType.Bills) {
                            visible = !!this.context.addons?.bills && (hasPrivilege('receivedinvoices', 'approve') || hasPrivilege('receivedinvoices', 'pre_approve'));
                        } else if (type == NotificationType.Expenses) {
                            visible = !!this.context.addons.expenses && (
                                hasPrivilege('worktrips', 'write') || hasPrivilege('worktrips', 'approve') ||
                                hasPrivilege('worktrips', 'approve_projectmanager')) || hasPrivilege('worktrips', 'approve_superior') ||
                                hasPrivilege('worktrips', 'modify_all')
                        } else if (type == NotificationType.TravelExpenses) {
                            visible = !!this.context.addons.expenses && ![10,11].includes(Number(this.context.versionId)) && (
                                hasPrivilege('worktrips', 'write') || hasPrivilege('worktrips', 'approve') ||
                                hasPrivilege('worktrips', 'approve_projectmanager') || hasPrivilege('worktrips', 'approve_superior') ||
                                hasPrivilege('worktrips', 'modify_all'))
                        } else if (type == NotificationType.RESOURCING) {
                            visible = !!this.context.addons?.resourcing;
                        }
                        return visible && <Category
                            type={type}
                            category={category}
                            notificationProps={{
                                refresh: this.refresh,
                                openTeamChat: openTeamChat,
                                close: this.close,
                                tr: this.tr
                            }} data={grouped[type] ?? []}
                            updateNotification={this.updateNotification}
                        />
                        
                    })}
                </div>
            </Slider>
        )
    }
}

export default Notifications