import React from 'react';
import addItemStyles from './AddItemComponents.module.scss';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import TaimerComponent from '../../TaimerComponent';
import { capitalize } from 'lodash';

interface AddAccountProps {
    selectProps: any;
}

export class AddAccount extends TaimerComponent<AddAccountProps> {
    constructor(props, context) {
        super(props, context, 'general/no-options/AddAccount');
    }

    onAddAccount = () => {
        const { selectProps } = this.props;
        const accountName = selectProps?.inputValue;
        const isSupplier = selectProps?.noOptionsProps?.accountType == 'sub_contractor';
        const {
            taimerAccount: { countryCode },
        } = this.context;
        const name = countryCode == 'FI' ? { label: accountName } : accountName;
        this.context.functions.addAccount({ companies_id: this.getCompaniesID(), name, types: isSupplier ? 'sub_contractor' : undefined, origin_point: "add_item" }, { onAccountCreated: this.onAccountCreated });
    };

    onAccountCreated = (account) => {
        const { selectProps } = this.props;
        selectProps.onItemCreated && selectProps.onItemCreated({ ...account, label: account.name, value: account.id });
    };

    getCompaniesID = () => {
        return this.props.selectProps?.companies_id || this.props.selectProps?.company || this.props.selectProps?.noOptionsProps?.company;
    };

    render() {
        const { selectProps } = this.props;
        const {
            functions: { checkPrivilege },
        } = this.context;
        if (!checkPrivilege('customers', 'write', this.getCompaniesID()) || selectProps?.noOptionsHidden) {
            return (
                <div className={addItemStyles.noOptionsItem}>
                    <p>{`${this.tr('No options')}`}</p>
                </div>
            );
        }
        return (
            <div data-testid='add-account-option' className={addItemStyles.addItem} onClick={this.onAddAccount}>
                <AddCircleOutline />
                <p>{selectProps?.inputValue ? `${this.tr('Add new account')}: ${selectProps?.inputValue}` : this.tr('Add new account')}</p>
            </div>
        );
    }
}

interface AddProjectProps {
    selectProps: any;
    inputValue?: string;
}
export class AddProject extends TaimerComponent<AddProjectProps> {
    constructor(props, context) {
        super(props, context, 'general/no-options/AddProject');
    }

    onAddProject = () => {
        const { selectProps, inputValue } = this.props;
        const name = selectProps?.inputValue || inputValue;
        this.context.functions.addProject(
            { companies_id: selectProps.companies_id, customers_id: selectProps.customers_id, name, origin_point: "add_item", },
            { onProjectCreated: this.onProjectCreated, width: selectProps?.sliderWidth }
        );
    };

    onProjectCreated = (project) => {
        const { selectProps } = this.props;
        selectProps.onItemCreated && selectProps.onItemCreated({ ...project, label: project.name, value: project.id });
    };

    render() {
        const { selectProps, inputValue } = this.props;
        const {
            functions: { checkPrivilege },
        } = this.context;
        if (!checkPrivilege('projects', 'write', selectProps?.companies_id) || selectProps?.noOptionsHidden) {
            return (
                <div className={addItemStyles.noOptionsItem}>
                    <p>{`${this.tr('No options')}`}</p>
                </div>
            );
        }
        return (
            <div className={addItemStyles.addItem} onClick={this.onAddProject}>
                <AddCircleOutline />
                <p>{selectProps?.inputValue || inputValue ? `${this.tr('Add new project')}: ${selectProps?.inputValue || inputValue}` : this.tr('Add new project')}</p>
            </div>
        );
    }
}

interface AddContactProps {
    selectProps: any;
}

export class AddContact extends TaimerComponent<AddContactProps> {
    constructor(props, context) {
        super(props, context, 'general/no-options/AddContact');
    }

    onAddContact = () => {
        const { selectProps } = this.props;
        const fullname = selectProps?.inputValue;
        const splitted = fullname.split(' ');
        const firstname = capitalize(splitted[0] || '');
        let lastname = '';
        if (splitted.length > 1) {
            lastname = capitalize(splitted[1]);
        }
        this.context.functions.addContact(
            { firstname, lastname, companies_id: selectProps.companies_id, customers_id: selectProps.customers_id, contact_type: selectProps.contact_type, projects: selectProps.projects },
            { onContactCreated: this.onContactCreated, existingContacts: selectProps.existingContacts }
        );
    };

    onContactCreated = (contact) => {
        const { selectProps } = this.props;
        selectProps.onItemCreated && selectProps.onItemCreated({ ...contact, label: `${contact.firstname} ${contact.lastname}`, value: contact.id });
    };

    render() {
        const { selectProps } = this.props;
        const {
            functions: { checkPrivilege },
        } = this.context;
        if (!checkPrivilege('persons', 'read', selectProps?.companies_id) || selectProps?.noOptionsHidden) {
            return (
                <div className={addItemStyles.noOptionsItem}>
                    <p>{`${this.tr('No options')}`}</p>
                </div>
            );
        }
        return (
            <div data-testid='add-contact-option' className={addItemStyles.addItem} onClick={this.onAddContact}>
                <AddCircleOutline />
                <p>{selectProps?.inputValue ? `${this.tr('Add new contact')}: ${selectProps?.inputValue}` : this.tr('Add new contact')}</p>
            </div>
        );
    }
}

export class AddTitle extends TaimerComponent<any> {
    constructor(props, context) {
        super(props, context, 'general/no-options/AddTitle');
    }

    addTitle = () => {
        const { 
            selectProps 
        } = this.props;

        const {
            inputValue
        } = selectProps;

        // DataHandler.post();
    };

    render() {
        const { 
            selectProps 
        } = this.props;

        return (
            <div className={addItemStyles.addItem} onClick={this.addTitle}>
                <AddCircleOutline />
                <p>{selectProps?.inputValue 
                    ? `${this.tr('Add new title')}: ${selectProps?.inputValue}` 
                    : this.tr('Add new title')}</p>
            </div>
        );
    }
}
