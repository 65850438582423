import React, { ReactElement } from 'react';
import Close from '@mui/icons-material/Close';
import WarningRounded from '@mui/icons-material/WarningRounded';
import { Button } from '@mui/material';
import colors from '../colors';

import styles from './DialogComponents.module.scss';

interface HeaderProps {
    /** Title string of the dialog, shown in the header. */
    title: string;
    /** Called when close button is pressed. */
    onClose: () => void;
    /** Optional icon component, shown on the left side of the title. Shouldn't be used together with showWarningIcon. */
    icon?: ReactElement;
    /** Optional icon color. */
    iconColor?: string;
    /** Boolean to determine if yellow warning icon is shown next to the header. Should be used in confirmation dialogs. Shouldn't be used together with the icon prop. */
    showWarningIcon?: boolean;
}

export const DialogHeader = (props: HeaderProps) => {
    const { title, onClose, icon, iconColor, showWarningIcon } = props;
    return (
        <div id={styles.dialogHeader}>
            {showWarningIcon && <WarningRounded className={`${styles.icon} ${styles.warningIcon}`} />}
            {icon &&
                React.cloneElement(icon, {
                    className: `${icon.props.className} ${styles.icon}`,
                    style: { ...icon.props.style, color: iconColor ? iconColor : colors.steel },
                })}
            <h1>{title}</h1>
            <button onClick={onClose}>
                <Close />
            </button>
        </div>
    );
};

export const DialogContent = (props) => {
    const { children } = props;
    return <div id={styles.dialogContent}>{children}</div>;
};

export interface DialogAction {
    /** Title be shown in the button. */
    title: string;
    /** Called when button is pressed. */
    action: () => void;
    /** Optional backgroundColor for the button. */
    color?: string;
    /** Optional disabling of the button. */
    disabled?: boolean;
    /** Optional variant, gives predefined styles for the button. For now only cancel is supported. */
    variant?: 'cancel';
}

interface FooterProps {
    /** Buttons to be shown in the left side of the footer. See DialogAction type inside DialogComponents.tsx to see the needed properties. */
    leftActions: DialogAction[];
    /** Buttons to be shown in the right side of the footer. See DialogAction type inside DialogComponents.tsx to see the needed properties. */
    rightActions: DialogAction[];
}

export const DialogFooter = (props: FooterProps) => {
    const { leftActions, rightActions } = props;
    return (
        <div id={styles.dialogFooter}>
            <div>
                {(leftActions || []).map((action) => (
                    <Button className={action.variant == 'cancel' ? styles.cancel : ''} disabled={action.disabled} onClick={action.action} style={{ backgroundColor: action.color }}>
                        {action.title}
                    </Button>
                ))}
            </div>
            <div>
                {(rightActions || []).map((action) => (
                    <Button disabled={action.disabled} onClick={action.action} style={{ backgroundColor: action.color || colors.dark_sky_blue }}>
                        {action.title}
                    </Button>
                ))}
            </div>
        </div>
    );
};
