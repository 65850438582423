import React, { Component } from 'react'
import TaimerComponent from "../TaimerComponent";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import './TrialDialog.css';
import { Button } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import { withSnackbar } from 'notistack';
import DataHandler from '../general/DataHandler';

import { ReactComponent as TimeTracker } from '../projects/images/time_tracker.svg';
import { ReactComponent as Resourcing } from '../resourcing/images/resourcing.svg';
import { ReactComponent as Billing } from '../bills/images/billing.svg';

class TrialDialog extends TaimerComponent {
	constructor(props, context) {
		super(props, context, "dialogs/TrialDialog");
	}

	activateTrial = async () => {
		const { name, enqueueSnackbar } = this.props;
		const { tr } = this;

		const data = await DataHandler.post({ url: 'settings/trials' }, {
			addon: [name]
		});

		//this.context.mixpanel.track("Try Add-on", {"Add-on name": name, "Trial end date": data[name].expires});

		const status = data[name];

		if (status.activated) {
			enqueueSnackbar(tr("Free trial activated!"), {
				variant: "success",
			});

			this.context.functions.whoami();

			this.props.onClose && this.props.onClose();
		}
		else {
			enqueueSnackbar(tr("Failed to activate free trial!"), {
				variant: "error",
			});

			this.context.functions.whoami();

			this.props.onClose && this.props.onClose();
		}
	}
	render() {
		const { functions: { checkPrivilege } } = this.context;
		const { name, addon, current } = this.props;
		const { tr } = this;

		const addonTexts = {
			default: {
				image: undefined,
				name: "Missing text",
				main: "Missing text",
				side: "Missing text",
			},
			timetracker: {
				image: TimeTracker,
				name: tr("Time Tracker"),
				main: tr("Spend your time on billable hours, not on keeping track of them."),
				side: tr("Taimer lets you track time in whatever way is easiest for you."),
			},
			resourcing: {
				image: Resourcing,
				name: tr("Resource Gantt"),
				main: tr("Fast scheduling and complete team visibility across projects and tasks."),
				side: tr("Manage tasks and hours in the same app"),
			},
			bills: {
				image: Billing,
				name: tr("Bills"),
				main: tr("Get a real-time overview of all your bills and where your business is spending money."),
				side: tr("Manage bills easily in Taimer."),
			}
		}

		const texts = addonTexts[name] || addonTexts.default;

		const Image = texts && texts.image;

		return (
			<Dialog className="TrialDialog" open onClose={this.props.onClose} maxWidth={false}>
				<div className="TrialDialogContent">
					<div className="TrialDialogLeft">
						<div>
							<h2>{texts.name}</h2>
							<p>{texts.main}</p>
							<small>
								{this.tr("Free trial for add-ons is applicable for 14 Days for the Free CRM solution and until the end of the trial period for all other solutions.")}
							</small>
							{checkPrivilege("admin", "admin") && <div className="TrialDialogAction">
								<Button onClick={this.activateTrial} size="large">{this.tr("Try now for free")}</Button>
							</div>}
							{!checkPrivilege("admin", "admin") && <small>
								{this.tr("Please contact administator to active this addon!")}
							</small>}
						</div>
					</div>
					<div className="TrialDialogRight">
						<div>
							<div className="TrialDialogClose">
								<Button onClick={this.props.onClose}><Clear /></Button>
							</div>
							<div className="TrialImage">
								{Image && <Image />}
							</div>
							<p>{texts.side}</p>
						</div>
					</div>
				</div>
			</Dialog>
		)
	}
}

export default withSnackbar(TrialDialog);