import DataHandler from "../general/DataHandler";

import { ReactComponent as PlusIcon } from './icons/add.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as InvoiceIcon } from './icons/invoices.svg';
import { ReactComponent as ProjectsIcon } from './icons/projects.svg';
import { ReactComponent as TimeTrackerIcon } from './icons/hours.svg';
import { ReactComponent as PurchaseOrderIcon } from './icons/purchase_orders.svg';
import { ReactComponent as ActivityIcon } from './icons/activities.svg';
import { ReactComponent as BillsIcon } from './icons/bills.svg';
import { ReactComponent as ExpensesIcon } from './icons/expenses.svg';
import { ReactComponent as TasksIcon } from './icons/tasks.svg';
import { ReactComponent as ReportsIcon } from './icons/reports.svg';

const icons = {
    'plus': PlusIcon,
    'info': InfoIcon,
    'timetracker': TimeTrackerIcon,
    'invoice': InvoiceIcon,
    'project': ProjectsIcon,
    'purchase_order': PurchaseOrderIcon,
    'activity': ActivityIcon,
    'bill': BillsIcon,
    'expense': ExpensesIcon,
    'tasks': TasksIcon,
    'reports': ReportsIcon,
}

/**
 * Notification type / category
 * 
 * Also update Notifications helper
 */
export enum NotificationType {
    TimeTracker = 1,
    Invoice = 2,
    Projects = 3,
    PurchaseOrders = 4,
    Activity = 5,
    Bills = 6,
    Expenses = 7,
    RESOURCING = 8,
    Reports = 9,
    TravelExpenses = 10,
}

/**
 * Notification subtypes
 * 
 * Also update Notifications helper
 */
export enum NotificationSubType {
    HOURBALANCE_MIN_EXCEED = 1,
    HOURBALANCE_MAX_EXCEED = 2,
    HOURBALANCE_MIN_USER_EXCEED = 3,
    HOURBALANCE_MAX_USER_EXCEED = 4,
    OVERTIME_APPROVE_ENTRY = 5,
    OVERTIME_APPROVED = 6,
    OVERTIME_DECLINED = 7,
    OVERTIME_APPROVE_ENTRY_MODIFY = 8,
    INVOICE_CREATED = 9,
    INVOICE_OVERDUE = 10,
    INVOICE_PAID = 11,
    /**
     * @deprecated
     */
    INVOICE_CREATED_ACCOUNT = 12,
    /**
     * @deprecated
     */
    INVOICE_OVERDUE_ACCOUNT = 13,
    /**
     * @deprecated
     */
    INVOICE_PAID_ACCOUNT = 14,
    /**
     * @deprecated
     */
    INVOICES_CREATED = 15,
        /**
     * @deprecated
     */
    INVOICES_OVERDUE = 16,
        /**
     * @deprecated
     */
    INVOICES_PAID = 17,
    PROJECT_CREATED = 18,
    PROJECT_ADDED_PM = 19,
    PROJECT_ADDED_SA = 20,
    PROJECT_ADDED_TM = 21,
    WORKTIME_APPROVED = 22,
    WORKTIME_DECLINED = 23,
    WORKTIME_SENT_TO_APPROVAL = 24,
    UNKNOWN_25 = 25,
    PO_CREATED = 26,
    PO_SYNCED = 27,
    PO_AUTO_SYNCED = 28,
    PO_MULTI_AUTO_SYNCED = 29,
    WORKTIME_DELETED = 30,
    WORKTIME_SENT_TO_MANAGER = 31,
    HOUR_ENTRY_REMINDER_DAILY = 32,
    HOUR_ENTRY_REMINDER_WEEKLY = 33,
    HOUR_ENTRY_REMINDER_MONTHLY = 34,

    // Project Activities
    ACTIVITY_ASSIGNED_PROJECT = 40,
    ACTIVITY_UNASSIGNED_PROJECT = 41,
    ACTIVITY_DUE_PROJECT = 42,
    ACTIVITY_CREATED_PROJECT = 43,

    // Account Activities
    ACTIVITY_ASSIGNED_ACCOUNT = 50,
    ACTIVITY_UNASSIGNED_ACCOUNT = 51,
    ACTIVITY_DUE_ACCOUNT = 52,
    ACTIVITY_CREATED_ACCOUNT = 53,

    // Resourcing
    TASK_CREATED = 80,
    TASK_ASSIGNED = 81,
    TASK_UNASSIGNED = 82,
    TASK_DUE = 83,
    TASK_DONE = 84,
    TASK_DONE_USER = 85,
    TASK_HOURS_TRACKED = 86,
    
    PROJECT_IMPORT_HUBSPOT = 10000,
    BILL_CREATED_RECEIVED = 60,
    BILL_ASSIGNED_MANUALLY = 61,
    BILL_ASSIGNED_DEFAULT_RECIPIENT = 62,
    BILL_TARGETED_PROJECT = 63,
    BILL_PAYMENT_DUE = 64,
    // Expenses
    EXPENSE_CREATED = 70,
    EXPENSE_CREATED_PROJECT = 71,
    EXPENSE_CREATED_SUBORDINATE = 72,
    EXPENSE_APPROVED = 73,
    EXPENSE_DECLINED = 74,
    // Travel Expenses
    TRAVEL_EXPENSE_CREATED = 100,
    TRAVEL_EXPENSE_CREATED_PROJECT = 101,
    TRAVEL_EXPENSE_CREATED_SUBORDINATE = 102,
    TRAVEL_EXPENSE_APPROVED = 103,
    TRAVEL_EXPENSE_DECLINED = 104,

    // New reports
    REPORT_SHARED = 90
}

export enum NotificationLevel {
    NOTIFICATION_GROUP = -2,
    NOTIFICATION_LEVEL_ALL = 0,
    NOTIFICATION_LEVEL_PROJECT_MANAGER = 1,
    NOTIFICATION_LEVEL_SALES_MANAGER = 2,
    NOTIFICATION_LEVEL_TEAM_MEMBER = 3,
    NOTIFICATION_LEVEL_SUPERIOR = 4,
    NOTIFICATION_LEVEL_ACCOUNT_MANAGER = 5,
    NOTIFICATION_LEVEL_ACCOUNT_TEAM = 6,
}

export enum NotifcationSeen {
    NotSeen = 0,
    Seen = 1,
    Hidden = 2,
}

export interface NotificationAction {
    name: string;
}

export interface NotificationItem {
    id: number;
    type: NotificationType;
    subtype: NotificationSubType;
    seen: NotifcationSeen;
    value: Record<string, any>;

    /**
     * User ID who caused creation of notification
     */
    acting_users_id: number | null;

    /**
     * Customer notification is related to
     */
    customers_id: number | null;

    /**
     * Project id notification is related to
     */
    projects_id: number | null;

    /**
     * Type specific target id
     */
    target_id: number;
    companies_id: number;

    header: string;
    msg: string;

    creation_date: string;

    actions: NotificationAction[];
}

interface GetNotificationsResponse {
    notifications: NotificationItem[];
    has_po_rights: boolean;
}

export interface NotificationDefinition {
    icon?: string;
    type: NotificationType;
    sub_type: NotificationSubType;
    // actions: Record<string, string>;
    visible: boolean;
}

export type NotificationTypes = Record<number, NotificationsCategory>;

export interface NotificationsCategory {
    icon: string;
    header: string;
    notifications: NotificationDefinition[];
    sortOrder: number;
}

interface GetNotificationTypesResponse {
    types: NotificationTypes;
}

interface NotificationOptions {
    lang?: string;
}

export async function getNotificationTypes(): Promise<GetNotificationTypesResponse> {
    const data = await DataHandler.get({ url: `notifications/types` }) as GetNotificationTypesResponse;

    return data;
}

export async function getNotifications(options?: NotificationOptions): Promise<GetNotificationsResponse> {
    const data = await DataHandler.get({ url: `notifications`, ...options }) as GetNotificationsResponse;

    return data;
}

export function getIconFromName(name?: string): React.ComponentType | null {
    return name && icons[name] ? icons[name] : null;
}