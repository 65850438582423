import React, { CSSProperties } from "react";
import { Button, Popover, PopoverOrigin } from "@mui/material";
import ArrowDropDownRounded from '@mui/icons-material/ArrowDropDownRounded';
import "./InsightDropDown.scss";
import { hexColorWithOpacity } from "../../helpers";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

interface InsightDropDownTab {
  key: string;
  label: string;
  action: () => void;
  icon?: string;
  style?: React.CSSProperties;
  iconComponent?: React.ReactType;
  iconComponentClass?: string;
}

interface InsightDropDownProps {
  selected?: string;
  disabled?: boolean;
  tabs: InsightDropDownTab[];
  style?: React.CSSProperties;
  color?: any;
  anchorVertical?: PopoverOrigin['vertical'];
  anchorHorizontal?: PopoverOrigin['horizontal'];
  transformHorizontal?: PopoverOrigin['horizontal'];
  titleColor?: CSSProperties['color'];
  title?: any;
  customComponent?: any;
  defaultSelection?: InsightDropDownTab;
  titleLabel?: any;
  popupClassName?: string;
  disableAutoClose?: boolean;
  icon?: React.ReactNode;
  'data-testid'?: string;
}

const InsightDropDown = forwardRef((props: InsightDropDownProps, ref) => {
  const { selected, disabled, disableAutoClose, icon, tabs = [], style, color, anchorVertical, anchorHorizontal, transformHorizontal, titleColor, title, customComponent, defaultSelection, titleLabel, popupClassName } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  useImperativeHandle(ref, () => ({
    close: () => {
      setAnchorEl(null);
    }
  }));

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const selectedItem = tabs.find(obj => obj.key === selected) || defaultSelection;
  const selectedLabel = titleLabel ? titleLabel : selectedItem?.label;
  return (
    <div style={style} className="insight-drop-down no-my-day-drag">
      <Button
        data-testid={props['data-testid'] ?? (title ? title : "")}
        disabled={disabled}
        style={{
          backgroundColor: hexColorWithOpacity(color || "#2d9ff7", 0.2),
          color: titleColor || (color || "#2d9ff7")
        }}
        onClick={handleClick}
      >
        {icon}
        <p>{title ? `${title}: ${selectedLabel}` : selectedLabel}</p>
        <ArrowDropDownRounded
          style={{ color: titleColor || (color || "#2d9ff7") }}
          className="icon"
        />
      </Button>
      <Popover
        open={open}
        elevation={4}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={popupClassName}
        anchorOrigin={{
          vertical: anchorVertical || 50,
          horizontal: anchorHorizontal || "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: transformHorizontal || "right"
        }}
      >
         {customComponent ||
          tabs.map((tab, i) => {
            const Icon = tab.icon;
            return (
              <div
                key={i}
                onClick={() => {
                  tab.action();
                  !disableAutoClose && handleClose();
                }}
                style={tab.style}
                className="insight-drop-down-tab-row"
                data-testid={tab['data-testid'] ? tab['data-testid'] : ""}
              >
                {tab.icon && Icon}
                {tab.iconComponent && <tab.iconComponent className={`icon ${tab.iconComponentClass}`} />}
                <p>{tab.label}</p>
              </div>
            );
          })}
      </Popover>
    </div>
  );
});

export default InsightDropDown;
