import DataHandler from './../general/DataHandler';

interface TriggerConfig {
    method: string;
    once: boolean;
    invocationCount?: number;
}

class TriggerService {
    private triggers: { [url: string]: TriggerConfig } = {
        "login": {
            method: "post",
            once: true,
        }
    };

    public trigger(url: string, body: any = undefined): Promise<any> {
        url = url.trim();

        if(url === "") {
            throw "Url is empty";
        }

        const config: TriggerConfig = this.triggers?.[url];

        if(!config) {
            throw `No trigger configured for ${url}`
        }

        if(config.once && (config?.invocationCount ?? 0) > 0) {
            return new Promise(() => {});
        }

        config.invocationCount = (config?.invocationCount ?? 0) + 1;

        return DataHandler?.[config.method]({ url: `triggers/${url}` }, body);
    }
}

export default TriggerService;
