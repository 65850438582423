import React from 'react';
import TaimerComponent from '../TaimerComponent';
import DataHandler from '../general/DataHandler';
import ExpenseDetailsTab, { 
    ExpenseDetailsTabWizardMode,
    ExpenseDetailsTabPreviewMode,
    ExpenseDetailsTabProps,
    ExpenseDetailsTabNoRenderMode
} from './ExpenseDetailsTab';
import ContextMenu from '../general/ContextMenu';
import { MenuItem, tabClasses } from '@mui/material';
import { SettingsContext } from '../SettingsContext';
import cardStyles from '../general/styles/CardStyles.module.scss';
import WithTabs from '../navigation/WithTabs';
import Utils from '../general/Utils';
import { format, parse } from 'date-fns';
import isEqual from "lodash/isEqual";
import moment from 'moment/min/moment-with-locales';
import { mapFieldsWithType } from "./ExpenseUtils";
import { withSnackbar, WithSnackbarProps } from 'notistack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';
import PrintIcon from '@mui/icons-material/Print';
import SendIcon from '@mui/icons-material/Send';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import NoPermissionOverlay from './../overlays/NoPermissionOverlay';
import ExpensePrintPreview from './ExpensePrintPreview';
import { 
    to12HourTime,
    to24HourTime,
} from "./../general/DateTimeUtils";

const MILEAGE_FIELD_MAP = {
    id: "id",
    mileage: "mileage", 
    mileage_allowance_id: "mileageAllowanceId",
    add_passenger_count: "additionalPassengerCount",
    // added_passenger_rate: "additionalPassengerRate",
    name: "name",
    type: "type",
    rate: "rate",
    a_year: "year",
    target_id: "mileageId",
    mileage_description: "description",
    jobtypes_id: "jobtypeId",
    content_type: "contentType",
    traveling_expenses_id: "travelingExpenseId",
    accounting_products_id: "accountingProductId",
    accounting_accounts_id: "accountingAccountId",
    bills_id: "billId",
    deleted: "deleted",
    attachments: "attachments",
};

const DAILY_FIELD_MAP = {
    id: "id",
    rate: "rate",
    part_rate: "partRate",
    daily_allowances_id: "allowanceId",
    days: "days",
    days_f: "daysF",
    start_date: "startDate",
    start_time: "startTime",
    end_date: "endDate",
    end_time: "endTime",
    jobtypes_id: "jobtypeId",
    content_type: "contentType",
    traveling_expenses_id: "travelingExpenseId",
    accounting_products_id: "accountingProductId",
    accounting_accounts_id: "accountingAccountId",
    bills_id: "billId",
    meals: "meals",
    total_meals: "totalMeals",
    total: "total",
    meal_deduction_total: "mealDeductionTotal",
    attachments: "attachments"
};

const EXPENSE_FIELD_MAP = {
    id: "id" ,
    traveling_expenses_id: "expenseId",
    accounting_products_id: "accountingProductId",
    accounting_accounts_id: "accountingAccountId",
    attachments_id: "attachmentId",
    description: "description",
    sum: "sum",
    total: "total",
    total_without_vat: "totalWithoutVat",
    total_with_vat: "totalWithVat",
    precise_total_without_vat: "preciseTotalWithoutVat",
    company_total_without_vat: "companyTotalWithoutVat",
    company_total_with_vat: "companyTotal",
    ratio_id: "ratioId",
    bills_id: "billId",
    vat: "vat",
    content_type: "contentType",
    jobtypes_id: "jobtypeId",
    type: "type",
    attachments: "attachments"
};

interface ExpenseDetails {
    id: string | number;
    companyName: string;
    accountId: string | number;
    accountName: string;
    projectId: string | number | undefined;
    projectNumber: string | number | undefined;
    projectName: string;
    quoteId: string | number;
    quoteRowId: string | number;
    description: string;
    userId: string | number;
    userFullName: string;
    companyId: string | number;
    billCustomer: boolean;
    route?: string;
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
    mileageRows?: MileageAllowance[];
    dailyRows?: DailyAllowance[];
    expenseRows?: Expense[];
    state: number;
    currencyRate?: number;
    currency?: string;
    companyCurrency?: string;
    expenseDate?: string;
    paymentTypeId?: string | number;
    billed: boolean;
    partiallyBilled: boolean;
    extendedCostTargeting: boolean;
    atLeastOneRowInvoiced: boolean;
    isBillable: boolean;
    approveAsSuperior: boolean;
    approveAsManager: boolean;
    notInCompanyCurrency: boolean;
    invoiceabilityStatus: number;
    companyTotalWithoutVat?: number;
    companyTotal?: number;
    companyVat?: number;
}

interface MileageAllowance {
    id: number;
    mileageId: number;
    mileageAllowanceId: number;
    additionalPassengerCount: number;
    additionalPassengerRate: number;
    name: string;
    type: number;
    rate: number;
    year: number;
    mileage: number;
    description: string;
    jobtypeId: number;
    contentType: number;
    travelingExpenseId: number;
    accountingProductId: number;
    accountingAccountId: number;
    billId: number;
    isAdditional: boolean;
    saved?: boolean;
    // TODO: type
    attachments: { [key: string]: string }[];
}

interface DailyAllowanceMeal {
    id: number;
    dailyAllowanceId: number;
    orderNr: number;
    meals: number;
}

interface DailyAllowance {
    id: number;
    accountingProductId: number;
    accountingAccountId: number;
    billId: number;
    contentType: number;
    allowanceId: number;
    days: number;
    daysF: number;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    jobtypeId: number;
    rate: number;
    partRate: number;
    total: number;
    mealDeductionTotal: number;
    travelingExpenseId: number;
    meals: { [date: string]: number };
    totalMeals: number;
    // TODO: type
    attachments: { [key: string]: string }[];
}

interface Expense {
    id: number;
    expenseId: number;
    accountingProductId: number;
    accountingAccountId: number;
    attachmentId: number;
    description: string;
    sum: number;
    total: number;
    totalWithoutVat: number;
    totalWithVat: number;
    preciseTotalWithoutVat: number;
    ratioId: number;
    billId: number;
    vat: number;
    contentType: number;
    jobtypeId: number;
    type: number;
    // TODO: type
    attachments: { [key: string]: string }[];
}

class ExpenseFile extends File {
    public saved?: boolean;
    public deleted?: boolean;
    private expenseRowType?: string;

    constructor(file: File) {
        super([file], file.name, {
            type: file.type
        });

        this.saved   = false;
        this.deleted = false;
    }

    public setExpenseRowType(type: string) {
        this.expenseRowType = type;
    }

    public getExpenseRowType(): string | undefined {
        return this.expenseRowType;
    }
}

interface TemporaryAttachmentsBundle {
    mileage_allowance: { [key: string | number]: ExpenseFile[] };
    additional_allowance: { [key: string | number]: ExpenseFile[] };
    daily_allowance: { [key: string | number]: ExpenseFile[] };
    other_allowance: { [key: string | number]: ExpenseFile[] };
    purchase_expense: { [key: string | number]: ExpenseFile[] };
    general: { [key: string | number]: ExpenseFile[] };
}

interface ExpenseViewProps extends WithSnackbarProps {
    id: string | number;
    company: string | number;
    expenseType: string;
    expenseDetails?: ExpenseDetails; 
    attachments?: any[];
    onDetailsEdit: (details: ExpenseDetails) => void;
    onTemporaryAttachmentsChange?: (attachments: TemporaryAttachmentsBundle) => void;
    onTotalsChange?: (totals) => void;
    temporaryAttachmentsBundle?: TemporaryAttachmentsBundle;
    dropdownData?: DropdownData;
    countryCode?: string;
    onDropdownDataChanged?: (dropdownData) => void;
    onCountryCodeChanged?: (code) => void;
    onExpenseFetched?: (expense: ExpenseDetails, expenseCompanyId: number | string) => void;
}

interface ExpenseViewState {
    noPermission: boolean;
    selectedTab: string;
    expense: ExpenseDetails;
    expenseLoaded: boolean;
    dropdownData: DropdownData;
    attachments: any[];
    countryCode: string;
    // TODO: Some type for stateLog.
    stateLog: any[];
    initDone: boolean;
    googleDrive: boolean;
    googleDriveAuthorized: boolean;
}

const MILEAGE_RATE_FIELD_MAP = {
    id: "id",
    name: "name",
    startdate: "startDate",
    enddate: "endDate",
    year: "year",
    rate: "rate",
    deleted: "deleted",
    integration_product: "integration_product"
}

const ADDITIONAL_MILEAGE_RATE_FIELD_MAP = {
    ...MILEAGE_RATE_FIELD_MAP,
    type: "type"
}

const DAILY_RATE_FIELD_MAP = {
    id: "id",
    name: "name",
    startdate: "startDate",
    enddate: "endDate",
    year: "year",
    rate: "rate",
    part_rate: "partRate",
    deleted: "deleted",
    integration_product: "integration_product"
}

interface DataSelectEntry {
    label: string;
    value: string | number;
    parentIdentifier?: string | number;
    deleted: boolean;
    id?: number | string;
}

interface MileageRate extends DataSelectEntry {
    rate: number;
    startDate: string;
    endDate: string;
    year: number;
    name?: string;
    id?: number | string;
    integration_product?: number | string;
}

interface AdditionalMileageRate extends MileageRate {
    type: number;
}

interface DailyRate extends DataSelectEntry {
    rate: number;
    partRate: number;
    startDate: string;
    endDate: string;
    year: number;
    id?: number | string;
}

interface ExpenseType extends DataSelectEntry {
    vat: number;
    deleted: boolean;
    id?: number | string;
}

interface Currency extends DataSelectEntry {
    id: string;
}

interface Quote extends DataSelectEntry {
    id: number | string;
    projects_id: number | string;
    children: any;
}

interface QuoteRow extends DataSelectEntry {
    projectId: number | string;
    quoteId: number | string;
    headerId: number | string;
}

interface Project extends DataSelectEntry {
    hasQuotes: boolean;
    expensesBillable: boolean;
    chargeTravelingAfter: string;
}

interface DropdownData {
    [key: string]: DataSelectEntry[];
    projects: Project[];
    mileageRates: MileageRate[];
    additionalRates: AdditionalMileageRate[];
    dailyRates: DailyRate[];
    expenseTypes: ExpenseType[];
    currencies: Currency[];
    quotes: Quote[];
    quoteRows: QuoteRow[];
    jobtypes: DataSelectEntry[];
}

interface MiscData {
    netvisorRate: number;
}

class ExpenseView extends TaimerComponent<ExpenseViewProps, ExpenseViewState> {
    static contextType  = SettingsContext;
    static defaultProps = {
        expenseDetails: undefined,
        id: -1,
        company: -1,
        expenseType: "2" // TODO:
    };

    protected detailsTab: any = React.createRef();
    protected backButton      = {
        visible: true,
        fallbackLocation: { 
            module: "costs", 
            action: "main", 
            selectedTab: "" 
        },
    };

    private tabs = [
        { id: 'details', label: this.tr('Details') },
    ];

    constructor(props: ExpenseViewProps, context) {
        super(props, context, 'expenses/ExpenseView');

        this.backButton.fallbackLocation.selectedTab = props.expenseType === "1" 
            ? "purchase-expenses"
            : "travel-expenses";

        this.state = {
            noPermission: false,
            selectedTab: "details",
            // @ts-ignore
            expense: props.expenseDetails || {},
            expenseLoaded: false,
            dropdownData: {
                mileageRates: [],
                additionalRates: [],
                dailyRates: [],
                expenseTypes: [],
                projects: [],
                currencies: [],
                quotes: [],
                quoteRows: [],
                jobtypes: []
            },
            initDone: false,
            countryCode: "FI",
            attachments: props.attachments || [],
            stateLog: [],
            // TODO: Should it be the expense creator's company id?
            googleDrive: (this.context.addons.googledrive && this.context.addons?.googledrive?.used_by_companies.indexOf(this.context.userObject.companies_id) > -1),
            googleDriveAuthorized: false,
        };
    }

    componentDidMount = (): void => {
        super.componentDidMount();

        this.init();
    }

    init = async (): Promise<void> => {
        let ed: ExpenseDetails;

        try {
            ed = await this.fetchExpense();
        } catch(e) {
            this.setState({ 
                noPermission: true 
            });

            return;
        }

        this.setState({ 
            expense: ed,
            expenseLoaded: true,
        }, async () => {
            this.setState({
                dropdownData: this.props.dropdownData || await this.fetchDropdownData(),
                countryCode:  this.props.countryCode || await this.fetchCountryCode(),
                attachments: await this.fetchAttachments(),
                stateLog: await this.fetchStateLog(),
                initDone: true
            });
        });
    }

    fetchExpenseTypes = async (): Promise<{ custom_types: any[] }> => {
        const { 
            id,
            expenseType
        }: {
            id: string | number;
            expenseType: string;
        } = this.props;

        return DataHandler.get({ 
            url: 'expenses/custom_types', 
            show_deleted: 'true', 
            expenses_id: id, 
            expense_type: expenseType == "1" ? "pe" : "te"
        }); 
    }

    fetchCountryCode = async (): Promise<string> => {
        const companyAddress          = await DataHandler.get({ url: `settings/company/${this.state.expense.companyId}/address` });
        const country: string         = companyAddress.country.toLowerCase();
        const mealCountries: string[] = [
            "suomi",
            "finland",
            "fi",
            "fin"
        ];

        // For the future, if we're going to add other countries.
        const code = mealCountries
            .map((c: string) => c.toLowerCase())
            .find((c: string) => country.toLowerCase().indexOf(c) > -1) !== undefined
                ? "FI"
                : "NOT_FI";

        this.props.onCountryCodeChanged && this.props.onCountryCodeChanged(code);

        return code;
    }

    fetchGoogleDriveAttachments = async (): Promise<any[]> => {
        const { 
            expenseType 
        } = this.props;

        const { 
            googleDrive, 
            expense: { 
                companyId, 
                id 
            } 
        } = this.state;

        if(!googleDrive) {
            return [];
        }

        try {
            const googleDriveData = await DataHandler.post({ url: `drive/google/connect` }, { company: companyId });

            if(!googleDriveData.authenticated) {
                return [];
            }
        } catch (error) {
            console.error(error);
            return [];
        }

        this.setState({ googleDriveAuthorized: true });

        const type = expenseType === "1" ? 'expenses' : 'travel-expenses';
        const googleAttachments = await DataHandler.post({ url: 'drive/google/getattachmentsforexpenses' }, { id, type });

        return googleAttachments.status !== 'no-attachments' && Object.keys(googleAttachments.files).length > 0
            ? googleAttachments.files
            : [];
    }

    fetchAttachments = async (): Promise<any[]> => {
        const { expenseType } = this.props;

        const endPart: string = expenseType === "2" 
            ? "/traveling"
            : "";

        const attachments = await DataHandler.get({ 
            url: `expenses/${this.state.expense.id}/attachments${endPart}` 
        });

        return [
            ...attachments,
            ...(await this.fetchGoogleDriveAttachments())
        ];
    }

    getRates = async (expenseP?: ExpenseDetails): Promise<{ [key: string]: DataSelectEntry[] }> => {
        const expense: ExpenseDetails    = expenseP ?? this.state.expense;
        const companyId: string | number = expense.companyId ?? this.props.company;
        const rates                      = await DataHandler.get({ 
            url: `expenses/rates/${companyId}`, 
            show_deleted: "true", 
            no_year: "1"
        });

        return {
            mileageRates: rates.mileage_rates?.map(rate => mapFieldsWithType<MileageRate>(rate, MILEAGE_RATE_FIELD_MAP)),
            additionalRates: rates.additional_rates?.map(rate => mapFieldsWithType<AdditionalMileageRate>(rate, ADDITIONAL_MILEAGE_RATE_FIELD_MAP)),
            dailyRates: rates.daily_rates?.map(rate => mapFieldsWithType<DailyRate>(rate, DAILY_RATE_FIELD_MAP)),
            ratios: rates.ratios,
        };
    }

    fetchDropdownData = async (): Promise<DropdownData> => {
        const data = await DataHandler.get({  
            url: `expenses/autoCompleteData`,
            added_project_id: this.state.expense.projectId,
            company_id: this.state.expense.companyId
        });

        const numberFn: <T extends DataSelectEntry>(T) => T = <T extends DataSelectEntry>(d: T): T => {
            d.value = Number(d.value);

            return d;
        }

        const rates: { 
            [key: string]: DataSelectEntry[] 
        }                  = await this.getRates();
        const expenseTypes = (await this.fetchExpenseTypes())?.custom_types ?? [];
        const paymentTypes = await DataHandler.get({ url: 'expenses/payment_types', only_own_types: true, expenses_id: this.state.expense.id });
        const currencies   = await this.getCurrencies();
        const jobtypes     = await DataHandler.get({ url: `subjects/nav/worktypes/${this.state.expense.companyId}` });

        const dropdownData: DropdownData = {
            mileageRates: [],
            dailyRates: [],
            additionalRates: [],
            projects: [],
            currencies,
            quotes: [],
            quoteRows: [],
            expenseTypes: expenseTypes.map<ExpenseType>(numberFn),
            jobtypes: jobtypes.map(numberFn),
        };

        const parentIdentifierKeyMap: { [key: string]: string } = {
            projects: "customers_id",
            quotes: "projects_id",
            quoteRows: "quote_id"
        };

        const valueLabelFn = d => {
            d.value = d.id ?? d.value ?? d.name;
            d.label = d.label ?? d.name;
            
            return d;
        };

        const makeFn = (pool) => {
            return (k: string) => {
                pool[k] instanceof Array ? dropdownData[k] = pool[k].map(d => {
                    d.value            = d.id ?? d.value ?? d.name;
                    d.label            = d.label ?? d.name;
                    d.parentIdentifier = d[parentIdentifierKeyMap[k]] ?? undefined;
                    // Facepalm.
                    d.deleted          = parseInt(d.deleted) === 1 
                        || d.deleted === true;

                    return d;
                })
                : dropdownData[k] = [];
            };
        };

        Object.keys(data).forEach(makeFn(data));
        Object.keys(rates).forEach(makeFn(rates));
        Object.keys(paymentTypes).forEach(makeFn(paymentTypes));

        dropdownData.accountingAccounts = (await this.getAccounts()).map(valueLabelFn);
        dropdownData.accountingProducts = (await this.getProducts()).map(valueLabelFn);
        dropdownData.expenseTypes       = expenseTypes
            .map(valueLabelFn)
            .map(et => {
                et.deleted = parseInt(et.deleted) === 1;
                et.vat     = parseFloat(et.vat);

                return et;
            });

        
        dropdownData.quoteRows = data.quote_rows.map((qr): QuoteRow => {
            const q: QuoteRow = {
                ...qr,
                projectId: qr.projects_id,
                quoteId: qr.quote_id,
                parentIdentifier: qr.quote_id,
                headerId: qr.header_id
            };

            return q;
        });

        dropdownData.accountingAccounts.unshift({ id: 0, value: 0, deleted: false, label: this.tr("Not selected") });
        dropdownData.accountingProducts.unshift({ id: 0, value: 0, deleted: false, label: this.tr("Not selected") });
        dropdownData.jobtypes.unshift({ id: 0, value: 0, deleted: false, label: this.tr("Not selected") });
        dropdownData.ratios.unshift({ id: 0, value: 0, deleted: false, label: this.tr("Not selected") });

        this.props.onDropdownDataChanged && this.props.onDropdownDataChanged(dropdownData);

        return dropdownData;
    }

    getCurrencies = async () => {
        const currencies : Currency[] = [];
        const currencyData = await DataHandler.get({ url: `settings/expenses/currencies/${this.state.expense.companyId}` });
        const defaultCurrency = currencyData.company_currency ?? this.context.taimerAccount.currency;

        currencies.push({ id: defaultCurrency, label: defaultCurrency, value: "1,000000", deleted: false });
        currencyData.currency_data.forEach((data) => {
            currencies.push({ id: data.name, label: data.name, value: data.rate, deleted: false });
        });

        return currencies;
    }

    getProducts = (yearP: string | number | undefined = undefined) => {     
        const year = yearP !== undefined 
            ? yearP 
            : (this.state.expense.startDate ? format(this.state.expense.startDate, "YYYY") : format(new Date(), "YYYY"));
        const companyId = this.state.expense.companyId;
        if(!companyId) {
            return;
        }

        return DataHandler.get({ url: `expenses/products/${companyId}/${year}` });
    }

    getAccounts = () => {
        const companyId = this.state.expense.companyId;
        if(!companyId) {
            return;
        }

        return DataHandler.get({ url: `expenses/accounts/${companyId}` })
    }

    mapMileageAllowanceFields = (m): MileageAllowance => {
        const ma: MileageAllowance = mapFieldsWithType<MileageAllowance>(m, MILEAGE_FIELD_MAP);

        ma.isAdditional = Number(m?.is_additional_row) === 1;

        return ma;
    }

    mapDailyAllowanceFields = (d): DailyAllowance => {
        const da: DailyAllowance = mapFieldsWithType<DailyAllowance>(d, DAILY_FIELD_MAP);
        const format: string     = Number(this.context.userObject.clock_format) === 0
            ? "hh:mm A"
            : "HH:mm";

        da.startTime = moment(da.startTime).format(format);
        da.endTime   = moment(da.endTime).format(format);

        return da;
    }

    saveExpenseState = async (n: number) => {
        const type = Number(this.props.expenseType) === 1 
            ? "expense"
            : "travel_expense";

        await DataHandler.put({ url: `expenses/${this.state.expense.id}/${type}/state` }, {
            state: n
        });

        this.refresh();
    }

    mapExpenseFields = (e): Expense => {
        return mapFieldsWithType<Expense>(e, EXPENSE_FIELD_MAP);
    }

    waiting = () => {
        this.saveExpenseState(1); 
    }

    approve = () => {
        this.saveExpenseState(2);
    }

    decline = () => {
        this.saveExpenseState(3);
    }

    archive = () => {
        this.saveExpenseState(4);
    }

    print = async () => {
        const { expenseType } = this.props;
        const { expense: { id } } = this.state;
		const { functions: { setOverlayComponent } } = this.context;

		const module = expenseType == "1" ? "expenses" : "travel_expenses";

		setOverlayComponent(<ExpensePrintPreview module={module} ids={[Number(id)]} companies_id={Number(this.state.expense.companyId)} printMode />);
	}

	send = async () => {
		const { functions: { setOverlayComponent } } = this.context;
        const { expenseType } = this.props;
        const { expense: { id } } = this.state;

		const module = expenseType == "1" ? "expenses" : "travel_expenses";

		setOverlayComponent(<ExpensePrintPreview module={module} ids={[Number(id)]} companies_id={Number(this.state.expense.companyId)} />);
	}

    renderActionButtons = (): JSX.Element | null => {
        const { 
            functions: { 
                checkPrivilege 
            },
            userObject: {
                usersId
            }
        } = this.context;

        const statusMap: {
            [status: number]: string
        } = {
            0: "draft",
            1: "waiting",
            2: "approved",
            3: "declined",
            4: "archived",
        } 

        const { 
            companyId,
            userId,
            state,
            billed,
            partiallyBilled,
            approveAsManager,
            approveAsSuperior
        }: {
            companyId: string | number;
            userId: string | number;
            state: number;
            billed: boolean;
            partiallyBilled: boolean;
            approveAsManager: boolean;
            approveAsSuperior: boolean;
        } = this.state.expense;

        if(!companyId || !userId || state === undefined) {
            return null;
        }

        const statusString: string = statusMap[state];

        const actionMap: {
            [action: string]: React.ReactNode;
        } = {
            approve: <MenuItem key="approve" onClick={this.approve}>
                <CheckIcon />
                {this.tr('Approve')}
            </MenuItem>,
            decline: <MenuItem key="decline" onClick={this.decline}>
                <CloseIcon />
                {this.tr('Decline')}
            </MenuItem>,
            waiting: <MenuItem key="waiting" onClick={this.waiting}>
                <HourglassFullIcon />
                {this.tr('Set to waiting status')}
            </MenuItem>,
            archive: <MenuItem key="archive" onClick={this.archive}>
                <ArchiveIcon />
                {this.tr('Archive')}
            </MenuItem>,
            print: <MenuItem key="print" onClick={this.print}>
                <PrintIcon />
                {this.tr('Print')}
            </MenuItem>,
            email: <MenuItem key="email" onClick={this.send}>
                <SendIcon />
                {this.tr('Send by email')}
            </MenuItem>
        };

        const statusActions: {
            [status: string]: string[]
        } = {
            draft: ["waiting", "print", "email"],
            waiting: !billed 
                ? ["approve", "decline", "print", "email"]
                : ["archive", "approve", "print", "email"],
            approved: !billed && !partiallyBilled
                ? ["archive", "decline", "waiting", "print", "email"]
                : ["archive", "print", "email"],
            declined: !billed
                ? ["approve", "waiting", "print", "email"]
                : ["archive", "approve", "print", "email"],
            archived: ["print", "email"],
        }

        const hasPrivilege: boolean = checkPrivilege("worktrips", "approve", companyId)
            || checkPrivilege("worktrips", "modify_all", companyId) || approveAsSuperior || approveAsManager;

        const actionAllowed: {
            [status: string]: boolean;
        } = {
            approve: hasPrivilege && [0, 2, 4].indexOf(state) === -1,
            decline: hasPrivilege && [0, 3, 4].indexOf(state) === -1,
            waiting: (usersId === userId && [0, 3].indexOf(state) > -1) || hasPrivilege,
            archive: hasPrivilege,
            print: true,
            email: true,
        };

        return (
            <div className={cardStyles.actionButtons}>
                <ContextMenu
                    //@ts-ignore
                    className={cardStyles.optionsMenu}
                    buttonProps={{
                        color: 'green',
                        stickyIcon: true,
                        size: 'large',
                    }}
                    variant="outlined"
                    label={this.tr('Actions')}
                    size="medium"
                    // menuOpenCallback={() => {}}
                    placement={'bottom-end'}>
                        {Object.keys(actionMap)
                            .filter((action: string): boolean => {
                                return statusActions[statusString].indexOf(action) > -1
                                    && actionAllowed[action];
                            })
                            .map((action: string): React.ReactNode => {
                                return actionMap[action];
                            })}
                </ContextMenu>
            </div>
        );
    }

    getHeaderTitles = () => {
        return this.props.expenseType === "1" 
            ? [this.tr("Purchase expense")]
            : [this.tr("Travel expense")];
    }

    showDetailsInHeaderTitle = (bool = true) => {
        const typeTitle: string = this.props.expenseType === "1" 
            ? this.tr("Purchase expense")
            : this.tr("Travel expense");

        this.context.functions.setOverrideHeaderTitles(
            bool
                ? [{
                    label: `${typeTitle} #${this.props.id}`,
                    sublabel: `${this.state?.expense?.userFullName}`
                }]
                : undefined
        );
    }

    handleDetailsScroll = (scroll: number) => {
        this.showDetailsInHeaderTitle(scroll >= 80);
    }

    // TODO: Conditionally set fields that don't exist in a pe.
    // fetchExpense = async (): Promise<ExpenseDetails> => {
    protected async fetchExpense(): Promise<ExpenseDetails> {
        const type = Number(this.props.expenseType) === 1 
            ? "purchase_expense"
            : "traveling_expense";

        // TODO:
        // If the response is NO_PERM, show the no permissions view.
        const url = `expenses/${this.props.id}/${type}`; 

        let expense, data;

        try {
            const r = await DataHandler.get({ url });

            expense = r.expense;
            data    = r.data;
        } catch(e: any) {
            throw Error();
        }

        const uses24hourClock: boolean = Number(this.context.userObject.clock_format) === 1;

        const exp: ExpenseDetails = {
            id: expense.id,
            companyName: expense.company_name,
            accountId: expense.customers_id,
            accountName: expense.customer_name,
            projectId: expense.projects_id,
            projectNumber: expense.project_number,
            projectName: expense.project_name,
            quoteId: expense.costestimate_id,
            quoteRowId: expense.costestimate_row_id,
            description: expense.description,
            route: expense.route,
            billCustomer: expense.bill_customer,
            userId: expense.users_id,
            companyId: expense.companies_id,
            userFullName: expense.fullname,
            startDate: expense?.startdate ?? "",
            endDate: expense?.enddate ?? "",
            startTime: uses24hourClock 
                ? expense?.starttime ?? "" 
                : to12HourTime(expense?.starttime ?? ""),
            endTime: uses24hourClock 
                ? expense?.endtime ?? "" 
                : to12HourTime(expense?.endtime ?? ""),
            expenseDate: expense?.expense_date ?? "",
            state: expense.state,
            billed: expense.billed,
            partiallyBilled: expense.partially_billed,
            extendedCostTargeting: expense.extended_cost_targeting,
            atLeastOneRowInvoiced: expense.at_least_one_invoiced_row,
            currency: expense.currency_label,
            currencyRate: expense.currency_rate,
            companyCurrency: expense?.company_currency,
            paymentTypeId: expense?.payment_type_id,
            isBillable: expense.is_billable,
            approveAsSuperior: expense.approveAsSuperior,
            approveAsManager: expense.approveAsManager,
            notInCompanyCurrency: expense.notInCompanyCurrency,
            invoiceabilityStatus: expense.invoiceability_status,
            companyTotalWithoutVat: expense.company_total_without_vat,
            companyTotal: expense.company_total_with_vat,
            companyVat: expense.company_vat
        };

        if(type === "traveling_expense") {
            exp.mileageRows = data.mileage_rows.map(o => this.mapMileageAllowanceFields(o));
            exp.dailyRows   = data.daily_rows.map(o => this.mapDailyAllowanceFields(o));
        }

        exp.expenseRows = data.expense_rows.map(o => this.mapExpenseFields(o));

        return exp;
    }

    fetchStateLog = async (): Promise<any> => {
        const type = Number(this.props.expenseType) === 1 
            ? "purchase_expense"
            : "travel_expense";

        return await DataHandler.get({ 
            url: `expenses/${type}/${this.props.id}/history`, 
            timezone_offset: (new Date()).getTimezoneOffset() 
        });
    }

    saveDetails = async (details: ExpenseDetails) => {
        await this._setState({
            expense: details
        });

        this.saveExpense(details);
    }

    refresh = (useTimeout = true) => {
        const fn = async() => {
            this.setState({ 
                expense: await this.fetchExpense(),
                attachments: await this.fetchAttachments(),
                stateLog: await this.fetchStateLog()
            }); 
        }

        useTimeout
            ? setTimeout(fn, 300)
            : fn();
    }

    saveExpense = async (details: ExpenseDetails) => {
        // TODO:
        // if (this.props.demoMode) return;

        const type: string = this.props.expenseType === "1" 
            ? "purchase_expense" 
            : "traveling_expense";

        details.startTime = to24HourTime(details.startTime ?? "");
        details.endTime   = to24HourTime(details.endTime ?? "");

        const mappedDetails = Utils.translateFields(details, {
            projectId: "projects_id",
            startDate: "startdate",
            startTime: "starttime",
            endDate: "enddate",
            endTime: "endtime",
            expenseDate: "expense_date",
            quoteRowId: "costestimate_row_id",
            billCustomer: "billing",
            currency: "currency_label",
            currencyRate: "currency_rate",
            paymentTypeId: "payment_type_id"
        });

        await DataHandler.post({ url: `expenses/${type}` }, mappedDetails);

        this.refresh();
    }

    getDropDownData = () => {
        return this.state.dropdownData;
    }

    getSaveRows = (type, rows) => {
        const saveRows = (rows || []).map(r => {
            return this.detailsTab.current?.getSaveRow(type, r) || r
        });

        return saveRows;
    }

    getAttachments = () => {
        return this.state.attachments;
    }

    onTotalsChange = (totals) => {
        this.props.onTotalsChange && this.props.onTotalsChange(totals);
    }

    getExpenseDetailsTabProps = (): ExpenseDetailsTabProps => {
        const {
            expense,
            dropdownData,
            attachments,
            stateLog,
            countryCode,
            initDone
        }: {
            expense: ExpenseDetails;
            dropdownData: DropdownData;
            attachments: any[];
            stateLog: any[];
            countryCode: string;
            initDone: boolean;
        } = this.state;
    
        return {
            initialFetchDone: initDone,
            data: expense,
            dropdownData: dropdownData,
            miscData: {
                netvisorRate: Number(dropdownData
                    .additionalRates
                    .filter((ar: AdditionalMileageRate) => ar.startDate <= (expense?.startDate ?? "0000-00-00") 
                        && (expense?.startDate ?? "0000-00-00") <= ar.endDate)
                    .filter((ar: AdditionalMileageRate) => !ar.deleted)
                    .filter((ar: AdditionalMileageRate) => Number(ar.type) === 1)?.[0]?.rate ?? 0)
            },
            stateLog: stateLog,
            onDetailsEdit: this.saveDetails,
            refresh: this.refresh,
            countryCode: countryCode,
            attachments: attachments,
            expenseType: this.props.expenseType === "1" ? "pe" : "te",
            enqueueSnackbar: this.props.enqueueSnackbar,
            onDetailsScroll: this.handleDetailsScroll,
            onTotalsChange: this.onTotalsChange,
        };
    }

    userCanView = (): boolean => {
        const { 
            checkPrivilege,
        }: {
            checkPrivilege: (m: string, p: string, c: string | number) => boolean
        } = this.context.functions;

        const { userObject } = this.context;
        const {
            expense,
            noPermission
        }: {
            expense: ExpenseDetails;
            noPermission: boolean;
        } = this.state;
        
        // If the expense is the user's own expense
        // or the user has any one of the relevant
        // privileges regarding expenses.
        // The write privilege isn't checked, since
        // users can always see their own expenses,
        // regardless of whether they have the privileges
        // to add new expenses.
        return !noPermission
            && (
                userObject.usersId === expense.userId
                || checkPrivilege("worktrips", "modify_all", expense.companyId)
                || checkPrivilege("worktrips", "approve", expense.companyId)
                || expense.approveAsManager
                || expense.approveAsSuperior
            );
    }

    render() {
        if(!this.state.expenseLoaded) {
            return null;
        }

        const props: ExpenseDetailsTabProps = this.getExpenseDetailsTabProps();

        const ExpenseTab = <ExpenseDetailsTab 
            {...props}
            ref={this.detailsTab}
        />;

        return this.userCanView() 
            ? (
                <div className="taimer-view" id="user-view">
                    <WithTabs 
                        height={55} 
                        tabs={this.tabs} 
                        selectedTab={this.state.selectedTab} 
                        rightComponent={this.renderActionButtons()}
                        tabsAlwaysVisible>
                        {(selectedTab) => {
                            switch(selectedTab) {
                                case 'details':
                                    return ExpenseTab;
                            }
                        }}
                    </WithTabs>
                    {/* userDialogData && userDialogData.open && <UserDialog {...userDialogData} />*/}
                </div>
            )
            : <NoPermissionOverlay />;
    }
}

class ExpenseViewWizardMode extends ExpenseView {
    componentDidUpdate = (prevProps: ExpenseViewProps, prevState: ExpenseViewState): void => {
        if(!isEqual(prevProps.attachments, this.props.attachments) 
           && this.props.attachments !== undefined) {
               this.setState({ 
                   attachments: this.props.attachments 
               });
        }
        if (!isEqual(prevProps.dropdownData, this.props.dropdownData) && this.props.dropdownData !== undefined) {
            this.setState({
                dropdownData: this.props.dropdownData
            });
        }
    }

    fetchAttachments = async (): Promise<any[]> => {
        return this.state.attachments;
    }

    protected async fetchExpense(): Promise<ExpenseDetails> {
        if (Number(this.props.id) > 0) {
            const expense = await super.fetchExpense();
            const expenseCompanyId = expense.companyId;
            if (expenseCompanyId != this.context.userObject.companies_id) { // Expense is created from draft of other company's expense, 
                expense.companyId   = this.context.userObject.companies_id; // Change company here so it is used when fetching dropdowndata.
                expense.companyName = this.context.userObject.company_name;
            }
            this.props.onExpenseFetched && this.props.onExpenseFetched(expense, expenseCompanyId);
            return expense;
        }
        return this.props?.expenseDetails ?? super.fetchExpense();
    }

    fetchStateLog = async (): Promise<any> => {
        return null;
    }

    saveDetails = async (details: ExpenseDetails) => {
        const { 
            onDetailsEdit
        } = this.props;

        await this._setState({
            expense: details
        });

        onDetailsEdit(details);
    }

    handleTemporaryAttachmentsChange = (attachments: TemporaryAttachmentsBundle) => {
        if(!this.props.onTemporaryAttachmentsChange) {
            return;
        }

        this.props.onTemporaryAttachmentsChange(attachments);
    }

    neededRatesExist = (expense: ExpenseDetails, dropdownData: DropdownData): boolean => {
        return this.detailsTab.current?.neededRatesExist(expense, dropdownData) || false;
    }

    getEligibleRates = <T extends (MileageRate | DailyRate)>(
        expense: ExpenseDetails,
        pool: T[], 
        includeId: number | undefined = undefined
    ): T[] => {
        return this.detailsTab.current?.getEligibleRates(expense, pool, includeId) || [];
    }

    render() {
        const props: ExpenseDetailsTabProps = this.getExpenseDetailsTabProps();

         return <ExpenseDetailsTabWizardMode
             {...props}
             ref={this.detailsTab}
             onTemporaryAttachmentsChange={this.handleTemporaryAttachmentsChange}
             temporaryAttachmentsBundle={this.props.temporaryAttachmentsBundle}
         />;
    }
}

class ExpenseViewPreviewMode extends ExpenseViewWizardMode {
    render() {
        const props: ExpenseDetailsTabProps = this.getExpenseDetailsTabProps();

         return <ExpenseDetailsTabPreviewMode
             {...props}
             ref={this.detailsTab}
             temporaryAttachmentsBundle={this.props.temporaryAttachmentsBundle}
         />;
    }
}

class ExpenseViewNoRender extends ExpenseViewWizardMode {
    render() {
        const props: ExpenseDetailsTabProps = this.getExpenseDetailsTabProps();

         return <ExpenseDetailsTabNoRenderMode
             {...props}
             ref={this.detailsTab}
             temporaryAttachmentsBundle={this.props.temporaryAttachmentsBundle}
         />;
    }
}

const ExpenseViewWizardModeWithSnackbar  = withSnackbar(ExpenseViewWizardMode);
const ExpenseViewPreviewModeWithSnackbar = withSnackbar(ExpenseViewPreviewMode);

export default withSnackbar(ExpenseView);
export {
    type ExpenseDetails,
    type MileageAllowance,
    type DailyAllowance,
    type DailyAllowanceMeal,
    type Expense,
    type ExpenseType,
    type DataSelectEntry,
    type DropdownData,
    type MiscData,
    type DailyRate,
    type MileageRate,
    type TemporaryAttachmentsBundle,
    type Project,
    type QuoteRow,
    ExpenseFile,
    MILEAGE_FIELD_MAP,
    DAILY_FIELD_MAP,
    EXPENSE_FIELD_MAP,
    ExpenseViewWizardModeWithSnackbar as ExpenseViewWizardMode,
    ExpenseViewPreviewModeWithSnackbar as ExpenseViewPreviewMode,
    ExpenseViewNoRender,
};
