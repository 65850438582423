import React from 'react';
import JoditEditor from 'jodit-react';
import { isEqual } from 'lodash';
import Delete from '@mui/icons-material/Delete';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { ProposalContentSection, ProposalContentBlock, ProposalContentBlockOptions } from '../types';
import TaimerComponent from '../../../TaimerComponent';
import AddContentButton from '../AddContentButton';
import styles from '../ProposalEditor.module.css';
import DataHandler from '../../../general/DataHandler';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

interface BlockProps {
    block: any;
    onChange: (value: any) => void;
    blockIndex: number;
    readMode: boolean;
}

interface ImageBlockProps extends BlockProps, WithSnackbarProps {
    quoteId: string;
}

class TextBlock extends React.Component<BlockProps> {
    shouldComponentUpdate = (oldProps: BlockProps) => {
        return oldProps.block.content != this.props.block.content || oldProps.blockIndex != this.props.blockIndex;
    };
    render() {
        const { block, onChange, readMode } = this.props;
        const { content } = block;
        const editorButtons = ['paragraph', 'font', 'fontsize', '|', 'bold', 'strikethrough', 'underline', 'italic', '|', 'ul', 'ol', '|', 'indent', 'outdent', 'align', 'undo', 'redo'];
        const editorConfig = {
            spellcheck: false,
            buttons: editorButtons,
            buttonsMD: editorButtons,
            buttonsSM: editorButtons,
            buttonsXS: editorButtons,
            showPlaceholder: false,
            showTooltip: true,
            globalFullsize: false,
            spellCheck: false,
            enableDragAndDropFileToEditor: false,
            askBeforePasteFromWord: false,
            askBeforePasteHTML: false,
            disabled: readMode,
        };
        return (
            <div className={styles.textBlock}>
                <JoditEditor config={editorConfig} onBlur={onChange} value={content} />
            </div>
        );
    }
}

interface ImageBlockState {
    loadedImage?: any;
    loadingImage: boolean;
}
class _ImageBlock extends TaimerComponent<ImageBlockProps, ImageBlockState> {
    _upload: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props, context) {
        super(props, context, 'projects/proposal/sections/ImageBlock');
        this.state = {
            loadedImage: undefined,
            loadingImage: false,
        };
    }

    componentDidMount = () => {
        this._getImageFile();
    };

    componentDidUpdate = (oldProps) => {
        if (!isEqual(oldProps.block.content, this.props.block.content)) {
            this._getImageFile();
        }
    };

    _getImageFile = () => {
        const { block, quoteId } = this.props;
        if (block.content?.filename) {
            this.setState({ loadingImage: true }, () => {
                DataHandler.get({ url: `projects/proposals/${quoteId}/attachments`, filename: block.content?.filename })
                    .then((response) => {
                        const loadedImage = response.attachment;
                        this.setState({ loadedImage, loadingImage: false });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ loadingImage: false });
                    });
            });
        }
    };

    shouldComponentUpdate = (oldProps: ImageBlockProps, oldState) => {
        return (
            oldProps.block.content != this.props.block.content ||
            oldProps.blockIndex != this.props.blockIndex ||
            oldState.loadedImage != this.state.loadedImage ||
            oldState.loadingImage != this.state.loadingImage
        );
    };

    _onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    };

    _uploadFile = (e) => {
        const { block, quoteId, enqueueSnackbar } = this.props;
        const {
            taimerAccount: { attachmentMaxSize },
        } = this.context;
        if (block.content) return;
        e.stopPropagation();
        e.preventDefault();
        const file = (e.target.files || e.dataTransfer.files)[0];
        if (!file) return;
        if (file.size > attachmentMaxSize) {
            enqueueSnackbar(this.tr('Selected file is too large'), {
                variant: 'error',
            });
            return;
        }
        this.setState({ loadingImage: true }, () => {
            this.props.onChange(URL.createObjectURL(file));
            DataHandler.file({ url: `projects/proposals/${quoteId}/attachments` }, file, undefined, (e) => {
                if (e.responseJSON === false) {
                    enqueueSnackbar(this.tr('Uploading file failed'), {
                        variant: 'error',
                    });
                    this.props.onChange(undefined);
                    this.setState({ loadingImage: false });
                    return;
                }
                setTimeout(() => {
                    const filename = e.responseJSON.filename;
                    this.props.onChange({ filename, localFile: URL.createObjectURL(file) });
                }, 1000);
            });
        });
    };

    _onUploadClick = () => {
        this._upload.current && this._upload.current.click();
    };

    render() {
        const { block, readMode } = this.props;
        const { loadedImage, loadingImage } = this.state;
        return (
            <div onDragOver={this._onDragOver} onDrop={this._uploadFile} className={`${styles.imageBlock} ${loadingImage ? styles.loading : ''} ${!block.content && styles.dashedBorder}`}>
                {block.content ? (
                    <img src={block.content.filename ? loadedImage || block.content.localFile : block.content} />
                ) : (
                    <>
                        {' '}
                        {!readMode && (
                            <p>
                                {this.tr('Drag your image here or')}{' '}
                                <span className={styles.upload} onClick={this._onUploadClick}>
                                    {this.tr('click here to upload')}
                                </span>
                                .
                            </p>
                        )}
                        {!readMode && <input ref={this._upload} onChange={this._uploadFile} type="file" accept="image/png,image/jpeg" />}{' '}
                    </>
                )}
                {loadingImage && <Loading className={styles.loader} />}
            </div>
        );
    }
}

const ImageBlock = withSnackbar<ImageBlockProps>(_ImageBlock);

interface BlankSectionProps {
    section: ProposalContentSection;
    contentBlockOptions: ProposalContentBlockOptions;
    blockIcons: any;
    addNewBlock: (index: number, type: string, sectionIndex?: number) => void;
    onBlockEdit: (sectionIndex: number, blockIndex: number, value: any) => void;
    onBlockDelete: (sectionIndex: number, blockIndex: number) => void;
    onMoveBlockUp: (sectionIndex: number, blockIndex: number) => void;
    onMoveBlockDown: (sectionIndex: number, blockIndex: number) => void;
    sectionIndex: number;
    quoteId: string;
    readMode: boolean;
}

export default class BlankSection extends TaimerComponent<BlankSectionProps> {
    constructor(props, context) {
        super(props, context, 'projects/proposal/sections/BlankSection');
    }

    shouldComponentUpdate = (oldProps: BlankSectionProps) => {
        return !isEqual(oldProps.section.content, this.props.section.content) || oldProps.sectionIndex != this.props.sectionIndex;
    };

    _renderContentForBlock = (block: ProposalContentBlock, blockIndex: number) => {
        const { section, contentBlockOptions, blockIcons, addNewBlock, onBlockEdit, onBlockDelete, onMoveBlockDown, onMoveBlockUp, quoteId, readMode } = this.props;
        let blockComponent: any = null;
        switch (block.type) {
            case 'text':
                blockComponent = <TextBlock readMode={readMode} blockIndex={blockIndex} block={block} onChange={(value) => onBlockEdit(this.props.sectionIndex, blockIndex, value)} />;
                break;
            case 'image':
                blockComponent = <ImageBlock readMode={readMode} quoteId={quoteId} blockIndex={blockIndex} block={block} onChange={(value) => onBlockEdit(this.props.sectionIndex, blockIndex, value)} />;
                break;
            case 'separator':
                blockComponent = <div className={styles.separator} />;
                break;
        }

        return (
            <div key={block.key} className={`${styles.block} ${block.type == 'image' ? styles.noPageBreak : ''}`}>
                <div className={styles.blockTop}>
                    {!readMode && (
                        <div className={styles.reorder}>
                            <button disabled={blockIndex == 0} onClick={() => onMoveBlockUp(this.props.sectionIndex, blockIndex)}>
                                <KeyboardArrowUp />
                            </button>
                            <button disabled={blockIndex == section.content.length - 1} onClick={() => onMoveBlockDown(this.props.sectionIndex, blockIndex)}>
                                <KeyboardArrowDown />
                            </button>
                        </div>
                    )}
                    {!readMode && (
                        <button className={styles.deleteButton} onClick={() => onBlockDelete(this.props.sectionIndex, blockIndex)}>
                            <Delete />
                        </button>
                    )}
                </div>
                {blockComponent}
                {!readMode && (
                    <AddContentButton
                        icons={blockIcons}
                        items={Object.values(contentBlockOptions)}
                        sectionIndex={this.props.sectionIndex}
                        index={blockIndex}
                        title={this.tr('Add new block')}
                        onItemClick={addNewBlock}
                    />
                )}
            </div>
        );
    };

    render() {
        const {
            section: { content },
            sectionIndex,
            contentBlockOptions,
            addNewBlock,
            blockIcons,
            readMode,
        } = this.props;

        return (
            <div className={styles.page}>
                <div className={styles.blankPage}>
                    {
                    // this whole table thing is just to get padding on printed blank pages' top and bottom 
                    // (with an empty header and footer)
                    }
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {!readMode && (
                                        <AddContentButton
                                            icons={blockIcons}
                                            items={Object.values(contentBlockOptions)}
                                            sectionIndex={sectionIndex}
                                            index={-1}
                                            title={this.tr('Add new block')}
                                            onItemClick={addNewBlock}
                                        />
                                    )}
                                    {content.map((c, i) => {
                                        return this._renderContentForBlock(c, i);
                                    })}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        );
    }
}
