import Close from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { Button, Tooltip } from '@mui/material';
import { ReactElement } from 'react';
import TaimerComponent from '../TaimerComponent';
import styles from './TaimerWizard.module.scss';
import stepIndicatorStyles from './StepIndicator.module.scss';
import WizardPageContentRenderer from './WizardPageContentRenderer';
import LoaderButton from './LoaderButton';

interface StepIndicatorProps {
    steps: {
        title?: string;
    }[];
    currentStep: number;
    onStepClicked: (step: number) => void;
}

const StepIndicator = (props: StepIndicatorProps) => {
    const { steps, currentStep, onStepClicked } = props;
    return (
        <div className={stepIndicatorStyles.stepIndicator}>
            {steps.map((step, i) => (
                <Tooltip title={step.title || ''}>
                    <div className={`${stepIndicatorStyles.step} ${currentStep == i + 1 ? stepIndicatorStyles.current : currentStep >= i + 1 ? stepIndicatorStyles.completed : ''}`} />
                </Tooltip>
            ))}
        </div>
    );
};

export interface TaimerWizardAction {
    label: string;
    action: 'next' | 'close' | (() => Promise<'next' | 'close' | void> | void) | (() => 'next' | 'close' | void);
    moveToNextWithAction?: boolean;
    setLoading?: boolean;
    disabled?: boolean | (() => boolean);
    className?: string;
    visible?: () => boolean | boolean;
    name?: string;
}

interface DropdownItem {
    value: string | number;
    label: string;
}

export interface TaimerWizardField {
    key: string;
    label?: string;
    type?: 'select' | 'text' | 'textarea' | 'optionalFields';
    options?: DropdownItem[];
    useOnKeyUp?: boolean;
    onFocus?: () => void;
    component?: ReactElement;
    fields?: TaimerWizardField[];
    required?: boolean;
    placeholder?: string;
    getSelectValue?: (item: any, options: DropdownItem[]) => object;
}

export type WizardPageContentObject = {
    header?: string;
    subheader?: string | ReactElement;
    subtitle?: any;
    description?: any;
    image?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }> | JSX.Element;
    animation?: any;
    fields?: TaimerWizardField[];
    action?: TaimerWizardAction;
    secondaryAction?: TaimerWizardAction;
    customContent?: () => ReactElement;
    stepParam?: string;
    showCloseButton?: boolean;
    showBackButton?: boolean;
    backgroundColor?: string;
    textColor?: string;
    showTestModeIndicator?: boolean;
    alignVertical?: 'center' | 'top';
    enableEnter?: boolean;
};

export type TaimerWizardPageContent = WizardPageContentObject | ReactElement | ((page: number) => ReactElement | WizardPageContentObject | null);

export interface TaimerWizardPage {
    id?: string;
    actions?: TaimerWizardAction[];
    content: {
        main: TaimerWizardPageContent;
        right?: TaimerWizardPageContent;
        left?: TaimerWizardPageContent;
    };
    title?: string;
    stepParam?: string;
    layout?:
        | {
              left?: {
                  width: number;
              };
              right?: {
                  width: number;
              };
          }
        | 'even'
        | 'default';
    noReturn?: boolean;
    noClose?: boolean;
    mainContentStyle?: | "fullWidth";
}

interface TaimerWizardProps {
    title?: string;
    hideTopBar?: boolean;
    hideStepIndicator?: boolean;
    pages: TaimerWizardPage[];
    updateTriggers?: object;
    initialItem?: any;
    onItemChanged?: (item: any) => void;
    onClose?: (currentPage: number) => void;
    beforeClose?: (onClose: () => void) => void;
    onPageChanged?: (currentPage: number) => void;
    showCloseConfirmation?: () => boolean;
    hideMainWhenSmall?: boolean;
    hideRight?: boolean;
    disableDefaultCloseConfirmation?: boolean;
}

interface TaimerWizardState {
    currentPage: number;
    item: any;
    loading?: number | boolean;
}

class TaimerWizard extends TaimerComponent<TaimerWizardProps, TaimerWizardState> {
    constructor(props, context) {
        super(props, context, 'general/TaimerWizard');
        this.state = {
            currentPage: 1,
            item: this.props.initialItem || {},
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.setStepParam();
    };

    componentDidUpdate = (_, oldState: TaimerWizardState) => {
        if (oldState.currentPage != this.state.currentPage) {
            this.setStepParam();
            this.setState({ loading: undefined });
        }
    };

    getCurrentPage = () => {
        return this.state.currentPage;
    };

    onStepClicked = (step) => {
        this.moveToPage(step);
    };

    setItem = (item) => {
        this.setState({ item });
    };

    onPageFieldChanged = (key, value) => {
        this.setState(
            {
                item: {
                    ...this.state.item,
                    [key]: value,
                },
            },
            () => {
                this.props.onItemChanged && this.props.onItemChanged(this.state.item);
            }
        );
    };

    setStepParam = () => {
        const page = (this.props.pages || [])[this.state.currentPage - 1];
        this.context.functions.updateView({ step: page?.stepParam, replace: true }, false, true, false, true);
    };

    nextPage = () => {
        const { pages } = this.props;
        const nextPage = Math.min(pages?.length || 0, this.state.currentPage + 1);
        this.moveToPage(nextPage);
    };

    previousPage = () => {
        const previousPage = Math.max(1, this.state.currentPage - 1);
        this.moveToPage(previousPage);
    };

    moveToPage = (currentPage) => {
        this.setState({ currentPage }, () => {
            this.props.onPageChanged && this.props.onPageChanged(currentPage);
        });
    };

    commitPageAction = async (action: TaimerWizardAction) => {
        if (action.action == 'next') {
            this.nextPage();
            return;
        }
        if (action.action == 'close') {
            this.close();
            return;
        }
        if (action.moveToNextWithAction) {
            this.nextPage();
        }
        const returnValue = await action.action();
        if (returnValue == 'next') {
            this.nextPage();
        } else if (returnValue == 'close') {
            this.close();
        }
    };

    handlePageAction = async (action: TaimerWizardAction, index?: number) => {
        if (action.setLoading) {
            this.setState({ loading: index == undefined ? true : index }, () => {
                this.commitPageAction(action);
            });
        } else {
            this.commitPageAction(action);
        }
    };

    close = (confirm = false) => {
        if (confirm && this.shouldConfirmClose()) {
            this.confirmClose();
            return;
        }
        if (this.props.beforeClose) {
            this.props.beforeClose(this.handleClose);
            return;
        }
        this.handleClose();
    };

    handleClose = () => {
        this.context.functions.setOverlayComponent(undefined);
        this.context.functions.updateView({ step: undefined, replace: true }, false, true, false, true);
        this.props.onClose && this.props.onClose(this.state.currentPage);
    };

    shouldConfirmClose = () => {
        const { currentPage } = this.state;
        const { pages, disableDefaultCloseConfirmation, showCloseConfirmation } = this.props;
        const confirmClose = currentPage != 1 && currentPage != (pages || []).length; // Not in first or last page.
        
        return (!disableDefaultCloseConfirmation && confirmClose) || (showCloseConfirmation && showCloseConfirmation());
    }

    confirmClose = () => {
        this.context.functions.showConfirmationDialog({
            header: this.tr('Close wizard?'),
            warning: this.tr("Are you sure you want to close the wizard? All unsaved changes will be lost."),
            confirmText: this.tr('Close'),
            onConfirm: () => {
                this.handleClose();
            },
        });
    }

    renderPageActions = (page: TaimerWizardPage) => {
        if (!page) return null;
        const { actions } = page;
        if (!actions) return null;
        const { loading } = this.state;
        return actions
            .filter((a) => a.visible == undefined || (typeof a.visible == 'function' ? a.visible() : a.visible))
            .map((action, i) => (
                <LoaderButton
                    data-testid={`action_button_${action.name}`}
                    key={i}
                    disabled={typeof action.disabled == 'function' ? action.disabled() : action.disabled}
                    loading={action.setLoading && (loading === true || loading === i) ? true : false}
                    size="large"
                    onClick={() => this.handlePageAction(action, i)}
                    text={action.label}
                    className={action.className}
                />
            ));
    };

    renderContentBlock = (content: TaimerWizardPageContent) => {
        const { currentPage } = this.state;
        const { updateTriggers } = this.props;
        let renderable: any = content;
        if (typeof content == 'function') {
            renderable = content(currentPage);
        }
        if (!renderable) return renderable;
        if (typeof renderable == 'object' && !renderable.$$typeof) {
            const casted = renderable as WizardPageContentObject;
            renderable = (
                <WizardPageContentRenderer
                    item={this.state.item}
                    content={casted}
                    onAction={this.handlePageAction}
                    onFieldChanged={this.onPageFieldChanged}
                    onClose={this.close}
                    onBack={this.previousPage}
                    showBackButton={currentPage > 1 && casted.showBackButton}
                    showCloseButton={casted.showCloseButton}
                    updateTriggers={updateTriggers}
                    tr={this.tr}
                />
            );
        }
        return renderable;
    };

    renderPageContent = (page: TaimerWizardPage) => {
        if (!page.content) return null;
        const { layout } = page;
        const layoutString = typeof layout == 'string' ? layout : '';
        const layoutObject = typeof layout == 'object' ? layout : {};
        return (
            <div className={`${styles.content} ${styles[layoutString]}`}>
                {page.content.left && (
                    <div className={`${styles.secondary} ${styles.left}`} style={{ width: layoutObject.left?.width }}>
                        {this.renderContentBlock(page.content.left)}
                    </div>
                )} 

                <div className={`${styles.main} ${page.mainContentStyle == "fullWidth" ? styles.fullWidth : ""}`}>{this.renderContentBlock(page.content.main)}</div>
                {page.content.right && !this.props.hideRight && (
                    <div className={`${styles.secondary} ${styles.right}`} style={{ width: layoutObject.right?.width }}>
                        {this.renderContentBlock(page.content.right)}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { title, pages, hideTopBar, hideStepIndicator, hideMainWhenSmall } = this.props;
        const { currentPage, loading } = this.state;
        const page = pages[currentPage - 1];
        return (
            <div id={styles.taimerWizard} className={`${loading ? styles.loading : ''} ${hideMainWhenSmall ? styles.hideMainWhenSmall : ''}`}>
                {!hideTopBar && (
                    <div className={styles.topBar}>
                        {!page.noClose && (
                            <Button className={styles.closeButton} onClick={() => this.close(true)}>
                                {<Close />}
                            </Button>
                        )}
                        <div className={styles.center}>
                            {currentPage > 1 && !page.noReturn && (
                                <Button onClick={this.previousPage}>
                                    <KeyboardArrowLeft />
                                </Button>
                            )}
                            <div className={styles.title}>
                                <h1>{title}</h1>
                                <p>{`${pages.length > 1 ? `${currentPage}/${pages.length} ` : ''}${page.title}`}</p>
                            </div>
                            {/* {currentPage < pages.length && (
                            <Button>
                                <KeyboardArrowRight />
                            </Button>
                        )} */}
                        </div>
                        <div className={styles.actions}>{this.renderPageActions(page)}</div>
                    </div>
                )}
                {!hideStepIndicator && pages.length > 1 && <StepIndicator steps={pages} currentStep={currentPage} onStepClicked={this.onStepClicked} />}
                {this.renderPageContent(page)}
            </div>
        );
    }
}

export default TaimerWizard;
