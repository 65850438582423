import React from 'react';
import TaimerComponent from '../TaimerComponent';
import NewsFeedComments from './NewsFeedComments';
import moment from 'moment/min/moment-with-locales';
import { renderWithEmoji } from '../helpers';
import DataHandler from './../general/DataHandler';
import SendMessage from './SendMessage';
import TaimerAvatar from '../general/TaimerAvatar';
import $ from 'jquery';
import { Modal } from '@mui/material';
import { default as Saving } from '@mui/icons-material/AccessTime';
import _ from 'lodash';

export default class NewsFeedMessage extends TaimerComponent {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            pendingMessages: [],
        }

        this.newMessageID = -1;
    }

    sendMessage = async (message, attachments) => {
        const { userObject } = this.context;

        const id = this.newMessageID--;

        this.setState( (state) => ({pendingMessages: [...state.pendingMessages, {
            pending: true,
            type: '0',
            id,
            comment: message,
            timestamp: moment(),
            creator: userObject.fullname,
            creator_id: userObject.usersId,
            creator_color: userObject.color,
            attachments_id: 0,
            comments: [],
        }]}));

        try {
            const realid = await this.onSendMessage(message, attachments);

            this.setState((state) => {
                const index = state.pendingMessages.findIndex(m => m.id == id);

                if (index !== -1) {

                    const pd = [...state.pendingMessages];

                    pd[index] = { ...pd[index], id: realid };

                    return {
                        pendingMessages: pd,
                    }
                }
                
                return {};
            });

            return realid;
        } catch (error) {
            return false;
        }
    }

    onSendMessage = async (message, attachments = []) => {
        const { msg, update } = this.props;

        const form = new FormData();
        form.append("message", message);

        if (attachments.length === 1)
            form.append("attachment", attachments[0].file);

        const id = await DataHandler.file({url: `teamchat/news/${msg.id}`}, form);

        update && update();

        return id;
    }

    render() {
        const { msg, msg: { attachments_id, timestamp, comments, creator_color, creator, creator_id } } = this.props;
        const { pendingMessages } = this.state;

        if (msg.type !== "0")
            return null;

        let attach = null;
        
        const params = {
            id: attachments_id,
            attachment: "newsfeed",
            is_comment: 'false',
            _auth: this.context.functions.getStorage().taimerToken,
        };
 
        const attachUrl = attachments_id && this.context.functions.getDownloadPath() + $.param(params);
 
        const openModal = (e) => { 
            e.preventDefault();
            this.setState({modalOpen: true}); 
        }
        const closeModal = () => this.setState({modalOpen: false});

        let modal = undefined;

        if (msg.attachment_type && msg.attachment_type.startsWith("image/")) {
            attach = <div><a onClick={openModal} href={attachUrl} target="_blank" rel="noreferrer"><img className="attachment" src={attachUrl} alt={msg.attachment_name} /></a></div>
            modal = <Modal className="TeamChatImageModalOuter" open={this.state.modalOpen} onClose={closeModal}>
                <div className="TeamChatImageModal" onClick={closeModal}><img src={attachUrl} alt={msg.attachment_name} /></div>
            </Modal>
        }
        else if (msg.attachment_type && msg.attachment_type.startsWith("video/")) {
            attach = <div><video src={attachUrl} controls /></div>
        }
        else if (attachments_id)
            attach = <div><a href={attachUrl} target="_blank" rel="noreferrer">{msg.attachment_name}</a></div>

        const time = moment.utc(timestamp).local();

        const ids = comments ? _.map(comments, 'id') : [];

        const pm = pendingMessages.filter( x => ids.indexOf(String(x.id)) === -1 );

        return (
            <div className="FeedItem">
                {modal}

                <div className="NewsFeedUser">
                    <TaimerAvatar id={creator_id} name={creator} color={creator_color} size="medium" />

                    <div className="UserInfo">
                        <div>{msg.creator}</div>
                        {!msg.pending ? <div className="UserTime">{time.fromNow()}</div> : 
                            <div className="UserTime"><Saving /></div>
                        }
                    </div>
                </div>

                <div className="MessageArea">
                    <p>
                        {renderWithEmoji(msg.message)}
                    </p>
                    {attach}

                    <NewsFeedComments item={msg} comments={[...comments, ...pm]} onMessageSent={this.props.onMessageSent} />

                    {!msg.pending && <SendMessage onSendMessage={this.sendMessage} />}
                </div>
            </div>
        );
    }
}
