import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { cloneDeep } from 'lodash';
import React from 'react';
import DataHandler from '../general/DataHandler';
import TaimerWizard, { TaimerWizardPage } from '../general/TaimerWizard';
import TaimerComponent from '../TaimerComponent';
import SliderFieldGroup from '../general/SliderFieldGroup';
import OutlinedField from '../general/OutlinedField';
import DataList from '../general/DataList';
import ProjectTreeDropdown from "../projects/ProjectTreeDropdown";
import TreeSelect from '../general/TreeSelect';
import { AddProject } from '../general/no-options/AddItemComponents';
import DatePicker from '../general/react-date-range/src/components/DatePicker';
import TimeOutlinedField from '../general/TimeOutlinedField';
import Utils from '../general/Utils';
import { CurrencyUtils } from "../general/CurrencyUtils";
import { 
    compareDateTimes, 
    to12HourTime,
    to24HourTime
} from "./../general/DateTimeUtils";
import { 
    ExpenseViewWizardMode,
    ExpenseViewPreviewMode,
    ExpenseViewNoRender,
    TemporaryAttachmentsBundle,
    DropdownData
} from './ExpenseView';

import { 
    calculateByCurrencyRateInverse,
} from "./ExpenseUtils";

import { 
    roundToDecimals
} from "./../general/MathUtils";

import { format, parse } from 'date-fns';
import moment from 'moment/min/moment-with-locales';

import { withSnackbar, WithSnackbarProps } from 'notistack';

import styles from './ExpenseWizard.module.scss';
import tabStyles from './ExpenseDetailsTab.module.scss';
import wizardStyles from '../general/TaimerWizard.module.scss';

import { 
    uploadFiles,
    UploadResponse
} from "./../general/FileUtils";

import { 
    ExpenseDetails, 
} from "./ExpenseView";

import { 
    createOptions
} from "../general/TreeSelect";

import ExpenseViewOverlay from './ExpenseViewOverlay';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

import { ReactComponent as CreateNewIcon } from '../general/icons/create_new.svg';
import { ReactComponent as AddRowsIcon } from '../general/icons/add_rows.svg';
import { ReactComponent as PreviewIcon } from '../general/icons/preview.svg';
import { ReactComponent as CompletedImage } from '../general/icons/completed.svg';
import { ReactComponent as ErrorImage } from '../general/icons/error.svg';
import { ReactComponent as PaymentTypesIcon } from '../general/icons/paymentTypes.svg';
import { ReactComponent as ExpenseTypesIcon } from '../general/icons/expenseTypes.svg';

interface ExpenseWizardProps extends WithSnackbarProps {
    sliderMode?: boolean;
    expenseType: string;
    projectId?: string | number;
    onBoarding?: boolean;
    origin_point?: string;
    draftId?: number | string;
}

interface ExpenseWizardState {
    expense: ExpenseDetails;
    pages: TaimerWizardPage[];
    autoCompleteData: any;
    projectIsBillable: boolean;
    yearLimit?: any;
    targetingMap: any;
    error: boolean;
    rowError: boolean;
    detailFieldErrors: any;
    attachments: TemporaryAttachmentsBundle | undefined;
    totals: any;
    initialProjectChanged: boolean;
    paymentTypes: any[];
    deletedPaymentTypes: any[];
    deletedExpenseTypes: any[];
    expenseTypes: any[];
    loadingPaymentTypes?: boolean;
    loadingExpenseTypes?: boolean;
    dropdownData: DropdownData;
    dropdownDataFetched: boolean;
    countryCode: string;
    expenseFetched: boolean;
    autocompleteDataFetched: boolean;
    showCompanyName: boolean;
    draftCompanyId: string| number;
}

interface TravelExpenseSaveResponse {
    idPairs: {
        mileage_allowance: { [key: number]: number },
        daily_allowance:   { [key: number]: number },
        other_allowance:   { [key: number]: number }
    },
    error: boolean;
}

interface ExpenseSaveResponse {
    idPairs: {
        purchase_expense: { [key: number]: number }
    },
    error: boolean;
}

class ExpenseWizard extends TaimerComponent<ExpenseWizardProps, ExpenseWizardState> {
    wizard: any = React.createRef();
    expenseView: any = React.createRef();
    projectTreeDropdown: any = React.createRef();

    deletedRows: any[] = [];
    selectFields: any[] = [];
    textFields: any[] = [];
    dateFields: any[] = [];
    extraFields: any[] = [];
    additionalFields: any[] = [];
    timeFieldsMap: any = {};
    initialState: any = {};
    requiredFields: string[] = [];
    paymetTypesPage = 0;
    expenseTypesPage = 0;
    detailsPage = 1;
    rowEditPage = 2;
    previewPage = 3;
    completedPage = 4;

    constructor(props, context) {
        super(props, context, 'general/ExpenseWizard');

        if (this.showSettingsEditPages()) {
            this.paymetTypesPage = 1;
            this.expenseTypesPage = 2;
            this.detailsPage = 3;
            this.rowEditPage = 4;
            this.previewPage = 5;
            this.completedPage = 6;
        }
      
        const pages: TaimerWizardPage[] = [
            ...(this.showSettingsEditPages()
            ? [
                  {
                      title: this.tr('Manage payment types'),
                      content: {
                          main: this.renderPaymentTypeEditor,
                          right: {
                              image: <PaymentTypesIcon />,
                              header: this.tr('Payment types'),
                              description: this.htmlTr(
                                  'A payment type is always selected when creating an expense.${linebreak}We added two payment types by default. Edit and add more types according to your needs.',
                                  { linebreak: [<br />, <br />] }
                              ),
                          },
                      },
                      actions: [
                          {
                              setLoading: true,
                              label: this.tr('Next'),
                              action: this.afterPaymentTypeEdits,
                              disabled: () => this.state.paymentTypes?.length == 0 || this.state.paymentTypes?.findIndex((pt) => !!pt.name) == -1,
                          },
                      ],
                  },
                  {
                      title: this.tr('Manage expense types'),
                      content: {
                          main: this.renderExpenseTypeEditor,
                          right: {
                              image: <ExpenseTypesIcon />,
                              header: this.tr('Expense types'),
                              description: this.htmlTr(
                                  'Expense types help you target expense rows to the right cost center.${linebreak}We added four expense types by default. Edit and add expense types to your needs.',
                                  { linebreak: [<br />, <br />] }
                              ),
                          },
                      },
                      actions: [
                          {
                              setLoading: true,
                              label: this.tr('Next'),
                              action: this.afterExpenseTypeEdits,
                              disabled: () => this.state.expenseTypes?.length == 0 || this.state.expenseTypes?.findIndex((et) => !!et.name && Number.isFinite(Number(et.vat))) == -1,
                          },
                      ],
                  },
              ]
            : []),
            {
                title: props.expenseType == "1" ? this.tr('Expense details') : this.tr('Travel expense details'),
                content: {
                    main: this.renderExpenseDetailsEditor,
                    right: this.renderExplainer,
                },
                actions: [
                    {
                        label: this.tr('Add rows'),
                        name: "add_rows",
                        action: () => this.saveDetails(),
                        disabled: () => this.shouldRenderOverlay(),
                    },
                ],
            },
            {
                title: this.tr('Edit rows'),
                mainContentStyle: "fullWidth",               
                content: {
                    main: this.renderExpenseRowEditor,
                    right: this.renderExplainer,
                },
                actions: [
                    {
                        setLoading: true,
                        name: "preview",
                        label: this.tr('Preview'),
                        action: () => this.wizard.current && this.wizard.current.nextPage(),
                    },
                ]
            },
            {
                title: this.tr('Expense preview'),
                mainContentStyle: "fullWidth",
                content: {
                    main: this.renderExpensePreview,
                    right: this.renderExplainer,
                },                
                actions: [
                    {
                        setLoading: true,
                        name: "save_as_draft",
                        label: this.tr('Save as draft'),
                        action: () => this.saveExpense(0),
                        className: styles.greyButton,
                        visible: () => !props.onBoarding
                    },
                    {
                        setLoading: true,
                        name: "save_expense",
                        label: this.tr('Save expense'),
                        action: () => this.saveExpense(1),
                    },
                ]
            },
            {
                title: this.tr('Expense saved'),
                content: { main: this.renderCompleted },
                actions: [
                    {
                        label: this.tr('Done'),
                        name: "done",
                        action: () => { return 'close'; }
                    },
                ],
                noReturn: true,
            },
        ];

        let startTime: string = moment(new Date()).format('HH:mm');
        let endTime: string   = moment(new Date()).format('HH:mm');

        startTime = Number(this.context.userObject.clock_format) === 0 ? to12HourTime(startTime) : startTime;
        endTime   = Number(this.context.userObject.clock_format) === 0 ? to12HourTime(endTime) : endTime;

        this.initialState = {
            accountId: "",
            projectId: props.projectId || "",
            quoteId: "",
            quoteRowId: "",
            description: "",
            route: "",
            billCustomer: false,
            expenseDate: format(new Date(), "YYYY-MM-DD"),
            startDate: format(new Date(), "YYYY-MM-DD"),
            endDate: format(new Date(), "YYYY-MM-DD"),
            startTime: startTime,
            endTime: endTime,
            currencyRate: 1.000000,
            currency: this.context.taimerAccount.currency,
            paymentTypeId: 0
        }

        this.state = {
            expense: {
                id: this.props.draftId || -1,
                userId: context.userObject.usersId,
                companyId: context.userObject.companies_id,
                companyName: context.userObject.company_name,
                userFullName: "",
                mileageRows: [],
                dailyRows: [],
                expenseRows: [],
                state: 0,
                ...this.initialState
            },
            draftCompanyId: context.userObject.companies_id,
            pages,
            autoCompleteData: {},
            projectIsBillable: true,
            yearLimit: undefined,
            targetingMap: {},
            error: false,
            rowError: false,
            detailFieldErrors: {},
            attachments: undefined,
            totals: { subtotal: 0, total: 0, vatTotal: 0 },
            initialProjectChanged: false,
            paymentTypes: [],
            deletedPaymentTypes: [],
            expenseTypes: [],
            deletedExpenseTypes: [],     
            dropdownData: {
                allMileageRates: [],
                allAdditionalRates: [],
                allDailyRates: [],
                mileageRates: [],
                additionalRates: [],
                dailyRates: [],
                expenseTypes: [],
                projects: [],
                currencies: [],
                quotes: [],
                quoteRows: [],
                jobtypes: []
            },
            dropdownDataFetched: false,
            countryCode: "FI",
            expenseFetched: this.props.draftId ? false : true, // If no draft id expense data is not fetched.
            autocompleteDataFetched: false,
            showCompanyName: false
        };

        this.selectFields = [
            { label: this.tr("Quote"), dataKey: "dropdownData", optionsKey: "quotes", valueKey: "quoteId", filterByKey: "projectId", filterBy: "projects_id", visibilityFilter: "showQuoteTargeting", hideEmpty: true },
            { type: TreeSelect, label: this.tr("Quote row"), dataKey: "autoCompleteData", optionsKey: "quote_rows", valueKey: "quoteRowId", filterByKey: "quoteId", filterBy: "quote_id", visibilityFilter: "showQuoteTargeting", hideEmpty: true, valueAsId: true }
        ];
        if (props.expenseType == "1") {
            this.selectFields.push({ label: this.tr("Payment type"), dataKey: "dropdownData", optionsKey: "payment_types", valueKey: "paymentTypeId", required: true });
        }

        this.textFields = [
            { label: this.tr("Description"), key: "description" }
        ];
        if (props.expenseType == "2") {
            this.textFields.push( { label: this.tr("Route"), key: "route" });
        }

        this.dateFields = props.expenseType == "1" ?
            [
                { label: this.tr("Date"), key: "expenseDate" },
            ]
            : [
                { label: this.tr("Start date"), key: "startDate" },
                { label: this.tr("End date"), key: "endDate" },
            ];

        this.timeFieldsMap = {
            startDate: { label: this.tr("Start time"), key: "startTime" },
            endDate: { label: this.tr("End time"), key: "endTime" },
        };

        this.additionalFields = props.expenseType == "1" ?
            [
                { type: "select", label: this.tr("Expenses currency"), dataKey: "dropdownData", optionsKey: "currencies", valueKey: "currency", visibilityFilter: "currencies" },
                { type: "text", label: this.tr("Rate"), key: "currencyRate", props: { disabled: true }, visibilityFilter: "currencies", displayFunc: (val) => CurrencyUtils.roundNumber(Number(val), 6) },
            ]
            : [];

        this.requiredFields = ["projectId"];
        if (props.expenseType == "1") {
            this.requiredFields.push("paymentTypeId");
        }
    }

    componentDidMount(): void {
        super.componentDidMount();
        if (this.showSettingsEditPages()) {
            this.getPaymentTypes();
        }
        else {
            this.fetcAutocompleteData();
        }
    }

    showSettingsEditPages = () => {
        return this.props.onBoarding && this.context.functions.isUserOwner();
    }

    getPaymentTypes = () => {
        this.setState({ loadingPaymentTypes: true }, async () => {
            const response = await DataHandler.get({ url: `expenses/regular_payment_types/${this.state.expense.companyId}` });
            this.setState({ paymentTypes: response.regular_payment_types || [], deletedPaymentTypes: [], loadingPaymentTypes: false });
        });
    };

    getExpenseTypes = () => {
        this.setState({ loadingExpenseTypes: true }, async () => {
            const response = await DataHandler.get({ url: `expenses/custom_types/${this.state.expense.companyId}` });
            this.setState({ expenseTypes: response.custom_types || [], deletedExpenseTypes: [], loadingExpenseTypes: false });
        });
    };

    fetcAutocompleteData = async () => {
        const companies = this.context.taimerAccount.isMulticompany 
            ? await DataHandler.get({ url: `subjects/companies/worktrips/write+approve+approve_superior+approve_projectmanager+modify_all` })
            : [];

        const targetingMap = await DataHandler.get({ url: `settings/company/cost_targeting_companies` });
        this.setState({
            targetingMap,
            showCompanyName: (companies || []).length > 1,
            autocompleteDataFetched: true
        });
    }

    validateFields = () => {
        const { enqueueSnackbar } = this.props;
        const { expense } = this.state;
        const detailFieldErrors = cloneDeep(this.state.detailFieldErrors);
        let errors = false;

        if (!this.checkRates(expense)) {
            errors = true;
        }
        if (!this.checkTimes(expense)) {
            errors = true;
        }

        let requiredMissing = false;
        this.requiredFields.forEach(field => {
            if (!expense[field]) {
                detailFieldErrors[field] = true;
                requiredMissing = true;
            }
            else {
                detailFieldErrors[field] = false;
            }
        });
        if (requiredMissing) {
            errors = true;
            enqueueSnackbar && enqueueSnackbar(this.tr('Required fields not filled!'), {
                variant: 'error',
            });
        }
        this.setState({ detailFieldErrors });
        return !errors;
    }

    saveDetails = () => {
        if (!this.validateFields()) {
            return;
        }
        this.wizard.current && this.wizard.current.nextPage();
    }

    saveExpense = async (state) => {
        const { expenseType } = this.props;
        const details = cloneDeep(this.state.expense);
        delete details.expenseRows;
        delete details.mileageRows;
        delete details.dailyRows;
        details.state = state;

        const type: string = this.props.expenseType === "1" 
            ? "purchase_expense" 
            : "traveling_expense";

        details.startTime = to24HourTime(details.startTime ?? "");
        details.endTime   = to24HourTime(details.endTime ?? "");

        const mappedDetails = Utils.translateFields(details, {
            projectId: "projects_id",
            startDate: "startdate",
            startTime: "starttime",
            endDate: "enddate",
            endTime: "endtime",
            quoteRowId: "costestimate_row_id",
            billCustomer: "billing",
            userId: "users_id",
            description: "description",
            route: "route",
            currencyRate: "currency_rate",
            currency: "currency_label",
            expenseDate: "expense_date",
            paymentTypeId: "payment_type_id"
        });

        let error = false;
        try {
            const response = await DataHandler.post({ url: `expenses/${type}` }, mappedDetails);
            details.id = response?.data?.id;
            error = Number(details.id) < 1;
        } catch (err) {
            error = true
            console.error(err);
        }

        const newState = {
            expense: details,
            error: error,
            rowError: false
        };

        let expenseResponse;
        let travelExpenseResponse;

        if(error) {
            this.setState(newState);
            return;
        }

        if(expenseType == "1") {
            expenseResponse = await this.saveExpenseRows(details.id);
        } else {
            travelExpenseResponse = await this.saveTravelExpenseRows(details.id);
        }

        await this.saveAttachments(
            Number(details.id),
            Number(expenseType) === 1
                ? expenseResponse.idPairs
                : travelExpenseResponse.idPairs
        );

        this.sendCreatedMixpanelEvents();

        setTimeout(() => {
            window.dispatchEvent(new Event(`new_expense_created`));
        }, 1000);

        this.setState({ 
            expense: details, 
            error, 
            rowError: Number(expenseType) === 1 
                ? expenseResponse.error
                : travelExpenseResponse.error
        }, () => this.wizard.current && this.wizard.current.nextPage());
    }

    sendCreatedMixpanelEvents = () => {
        const { expenseType, origin_point } = this.props;
        if (expenseType == "1") {
            this.context.functions.sendMixpanelEvent('create_expense', {
                'origin_point': origin_point,
            });
            this.context.functions.sendMixpanelPeople('set_once', {
                'first_create_expense_start': new Date().toISOString(),
            });
            this.context.functions.sendMixpanelPeople('set', {
                'last_create_expense_start': new Date().toISOString(),
            });
            this.context.functions.sendMixpanelPeople('increment', {
                'lifetime_create_expense': 1,
            });
        }
        else {
            this.context.functions.sendMixpanelEvent('create_travel_expense', {
                'origin_point': origin_point,
            });
            this.context.functions.sendMixpanelPeople('set_once', {
                'first_create_travel_expense_start': new Date().toISOString(),
            });
            this.context.functions.sendMixpanelPeople('set', {
                'last_create_travel_expense_start': new Date().toISOString(),
            });
            this.context.functions.sendMixpanelPeople('increment', {
                'lifetime_create_travel_expense': 1,
            });

        }
    }

    saveAttachments = async (
        id: number, 
        idPairs: { [key: number]: number }
    ) => {
        const { 
            expenseType 
        } = this.props;
        const {
            attachments
        }: { 
            attachments: TemporaryAttachmentsBundle | undefined; 
        } = this.state;

        if(attachments === undefined) {
            return;
        }

        const attachmentUrlEndPart: string = Number(expenseType) === 2
            ? "/traveling"
            : "";

        // TODO: Liikaa purkkaa.
        const keys: string[] = Object.keys(attachments)
            .filter((k: string) => k !== "general" && k !== "additional_allowance")

        // await doesn't like Array.forEach.
        for(const rowType of keys) {
            const map: { 
                [key: string | number]: File[] 
            } = attachments[rowType];

            for(const originalRowId of Object.keys(map)) {
                const rowId: number = idPairs[rowType][originalRowId];

                await uploadFiles(
                    map[originalRowId],
                    {
                        url: `expenses/${id}/attachments${attachmentUrlEndPart}`,
                        row_id: rowId,
                        row_type: rowType
                    },
                    await DataHandler.get({ url: `attachments/max_size` })
                );
            }
        }

        if(!attachments.general?.[-1]) {
            return;
        }

        await uploadFiles(
            attachments.general?.[-1],
            {
                url: `expenses/${id}/attachments${attachmentUrlEndPart}`
            },
            await DataHandler.get({ url: `attachments/max_size` })
        );
    }

    saveExpenseRows = async (id): Promise<ExpenseSaveResponse> => {
        const { expense } = this.state;
        const data = {
            rows: this.expenseView.current?.getSaveRows("expense", expense.expenseRows) || expense.expenseRows,
            id
        }

        data.rows = data.rows.map(row => ({
            ...row,
            sum: roundToDecimals(Number(row.total) / (Number(row.vat) / 100 + 1), 2)
        }));

        const response: ExpenseSaveResponse = {
            idPairs: {
                purchase_expense: {}
            },
            error: false
        }

        try {
            const r = await DataHandler.post({
                url: 'expenses/new_expenses_rows'
            }, data);

            r.data.forEach(r => response.idPairs.purchase_expense[r.original_id] = r.id)
        } catch(err) {
            response.error = true;
        }

        return response;
    }

    saveTravelExpenseRows = async (id): Promise<TravelExpenseSaveResponse> => {
        const { expense } = this.state;

        const mileageRows = this.expenseView.current?.getSaveRows("mileage", expense.mileageRows) || expense.mileageRows;
        const dailyRows = this.expenseView.current?.getSaveRows("daily", expense.dailyRows) || expense.dailyRows;
        const otherRows = this.expenseView.current?.getSaveRows("expense", expense.expenseRows) || expense.expenseRows;

        const otherData = {
            rows: otherRows,
            id
        }
        const mileageData = {
            rows: mileageRows,
            id
        }
        const dailyData = {
            rows: dailyRows,
            id
        } 

        const response: TravelExpenseSaveResponse = {
            idPairs: {
                mileage_allowance: {},
                daily_allowance:   {},
                other_allowance:   {},
            },
            error: false
        };

        if (otherRows.length > 0) {
            try {
                const r = await DataHandler.post({url: 'expenses/new_other_rows'}, otherData);

                r.data.forEach(r => response.idPairs.other_allowance[r.original_id] = r.id);
            } catch (err) {
                response.error = true;
            } 
        }
        if (mileageRows.length > 0) {
            try {
                const r = await DataHandler.post({url: 'expenses/new_mileage_rows'}, mileageData);

                r.data.forEach(r => response.idPairs.mileage_allowance[r.original_id] = r.id);
            } catch (err) {
                response.error = true;
            } 
        }
        if (dailyRows.length > 0) {
            try {
                const r = await DataHandler.post({url: 'expenses/new_daily_rows'}, dailyData);

                r.data.forEach(r => response.idPairs.daily_allowance[r.original_id] = r.id);
            } catch (err) {
                response.error = true;
            }
        }

        DataHandler.post({url: `expenses/${id}/traveling_expense_created`});

        return response;
    }

    onOnboardingDone = () => {
        const { error } = this.state;

        if (!error) {
            this.context.functions.sendMixpanelEvent('expenses_wizard_completed');
            this.context.functions.sendMixpanelPeople('set', {
                "expenses_wizard_end_date": new Date().toISOString(),
            });
            this.context.functions.onOnboardingItemCompleted('expense');
        }
    }

    renderLoading = () => {
        return <div><Loading className='main-page-loading-indicator' /></div>
    }

    renderOverlay = () => {
        return <ExpenseViewOverlay onButtonClick={() => this.wizard.current && this.wizard.current.close() } />
    }

    shouldRenderOverlay = () => {
        const { expense, dropdownDataFetched } = this.state;
        return dropdownDataFetched && expense.startDate == this.initialState.startDate && expense.endDate == this.initialState.endDate && !this.isRatesFound(expense);
    }

    showQuoteTargeting = () => {
        const { expense, targetingMap } = this.state;
        return targetingMap[expense.companyId] > 0;
    }

    handleAttachmentsChange = (attachments: TemporaryAttachmentsBundle) => {
        this.setState({ 
            attachments: attachments 
        });
    }

    renderExpenseDetailsEditor = () => {
        const { expense, dropdownData, detailFieldErrors, dropdownDataFetched, autocompleteDataFetched, expenseFetched, showCompanyName } = this.state;
        const { expenseType } = this.props;

        if (!dropdownDataFetched || !autocompleteDataFetched || !expenseFetched) {
            return this.renderLoading();
        }
        else if (this.shouldRenderOverlay()) {
            return this.renderOverlay();
        }

        const visibilityFilters = {
            showQuoteTargeting: this.showQuoteTargeting(),
            currencies: dropdownData.currencies?.length > 0
        }

        return (
            <div className={`${styles.content}`} style={{ width: 700 }}>
                <div>
                    <div className={styles.box}>
                        <div className={styles.title}>
                            <div className={styles.icon}>
                                <Info />
                            </div>
                            <div className={styles.right}>
                                <h2>{expenseType == "1" ? this.tr('Expense details') : this.tr('Travel expense details')}</h2>
                            </div>
                        </div>
                        {showCompanyName && 
                            <Tooltip 
                                title={this.tr("You can only add travel expenses for your own company")} 
                                arrow 
                                classes={{ tooltip: 'darkblue-tooltip' }}>
                                    <div>
                                        <OutlinedField
                                            name={"companyName"}
                                            value={this.context.userObject?.company_name}
                                            label={this.tr("Company")}
                                            disabled={true}
                                            className={`${styles.fullWidth}`}
                                        />
                                    </div>
                            </Tooltip>
                        }
                        <ProjectTreeDropdown
                            ref={this.projectTreeDropdown}
                            name="projectSelect"
                            label={this.tr("Project") + ' *'}
                            clearOnEmpty
                            treeDropdownProps={{
                                activateBestMatch: true,
                                highlightMatches: true,
                                useTooltip: true,
                                growOptionContainer: true,
                                usePopper: true,
                            }}
                            value={expense.projectId}
                            error={detailFieldErrors.projectId}
                            disabled={false}
                            queryParameters={{ 
                                right: 'read', 
                                company: expense.companyId
                            }}
                            noOptionsMessageProps={{
                                selectProps: {
                                    companies_id: expense.companyId,
                                    onItemCreated: (e) => {
                                        this.onProjectChange(e, true);
                                    }
                                }
                            }}
                            noOptionsMessage={AddProject}
                            onSelect={e => this.onProjectChange(e)}
                            onInitialSelect={e => this.onProjectChange(e, false, true)}
                        />

                        {this.selectFields.map(f => {
                            const FieldType = f.type || DataList;

                            // If field has some own visibilityfilter or if expense doesn't have value selected for field that is used to filter it's options.
                            if ((f.visibilityFilter && !visibilityFilters[f.visibilityFilter]) || (f.filterByKey && !expense[f.filterByKey])) {
                                return;
                            }
                            const data = this.state[f.dataKey];
                            let options = data ? (data[f.optionsKey] || []): [];
                            if (f.filterBy) {
                                options = options.filter(o => o[f.filterBy] == expense[f.filterByKey])
                            }
                            if (f.hideEmpty && options.length < 1) { // Hide when field has no options.
                                return;
                            }
                            return (
                                <FieldType
                                    label={f.label + (f.required ? " *" : "")}
                                    name={f.valueKey}
                                    value={f.valueAsId ? expense[f.valueKey] : options.find(d => d.id == expense[f.valueKey])}
                                    options={options}
                                    onChange={(val) => this.onChangeField(f.valueKey, val.target ? val.target.value : val.id, val)}
                                    shownCount={20}
                                    error={detailFieldErrors[f.valueKey]}
                                />
                            )
                        })}

                        {this.textFields.map(f => {
                            return (
                                <OutlinedField
                                    name={f.key}
                                    value={expense[f.key]}
                                    label={f.label}
                                    onChange={(e) => this.onChangeField(f.key, e.target.value)}
                                    callOnChangeOnKeyUp
                                />
                            )
                        })}

                        {this.dateFields.map(f => {
                            const timeField = this.timeFieldsMap[f.key];
                            return (
                                <div className={timeField ? styles.dateTimeInputs : ""}>
                                    <DatePicker
                                        key={f.key}
                                        name={f.key}
                                        className={`date ${timeField ? "" : "full"}`}
                                        disabled={false}
                                        date={expense[f.key]}
                                        onChange={(date) => date && this.onChangeField(f.key, moment(date).format('YYYY-MM-DD'))}
                                        onInputChange={(_, date) => date && this.onChangeField(f.key, moment(date).format('YYYY-MM-DD'))}
                                        label={f.label}
                                        dateFormat={this.context.userObject.dateFormat}
                                        error={detailFieldErrors[f.key]}
                                        usePopper
                                        popperBottom
                                    />
                                    {timeField && 
                                        <TimeOutlinedField
                                            key={timeField.key}
                                            name={timeField.key}
                                            value={expense[timeField.key]}
                                            onBlur={(event, value) => {
                                                this.onChangeField(timeField.key, event.target.value?.replaceAll("-", "0"));
                                            }}
                                            label={timeField.label}
                                            error={detailFieldErrors[timeField.key]}
                                            type="time"
                                            clock={Number(this.context.userObject.clock_format) === 0 ? 12 : 24}
                                        />
                                    }
                                </div>
                            )
                        })}

                        {this.additionalFields.map(f => {
                            // If field has some own visibilityfilter.
                            if ((f.visibilityFilter && !visibilityFilters[f.visibilityFilter])) {
                                return;
                            }
                            switch (f.type) {
                                case "select":
                                    const data = this.state[f.dataKey];
                                    const options = data ? (data[f.optionsKey] || []): [];
                                    return ( 
                                        <DataList
                                            label={f.label}
                                            name={f.valueKey}
                                            value={options.find(d => d.id == expense[f.valueKey])}
                                            options={options}
                                            onChange={(val) => this.onChangeField(f.valueKey, val.id, val)}
                                            shownCount={20}
                                        />)
                                case "text":
                                    return (
                                        <OutlinedField
                                            name={f.key}
                                            value={f.displayFunc ? f.displayFunc(expense[f.key]) : expense[f.key]}
                                            label={f.label}
                                            onChange={(e) => this.onChangeField(f.key, e.target.value)}
                                            inputProps={f.inputProps || {}}
                                            callOnChangeOnKeyUp
                                            {...f.props}
                                        />
                                    )
                                default:
                                    return <></>
                            }
                        })}
                       
                        <div className={styles.labelCheckBox}>
                            <Tooltip title={!this.state.projectIsBillable ? this.tr("The selected project isn't billable") : ""} placement="right">
                                <FormControlLabel
                                    data-testid="bill_customer_checkbox"
                                    control={<Checkbox checked={expense.billCustomer} onChange={(e) => this.onChangeField("billCustomer", e.target.checked)} disabled={!this.state.projectIsBillable} />}
                                    label={this.tr('Bill customer')}
                                />
                            </Tooltip>
                        </div> 

                    </div>
                </div>
            </div>
        );
    };

    renderExpenseRowEditor = () => {
        return this.renderExpenseView();
    }

    renderExpensePreview = () => {
        const { expenseType } = this.props;
        const header = expenseType == "1" ? this.tr('Expense details') : this.tr('Travel expense details');
        return (
            <div className={styles.previewPage}>
                <div className={styles.title}>
                    <div className={styles.icon}>
                        <Info />
                    </div>
                    <div className={styles.right}>
                        <h2>{header}</h2>
                    </div>
                </div>
                {this.renderExpenseDetailsExplainer()}
                {this.renderExpenseViewPreviewMode()}
            </div>
        )
    }

    transformTemporaryAttachmentsIntoAnArray = (attachments: TemporaryAttachmentsBundle): File[] => {
        return Object.keys(attachments)
            .map((key: string): File[] => Object.values(attachments[key]))
            .flat()
            .flat();
    }

    renderExpenseView = () => {
        const { expenseType } = this.props;
        const { 
            expense, 
            attachments,
            dropdownData,
            countryCode
        }: {
            expense: ExpenseDetails;
            attachments: TemporaryAttachmentsBundle | undefined;
            dropdownData: DropdownData;
            countryCode: string;
        } = this.state;

        return (
            // @ts-ignore
            <ExpenseViewWizardMode
                ref={this.expenseView}
                id={expense.id}
                expenseDetails={expense}
                expenseType={expenseType}
                company={expense.companyId}
                onDetailsEdit={(expense) => this.setState({ expense })}
                attachments={attachments
                    ? this.transformTemporaryAttachmentsIntoAnArray(attachments)
                    : []}
                temporaryAttachmentsBundle={attachments}
                onTemporaryAttachmentsChange={this.handleAttachmentsChange}
                onTotalsChange={this.onTotalsChange}
                dropdownData={dropdownData}
                countryCode={countryCode}
            />
        )
    }

    renderExpenseViewPreviewMode = () => {
        const { expenseType } = this.props;
        const { 
            expense, 
            attachments,
            dropdownData,
            countryCode
        }: {
            expense: ExpenseDetails;
            attachments: TemporaryAttachmentsBundle | undefined;
            dropdownData: DropdownData;
            countryCode: string;
        } = this.state;

        return (
            // @ts-ignore
            <ExpenseViewPreviewMode
                ref={this.expenseView}
                id={expense.id}
                expenseDetails={expense}
                expenseType={expenseType}
                company={expense.companyId}
                onDetailsEdit={(expense) => this.setState({ expense })}
                attachments={attachments
                    ? this.transformTemporaryAttachmentsIntoAnArray(attachments)
                    : []}
                temporaryAttachmentsBundle={attachments}
                onTemporaryAttachmentsChange={this.handleAttachmentsChange}
                onTotalsChange={this.onTotalsChange}
                dropdownData={dropdownData}
                countryCode={countryCode}
            />
        );
    }

    renderExpenseViewNoRender = () => {
        const { expenseType } = this.props;
        const { 
            expense, 
            dropdownData,
            dropdownDataFetched
        }: {
            expense: ExpenseDetails;
            dropdownData: DropdownData;
            dropdownDataFetched: boolean;
        } = this.state;

        return (
            // @ts-ignore
            <ExpenseViewNoRender
                ref={this.expenseView}
                expenseDetails={expense}
                expenseType={expenseType}
                company={expense.companyId}
                dropdownData={dropdownDataFetched ? dropdownData : undefined}
                onDropdownDataChanged={(dropdownData) => this.updateDropdownData(dropdownData)}
                onCountryCodeChanged={(code) => this.updateCountryCode(code)}
                id={expense.id}
                onExpenseFetched={(expense, expenseCompanyId) => this.onExpenseFetched(expense, expenseCompanyId)} // When expense is dublicated.
            />
        );
    }

    shouldRenderExpenseViewNoRender = () => {
        const currentPage = this.wizard.current ? this.wizard.current.getCurrentPage() : 0;
        if (!currentPage) {
            return false;
        }
        return currentPage == this.detailsPage; // Render empty expenseview when page is details page, so dropdowndata gets fetched correctly.
    }

    onExpenseFetched = (expense, draftCompanyId) => {
        expense.id                    = -1;
        expense.userId                = this.context.userObject.usersId;
        expense.state                 = 1;
        expense.billed                = 0;
        expense.partiallyBilled       = 0;
        expense.atLeastOneRowInvoiced = 0;

        this.setState({ expenseFetched: true, expense, draftCompanyId });
    }

    updateDropdownData = (dropdownData) => {
        const autoCompleteData = cloneDeep(this.state.autoCompleteData);
        if (this.state.expense.paymentTypeId && !dropdownData.payment_types?.find(p => p.id == this.state.expense.paymentTypeId)) { // If payment type gets deleted in settings (in onboarding mode) and selected type is not found.
            this.onChangeField("paymentTypeId", 0);
        }
        const dropdownDataFetched = this.state.dropdownDataFetched;

        autoCompleteData.quote_rows = createOptions(
            dropdownData.quote_rows ?? [], 
            "value",
            "header_id",
            false
        );

        this.setState({ dropdownDataFetched: true, dropdownData, autoCompleteData }, () => !dropdownDataFetched && this.updateDraftExpense()); // Update fetched expense after dropdowndata is fetched. So correct rates and values can be added. Only in first dropdowndata fetch.
    }

    updateCountryCode = (countryCode) => {
        this.setState({ countryCode });
    }

    updateDraftExpense = () => {
        const { dropdownData, draftCompanyId } = this.state;
        const expense = cloneDeep(this.state.expense);

        if (draftCompanyId != this.context.userObject.companies_id) { // Expense is created from draft of other company's expense,
            expense.projectId = undefined; // First project gets selected to projectTreeDropdown.
            
            if (this.props.expenseType == "1") {
                // Select first payment type from list.
                const paymentTypes    = dropdownData.payment_types?.filter(p => !p.deleted) || undefined;
                const paymentType     = paymentTypes ? paymentTypes[0] : undefined;
                expense.paymentTypeId = paymentType?.id || 0; 
    
                // Check if user's company has rate for expense's currency.
                if (expense.currency != this.context.taimerAccount.currency) {
                    const currency = dropdownData.currencies?.find(c => !c.deleted && c.label == expense.currency);
                    expense.currency = currency?.label || this.context.taimerAccount.currency;
                    expense.currencyRate = Number(currency?.value) || 1;
                }
                else {
                    expense.currencyRate = 1; // Set rate to 1 if currency is same as company's (rate might be different if dublicating from other company).
                }
            }
            else {
                expense.currency     = this.context.taimerAccount.currency; // Currencies don't exist in Travel expenses. Set to company currency.
                expense.currencyRate = 1;
            }
        }

        this.setState({ expense }, () => this.updateFetchedRows());
    }

    updateFetchedRows = () => {
        const { expense, draftCompanyId, dropdownData } = this.state;

        const isOtherCompanyExpense = draftCompanyId != this.context.userObject.companies_id;

        const mileageRates    = this.expenseView.current?.getEligibleRates(expense, dropdownData.mileageRates) || undefined;
        const dailyRates      = this.expenseView.current?.getEligibleRates(expense, dropdownData.dailyRates) || undefined;
        const additionalRates = this.expenseView.current?.getEligibleRates(expense, dropdownData.additionalRates) || undefined;
        const expenseTypes    = dropdownData.expenseTypes?.filter(t => !t.deleted) || undefined;

        const defaultMileageRate    = mileageRates ? mileageRates[0] : undefined;
        const defaultAdditionalRate = additionalRates ? additionalRates[0] : undefined;
        const defaultDailyRate      = dailyRates ? dailyRates[0] : undefined;
        const defaultExpenseType    = expenseTypes ? expenseTypes[0] : undefined;

        const netvisorActive = this.context.addons && this.context.addons.netvisor && this.context.addons.netvisor.used_by_companies.indexOf(this.context.userObject.companies_id) > -1;

        if (netvisorActive) {
            expense.mileageRows = (expense.mileageRows || []).filter(m => !m.isAdditional); // Additional rows are not used if Netvisor is active.
        }

        let newMileageId = -1;
        let newDailyId   = -1;
        let newExpenseId = -1;
        const origMileageIds: any[] = [];

        expense.mileageRows = (expense.mileageRows || []).map(m => {
            if (isOtherCompanyExpense) {
                const defaultRate     = m.isAdditional ? defaultAdditionalRate : defaultMileageRate; 
                m.accountingAccountId = 0;
                m.accountingProductId = 0;
                m.jobtypeId           = 0;
                m.mileageId           = Number(defaultRate?.id) || 0; 
                m.rate                = defaultRate?.rate || 0;
                m.name                = defaultRate?.name;
            }

            if (!netvisorActive) {
                m.additionalPassengerCount = 0; // extraPassengerCount is only used if Netvisor is active.
            }

            origMileageIds.push({ id: newMileageId, origId: m.id });
            m.id = newMileageId;
            newMileageId--;

            m.attachments        = [];
            m.billId             = 0;
            m.travelingExpenseId = -1;
            return m;
        });
        expense.dailyRows = (expense.dailyRows || []).map(d => {
            if (isOtherCompanyExpense) {
                d.accountingAccountId = 0;
                d.accountingProductId = 0;
                d.jobtypeId           = 0;
                d.allowanceId         = Number(defaultDailyRate?.id) || 0;
                d.rate                = defaultDailyRate?.rate || 0;
                d.partRate            = defaultDailyRate?.partRate || 0;
                d.meals               = {};
            }
            
            d.id = newDailyId;
            newDailyId--;
            
            d.attachments         = [];
            d.billId              = 0;
            d.travelingExpenseId  = -1;
            return d;
        });
        expense.expenseRows = (expense.expenseRows || []).map(e => {
            if (isOtherCompanyExpense) {
                e.accountingAccountId = 0;
                e.accountingProductId = 0;
                e.jobtypeId           = 0;
                e.ratioId             = 0; // Netvisor ratio.
                e.type                = Number(defaultExpenseType?.id) || 0;
                e.vat                 = Number(defaultExpenseType?.vat) || 0;
                e.total               = roundToDecimals((Number(e.vat) / 100 + 1) * e.sum, 2); // Calculate total again because vat might have changed.
            }
           
            e.id = newExpenseId;
            newExpenseId--;

            e.attachmentId = 0;
            e.attachments  = [];
            e.billId       = 0;
            e.expenseId    = -1;
            return e;
        });

        if (!netvisorActive) {
            // Add new mileageAllowanceIds for additional allowances.
            expense.mileageRows = (expense.mileageRows || []).map(a => {
                if (a.isAdditional) {
                    a.mileageAllowanceId = origMileageIds.find(e => e.origId == a.mileageAllowanceId)?.id || 0; // Get new id from added mileageallowanses.
                }

                return a;
            })
        }

        this.setState({ expense });
    }

    renderCompleted = () => {
        const { error, rowError, expense } = this.state;
        const { expenseType } = this.props;

        const successHeader = expense.state == 0 ? ( expenseType == "1" ? this.tr('Draft expense saved header!') : this.tr('Draft travel expense saved header!')) : (expenseType == "1" ? this.tr('Expense saved successfully!') : this.tr('Travel expense saved successfully!'));
        const successText = expense.state == 0 ? this.tr('Draft expense saved text') : this.tr('Now sit back and wait for approval.');
        const errorHeader = expenseType == "1" ? this.tr('Saving expense failed!') : this.tr('Saving travel expense failed!');
        const errorText = this.tr('Please try again later.');
        const rowErrorHeader = this.tr('Saving expense rows failed!');
        const rowErrorText = this.tr('Try saving expense rows again.');
        const linkText = expenseType == "1" ? this.tr("View expense") :  this.tr("View travel expense") ;

        return (
            <div className={styles.sent}>
                {error ? <ErrorImage/> : <CompletedImage/>}
                <h2>{error ? errorHeader : (rowError ? rowErrorHeader : successHeader)}</h2>
                <p>
                    {error ? errorText : (rowError ? rowErrorText : successText)}
                    {!error && <span className={styles.link} onClick={() => this.openExpense()}>{ " " + linkText }</span>}
                </p>
            </div>
        );
    };

    openExpense = () => {
        const { expense } = this.state;
        const { expenseType } = this.props;

        this.wizard.current && this.wizard.current.close();

        this.context.functions.updateView({
            "module": "worktrips",
            "action": "modify",
            "expenseType": expenseType,
            "id": expense.id
        });
    }

    onProjectChange = (e, isNew = false, initial = false) => {    
        const { expense, detailFieldErrors, initialProjectChanged, draftCompanyId } = this.state;
        const { draftId } = this.props;
        const dropdownData = cloneDeep(this.state.dropdownData);
        const noInitialUpdate = initialProjectChanged && initial;

        if (!e || noInitialUpdate || (e?.id == expense.projectId && !initial)) {
            return;
        }

        const isUserCompanyDraft = draftId && draftCompanyId == this.context.userObject.companies_id; 

        if (!isUserCompanyDraft && this.showQuoteTargeting()) { // If draft is from users company, use draft expenses quote.
            const firstQuote = (dropdownData?.quotes || []).find(el => el.projects_id == e.id);
            expense.quoteId = firstQuote?.id || "0";
            const firstRow = (firstQuote && firstQuote.children.length > 0 && firstQuote.children[0].id) || 0;
            expense.quoteRowId = firstRow;
        }

        if (isNew) {
            dropdownData.projects?.unshift(e);
            this.projectTreeDropdown.current && this.projectTreeDropdown.current.fetchProjects();
        }

        expense.projectNumber = e?.project_number;

        this.setState({
            expense: {
                ...expense,
                accountId: e.customer || e.customers_id,
                projectId: e.id,
                billCustomer: isUserCompanyDraft ? (e.charge_traveling == 1 ? expense.billCustomer : false) : e.charge_traveling == 1, // If draft is from users company, use draft expenses data (if draft's project is not billable, put false).
            },
            projectIsBillable: e.charge_traveling == 1,
            detailFieldErrors: { ...detailFieldErrors, projectId: false },
            dropdownData,
            initialProjectChanged: true
        });
    }

    onChangeField = async (name, value, data = {charge_traveling: 0, label: "", value: ""}) => {
        const { dropdownData, detailFieldErrors } = this.state;
        const expense = cloneDeep(this.state.expense);

        if (expense[name] == value) {
            return;
        }
        expense[name] = value;

        if (name?.includes("Time") || name?.includes("Date")) {
            const rateError = !this.checkRates(expense);
            this.checkTimes(expense, { startDate: rateError, endDate: rateError });
        }
        else if (value && this.requiredFields.includes(name)) {
            detailFieldErrors[name] = false;
            this.setState({ detailFieldErrors });
        }

        if (name == "quoteId") {
            const quote = (dropdownData?.quotes || []).find(q => q.id == value);
            expense["quoteRowId"] = (quote && quote.children.length > 0 && quote.children[0].id) || 0;
        }
        if (name == "currency") {
            expense["currencyRate"] = Number(String(data.value)?.replace(",", "."));
        }

        this.setState({ expense });
    }

    checkTimes = (expense, previousErrors = {}) => {
        const { enqueueSnackbar, expenseType } = this.props;
        const { detailFieldErrors } = this.state;
        if (expenseType == "1") { // Purchase expense has only one date. No need to compare times.
            return true;
        }
        const startTime   = expense.startDate + " " + expense.startTime;
        const endTime     = expense.endDate + " " + expense.endTime;
        const timeCompare = compareDateTimes(startTime, endTime);
        const startYear   = moment(expense.startDate).format("YYYY")
        const endYear     = moment(expense.endDate).format("YYYY")
        let errors        = false;

        ['startDate', 'endDate', 'startTime', 'endTime'].forEach(e => {
            detailFieldErrors[e] = previousErrors[e] ? previousErrors[e] : false; 
        });

        if (timeCompare > 0) {
            enqueueSnackbar && enqueueSnackbar(this.tr('End time cannot be before start time'), {
                variant: 'error',
            });
            detailFieldErrors['startDate'] = detailFieldErrors['endDate'] = true;
            detailFieldErrors['startTime'] = detailFieldErrors['endTime'] = true;
            errors = true;
        }
        else if (startYear != endYear) {
            enqueueSnackbar && enqueueSnackbar(this.tr("Expense can't span past the end of the year"), {
                variant: 'error',
            });
            detailFieldErrors['startDate'] = detailFieldErrors['endDate'] = true;
            errors = true;
        }

        this.setState({ detailFieldErrors });
        return !errors;
    }

    checkRates = (expense) => {
        const { enqueueSnackbar } = this.props;
        const { detailFieldErrors } = this.state;
        const found = this.isRatesFound(expense);

        if (!found) {
            enqueueSnackbar && enqueueSnackbar(this.tr('Either no daily allowances or no mileage allowances exist for the selected year'), {
                variant: "error"
            });
        }

        detailFieldErrors['rates'] = !found;
        this.setState({ detailFieldErrors });
        return found;
    }

    isRatesFound = (expense) => {
        const { expenseType } = this.props;
        const { dropdownData, dropdownDataFetched } = this.state;

        if (expenseType == "1") { // Check rates only for travel expenses.
            return true;
        }
        return this.expenseView.current && dropdownDataFetched ? this.expenseView.current.neededRatesExist(expense, dropdownData) : true;
    }

    onTotalsChange = (totals) => {
        this.setState({totals});
    }

    renderExplainerTotals = () => {
        const { expense: { currency, currencyRate } } = this.state;
        const { totals } = this.state;

        const presentCurrency = (amount: number | string) => {
            return this.context.functions.presentCurrency(amount, currency);
        };

        const presentInCompanyCurrency = (amount: number | string) => {
            return this.context.functions.presentCurrency(amount);
        };
        const rate: number = currencyRate ?? 1;
        const showCompanyTotalSeparately = this.context.taimerAccount.currency != currency;

        // Won't be shown if the expense is in company currency.
        const companyTotal: number    = roundToDecimals(calculateByCurrencyRateInverse(totals.total, rate), 2);
        const companySubtotal: number = roundToDecimals(calculateByCurrencyRateInverse(totals.subtotal, rate), 2);
        const companyVatTotal         = roundToDecimals(calculateByCurrencyRateInverse(totals.vatTotal, rate), 2);

        return (
            <div data-testId="explainerTotals">
                <p data-testId="subtotal" className={tabStyles.titleRowParagraph}>
                    {this.tr("Subtotal")}: <strong>{`${presentCurrency(totals?.subtotal || 0)}`}</strong>
                    {showCompanyTotalSeparately && ` (${presentInCompanyCurrency(companySubtotal)})`}
                </p>
                <p data-testId="vat" className={tabStyles.titleRowParagraph}>
                    {this.tr("VAT")}: <strong>{`${presentCurrency(totals?.vatTotal || 0)}`}</strong>
                    {showCompanyTotalSeparately && ` (${presentInCompanyCurrency(companyVatTotal)})`}
                </p>
                <p data-testId="total" className={tabStyles.titleRowParagraph}>
                    {this.tr("Total")}: <strong>{`${presentCurrency(totals?.total || 0)}`}</strong>
                    {showCompanyTotalSeparately && ` (${presentInCompanyCurrency(companyTotal)})`}
                </p>
            </div>
        )
    }

    renderExplainerDetails = () => {
        const { expense, showCompanyName, dropdownData: { quotes, quote_rows, projects, customers, currencies, payment_types } } = this.state;
        const { expenseType } = this.props;

        return (
            <SliderFieldGroup
                editingDisabled
                showAll={false}
                items={[expense]}
                fields={[
                    {
                        key: "companyName",
                        title: this.tr("Company"),
                        isHidden: () => !showCompanyName
                    },
                    {
                        key: "accountId",
                        title: this.tr("Account"),
                        type: "data_select",
                        options: customers,
                    },
                    {
                        key: "projectId",
                        title: this.tr("Project"),
                        type: "data_select",
                        options: projects,
                        formatValue: (value: string, item: any) => {
                            return item?.projectNumber 
                                ? `${value} (${item?.projectNumber})`
                                : value;
                        }
                    },
                    {
                        key: "quoteId",
                        title: this.tr("Quote"),
                        type: "data_select",
                        options: quotes,
                    },
                    {
                        key: "quoteRowId",
                        title: this.tr("Quote row"),
                        type: "data_select",
                        options: quote_rows,
                    },
                    expenseType == "1" && {
                        key: "paymentTypeId",
                        title: this.tr("Payment type"),
                        type: "data_select",
                        options: payment_types,
                    },
                    {
                        key: "description",
                        title: this.tr("Description"),
                    },
                    expenseType == "1" && {
                        key: "expenseDate",
                        title: this.tr("Date"),
                        type: "date",
                    },
                    expenseType == "1" && currencies?.length > 0 && {
                        key: "currency",
                        title: this.tr("Currency"),
                    },
                    expenseType == "1" && currencies?.length > 0 && {
                        key: "currencyRate",
                        title: this.tr("Rate"),
                        displayFunc: (val) => CurrencyUtils.roundNumber(Number(val), 6)
                    },
                    expenseType == "2" && {
                        key: "route",
                        title: this.tr("Route"),
                    },
                    expenseType == "2" && {
                        key: "startDate",
                        title: this.tr("Start date"),
                        type: "date",
                    },
                    expenseType == "2" && {
                        key: "startTime",
                        title: this.tr("Start time"),
                        type: "time",
                    },
                    expenseType == "2" && {
                        key: "endDate",
                        title: this.tr("End date"),
                        type: "date",
                    },
                    expenseType == "2" && {
                        key: "endTime",
                        title: this.tr("End time"),
                        type: "time",
                    },
                    {
                        key: "billCustomer",
                        title: this.tr("Bill customer"),
                        type: "switch",
                        showFalse: true,
                        forceVisible: true
                    },
                ]}
            />
        );
    };

    renderExpenseDetailsExplainer = (header = "") => {
        return (
            <div className={styles.explainer}>
                {header && <h2>{header}</h2>}
                {this.renderExplainerTotals()}
                {this.renderExplainerDetails()}
            </div>
        )
    }

    renderExplainer = (currentPage) => {
        const { expenseType, onBoarding } = this.props;

        if (onBoarding && currentPage == this.rowEditPage) {
            return {
                image: <AddRowsIcon/>,
                header: this.tr("Add rows to your expense"),
                description: this.htmlTr('What have you purchased? Start adding expense rows and their attachments to your expense here.', {
                    linebreak: [<br />, <br />],
                }),
            };
        }

        let header = expenseType == "1" ? this.tr('Expense details') : this.tr('Travel expense details');
        const previewHeader = expenseType == "1" ? this.tr('Preview your expense') : this.tr('Preview your travel expense');
        let previewDescription = expenseType == "1" ? this.tr("preview_text_normal") : this.tr("preview_text_travel");
        let createDescription = this.tr("Start filling in the details on the left to see them here.");
        
        if (onBoarding) {
            createDescription = this.htmlTr("Let's take you through creating your first expense.${linebreak} Start filling in the details on the left to see them here.", {linebreak: [<br />, <br />]});
            previewDescription = this.tr("preview_text_onboarding");
            header = this.tr("Creating your first expense");
        }

        switch (currentPage) {
            case this.detailsPage:
                return {
                    image: <CreateNewIcon/>,
                    header: header,
                    description: createDescription
                };
            case this.previewPage:
                return {
                    image: <PreviewIcon/>,
                    header: previewHeader,
                    description: previewDescription
                };
        }
        return this.renderExpenseDetailsExplainer(header);
    };

    renderPaymentTypeEditor = () => {
        const { paymentTypes, loadingPaymentTypes } = this.state;
        return (
            <div className={`${styles.content}`} style={{ width: 700 }}>
                <div>
                    <div className={wizardStyles.box}>
                        <div className={wizardStyles.title}>
                            <div className={wizardStyles.icon}>
                                <Edit />
                            </div>
                            <h2>{this.tr('Edit payment types to ones that suit your company')}</h2>
                        </div>
                        {loadingPaymentTypes ? (
                            <Loading className={styles.loader} />
                        ) : (
                            paymentTypes.map((pt) => {
                                return (
                                    <div key={pt.id} className={styles.row}>
                                        <OutlinedField label={this.tr('Name')} name="name" value={pt.name} onChange={(e) => this.onEditPaymentType(pt.id, e)} />
                                        <button onClick={() => this.onDeletePaymentType(pt.id)}>
                                            <Delete />
                                        </button>
                                    </div>
                                );
                            })
                        )}
                        {!loadingPaymentTypes && (
                            <button className={wizardStyles.secondaryAction} onClick={this.onAddPaymentType}>
                                <AddCircleOutline />
                                {this.tr('Add payment type')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    savePaymentTypes = async () => {
        const promises: any = [];
        this.state.paymentTypes.forEach((pt) => {
            if (pt.name) {
                if (Number(pt.id) <= 0) {
                    promises.push(this.saveNewPaymentType(pt));
                } else if (pt.editedInWizard) {
                    promises.push(this.savePaymentTypeEdit(pt));
                }
            }
        });
        const responses = await Promise.all(promises);
        return responses;
    };

    deletePaymentTypes = async () => {
        const promises: any = [];
        this.state.deletedPaymentTypes.forEach((dp) => {
            promises.push(DataHandler.delete({ url: `expenses/payment_type` }, { id: dp.id }));
        });
        const responses = await Promise.all(promises);
        return responses;
    };

    afterPaymentTypeEdits: () => Promise<'next'> = () => {
        return new Promise((resolve) => {
            this.savePaymentTypes().then(() => {
                this.deletePaymentTypes().then(() => {
                    resolve('next');
                });
            });
        });
    };

    savePaymentTypeEdit = async (paymentType) => {
        const response = await DataHandler.post({ url: `expenses/payment_type` }, { data: { ...paymentType, company: this.context.userObject.companies_id } });
        return response;
    };

    saveNewPaymentType = async (paymentType) => {
        const response = await DataHandler.post({ url: `expenses/payment_type` }, { data: { ...paymentType, company: this.context.userObject.companies_id } });
        return response;
    };

    onEditPaymentType = (id, { target: { name, value } }) => {
        const paymentTypes = cloneDeep(this.state.paymentTypes);
        const index = paymentTypes.findIndex((jt) => jt.id == id);
        if (index != -1) {
            const paymentType = {
                ...paymentTypes[index],
                [name]: value,
                editedInWizard: true,
            };
            paymentTypes[index] = paymentType;
            this.setState({ paymentTypes });
        }
    };

    onDeletePaymentType = (id) => {
        const paymentTypes = cloneDeep(this.state.paymentTypes);
        const index = paymentTypes.findIndex((jt) => jt.id == id);
        if (index != -1) {
            const paymentType = paymentTypes[index];
            paymentTypes.splice(index, 1);
            const deletedPaymentTypes = cloneDeep(this.state.deletedPaymentTypes);
            if (Number(id) > 0 && deletedPaymentTypes.findIndex((dp) => dp.id == id) == -1) {
                deletedPaymentTypes.push(paymentType);
            }
            this.setState({ paymentTypes, deletedPaymentTypes });
        }
    };

    onAddPaymentType = () => {
        const paymentTypes = cloneDeep(this.state.paymentTypes);
        paymentTypes.push({ id: -(paymentTypes.length + 1), name: '', type: 0 });
        this.setState({ paymentTypes });
    };

    renderExpenseTypeEditor = () => {
        const { expenseTypes, loadingExpenseTypes } = this.state;
        return (
            <div className={`${styles.content}`} style={{ width: 700 }}>
                <div>
                    <div className={wizardStyles.box}>
                        <div className={wizardStyles.title}>
                            <div className={wizardStyles.icon}>
                                <Edit />
                            </div>
                            <h2>{this.tr('Edit expense types to ones that suit your company')}</h2>
                        </div>
                        {loadingExpenseTypes ? (
                            <Loading className={styles.loader} />
                        ) : (
                            expenseTypes.map((et) => {
                                return (
                                    <div key={et.id} className={styles.row}>
                                        <OutlinedField label={this.tr('Name')} name="name" value={et.name} onChange={(e) => this.onEditExpenseType(et.id, e)} />
                                        <OutlinedField validation={['numeric']} label={this.tr('VAT %')} name="vat" value={et.vat} onChange={(e) => this.onEditExpenseType(et.id, e)} />
                                        <button onClick={() => this.onDeleteExpenseType(et.id)}>
                                            <Delete />
                                        </button>
                                    </div>
                                );
                            })
                        )}
                        {!loadingExpenseTypes && (
                            <button className={wizardStyles.secondaryAction} onClick={this.onAddExpenseType}>
                                <AddCircleOutline />
                                {this.tr('Add expense type')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    saveExpenseTypes = async () => {
        const promises: any = [];
        this.state.expenseTypes.forEach((et) => {
            if (Number.isFinite(Number(et.vat))) {
                if (Number(et.id) <= 0) {
                    promises.push(this.saveNewExpenseType(et));
                } else if (et.editedInWizard) {
                    promises.push(this.saveExpenseTypeEdit(et));
                }
            }
        });
        const responses = await Promise.all(promises);
        return responses;
    };

    deleteExpenseTypes = async () => {
        const promises: any = [];
        this.state.deletedExpenseTypes.forEach((de) => {
            promises.push(DataHandler.delete({ url: `settings/delete_custom_expense_type/${de.id}` }, de));
        });
        const responses = await Promise.all(promises);
        return responses;
    };

    afterExpenseTypeEdits: () => Promise<'next'> = () => {
        return new Promise((resolve) => {
            this.saveExpenseTypes().then(() => {
                this.deleteExpenseTypes().then(() => {
                    this.fetcAutocompleteData();
                    resolve('next');
                });
            });
        });
    };

    saveExpenseTypeEdit = async (expenseType) => {
        const response = await DataHandler.post(
            { url: `settings/update_custom_expense_type/${expenseType.id}` },
            { data: JSON.stringify({ ...expenseType, company: this.context.userObject.companies_id }) }
        );
        return response;
    };

    saveNewExpenseType = async (expenseType) => {
        const response = await DataHandler.post({ url: `settings/add_custom_expense_type/${this.context.userObject.companies_id}` }, { data: JSON.stringify(expenseType) });
        return response;
    };

    formatVATValue = (value) => {
        if (!value) return value;
        let formatted = String(value).replace(',', '.');
        formatted = Utils.truncateDecimals(formatted, 2, false);
        return formatted;
    };

    onEditExpenseType = (id, { target: { name, value } }) => {
        const expenseTypes = cloneDeep(this.state.expenseTypes);
        const index = expenseTypes.findIndex((jt) => jt.id == id);
        if (index != -1) {
            const formattedValue = name == 'vat' && value != '' ? this.formatVATValue(value) : value;
            const expenseType = {
                ...expenseTypes[index],
                [name]: formattedValue,
                editedInWizard: true,
            };
            expenseTypes[index] = expenseType;
            this.setState({ expenseTypes });
        }
    };

    onDeleteExpenseType = (id) => {
        const expenseTypes = cloneDeep(this.state.expenseTypes);
        const index = expenseTypes.findIndex((jt) => jt.id == id);
        if (index != -1) {
            const expenseType = expenseTypes[index];
            expenseTypes.splice(index, 1);
            const deletedExpenseTypes = cloneDeep(this.state.deletedExpenseTypes);
            if (Number(id) > 0 && deletedExpenseTypes.findIndex((de) => de.id == id) == -1) {
                deletedExpenseTypes.push(expenseType);
            }
            this.setState({ expenseTypes, deletedExpenseTypes });
        }
    };

    onAddExpenseType = () => {
        const expenseTypes = cloneDeep(this.state.expenseTypes);
        expenseTypes.push({ id: -(expenseTypes.length + 1), name: '', vat: null });
        this.setState({ expenseTypes });
    };

    onClose = (currentPage) => {
        if (this.props.onBoarding && currentPage == this.state.pages.length) {
            this.onOnboardingDone();
        }
    }

    hasDetailsChanged = () => {
        const { expense } = this.state;

        let detailsChanged = false;
        Object.keys(this.initialState).forEach(s => {
            if (expense[s] != this.initialState[s])
                detailsChanged = true;
        })

        return detailsChanged
    }

    showCloseConfirmation = () => {
        const currentPage = this.wizard.current ? this.wizard.current.getCurrentPage() : this.detailsPage;
        return currentPage >= this.detailsPage && currentPage < this.completedPage && this.hasDetailsChanged();
    }

    onPageChanged = (currentPage) => {
        if (this.showSettingsEditPages() && currentPage == this.paymetTypesPage) {
            this.setState({ dropdownDataFetched: false }); // Put dropdownDataFetched false so new dropdownData will be fetched when detailspage is opened.
            this.getPaymentTypes();
        }
        else if (this.showSettingsEditPages() && currentPage == this.expenseTypesPage) {
            this.setState({ dropdownDataFetched: false }); // Put dropdownDataFetched false so new dropdownData will be fetched when detailspage is opened.
            this.getExpenseTypes();
        }
    }

    render() {
        const { pages } = this.state;
        const { expenseType } = this.props;

        return (
            <>
                {this.shouldRenderExpenseViewNoRender() && this.renderExpenseViewNoRender()}
                <TaimerWizard
                    showCloseConfirmation={this.showCloseConfirmation}
                    disableDefaultCloseConfirmation={true}
                    hideRight={this.shouldRenderOverlay()}
                    ref={this.wizard}
                    title={expenseType == "1" ? this.tr('Create expense') : this.tr('Create travel expense')}
                    pages={pages}
                    onClose={(currentPage) => this.onClose(currentPage)}
                    onPageChanged={(currentPage) => this.onPageChanged(currentPage)}
                />
            </>
        );
    }
}

export default withSnackbar(ExpenseWizard);
