import React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import TaimerComponent from "../TaimerComponent";
import DataHandler from '../general/DataHandler';
import PhotoIcon from '@mui/icons-material/PhotoOutlined'
import classNames from 'classnames';
import $ from 'jquery';
import { SettingsContext } from '../SettingsContext';
import FileResizer from 'react-image-file-resizer';

const styles = theme => createStyles({
    icon: {
        color: 'white',
        fontSize: '70px',
        height: '40px',
        width: '40px'
    },
    logoContainer: {
        gridArea: "logo",
        display: "grid",
        height: "100%",
        width: "100%",
    },
    imgContainer: {
        gridColumn: "1",
        gridRow: "1"
    },
    logoText: {
        position: "absolute",
        fontSize: "12px",
        maxWidth: "20%",
        paddingLeft: "10px",
        color: "rgb(249, 249, 249)",
        zIndex: 3

    }
});

interface Props extends WithSnackbarProps {
    base64Encode: boolean;
    company: string;
    editMode: boolean;
    hideWhenNotInEditMode?: boolean;
    onLogoLoaded?: () => void;
    shrinkLogo?: boolean;
}

type FinalProps = Props & WithStyles<typeof styles>;

interface State {
    logoUrl: string; 
    logoBase64: string; 
    imageLoaded: boolean; 
    loadingImage: boolean;
}

class Logo extends TaimerComponent<FinalProps, State> {
    static contextType = SettingsContext;
    refFileInput: React.RefObject<HTMLInputElement> = React.createRef();
    constructor(props, context?) {
        super(props, context, "invoices/Logo");

        this.state = {
            imageLoaded: true,
            loadingImage: true,
            logoUrl: this.getLogoUrl(),
            logoBase64: ""
        }
    }

    isImageLoaded = () => {
        return this.state.imageLoaded;
    }

    shrinkLogo = (file) =>{
        return new Promise<string>((resolve) => {
            FileResizer.imageFileResizer(
                file,
                200,
                200,
                "PNG",
                100,
                0,
                (uri) => {
                    if (typeof uri == 'string') resolve(uri);
                },
                "base64"
            );
        });
    }

    getLogoUrl = () => {
        if (this.props.base64Encode == true) {
            DataHandler.get({url: `/settings/company/${this.props.company}/logoBase64`}).done(response => {
                if (response.logoString != "") {
                    if (this.props.shrinkLogo) {
                        fetch(response.logoString)
                        .then(async res => {
                            const blob = await res.blob();
                            this.shrinkLogo(blob).then((shrinked: string) => {
                                this.setState({logoBase64: shrinked}, () => this.onLogoLoaded())
                            })
                        })
                    } else {
                        this.setState({logoBase64: response.logoString}, () => this.onLogoLoaded())
                    }
                }
                else {
                    this.onLogoLoaded();
                }
            }).fail(err => {
                this.onLogoLoaded();
            })
        } 
        else {
            this.onLogoLoaded();
        }

        const params = {
            attachment: "company_logo",
            id: this.props.company,
            t: this.context.taimerAccount.logo,
            _auth: this.context.functions.getStorage().taimerToken,
        };
        
        return this.context.functions.getDownloadPath() + $.param(params);
    }

    onLogoLoaded = () => {
        // Making sure the image is rendered – sometimes hadn't in quote print
        setTimeout(() => {
            this.props.onLogoLoaded && this.props.onLogoLoaded();
        }, 500);
    }

    upload = () => {
        if (!this.props.editMode)
            return;

        this.refFileInput.current && this.refFileInput.current.click();
    }

    uploadClick = () => {
        this.refFileInput.current && this.refFileInput.current.click();
    }

    uploadLogo  = (e) => {
        const file = e.target.files[0];
        e.target.value = '';

        if (file.type != 'image/png' && file.type != 'image/jpeg') {
            this.props.enqueueSnackbar(this.tr("Only jpeg and png are supported"), {
                variant: "error",
            });
            return;
        }
        else if (file.size > 10 * 1024 * 1024) { // 10 MB
            this.props.enqueueSnackbar(this.tr('File is too large (max 10 MB)'), {
                variant: 'error',
            });
            return;
        }
        
        const formData = new FormData();

        formData.append('avatar', file);

        DataHandler.file({url: `settings/company/${this.props.company}/logoupload` }, file).always(() => {
            // To Ensure logo is not cached
            setTimeout(() => {
                this.setState({logoUrl: this.getLogoUrl() + "&d=" + new Date(), imageLoaded: true});
            }, 1000);
        });

    }
    
    componentDidUpdate(prevProps, prevState) {
        prevProps.company !== this.props.company && this.setState({logoUrl: this.getLogoUrl() + "&d=" + new Date()});
    }
    
    render() {
        const { classes, editMode, hideWhenNotInEditMode } = this.props;
        const { logoUrl, logoBase64, imageLoaded, loadingImage } = this.state;

        const layerStyle = {display: imageLoaded ? 'none' : (hideWhenNotInEditMode && !editMode ? 'none' : 'flex')};

        return (<React.Fragment>
            <div className={`${classNames(classes.logoContainer)} ${this.props.editMode ? 'editable-logo' : ''}`} onClick={this.upload}>
                <div className={classNames(classes.imgContainer)} style={{opacity: loadingImage ? 0 : 1}}>
                    {imageLoaded && <img className={`company-logo`} alt="" src={logoBase64 != "" ? logoBase64 : logoUrl} onError={() => this.setState({ imageLoaded: false, loadingImage: false })} onLoad={() => this.setState({ imageLoaded: true, loadingImage: false })} />}
                </div>
                <div className={`invoice-logo`} style={layerStyle}>
                    <PhotoIcon classes={{root: classes.icon}} />
                </div>
                {!imageLoaded && <p className={classNames(classes.logoText)}>{this.tr("Upload Logo")} (jpg/png, {this.tr("size")} 217 x 177)</p>}
            </div>
            <input ref={this.refFileInput} onChange={this.uploadLogo} type="file" accept="image/png,image/jpeg" style={{ display: 'none' }} />
        </React.Fragment>)
    }
}


const withStylesComponent: any = withStyles(styles)(Logo);
export default withSnackbar<Props>(withStylesComponent);
