import React, { ReactNode } from 'react';
import moment from 'moment/min/moment-with-locales';
import { Button, MenuItem, Tooltip } from '@mui/material';
import { cloneDeep } from 'lodash';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Check from '@mui/icons-material/Check';
import Delete from '@mui/icons-material/Delete';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { DateRangePopper } from './react-date-range/src/components/DateRangePicker';
import TaimerComponent from '../TaimerComponent';
import Slider from './Slider';
import SearchTextInput from './SearchTextInput';
import InsightDropDown from '../dashboard/insights/InsightDropDown';
import CoreDialog from '../dialogs/mass_operations/CoreDialog';
import DataHandler from './DataHandler';
import ContextMenu from './ContextMenu';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

import styles from './PersonalNote.module.scss';

interface Props {}

interface Note {
    content: string;
    due?: string | null;
    id: string;
    done: '0' | '1';
}

interface State {
    open: boolean;
    newNote: Note;
    datePickerAnchor?: ReactNode;
    datePickerRow?: any;
    notes: Note[];
    listSettings: { sortBy: string; status: string };
    loading: boolean;
    confirmDialogData?: any;
    focusedNote?: any;
    slideOutTransform: number;
    slideOutOpacity: number;
}

class PersonalNote extends TaimerComponent<Props, State> {
    emptyNote: Note = {
        id: '-1',
        content: '',
        done: '0',
    };
    input: any = React.createRef();
    updateNotesTimeout: any;
    menuRefs: any = {};
    searchValue = '';
    notesToUpdate: Note[] = [];
    isEditingNote = false;
    sortByItems = [
        { key: 'created_on', label: this.tr('Creation Date'), action: () => this.setListSetting('sortBy', 'created_on') },
        { key: 'due', label: this.tr('Due Date'), action: () => this.setListSetting('sortBy', 'due') },
    ];
    statusItems = [
        { key: 'all', 'data-testid':'show_all_personalnotes', label: this.tr('All'), action: () => this.setListSetting('status', 'all') },
        { key: 'undone', label: this.tr('Undone'), action: () => this.setListSetting('status', 'undone') },
        { key: 'done', label: this.tr('Done'), action: () => this.setListSetting('status', 'done') },
        // { key: 'due', label: this.tr('Due'), action: () => this.setListSetting('status', 'due') },
        // { key: 'overdue', label: this.tr('Overdue'), action: () => this.setListSetting('status', 'overdue') },
    ];
    constructor(props, context) {
        super(props, context, 'general/PersonalNote');
        const listSettings = this.getListSettingsFromLS();
        this.state = {
            open: false,
            listSettings,
            slideOutTransform: 0,
            slideOutOpacity: 1,
            loading: false,
            notes: [],
            newNote: { ...this.emptyNote },
        };
    }

    componentDidUpdate = (_, prevState) => {
        if (prevState.open !== this.state.open && this.state.open) {
            this.getData();
        }
    };

    setListSetting = (key, value) => {
        this.setState(
            {
                listSettings: {
                    ...this.state.listSettings,
                    [key]: value,
                },
            },
            this.getData
        );
    };

    getListSettingsFromLS = () => {
        const listSettings = {
            sortBy: 'created_on',
            status: 'undone',
        };
        try {
            listSettings.sortBy = localStorage.getItem('personalNotesSortBy') || 'created_on';
            listSettings.status = localStorage.getItem('personalNotesStatus') || 'undone';
        } catch (e) {
            console.error(e);
        }
        return listSettings;
    };

    saveListSettingsToLS = () => {
        const {
            listSettings: { sortBy, status },
        } = this.state;
        try {
            localStorage.setItem('personalNotesSortBy', sortBy);
            localStorage.setItem('personalNotesStatus', status);
        } catch (e) {
            console.error(e);
        }
    };

    getData = (setLoading = true) => {
        this.setState({ loading: setLoading }, () => {
            const {
                listSettings: { sortBy: sort_by, status },
            } = this.state;
            this.saveListSettingsToLS();
            DataHandler.get({ url: `personal_notes`, search: this.searchValue, sort_by, status })
                .done((notes) => {
                    this.setState({ notes, loading: false });
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    };

    open = () => this.setState({ open: true });
    close = () => {
        if (!!this.state.newNote?.content || !!this.isEditingNote) {
            this.showConfirmDialog({
                header: this.tr('Close slider'),
                translatedConfirmButtonText: this.tr('Close slider'),
                warning: () =>
                    this.isEditingNote
                        ? this.tr("Are you sure you want to close the slider? Seems like you're currently editing a note.")
                        : this.tr('Are you sure you want to close the slider? All unsaved changes will be lost.'),
                onConfirm: () => this.setState({ open: false, confirmDialogData: undefined }),
            });
        } else {
            this.setState({ open: false });
        }
    };

    showDatePicker = (e, datePickerRow?) => this.setState({ datePickerAnchor: e.target, datePickerRow });
    closeDatePicker = () => this.setState({ datePickerAnchor: undefined, datePickerRow: undefined });

    setDueDate = (date) => {
        if (this.state.datePickerRow) {
            let notes = cloneDeep(this.state.notes);
            const foundIndex = notes.findIndex((n) => n.id == this.state.datePickerRow.id);
            if (foundIndex != -1) {
                const note = {
                    ...notes[foundIndex],
                    due: moment(date).format('YYYY-MM-DD'),
                };
                notes[foundIndex] = note;
                notes = this.sortAndFilter(notes);
                this.setState({ notes }, () => {
                    this.updateNote(note);
                });
            }
        } else {
            if (this.input.current) {
                this.input.current.blur();
                window.getSelection()?.removeAllRanges();
            }
            this.setState({
                newNote: {
                    ...this.state.newNote,
                    due: moment(date).format('YYYY-MM-DD'),
                },
            });
        }
    };

    removeDueDate = () => {
        if (this.state.datePickerRow) {
            let notes = cloneDeep(this.state.notes);
            const foundIndex = notes.findIndex((n) => n.id == this.state.datePickerRow.id);
            if (foundIndex != -1) {
                const note = {
                    ...notes[foundIndex],
                    due: null,
                };
                //@ts-ignore
                notes[foundIndex] = note;
                notes = this.sortAndFilter(notes);
                this.setState({ notes }, () => {
                    this.updateNote(note);
                    this.closeDatePicker();
                });
            }
        } else {
            if (this.input.current) {
                this.input.current.blur();
                window.getSelection()?.removeAllRanges();
            }
            this.setState(
                {
                    newNote: {
                        ...this.state.newNote,
                        due: undefined,
                    },
                },
                () => {
                    this.closeDatePicker();
                }
            );
        }
    };

    setNewNoteText = (e) => {
        if (!e.currentTarget.textContent) {
            e.currentTarget.innerHTML = '';
        }
        this.setState({
            newNote: {
                ...this.state.newNote,
                content: e.currentTarget.textContent,
            },
        });
    };

    setNoteText = (e, note) => {
        const notes = cloneDeep(this.state.notes);
        const newNote = {
            ...note,
            content: e.currentTarget.textContent,
        };
        const foundIndex = notes.findIndex((n) => n.id == note.id);
        if (foundIndex != -1) {
            notes[foundIndex] = newNote;
            this.setState(
                {
                    notes,
                },
                () => {
                    this.updateNote(newNote);
                }
            );
        }
    };

    addNote = () => {
        if (this.input.current) {
            this.input.current.blur();
            this.input.current.innerHTML = '';
            window.getSelection()?.removeAllRanges();
        }
        if (!this.state.newNote.content) return;
        let notes = cloneDeep(this.state.notes);
        const newNote = { ...this.state.newNote, created_on: moment().format('YYYY-MM-DD HH:mm:ss') };
        if (!newNote.content) return;
        notes.unshift(newNote);
        notes = this.sortAndFilter(notes);
        this.setState({ notes, newNote: { ...this.emptyNote } }, () => {
            DataHandler.post({ url: `personal_notes` }, { content: newNote.content, due: newNote.due }).done((res) => {
                const id = res.id;
                const newNotes = cloneDeep(this.state.notes);
                // @ts-ignore TODO: tarkista tämä
                const newNoteIndex = newNotes.findIndex((n) => n.id == -1 && n.content == newNote.content);
                if (newNoteIndex != -1) {
                    newNotes[newNoteIndex].id = id;
                    this.setState({
                        notes: newNotes,
                    });
                }
            });
            // setTimeout(() => {
            //     this.getData(false);
            // }, 1000);
        });
    };

    showConfirmDialog = (confirmDialogData) => this.setState({ confirmDialogData });
    closeConfirmDialog = () => this.setState({ confirmDialogData: undefined });

    deleteNote = () => {
        const note = this.state.confirmDialogData?.note;
        if (!note) return;
        const notes = cloneDeep(this.state.notes);
        const foundIndex = notes.findIndex((n) => n.id == note.id);
        if (foundIndex != -1) {
            notes.splice(foundIndex, 1);
        }
        this.setState({ notes, confirmDialogData: undefined }, () => {
            DataHandler.delete({ url: `personal_notes/${note.id}` }).done(() => {
                // setTimeout(() => {
                //     this.getData(false);
                // }, 1000);
            });
        });
    };

    updateNote = (note) => {
        DataHandler.put({ url: `personal_notes/${note.id}` }, { content: note.content, due: note.due, done: note.done }).done(() => {
            // setTimeout(() => {
            //     this.getData(false);
            // }, 1000);
        });
    };

    updateNotes = () => {
        if ((this.notesToUpdate || []).length == 0) return;
        this.setState({ slideOutTransform: this.state.listSettings.status == 'undone' ? 600 : 0, slideOutOpacity: this.state.listSettings.status == 'undone' ? 0.5 : 1 }, () => {
            setTimeout(() => {
                const notes = this.sortAndFilter(this.state.notes);
                this.setState({ notes, slideOutTransform: 0, slideOutOpacity: 1 }, () => {
                    DataHandler.put({ url: `personal_notes_batch` }, { notes: this.notesToUpdate })
                        .done(() => {
                            this.notesToUpdate = [];
                            // setTimeout(() => {
                            //     this.getData(false);
                            // }, 1000);
                        })
                        .catch((err) => {
                            console.error(err);
                            this.notesToUpdate = [];
                        });
                });
            }, 300);
        });
    };

    getDueTextAndClassName = (due) => {
        if (!due) return { text: '', className: '' };
        const today = moment();
        const dueMoment = moment(due);
        let text = dueMoment.format(this.context.userObject.dateFormat);
        let className = '';
        if (dueMoment.isBefore(today, 'day')) {
            className = styles.overdue;
        }
        if (dueMoment.isSame(today, 'day')) {
            text = this.tr('Today');
            className = styles.today;
        } else if (dueMoment.isSame(moment().subtract(1, 'day'), 'day')) {
            text = this.tr('Yesterday');
        } else if (dueMoment.isSame(moment().add(1, 'day'), 'day')) {
            className = styles.tomorrow;
            text = this.tr('Tomorrow');
        }
        return { text, className };
    };

    sortAndFilter = (notes) => {
        const {
            listSettings: { sortBy, status },
        } = this.state;
        const today = moment();
        return cloneDeep(notes)
            .filter((note) => {
                switch (status) {
                    case 'undone':
                        return note.done == 0;
                    case 'done':
                        return note.done == 1;
                    case 'due':
                        return note.due && moment(note.due).isSameOrAfter(today, 'day');
                    case 'overdue':
                        return note.due && moment(note.due).isBefore(today, 'day');
                }
                return true;
            })
            .sort((a, b) => {
                switch (sortBy) {
                    case 'created_on': {
                        const aDate: any = moment(a.created_on).toDate();
                        const bDate: any = moment(b.created_on).toDate();
                        return bDate - aDate;
                    }
                    case 'due': {
                        const aDate: any = a.due && moment(a.due).toDate();
                        const bDate: any = b.due && moment(b.due).toDate();
                        if (!aDate && bDate) return 1;
                        if (aDate && !bDate) return -1;
                        if (!aDate && !bDate) {
                            const aCreatedDate: any = moment(a.created_on).toDate();
                            const bCreatedDate: any = moment(b.created_on).toDate();
                            return bCreatedDate - aCreatedDate;
                        }
                        return aDate - bDate;
                    }
                }
            });
    };

    onInputKeyDown = (e) => {
        if (e.key == 'Enter') {
            if (!e.shiftKey) {
                this.addNote();
            } else {
                document.execCommand('insertLineBreak');
            }
            e.preventDefault();
        }
    };

    onInputKeyUp = (e) => {
        if (e.key == 'Enter') {
            e.preventDefault();
            return;
        }
        this.setNewNoteText(e);
    };

    onNoteInputKeyDown = (e) => {
        if (e.key == 'Enter') {
            if (!e.shiftKey) {
                e.currentTarget.blur && e.currentTarget.blur();
                window.getSelection()?.removeAllRanges();
            } else {
                document.execCommand('insertLineBreak');
            }
            e.preventDefault();
        }
        this.isEditingNote = true;
    };

    toggleNoteDone = (note) => {
        if (note.id == -1) return;
        clearTimeout(this.updateNotesTimeout);
        const notes = cloneDeep(this.state.notes);
        const index = notes.findIndex((n) => n.id == note.id);
        if (index != -1) {
            notes[index].done = notes[index].done == '1' ? '0' : '1';
            const foundIndex = this.notesToUpdate.findIndex((n) => n.id == notes[index].id);
            if (foundIndex != -1) {
                this.notesToUpdate.splice(foundIndex, 1);
            }
            this.notesToUpdate.push(cloneDeep(notes[index]));
            this.setState({ notes }, () => {
                this.updateNotesTimeout = setTimeout(() => {
                    this.updateNotes();
                }, 1500);
            });
        }
    };

    searchInputKeyPressed = (event) => {
        this.searchValue = event.currentTarget.value;
        if (event.keyCode === 13 || event.currentTarget.value === '') {
            this.getData();
        }
    };

    decodeContent = (content) => {
        const replaced = content.split('%0A').join('\n');
        try {
            return decodeURIComponent(replaced);
        } catch (e) {
            return replaced;
        }
    };

    onInputPaste = (e) => {
        e.preventDefault();
        const text = (e.originalEvent || e).clipboardData.getData('text/plain');
        document.execCommand('insertHTML', false, text);
    };

    setFocusedNote = (focusedNote) => this.setState({ focusedNote });

    render() {
        const {
            open,
            newNote,
            notes,
            loading,
            confirmDialogData,
            focusedNote,
            listSettings: { sortBy, status },
            slideOutTransform,
            slideOutOpacity,
        } = this.state;
        return (
            <Slider id={styles.personalNote} mainContentClassName={styles.slider} title={this.tr('Personal Notes')} open={open} onClose={this.close}>
                <div className={styles.inputContainer}>
                    <span
                        onKeyDown={this.onInputKeyDown}
                        onKeyUp={this.onInputKeyUp}
                        onPaste={this.onInputPaste}
                        ref={this.input}
                        placeholder={this.tr('Write your note here')}
                        contentEditable={true}
                        spellCheck={false}
                        className={styles.input}
                        data-testid={"personal-input"}
                    />
                    <div className={styles.actions}>
                        <div className={styles.selections}>
                            <button  data-testid="set-due-date"  onClick={this.showDatePicker}>
                                <CalendarToday />
                                <p>{newNote.due ? this.getDueTextAndClassName(newNote.due).text : this.tr('Set due date')}</p>
                            </button>
                        </div>
                        <Button data-testid="add-note" disabled={!newNote.content} onClick={this.addNote}>
                            {this.tr('Add Note')}
                        </Button>
                    </div>
                </div>
                <div className={styles.filters}>
                    <SearchTextInput placeholder={this.tr('Search')} onKeyUp={this.searchInputKeyPressed} onSearchPressed={this.getData} />
                    <div className={styles.dropdowns}>
                        {
                            //@ts-ignore
                            <InsightDropDown title={this.tr('Sort by')} tabs={this.sortByItems} selected={sortBy} />
                        }
                        {
                            //@ts-ignore
                            <InsightDropDown title={this.tr('Status')} tabs={this.statusItems} selected={status} />
                        }
                    </div>
                </div>
                {loading ? (
                    <Loading  />
                ) : notes.length == 0 ? (
                    <div className={styles.noContent}>
                        <Loading  />
                        <h1>{this.tr('Nothing to show.')}</h1>
                        <p>{this.tr('Start creating personal notes to see them here.')}</p>
                    </div>
                ) : (
                    <ul data-testid="personalnotes-rows">
                        {notes.map((note, i) => {
                            const { text: dueText, className: dueClassName } = this.getDueTextAndClassName(note.due);
                            return (
                                <li
                                    style={this.notesToUpdate.findIndex((n) => n.id == note.id) != -1 ? { transform: `translateX(${slideOutTransform}px)`, opacity: slideOutOpacity } : undefined}
                                    className={note.id == '-1' ? styles.disabled : ''}
                                    key={`${note.id}_${i}`}
                                >
                                    <div onClick={() => this.toggleNoteDone(note)} className={`${styles.checkbox} ${note.done == '1' && styles.done}`}>
                                        <Check />
                                    </div>
                                    <div className={styles.content}>
                                        <div className={styles.text}>
                                            {focusedNote?.id != note.id && <div className={styles.hoverContainer} />}
                                            <span
                                                onFocus={() => {
                                                    this.setFocusedNote(note);
                                                }}
                                                onPaste={this.onInputPaste}
                                                onKeyDown={this.onNoteInputKeyDown}
                                                placeholder={this.tr('Write your note here')}
                                                onBlur={(e) => {
                                                    this.setFocusedNote(undefined);
                                                    this.setNoteText(e, note);
                                                    setTimeout(() => {
                                                        this.isEditingNote = false;
                                                    }, 500);
                                                }}
                                                contentEditable={true}
                                                spellCheck={false}
                                            >
                                                {this.decodeContent(note.content)}
                                            </span>
                                        </div>
                                        {note.due && (
                                            <div className={`${styles.due} ${dueClassName}`}>
                                                <CalendarToday />
                                                {dueText}
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.hoverActions}>
                                        <Tooltip title={this.tr('Set due date')}>
                                            <button onClick={(e) => this.showDatePicker(e, note)}>
                                                <CalendarToday />
                                            </button>
                                        </Tooltip>
                                        <Tooltip title={this.tr('Delete note')}>
                                            <button
                                                onClick={() =>
                                                    this.showConfirmDialog({
                                                        note,
                                                        header: this.tr('Delete note'),
                                                        translatedConfirmButtonText: this.tr('Delete'),
                                                        warning: () => this.tr('Are you sure you want to delete this note?'),
                                                        onConfirm: this.deleteNote,
                                                    })
                                                }
                                            >
                                                <Delete />
                                            </button>
                                        </Tooltip>
                                    </div>
                                    {
                                        //@ts-ignore
                                        <ContextMenu data-testid="context-menu" customGrowId={styles.menu} disablePortal={false} buttonRef={(ref) => (this.menuRefs[note.id] = ref)} label={<MoreHoriz />} noExpandIcon>
                                            <MenuItem onClick={(e) => this.showDatePicker({ target: this.menuRefs[note.id] || e }, note)}>
                                                <CalendarToday />
                                                {this.tr('Set due date')}
                                            </MenuItem>
                                            <MenuItem
                                                className={styles.delete}
                                                data-testid="delete-personalnote"
                                                onClick={() =>
                                                    this.showConfirmDialog({
                                                        note,
                                                        header: this.tr('Delete note'),
                                                        translatedConfirmButtonText: this.tr('Delete'),
                                                        warning: () => this.tr('Are you sure you want to delete this note?'),
                                                        onConfirm: this.deleteNote,
                                                    })
                                                }
                                            >
                                                <Delete />
                                                {this.tr('Delete note')}
                                            </MenuItem>
                                        </ContextMenu>
                                    }
                                </li>
                            );
                        })}
                    </ul>
                )}
                <DateRangePopper
                    ranges={[]}
                    date={(this.state.datePickerRow ? (this.state.datePickerRow.due ? moment(this.state.datePickerRow.due) : moment()) : newNote.due ? moment(newNote.due) : moment()).toDate()}
                    className={styles.dateRangePopper}
                    mode={'date'}
                    additionalBottomComponent={
                        (this.state.datePickerRow?.due || newNote.due) && (
                            <Button onClick={this.removeDueDate} className={styles.removeDueDateButton}>
                                {this.tr('Remove due date')}
                            </Button>
                        )
                    }
                    onChange={this.setDueDate}
                    open={!!this.state.datePickerAnchor}
                    handleBlur={this.closeDatePicker}
                    anchorEl={this.state.datePickerAnchor}
                />
                {confirmDialogData && (
                    <CoreDialog
                        dialogType="delete"
                        data-testid="button-delete"
                        dialogProps={{
                            onCloseClick: this.closeConfirmDialog,
                            close: this.closeConfirmDialog,
                            onCancel: this.closeConfirmDialog,
                            onConfirm: confirmDialogData.onConfirm,
                            cancelButtonText: this.tr('Cancel'),
                            header: confirmDialogData.header,
                            translatedConfirmButtonText: confirmDialogData.translatedConfirmButtonText,
                            warning: confirmDialogData.warning,
                        }}
                    />
                )}
            </Slider>
        );
    }
}

export default PersonalNote;
