import ArrowDropDownSharp from '@mui/icons-material/ArrowDropDownSharp';
import PrintDisabled from '@mui/icons-material/PrintDisabled';
import Visibility from '@mui/icons-material/Visibility';
import { Checkbox, Divider, Popover, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import FileSaver from 'file-saver';
import { clone, cloneDeep, isEqual } from 'lodash';
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { DatePicker } from '../general/react-date-range/src';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DescriptionIcon from '@mui/icons-material/Description';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TaimerComponent from '../TaimerComponent';
import colors from '../colors';
import DataHandler from '../general/DataHandler';
import FieldEditSlider from '../general/FieldEditSlider';
import LabelFieldGroup from '../general/LabelFieldGroup';
import StatusTag from '../general/StatusTag';
import { AddContact } from '../general/no-options/AddItemComponents';
import Logo from '../invoices/Logo';
import List from '../list/List';
import { ColumnHeaderButton } from '../list/ListHeader';
import { makeMapOfPrimitives } from '../list/ListUtils';
import TextAreaCell from '../list/cells/TextAreaCell';
import navicons from '../navigation/NavIcons';
import { insertAfterFn } from './../general/ArrayUtils';
import QuoteRow from './QuoteRow';
import { quoteRowTypes } from './TabQuotes';
import { ReactComponent as CalendarClockIcon } from '../general/icons/calendar_clock.svg';

import styles from './TabQuotes.module.scss';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

const ResourcingIcon = navicons.resourcing;
interface ExcelCellConfig {
    name: string;
    dataField?: string | null;
    translation: string;
    format: string;
}

interface Dictionary<T> {
    [key: string]: T;
}

interface PassedListProps {
    disableCheck: boolean;
}
interface ListProps extends Props {
    tr: any;
    activeCell: any;
    listColumns: any[];
    columnValuesPrintHidden: boolean;
    listProps: PassedListProps;
    setActiveCell: (cell: any) => void;
    createPurchaseOrder: (rows: number[]) => void;
    createInvoice: (rows: number[], headerRows: number[]) => void;
    exportToExcel: (quoteRows: any[], columnConfig: { name: string, header: string }[]) => void;
    onListMounted: () => void;
    onDraggedRowDrop: any;
    onColumnVisibilityChange: (columns: any) => void;
    isColumnHiddenFromPrint: (column: any) => boolean;
    checkForAlreadyInvoicedRows: (quoteRowIds: number[], type?: string) => Promise<number[]>
}

interface QuoteListState {
    disableCreateInvoiceButton: boolean;
}

// Quote list is in its own component just to avoid all unnecessary re-renders
class QuoteList extends TaimerComponent<ListProps, QuoteListState> {
    list: any = React.createRef();
    constructor(props, context) {
        super(props, context, 'projects/QuoteList');

        this.state = {
            disableCreateInvoiceButton: false
        };
    }

    shouldComponentUpdate = (newProps, newState) => {
        if (
            !isEqual(newProps.quoteRows, this.props.quoteRows) ||
            !isEqual(newProps.listColumns, this.props.listColumns) ||
            !isEqual(newProps.products, this.props.products) ||
            !isEqual(newProps.CPQParents, this.props.CPQParents) ||
            !isEqual(newProps.jobtypes, this.props.jobtypes) ||
            newProps.editMode != this.props.editMode ||
            newProps.activeCell != this.props.activeCell ||
            newProps.currencyFormatter != this.props.currencyFormatter ||
            this.state.disableCreateInvoiceButton !== newState.disableCreateInvoiceButton
        ) {
            return true;
        }

        return false;
    };

    componentDidUpdate = (oldProps) => {
        if (!isEqual(oldProps.listColumns, this.props.listColumns)) {
            this.list.current && this.list.current.saveColumnConfig(this.props.listColumns);
        }
    };

    onDeleteRow = (row) => {
        this.props.onRowEdit({ ...row, deleted: 1 });
    };

    quoteRowVisibilityFilter = (row) => {
        return row && (!row.deleted || row.deleted === '0');
    };

    onColumnVisibilityChange = (columns) => {
        this.props.onColumnVisibilityChange(columns);
    };

    renderIndicators = (row) => {
        const hasTask = Number(row.has_task) > 0;
        const hiddenFromPrint = row.hidden_for_print == 1;
        if (!hasTask && !hiddenFromPrint) return null;
        return (
            <div className={`${styles.rowIndicators} ${hasTask ? styles.print : ''} ${hasTask && hiddenFromPrint ? styles.multiple : ''}`}>
                {hasTask && (
                    <Tooltip title={this.tr('A task has been linked to this row')}>
                        <div className={styles.taskLinked}>
                            <ResourcingIcon />
                        </div>
                    </Tooltip>
                )}
                {hiddenFromPrint && (
                    <Tooltip title={this.tr('Hidden from print')}>
                        <div className={styles.hiddenFromPrint}>
                            <PrintDisabled />
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    };

    render() {
        const {
            editMode,
            tr,
            renderField,
            activeCell,
            setActiveCell,
            createPurchaseOrder,
            createInvoice,
            getEditableFields,
            onListMounted,
            listColumns,
            quoteRows,
            onRowEdit,
            onAddRow,
            currencyFormatter,
            quantityTypes,
            jobtypes,
            workTypes,
            project,
            products,
            CPQParents,
            onDraggedRowDrop,
            useLazyLoad,
            checkPrivilege,
            openTaskDialog,
            listProps,
            canCreateInvoice,
            columnValuesPrintHidden,
            printMode,
            quote,
            isColumnHiddenFromPrint,
            openScheduledInvoiceDialog,
            checkForAlreadyInvoicedRows
        } = this.props;

        const {
            disableCreateInvoiceButton
        } = this.state;

        const canCreatePurchaseOrder = project.rights.includes('purchase_order_write');
        const toolbarColumns: string[] = [
            "export"
        ];
        if (canCreatePurchaseOrder) {
            toolbarColumns.push("create_po");
        }
        if (project.type == '1' && project.charge_costest == '1' && canCreateInvoice && Number(quote?.active) > 0) {
            toolbarColumns.push("create_invoice");
        }
        if (project.rights.includes('project_billing_entries_write') && Number(quote?.active) > 0) {
            toolbarColumns.push("create_scheduled_invoice");
        }
        toolbarColumns.push("selected_quantity");

        return (
            <List
                ref={this.list}
                className={`${styles.list} ${editMode ? styles.editMode : ''}`}
                fluid
                manualCreate
                checkableRowFilter={row => Number(row.type) < 99}
                onCheck={({ checkedRows, allChecked, allCheckedExcept }) => {
                    if(!this.list.current) {
                        return false;
                    }

                    const data = this.list.current
                        .getCheckedData()
                        .filter(d => d._checked)
                        .filter(d => Number(d.parentId) > 0 && Number(d.type) !== 2);

                    const disable = data.length === 0;

                    if(disableCreateInvoiceButton === disable) {
                        return;
                    }

                    this.setState({
                        disableCreateInvoiceButton: disable
                    });
                }}
                hideUndefinedCells
                useLazyLoad={useLazyLoad}
                showHiddenFromPrintIndicator
                renderIndicators={this.renderIndicators}
                saveColumnConfig
                noElementWithMissingDefinition
                userListSettingsKey="quote_column_settings"
                onListMounted={onListMounted}
                enableToolbar={true}
                hiddenToolbarColumns={["edit", "delete"]}
                additionalToolbarColumns={[
                    {
                        name: "export",
                        header: "",
                        columnHeaderType: "exportButton",
                        width: 100,
                        className: styles.toolbarColumnTransparent
                    },
                    {
                        name: "create_po",
                        header: "",
                        columnHeaderType: "poButton",
                        width: 100,
                        className: styles.toolbarColumnTransparent
                    },
                    {
                        name: "create_invoice",
                        header: "",
                        columnHeaderType: "invoiceButton",
                        width: 100,
                        className: styles.toolbarColumnTransparent,
                    },
                    {
                        name: "create_scheduled_invoice",
                        header: "",
                        columnHeaderType: "scheduledInvoiceButton",
                        width: 100,
                        className: styles.toolbarColumnTransparent,
                    },
                ]}
                additionalToolbarButtons={{
                    exportButton: <ColumnHeaderButton
                        color={"blue"}
                        title={this.tr("Export")}
                        icon={CloudDownloadIcon}
                        onClick={() => {
                            const rows: any[] = this.list.current.getCheckedData();

                            this.props.exportToExcel(rows, this.list.current.getColumnConfig());
                        }}
                    />,
                    poButton: <ColumnHeaderButton
                        color={"blue"}
                        title={this.tr("Create Purchase Order")}
                        icon={ShoppingCartIcon}
                        onClick={() => {
                            const ids: number[] = this.list.current.getCheckedData()
                                .map(r => Number(r.id));

                            this.props.createPurchaseOrder(ids);
                        }}
                    />,
                    invoiceButton: <ColumnHeaderButton
                        color={"blue"}
                        title={this.tr("Create Invoice")}
                        icon={DescriptionIcon}
                        disabled={disableCreateInvoiceButton}
                        onClick={() => {
                            const ids: number[] = this.list.current.getCheckedData()
                                .map(r => Number(r.id));

                            const headerIds: number[] = this.list.current.getCheckedData()
                                .filter((row) => row.parentId == "0")
                                .map(r => Number(r.id));

                            this.props.createInvoice(ids, headerIds);
                        }}
                    />,
                    scheduledInvoiceButton: <ColumnHeaderButton
                        color={"blue"}
                        title={this.tr("Create scheduled invoice")}
                        icon={CalendarClockIcon}
                        iconClassName={"listHeaderIconMarginBottom"}
                        disabled={disableCreateInvoiceButton}
                        onClick={async () => {
                            const rows = this.list.current.getCheckedData();

                            const quoteRowIds = await checkForAlreadyInvoicedRows(rows.map(r => Number(r.id)), "scheduled_invoice");

                            if(quoteRowIds.length === 0) {
                                return;
                            }

                            openScheduledInvoiceDialog(rows.filter(r => quoteRowIds.find(q => q == r.id)));
                        }}
                    />,
                }}
                toolbarColumnOrder={toolbarColumns}
                idType="string"
                visibilityFilter={this.quoteRowVisibilityFilter}
                height="auto"
                columns={listColumns}
                listRowType={QuoteRow}
                noColorVariance
                noStateData
                ignoreRowPropsChange={false}
                controlOrder={true}
                data={quoteRows}
                onEdit={onRowEdit}
                onColumnVisibilityChange={this.onColumnVisibilityChange}
                hideBottomMarker={false}
                rowProps={{
                    editMode,
                    tr,
                    onAddRow: onAddRow,
                    currencyFormatter,
                    jobtypes,
                    quantityTypes: quantityTypes,
                    workTypes: workTypes,
                    canCreatePurchaseOrder: canCreatePurchaseOrder,
                    products,
                    CPQParents,
                    renderField: renderField,
                    activeCell: activeCell,
                    setActiveCell: setActiveCell,
                    getEditableFields: getEditableFields,
                    createPurchaseOrder: createPurchaseOrder,
                    createInvoice: createInvoice,
                    onDeleteRow: this.onDeleteRow,
                    createTask: openTaskDialog,
                    canCreateTask:
                        (this.context.addons && this.context.addons.resourcing && checkPrivilege('projects', 'project_resourcing_write')) || checkPrivilege('projects', 'own_resourcing_write'),
                    company: this.props.project.companies_id,
                    formatNumberInput: this.props.formatNumberInput,
                    canCreateInvoice: this.props.canCreateInvoice,
                    columnValuesPrintHidden,
                    printMode,
                    isColumnHiddenFromPrint: isColumnHiddenFromPrint
                }}
                rowDragging={true}
                onDraggedRowDrop={onDraggedRowDrop}
                dragDropIsAllowed={(rowToDrop, onRow) => {
                    if (rowToDrop.data.id == onRow.data.id) {
                        return false;
                    }

                    const r = rowToDrop.data;
                    const o = onRow.data;
                    const onNormalRow =
                        o.type == quoteRowTypes.quoteRow ||
                        o.type == quoteRowTypes.descriptionRow ||
                        o.type == quoteRowTypes.productRow ||
                        o.type == quoteRowTypes.cpqGroupedRow ||
                        o.type == quoteRowTypes.summaryRow;
                    const droppedIsNormalRow =
                        r.type == quoteRowTypes.quoteRow || r.type == quoteRowTypes.descriptionRow || r.type == quoteRowTypes.productRow || r.type == quoteRowTypes.cpqGroupedRow;
                    const droppedIsHeaderRow = r.type == quoteRowTypes.headerRow;
                    return (
                        (onRow.data.id == 'BOTTOM_MARKER' && droppedIsHeaderRow) ||
                        (onNormalRow && droppedIsNormalRow) ||
                        (o.type == quoteRowTypes.headerRow && droppedIsHeaderRow) ||
                        (onRow.lastInGroup && r.type != quoteRowTypes.headerRow)
                    );
                }}
                {...listProps}
            />
        );
    }
}

interface Props {
    editMode: boolean;
    onQuoteEdited: (e: any, validation?: string) => void;
    onAddressEdited: (e: any, additionalValues?: any) => void;
    updateHeaders: (headers: any) => void;
    quote?: any;
    project: any;
    visitingAddresses: any[];
    receiverContacts: any[];
    companyContacts: any[];
    allUsers: any[];
    jobtypes: any[];
    products: any[];
    CPQParents: any[];
    quoteRows: any[];
    quantityTypes: any[];
    workTypes: any[];
    totals: any;
    companyAddress: any;
    onCompanyAddressEdited: (e: any) => void;
    onRowEdit: (row: any) => void;
    onAddRow: (row: any, rowId?: any) => void;
    onAddressSelected: (addressId: string, data: any) => void;
    onContactSelected: (contact: any) => void;
    onSenderContactSelected: (contact: any) => void;
    getVisitingAddresses: () => void;
    currencyFormatter: any;
    printDateFormat: string;
    onMount?: () => void;
    innerRef?: any;
    loadingQuotes: boolean;
    useLazyLoad: boolean;
    printMode: boolean;
    checkPrivilege: (module: string, permission: string) => boolean;
    openTaskDialog: any;
    openScheduledInvoiceDialog: (rows: any) => void;
    getEditableFields: any;
    renderField: any;
    printLanguage: string;
    formatNumberInput: (value: string) => any;
    canCreateInvoice?: boolean;
    onGrandTotalVisibilityChange: (visible: boolean) => void;
    grandTotalHidden: boolean;
    setTargetingSectionY: (value: number) => void;
    statusMap: {};
    getQuoteRows?: (listColumns: any[]) => { quoteRows: any; totals: any };
    showDialog: (dialog: string, data: any) => {};
    activeCurrencies: any[];
    companyCurrency: string;
    onCurrencyEdited: (value: any) => void;
    quoteErrors: string[];
}

interface State {
    listColumns: any[];
    activeCell?: any;
    columnSettingsAnchor?: any;
    listMounted: boolean;
    logoLoaded: boolean;
    editSliderData?: any;
}

class Quote extends TaimerComponent<Props, State> {
    quoteList: any = React.createRef();
    // For forcing column order even when column visibility changes
    listColumnOrderMap = {
        check: 1,
        name: 2,
        code: 3,
        quantity: 4,
        cost: 4,
        value: 6,
        vat: 7,
        sum: 8,
        margin: 9,
        total: 10,
        workType: 11,
        jobtype: 12,
        invoiced: 13,
        added_to_po: 14,
    };
    addressSliderFields: any[];
    resizeTimeout: any;
    topSection: any = React.createRef();
    constructor(props, context) {
        super(props, context, 'projects/Quote');

        const {
            taimerAccount: { showState },
        } = this.context;

        this.addressSliderFields = [
            {
                title: this.tr('Company'),
                key: 'name',
            },
            {
                title: this.tr('VAT no.'),
                key: 'vatid',
            },
            {
                title: this.tr('Address'),
                key: 'address',
            },
            ...(showState
                ? [
                      {
                          title: this.tr('City'),
                          key: 'city',
                      },
                      {
                          title: this.tr('State'),
                          key: 'state',
                      },
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                      },
                  ]
                : [
                      {
                          title: this.tr('Zip'),
                          key: 'postalcode',
                      },
                      {
                          title: this.tr('City'),
                          key: 'city',
                      },
                  ]),
            {
                title: this.tr('Country'),
                key: 'country',
            },
        ];

        let listColumns = this.getInitialListColumns();
        listColumns = this.setColumnPrintHides(listColumns);

        this.state = {
            listColumns,
            listMounted: false,
            logoLoaded: false,
        };
    }

    componentDidMount = () => {
        super.componentDidMount();
        this.setTargetingSectionY();
        window.addEventListener('resize', this.onResize);
    };

    componentWillUnmount = () => {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.onResize);
    };

    onResize = () => {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = setTimeout(() => {
            this.setTargetingSectionY();
        }, 200);
    };

    setTargetingSectionY = () => {
        const topSectionDimensions = this.topSection.current?.getBoundingClientRect();
        this.props.setTargetingSectionY(topSectionDimensions?.height || 500);
    };

    componentDidUpdate = (oldProps) => {
        if (!isEqual(oldProps.quote?.print_exceptions, this.props.quote?.print_exceptions) || oldProps.printLanguage != this.props.printLanguage) {
            this.setColumns(this.state.listColumns);
        }

        if (oldProps.editMode != this.props.editMode) {
            const listColumns = [...this.state.listColumns];
            // Showing drag indicator only in edit mode, need to tweak widths etc.
            if (this.props.editMode) {
                this.quoteList.current.list.current.uncheckAll();

                const dragIndex = listColumns.findIndex((c) => c.field == 'drag');
                if (dragIndex == -1) {
                    listColumns.unshift({
                        field: 'drag',
                        name: 'drag',
                        header: '',
                        width: 22,
                        showMenu: false,
                        resizeable: false,
                        moveable: false,
                        hideable: false,
                        staticIndex: 0,
                        visibleInToolbar: true,
                        columnHeaderType: "",
                        alwaysHiddenFromPrint: true,
                        noFlex: true,
                        dontSaveConfig: true,
                        hideFromPrintOptions: true,
                    });
                }
                const menuIndex = listColumns.findIndex((c) => c.field == 'menu');
                if (menuIndex != -1) {
                    listColumns[menuIndex].width = 35;
                    listColumns[menuIndex].staticIndex = 1;
                }
            } else {
                const dragIndex = listColumns.findIndex((c) => c.field == 'drag');
                if (dragIndex != -1) {
                    listColumns.splice(dragIndex, 1);
                }
                const menuIndex = listColumns.findIndex((c) => c.field == 'menu');
                if (menuIndex != -1) {
                    listColumns[menuIndex].width = 40;
                    listColumns[menuIndex].staticIndex = 0;
                }
            }
            this.setColumns(listColumns);
        }

        if (this.props.jobtypes != oldProps.jobtypes) {
            const listColumns = cloneDeep(this.state.listColumns);
            const jobtypeColumnIndex = listColumns.findIndex((c) => c.field == 'jobtype');
            if (jobtypeColumnIndex != -1) {
                listColumns[jobtypeColumnIndex].hidden = (this.props.jobtypes || []).length == 0;
            }
            this.setState({ listColumns });
        }

        if (this.state.listMounted && (!this.showLogo() || this.state.logoLoaded)) {
            this.props.onMount && this.props.onMount();
        }
    };

    getInitialListColumns = () => {
        const {
            project: { charge_costest, type },
            canCreateInvoice,
        } = this.props;
        const commonColumnProps = {
            showMenu: false,
            resizeable: false,
            moveable: false,
            hideable: false,
        };
        const listColumns = [
            {
                field: 'check',
                name: 'check',
                columnHeaderType: "checkbox",
                className: 'checkboxHeader',
                visibleInToolbar: true,
                alwaysHiddenFromPrint: true,
                hideFromPrintOptions: true,
                width: 28,
                visible: true,
                noFlex: true,
                ...commonColumnProps,
            },
            {
                field: 'menu',
                name: 'menu',
                columnHeaderType: "",
                visibleInToolbar: true,
                header: '',
                width: 40,
                staticIndex: 0,
                alwaysHiddenFromPrint: true,
                noFlex: true,
                dontSaveConfig: true,
                hideFromPrintOptions: true,
                ...commonColumnProps,
            },
            {
                field: 'code',
                name: 'code',
                header: this.tr('Product code'),
                headerText: 'Product code',
                width: 40,
                visible: false,
                ...commonColumnProps,
            },
            {
                field: 'name',
                name: 'name',
                header: this.tr('Rows'),
                headerText: 'Rows',
                width: 90,
                ...commonColumnProps,
            },
            {
                field: 'quantity',
                name: 'quantity',
                header: this.tr('Amount'),
                headerText: 'Amount',
                width: 30,
                alignRight: true,
                ...commonColumnProps,
            },
            {
                field: 'cost',
                name: 'cost',
                header: this.tr('Unit cost'),
                headerText: 'Unit cost',
                width: 35,
                alignRight: true,
                currency: true,
                shownInSummary: true,
                dataKey: "currency_cost",
                ...commonColumnProps,
            },
            {
                field: 'value',
                name: 'value',
                header: this.tr('Selling price'),
                headerText: 'Selling price',
                width: 35,
                alignRight: true,
                currency: true,
                shownInSummary: true,
                ...commonColumnProps,
            },
            {
                field: 'vat',
                name: 'vat',
                header: this.tr('Vat'),
                headerText: 'Vat',
                width: 30,
                alignRight: true,
                shownInSummary: true,
                ...commonColumnProps,
            },
            {
                field: 'sum',
                name: 'sum',
                header: this.tr('Sum 0%'),
                headerText: 'Sum 0%',
                width: 40,
                alignRight: true,
                currency: true,
                disabled: true,
                shownInSummary: true,
                ...commonColumnProps,
            },
            {
                field: 'margin',
                name: 'margin',
                header: this.tr('Margin 0%'),
                headerText: 'Margin 0%',
                width: 40,
                alignRight: true,
                currency: true,
                shownInSummary: true,
                ...commonColumnProps,
            },
            {
                field: 'total',
                name: 'total',
                header: this.tr('Total'),
                headerText: 'Total',
                width: 40,
                alignRight: true,
                currency: true,
                disabled: true,
                visible: false,
                shownInSummary: true,
                ...commonColumnProps,
            },
            {
                field: 'workType',
                name: 'workType',
                header: this.tr('Type of work'),
                headerText: 'Type of work',
                width: 40,
                alignRight: true,
                ...commonColumnProps,
            },
            ...(this.context.addons.nav
                ? [
                      {
                          field: 'jobtype',
                          name: 'jobtype',
                          header: this.tr('Jobtype'),
                          headerText: 'Jobtype',
                          width: 40,
                          alignRight: true,
                          visible: false,
                          hiddenFromPrint: true,
                          hidden: (this.props.jobtypes || []).length == 0,
                          ...commonColumnProps,
                      },
                  ]
                : []),
            ...(type == '1' && charge_costest == '1' && canCreateInvoice
                ? [
                      {
                          field: 'invoiced',
                          name: 'invoiced',
                          header: this.tr('Invoiced'),
                          headerText: 'Invoiced',
                          width: 30,
                          alignRight: true,
                          disabled: true,
                          visible: false,
                          alwaysHiddenFromPrint: true,
                          ...commonColumnProps,
                      },
                  ]
                : []),
            {
                field: 'added_to_po',
                name: 'added_to_po',
                header: this.tr('Added to Purchase Order'),
                headerText: 'Added to Purchase Order',
                width: 30,
                alignRight: true,
                disabled: true,
                visible: false,
                alwaysHiddenFromPrint: true,
                ...commonColumnProps,
            }
        ];
        return listColumns;
    };

    onListMounted = () => this.setState({ listMounted: true });
    onLogoLoaded = () => this.setState({ logoLoaded: true });

    tr = (value) => {
        // overriding tr function to use selected print language when printing and Taimer language otherwise
        const { printMode, printLanguage } = this.props;
        return !printMode ? super.tr(value) : this.beTr(value, printLanguage || 'en', {}, 'general/backendTranslations/QuotePrint');
    };

    // Setting column print hides, could be checked in render but this should be more efficient.
    // Stupid because needs to be remembered to be called when listColumns change.
    setColumnPrintHides = (columns) => {
        const listColumns = cloneDeep(columns);
        listColumns.forEach((col, i) => {
            listColumns[i].hiddenFromPrint = this.isColumnHiddenFromPrint(col);
            listColumns[i].header = this.tr(listColumns[i].headerText);
        });
        return listColumns;
    };

    isColumnHiddenFromPrint = (col) => {
        return col.alwaysHiddenFromPrint || (this.props.quote?.print_exceptions || []).includes(col.name);
    }

    isColumnValuesPrintHidden = () => {
        return this.context.addons &&
            this.context.addons.quoterow_show_only_quantity_in_print &&
            (this.props.quote?.print_exceptions || []).includes('columnValuesHidden');
    }

    setActiveCell = (activeCell) => this.setState({ activeCell });

    openColumnSettings = (e) => this.setState({ columnSettingsAnchor: e.target });
    closeColumnSettings = () => this.setState({ columnSettingsAnchor: undefined });

    onColumnVisibilityChange = (field, visible) => {
        const listColumns = cloneDeep(this.state.listColumns);
        const index = listColumns.findIndex((c) => c.field == field);
        let alwaysHiddenFromPrint = false;
        if (index != -1) {
            listColumns[index].visible = visible;
            alwaysHiddenFromPrint = listColumns[index].alwaysHiddenFromPrint;
        }
        this.setState({ listColumns }, () => {
            if (!alwaysHiddenFromPrint) {
                this.onColumnPrintVisibilityChange(field, visible);
            }
        });
    };

    onColumnPrintVisibilityChange = (field, visible) => {
        const printExceptions = cloneDeep(this.props.quote?.print_exceptions || []);
        if (visible) {
            const foundIndex = printExceptions.findIndex((p) => p == field);
            if (foundIndex != -1) {
                printExceptions.splice(foundIndex, 1);
            }
        } else {
            printExceptions.push(field);
        }
        this.props.onQuoteEdited({ target: { name: 'print_exceptions', value: printExceptions } });
    };

    onDraggedRowDrop = (droppedRow, onRow, currentOrder, dataMap, listRef) => {
        const dId = Number(droppedRow.data.id);
        let onId = typeof onRow.data === 'object' ? onRow.data.id : null;
        const newOrder: any = [];
        const toMove = [dId];
        let toFlip = [dId];
        let after = false;
        const onRowParentId = onRow.data.id !== 'BOTTOM_MARKER' ? onRow.parentId || onRow.data.parentId : null;

        if (onRow.lastInGroup) {
            after = true;

            const inHeader = listRef.getData().filter((d) => d.parentId == onRowParentId);

            if (!inHeader || inHeader.length === 0) {
                onId = onRowParentId;
            } else {
                onId = inHeader[inHeader.length - 1].id;
            }
        }

        // If we're moving a group of rows.
        if (droppedRow.data.type == quoteRowTypes.headerRow) {
            listRef
                .getData()
                .filter((d) => d.parentId == dId)
                .forEach((d) => toMove.push(d.id));

            const treeMap = makeMapOfPrimitives(toMove, true);
            currentOrder = currentOrder.filter((id) => !treeMap[id]);
            toFlip = clone(toMove);
        } else if (droppedRow.data.parentId && onRowParentId && droppedRow.data.parentId != '0' && droppedRow.data.parentId != onRowParentId) {
            // If we're moving a row under a new parent (into a new group).
            toFlip.push(droppedRow.data.parentId);

            dataMap[dId].parentId = onRowParentId;
            currentOrder = currentOrder.filter((id) => id != dId);
        } else {
            currentOrder = currentOrder.filter((id) => id != dId);
        }

        let cur;

        for (const i in currentOrder) {
            cur = currentOrder[i];

            if (cur == onId && !after) {
                toMove.forEach((id) => newOrder.push(id));
            }

            newOrder.push(cur);

            if (cur == onId && after) {
                toMove.forEach((id) => newOrder.push(id));
            }
        }

        if (onRow.data.id === 'BOTTOM_MARKER') {
            toMove.forEach((id) => newOrder.push(id));
        }

        // Rows filtered out by List.props.visibilityFilter are not in currentOrder,
        // so we have to add them manually to the
        // data that will replace List's current data.
        const deletedRows = listRef.getData().filter((d) => newOrder.findIndex((n) => n == d.id) === -1);

        const rows = newOrder.map((id) => dataMap[id]).concat(deletedRows);
        const headers: any = [];
        rows.forEach((row) => {
            if (row.type == quoteRowTypes.headerRow) {
                headers.push({ ...row, rows: [] });
            } else if (row.type != quoteRowTypes.summaryRow) {
                const headerIndex = headers.findIndex((h) => h.id == row.parentId);
                if (headerIndex != -1) {
                    headers[headerIndex].rows.push(row);
                }
            }
        });
        return [rows, () => this.props.updateHeaders(headers)];
    };

    showEditSlider = (editSliderData) => this.setState({ editSliderData });
    closeEditSlider = () => this.setState({ editSliderData: undefined });

    showNewAddressSlider = (address) => {
        this.showEditSlider({
            onSave: this.onSaveNewVisitingAddress,
            fields: this.addressSliderFields,
            item: { name: this.props.project?.account?.name, address, vatid: this.props.project?.account?.vatid },
        });
    };

    onSaveNewVisitingAddress = async (address) => {
        const addressData = {
            id: -1,
            ...address,
        };
        this.closeEditSlider();
        const response = await DataHandler.put({ url: `accounts/${this.props.project.customers_id}/visiting_addresses/${this.props.project.companies_id}` }, { visiting_addresses: [addressData] });
        setTimeout(this.props.getVisitingAddresses, 1000);
        this.props.onAddressSelected(response[0].id, addressData);
    };

    renderToAddress = () => {
        const { quote, visitingAddresses, receiverContacts, editMode, onAddressEdited, onAddressSelected, onContactSelected } = this.props;
        const {
            taimerAccount: { showState },
        } = this.context;
        const address = quote?.editedAddress;
        return (
            <LabelFieldGroup
                title={this.tr('To')}
                editMode={editMode}
                values={quote?.address_id && Number(quote?.address_id) > 0 && visitingAddresses ? visitingAddresses.find((addr) => addr.id === quote?.address_id) : {}}
                fields={[
                    {
                        label: this.tr('Company'),
                        name: 'name',
                        value: address?.name,
                        onChange: onAddressEdited,
                        className: 'quote-header-email-field',
                        editorType: editMode ? undefined : TextAreaCell,
                    },
                    {
                        label: this.tr('Address'),
                        name: 'address',
                        value: { label: address?.address, value: quote?.address_id },
                        editorType: CreatableSelect,
                        options: visitingAddresses,
                        onChange: async (address) => {
                            if (address.__isNew__) {
                                this.showNewAddressSlider(address.value);
                            } else if (address.id) {
                                onAddressSelected(address.id, address);
                            }
                        },
                    },
                    {
                        label: this.tr('Zip code'),
                        name: 'postalcode',
                        value: address?.postalcode,
                        onChange: onAddressEdited,
                    },
                    {
                        label: this.tr('City'),
                        name: 'city',
                        value: address?.city,
                        onChange: onAddressEdited,
                    },
                    showState && {
                        label: this.tr('State'),
                        name: 'state',
                        value: address?.state,
                        onChange: onAddressEdited,
                    },
                    {
                        label: this.tr('Country'),
                        name: 'country',
                        value: address?.country,
                        onChange: onAddressEdited,
                    },
                    {
                        label: this.tr('Business ID'),
                        name: 'vatid',
                        value: address?.vatid,
                        onChange: onAddressEdited,
                    },
                    !address?.custom_contact || receiverContacts.findIndex((c) => c.label == address?.custom_contact) != -1
                        ? {
                              label: this.tr('Contact'),
                              name: 'receiver_contact',
                              value: (receiverContacts || []).find((contact) => contact.id == quote?.receiver_contact_id),
                              editorType: CreatableSelect,
                              noOptions: AddContact,
                              options: receiverContacts,
                              onItemCreated: (contact) => onContactSelected(contact),
                              onChange: (value) => {
                                  if (value.__isNew__ || !value.id) {
                                      onAddressEdited({ target: { name: 'custom_contact', value: value.label } }, { phone: '', email: '' });
                                  } else {
                                      onContactSelected(value);
                                  }
                              },
                          }
                        : {
                              label: this.tr('Contact'),
                              name: 'custom_contact',
                              value: address?.custom_contact,
                              onChange: (e) => onAddressEdited({ target: { name: 'custom_contact', value: !e.target.value ? undefined : e.target.value } }),
                          },
                    {
                        label: this.tr('Phone'),
                        name: 'phone',
                        value: address?.phone,
                        onKeyUp: onAddressEdited,
                    },
                    {
                        label: this.tr('Email address'),
                        name: 'email',
                        value: address?.email,
                        onKeyUp: onAddressEdited,
                        className: 'quote-header-email-field',
                        editorType: editMode ? undefined : TextAreaCell,
                    },
                ]}
            />
        );
    };

    renderFromAddress = () => {
        const { quote, onQuoteEdited, companyAddress, onCompanyAddressEdited, editMode, companyContacts, allUsers } = this.props;
        const {
            taimerAccount: { showState },
        } = this.context;
        let senderContact = companyContacts.find((c) => c.id == quote?.sender_contact_id);
        if (!senderContact) {
            senderContact = allUsers && allUsers.find((u) => u.id == quote?.sender_contact_id);
        }
        if (senderContact) {
            senderContact.value = senderContact.id;
            senderContact.label = senderContact.name;
        }
        return (
            <LabelFieldGroup
                title={this.tr('From')}
                editMode={editMode}
                values={companyAddress}
                fields={[
                    { label: this.tr('From Company'), name: 'name', value: undefined, editable: false, className: 'quote-header-email-field', editorType: editMode ? undefined : TextAreaCell },
                    { label: this.tr('Address'), name: 'address', value: undefined, editable: false },
                    {
                        label: this.tr('Zip code'),
                        name: 'postalcode',
                        value: undefined,
                        onKeyUp: onCompanyAddressEdited,
                        editable: false,
                    },
                    { label: this.tr('City'), name: 'city', value: undefined, onKeyUp: onCompanyAddressEdited, editable: false },
                    showState && {
                        label: this.tr('State'),
                        name: 'state',
                        value: undefined,
                        onKeyUp: onCompanyAddressEdited,
                        editable: false,
                    },
                    { label: this.tr('Country'), name: 'country', value: undefined, onKeyUp: onCompanyAddressEdited, editable: false },
                    { label: this.tr('Business ID'), name: 'vatid', value: undefined, onKeyUp: onCompanyAddressEdited, editable: false },
                    {
                        label: this.tr('Contact'),
                        name: 'sender_contact',
                        value: senderContact,
                        showStringValue: !senderContact?.id,
                        editorType: Select,
                        options: companyContacts || [],
                        onChange: (value) => {
                            this.props.onSenderContactSelected(value);
                        },
                    },
                    {
                        label: this.tr('Phone'),
                        name: 'company_phone',
                        value: quote?.company_phone,
                        onKeyUp: onQuoteEdited,
                    },
                    {
                        label: this.tr('Email address'),
                        name: 'company_email',
                        value: quote?.company_email,
                        onKeyUp: onQuoteEdited,
                        className: 'quote-header-email-field',
                        editorType: editMode ? undefined : TextAreaCell,
                    },
                ]}
            />
        );
    };

    renderDates = () => {
        const { quote, companyAddress, onQuoteEdited, editMode, printMode, printDateFormat, activeCurrencies, companyCurrency, onCurrencyEdited, quoteErrors, project } = this.props;
        const { addons, functions: { isProjectReadCompanyUsingQuoteCurrencies } } = this.context;

        const currencyRateEditable = quote?.currency && Number(quote?.currency_rates_id) > 0; // If quote has no currency, company currency is shown with rate 1, then rate should be disabled.

        return (
            <LabelFieldGroup
                errorsInFields={quoteErrors}
                editMode={editMode}
                values={{...companyAddress, currency: quote?.currency, currency_rate: quote?.currency_rate}}
                fields={[
                    {
                        label: this.tr('Sent'),
                        name: 'sent_date',
                        date: quote?.sent_date,
                        onInputChange: (name, date) => onQuoteEdited({ target: { name: 'sent_date', value: format(date, 'YYYY-MM-DD') } }),
                        onChange: (date) => onQuoteEdited({ target: { name: 'sent_date', value: format(date, 'YYYY-MM-DD') } }),
                        editorType: DatePicker,
                        dateFormat: printMode ? printDateFormat : this.context.userObject.dateFormat,
                    },
                    {
                        label: this.tr('Valid until'),
                        name: 'valid_until',
                        date: quote?.valid_until,
                        onInputChange: (name, date) => onQuoteEdited({ target: { name: 'valid_until', value: format(date, 'YYYY-MM-DD') } }),
                        onChange: (date) => onQuoteEdited({ target: { name: 'valid_until', value: format(date, 'YYYY-MM-DD') } }),
                        editorType: DatePicker,
                        dateFormat: printMode ? printDateFormat : this.context.userObject.dateFormat,
                    },
                    {
                        label: this.tr('Delivered'),
                        name: 'delivery_date',
                        date: quote?.delivery_date,
                        onInputChange: (name, date) => onQuoteEdited({ target: { name: 'delivery_date', value: format(date, 'YYYY-MM-DD') } }),
                        onChange: (date) => onQuoteEdited({ target: { name: 'delivery_date', value: format(date, 'YYYY-MM-DD') } }),
                        editorType: DatePicker,
                        dateFormat: printMode ? printDateFormat : this.context.userObject.dateFormat,
                    },
                    ...(isProjectReadCompanyUsingQuoteCurrencies(project.companies_id) ? [
                        {
                            label: this.tr('Currency'),
                            name: 'currency',
                            value: quote?.currency || companyCurrency,
                            editorType: Select,
                            options: activeCurrencies,
                            onChange: (val) => {
                                onCurrencyEdited(val)
                            },
                            icon: <PrintDisabled />,
                            iconTooltip: this.tr("Hidden from print"),
                            className: "hiddenFromPrint"
                        },
                        {
                            label: this.tr('Currency rate'),
                            name: 'currency_rate',
                            value: quote?.currency_rate,
                            disabled: !currencyRateEditable,
                            onBlur: (e) => onQuoteEdited(e, "numeric"),
                            icon: <PrintDisabled />,
                            iconTooltip: this.tr("Hidden from print"),
                            className: "hiddenFromPrint"
                        }
                    ] : []),
                ]}
            />
        );
    };

    checkParentRows = (quoteRowIds: number[]) => {
        quoteRowIds = quoteRowIds.map(id => Number(id));

        const list    = this.quoteList.current.list.current;
        const checked = list.getCheckedData().map(d => Number(d.id));
        const parents = list
            .getData()
            .filter(d => quoteRowIds.indexOf(Number(d.id)) > -1)
            .filter(d => Number(d.parentId) > 0)
            .map(d => d.parentId)
            .filter(id => checked.indexOf(Number(id)) === -1);

        return new Promise((resolve: (r: string) => void) => {
            // setTimeout to allow the animations of
            // the parent rows being checked finish.
            parents.length > 0
                ? list.check(parents, true, () => setTimeout(resolve, 500))
                : resolve("");
        });
    };

    checkForAlreadyPOdRows = (quoteRowIds: number[]): Promise<number[]> => {
        const { showDialog } = this.props;

        const data = this.quoteList.current.list.current
            .getData()
            .filter(d => quoteRowIds.indexOf(Number(d.id)) > -1);

        const alreadyPOd = data.filter(d => Number(d.added_to_po) > 0);
        const left       = data
            .filter(d => {
                return alreadyPOd
                    .map(d => Number(d.id))
                    .indexOf(Number(d.id)) === -1
            })
            .map(d => d.id);

        const props = {
            okText: this.tr("OK"),
            noCancelButton: true,
        };


        const uncheck: (res: (quoteRowIds: number[]) => void) => void = (res): void => {
            const list = this.quoteList.current.list.current;

            list.check(alreadyPOd.map(d => d.id), false, () => res(left));
        };

        return new Promise((resolve, reject) => {
            if(quoteRowIds.length === alreadyPOd.length) {
                showDialog("confirmation", {
                    ...props,
                    text: this.tr("All of the rows you've selected have already \
                        been added to a purchase order."),
                    saveFunc: () => uncheck(resolve)
                });
            } else if(alreadyPOd.length > 0) {
                showDialog("confirmation", {
                    ...props,
                    text: this.tr("You have selected rows that have already been \
                        added to another purchase order. These rows will be ignored."),
                    saveFunc: () => uncheck(resolve)
                });
            } else {
                resolve(left);
            }
        });
    };

    createPurchaseOrderFromAllRows = () => {
        const list          = this.quoteList.current.list.current;
        const ids: number[] = list
            .getData()
            .filter(d => Number(d.type) < 99)
            .map(d => Number(d.id));

        list.checkAll(true, () => setTimeout(() => {
            this.createPurchaseOrder(ids);
        }, 500));

    };

    createPurchaseOrder = async (quoteRowIds: number[]) => {
        quoteRowIds = await this.checkForAlreadyPOdRows(quoteRowIds);

        if(quoteRowIds.length === 0) {
            return;
        }

        this.context.functions.updateView({
            module: 'purchaseorder',
            action: 'view',
            companies_id: this.props.project.companies_id,
            projects_id: this.props.project.id,
            quote: this.props.quote.id,
            quote_rows: quoteRowIds
        });
    };

    checkForAlreadyInvoicedRows = (quoteRowIds: number[], type = "invoice"): Promise<number[]> => {
        const normalRowFn    = d => Number(d.parentId) > 0 && Number(d.type) !== 2;
        const { showDialog } = this.props;
        const data           = this.quoteList.current.list.current
                                .getData()
                                .filter(d => (quoteRowIds.indexOf(Number(d.id)) > -1));


        // Quote rows that aren't parent rows or description rows.
        const normalRows      = data.filter(normalRowFn);
        const alreadyInvoiced = normalRows.filter(d => !d.has_refunded_material && (Number(d.bills_id) > 0 || d.scheduled_invoices?.length > 0));
        let left              = data.filter(d => alreadyInvoiced.map(d => Number(d.id)).indexOf(Number(d.id)) === -1);
        const leftNormal      = left.filter(normalRowFn).map(d => d.id);
        left                  = left.map(d => d.id);

        const allInvoicedProps = {
            okText: this.tr("OK"),
            noCancelButton: true,
        };

        const someInvoicedProps = {
            okText: this.tr("OK, continue"),
            cancelText: this.tr("Cancel"),
            noCancelButton: false,
        };

        const uncheck: (res: (quoteRowIds: number[]) => void) => void = (res): void => {
            const list = this.quoteList.current.list.current;

            list.check(quoteRowIds, false, () => {
                list.check(leftNormal.length > 0 ? left : [], true, () => setTimeout(() => res(leftNormal), 0));
            });
        };

        return new Promise((resolve, reject) => {
            if(leftNormal.length === 0) {
                showDialog("confirmation", {
                    ...allInvoicedProps,
                    text: this.tr("All selected rows are already invoiced"),
                    saveFunc: () => uncheck(resolve)
                });
            } else if(alreadyInvoiced.length > 0) {
                showDialog("confirmation", {
                    ...someInvoicedProps,
                    text: type == "scheduled_invoice"
                        ? this.tr("Already invoiced rows will not be exported to scheduled invoice creation")
                        : this.tr("Already invoiced rows will not be exported to invoice template"),
                    saveFunc: () => uncheck(resolve)
                });
            } else {
                resolve(leftNormal);
            }
        });
    };

    createInvoice = async (quoteRowIds: number[], quoteHeaderRowIds: number[]) => {
        quoteRowIds = await this.checkForAlreadyInvoicedRows(quoteRowIds);

        if(quoteRowIds.length === 0) {
            return;
        }

        quoteRowIds = quoteRowIds.concat(quoteHeaderRowIds);

        // Changes in TR-1298.
        // await this.checkParentRows(quoteRowIds);

        this.context.functions.updateView({
            module: 'invoices',
            action: 'view',
            companies_id: this.props.project.companies_id,
            projects_id: this.props.project.id,
            customers_id: this.props.project.customers_id,
            /* invoiceType: 2 -> material invoice, invoiceType: 3 -> refund invoice */
            invoiceType: this.props.quote.type === '1' ? '2' : '3',
            start: this.props.quote.created,
            end: this.props.quote.created,
            preselect: 'quote',
            reference: this.props.project.customer_reference,
            quote: this.props.quote.id,
            preselect_quote_row_ids: quoteRowIds.toString(),
        });
    };

    exportToExcel = async (quoteRows: any[], columnConfig: { name: string, header: string }[]) => {
        type ColConf = { name: string, header: string };

        const {
            quantityTypes,
            workTypes,
            quote: { currency }
        } = this.props;

        columnConfig = columnConfig.map(c => {
            if(c.name === "vat") {
                c.header = this.tr("VAT %");
            }

            return c;
        });

        columnConfig = insertAfterFn<ColConf>(columnConfig, (cc: ColConf) => cc.name === "quantity",
            {
                name: "unit",
                header: this.tr("Unit")
            }
        );

        columnConfig = insertAfterFn<ColConf>(columnConfig, (cc: ColConf) => cc.name === "value",
            {
                name: "discountPercentage",
                header: this.tr("Discount %")
            }
        );

        columnConfig = insertAfterFn<ColConf>(columnConfig, (cc: ColConf) => cc.name === "discountPercentage",
            {
                name: "discount",
                header: this.tr("Discount")
            }
        );

        columnConfig = insertAfterFn<ColConf>(columnConfig, (cc: ColConf) => cc.name === "margin",
            {
                name: "marginPercentage",
                header: this.tr("Margin %")
            }
        );

        columnConfig = insertAfterFn<ColConf>(columnConfig, (cc: ColConf) => cc.name === "added_to_po",
            {
                name: "targeted",
                header: this.tr("Targeted")
            }
        );

        columnConfig = insertAfterFn<ColConf>(columnConfig, (cc: ColConf) => cc.name === "vat",
            {
                name: "vat_amount",
                header: this.tr("Vat")
            }
        );

        const formats = {
            name:               "text",
            code:               "text",
            cost:               "currency",
            margin:             "currency",
            marginPercentage:   "number",
            quantity:           "number",
            unit:               "text",
            sum:                "currency",
            total:              "currency",
            value:              "currency",
            discountPercentage: "number",
            discount:           "currency",
            vat:                "number",
            vat_amount:         "currency",
            workType:           "text",
            added_to_po:        "text",
            invoiced:           "text",
            targeted:           "currency",
        };

        columnConfig = columnConfig
            .filter(c => ["check", "menu"].indexOf(c.name) === -1);

        const config: Dictionary<ExcelCellConfig> = {};
        const data: Dictionary<string>[]          = quoteRows.filter(el => el.type != 99).map((r: any): Dictionary<string> => {


            if(r.type === "0" || r.type === "2") {
                return {
                    name: r.name,
                    targeted: r.currency_targeted
                };
            }

            const calcValue = parseInt(r.discountPercentage) > 0
                ? r.currency_value - (parseInt(r.discountPercentage) / 100) * r.currency_value
                : r.currency_value;

            const sum              = r.currency_total_no_vat;
            const margin           = r.margin;
            const mp               = !sum ? 0 : (margin / sum) * 100;
            const marginPercentage = Math.round(mp * 100) / 100;
            const discount         = Number(r.currency_discount_amount);
            const vatAmount        = Number(r.currency_vatTotal).toFixed(2);
            // const rowTotal         = Number(r.quantity) * Number(r.value) + Number(vatAmount);
            let unit               = r.type === "3"
                ? r.quantityType
                : quantityTypes.find(qt => qt.id === Number(r.quantityType))?.label || "";

            unit = !unit || unit.trim() === ""
                ? quantityTypes?.[0]?.label || ""
                : unit;

            if(r.type === "3" && !isNaN(unit)) {
                unit = quantityTypes.find(qt => qt.id === Number(r.quantityType))?.label || "";
            }

            return {
                name: ["1", "2"].indexOf(r.type) > -1
                    ? r.name
                    : r.product_name,
                code: r.type !== "4"
                    ? r.code
                    : "",
                cost: r.currency_cost,
                discountPercentage: r.discountPercentage,
                discount: String(discount),
                quantity: r.quantity,
                unit: unit,
                sum: String(sum),
                margin: String(margin),
                marginPercentage: String(marginPercentage),
                value: r.currency_value,
                total: String(r.currency_total),
                // total: String(Number(r.quantity) * parseInt(r.discountPercentage) > 0
                    // ? rowTotal - (parseInt(r.discountPercentage) / 100) * rowTotal
                    // : rowTotal),
                vat: r.vat,
                vat_amount: vatAmount,
                targeted: r.currency_targeted,
                workType: workTypes.find(wt => wt.id === Number(r.workType))?.label || "",
                invoiced: r.invoice_number !== null
                    ? "#" + r.invoice_number
                    : "-",
                added_to_po: Number(r.added_to_po) === 1
                    ? "#" + r.po_id
                    : "-"
            };
        });

        columnConfig.forEach(c => {
            config[c.name] = {
                name: c.name,
                translation: c.header,
                format: formats[c.name],
                // dataField: fieldMap[c.name] ?? null
            };
        });

        this.excelExport(data, config, currency);
    };

    excelExport = async (data: Dictionary<string>[], config: Dictionary<ExcelCellConfig>, currency: string) => {
        DataHandler.postArrayBuffer(
            { url: `export/excel` },
            {
                data: data,
                config: config,
                currency
            }
        ).done(response => {
            FileSaver.saveAs(
                new Blob([response],
                { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" }
            ), "Quote rows.xlsx");
        });
    };

    sortByColumnOrder = (a, b) => this.listColumnOrderMap[a.field] - this.listColumnOrderMap[b.field];
    setColumns = (listColumns) => {
        const columns = this.setColumnPrintHides(listColumns);
        this.setState({ listColumns: columns.sort(this.sortByColumnOrder) }, () => {
            this.setTargetingSectionY();
        });
    };

    getInvoicedStatus = (quote) => {
        if (!quote) return { text: '', color: '' };

        if (quote.fully_invoiced == 1) {
            return { text: this.tr('Invoiced'), color: colors.greenish_cyan };
        }
        for (let i = 0; i < (quote.headers || []).length; i++) {
            const header = quote.headers[i];
            if ((header.rows || []).findIndex((r) => (r.bills_id || 0) != 0) != -1) {
                return { text: this.tr('Partially invoiced'), color: colors.orangey_yellow };
            }
        }
        return { text: this.tr('Not invoiced'), color: colors.bluey_purple };
    };

    getListColumns = () => {
        return this.state.listColumns;
    };

    onGrandTotalPrintVisibilityChange = (e) => this.onColumnPrintVisibilityChange('quoteTotal', e.target.checked);
    onGrandTotalVisibilityChange = (e) => this.props.onGrandTotalVisibilityChange(!e.target.checked);

    showLogo = () => {
        return this.props.project?.show_logo == 1;
    };

    render() {
        const {
            editMode,
            onQuoteEdited,
            quote,
            project,
            currencyFormatter,
            loadingQuotes,
            printMode,
            getEditableFields,
            renderField,
            grandTotalHidden,
            project: { charge_costest, type },
            canCreateInvoice,
            statusMap,
        } = this.props;
        const { listColumns, columnSettingsAnchor, activeCell, editSliderData } = this.state;
        let { quoteRows, totals } = this.props;
        if (this.props.getQuoteRows) {
            const data = this.props.getQuoteRows(listColumns);
            quoteRows = data.quoteRows;
            totals = data.totals;
        }

        let columnsToUse = listColumns;
        if (printMode) {
            columnsToUse = (listColumns || []).filter((c) => !c.hiddenFromPrint);
        }

        const grandTotalPrintHidden = (quote?.print_exceptions || []).includes('quoteTotal');
        const columnValuesPrintHidden = this.isColumnValuesPrintHidden();

        const { text: invoicedStatusText, color: invoicedStatusColor } = this.getInvoicedStatus(quote);

        const commonTooltipProps = {
            classes: { tooltip: 'darkblue-tooltip' },
            interactive: true,
            arrow: true,
            placement: 'top',
        };

        return (
            <div ref={this.props.innerRef} className={`${styles.quote} ${loadingQuotes ? styles.loading : ''}`}>
                {
                    // this whole table thing is just to get padding on printed blank pages' top and bottom
                    // (with an empty header and footer)
                }
                <table cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div ref={this.topSection} className={`${styles.topSection}`}>
                                    <div className={styles.row}>
                                        <div className={styles.names}>
                                            {quote ? (
                                                <>
                                                    <div className={styles.input}>
                                                        <input
                                                            className={editMode ? styles.edit : ''}
                                                            name="project_name"
                                                            placeholder={project.name}
                                                            onChange={onQuoteEdited}
                                                            value={editMode ? quote?.project_name : `${quote?.project_name || project.name} (${project.project_id})`}
                                                        />
                                                        {editMode && <h1>{`(${project.project_id})`}</h1>}
                                                    </div>
                                                    <div className={styles.subtitle}>
                                                        {editMode ? (
                                                            <input
                                                                className={editMode ? styles.edit : ''}
                                                                name="name"
                                                                value={quote?.name == 'New quote' ? this.tr('New quote header') : quote?.name}
                                                                onChange={onQuoteEdited}
                                                                data-testid="quote-input-name"
                                                            />
                                                        ) : (
                                                            <h1>{quote?.name == 'New quote' ? this.tr('New quote header') : quote?.name}</h1>
                                                        )}
                                                    </div>
                                                    <div className={styles.tags}>
                                                        <Tooltip {...commonTooltipProps} title={this.tr('quote_activity_tooltip')} placement="top">
                                                            <span className={styles.activeText}>{quote?.active == 1 ? this.tr('Active').toUpperCase() : this.tr('Inactive').toUpperCase()}</span>
                                                        </Tooltip>
                                                        <StatusTag
                                                            text={statusMap[quote?.status]?.name}
                                                            color={statusMap[quote.status]?.color}
                                                            tooltip={{ ...commonTooltipProps, title: this.tr('quote_status_tooltip') }}
                                                        />
                                                        {type == '1' && charge_costest == '1' && canCreateInvoice && (
                                                            <StatusTag
                                                                text={invoicedStatusText}
                                                                color={invoicedStatusColor}
                                                                tooltip={{ ...commonTooltipProps, title: this.tr('quote_invoiced_tooltip') }}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                loadingQuotes && <Loading  />
                                            )}
                                        </div>
                                        <div className={styles.logoDates}>
                                            {this.showLogo() && (
                                                <div className={`${styles.logoContainer} ${editMode ? styles.editMode : ''}`}>
                                                    <Logo
                                                        editMode={editMode}
                                                        hideWhenNotInEditMode
                                                        company={project.companies_id ? project.companies_id : 1}
                                                        base64Encode={true}
                                                        onLogoLoaded={this.onLogoLoaded}
                                                        shrinkLogo
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`${styles.addresses} ${!editMode ? styles.readMode : ''}`}>
                                        <div>{this.renderToAddress()}</div>
                                        <div>{this.renderFromAddress()}</div>
                                        <div className={styles.dates}>{this.renderDates()}</div>
                                    </div>
                                    <Tooltip title={this.tr('Column visibility settings')}>
                                        <button onClick={this.openColumnSettings} className={styles.viewOptionsButton}>
                                            <Visibility />
                                            {this.tr('Columns')}
                                            <ArrowDropDownSharp />
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className={styles.listContainer}>
                                    <QuoteList
                                        {...this.props}
                                        ref={this.quoteList}
                                        quoteRows={quoteRows}
                                        totals={totals}
                                        createPurchaseOrder={this.createPurchaseOrder}
                                        createInvoice={this.createInvoice}
                                        exportToExcel={this.exportToExcel}
                                        listColumns={columnsToUse}
                                        columnValuesPrintHidden={columnValuesPrintHidden}
                                        isColumnHiddenFromPrint={this.isColumnHiddenFromPrint}
                                        getEditableFields={getEditableFields}
                                        renderField={renderField}
                                        setActiveCell={this.setActiveCell}
                                        activeCell={activeCell}
                                        onListMounted={this.onListMounted}
                                        onDraggedRowDrop={this.onDraggedRowDrop}
                                        tr={this.tr}
                                        onColumnVisibilityChange={this.setColumns}
                                        checkForAlreadyInvoicedRows={this.checkForAlreadyInvoicedRows}
                                        listProps={{
                                            disableCheck: editMode
                                        }}
                                    />
                                    {!grandTotalHidden && (
                                        <div className={`${styles.totalRow} ${grandTotalPrintHidden ? styles.hiddenFromPrint : 'lol'}`}>
                                            {grandTotalPrintHidden && (
                                                <div className={styles.rowIndicators}>
                                                    <Tooltip title={this.tr('Hidden from print')}>
                                                        <div className={styles.hiddenFromPrint}>
                                                            <PrintDisabled />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            )}
                                            <h2>{this.tr('Grand total')}</h2>
                                            <div className={styles.totalValues}>
                                                <div className={styles.totalValue}>
                                                    <h3>{this.tr('Subtotal')}</h3>
                                                    <p>{currencyFormatter.format(totals?.subtotal || 0)}</p>
                                                </div>
                                                {Number(totals?.discount || 0) > 0 && (
                                                    <div className={styles.totalValue}>
                                                        <h3>{this.tr('Discount')}</h3>
                                                        <p>-{currencyFormatter.format(totals?.discount || 0)}</p>
                                                    </div>
                                                )}
                                                {Number(totals?.discount || 0) > 0 && (
                                                    <div className={styles.totalValue}>
                                                        <h3>{this.tr('Discounted subtotal')}</h3>
                                                        <p>{currencyFormatter.format(totals?.discountedSubtotal || 0)}</p>
                                                    </div>
                                                )}
                                                <div>
                                                    <div className={styles.totalValue}>
                                                        <h3>{this.tr('Vat')}</h3>
                                                        <p>{`${currencyFormatter.format(totals?.currency_vatTotal || 0)}`}</p>
                                                    </div>
                                                    {Object.values(totals?.vatTotals || {}).filter((vatValue) => (vatValue || 0) != 0).length > 0 && (
                                                        <div
                                                            className={styles.vatTotals}
                                                            style={{ height: (printMode ? 14 : 17) * Object.values(totals?.vatTotals || {}).filter((vatValue) => (vatValue || 0) != 0).length + 17 }}
                                                        >
                                                            <hr />
                                                            {Object.keys(totals?.vatTotals || {})
                                                                .sort((a, b) => Number(a) - Number(b))
                                                                .map((vat) => {
                                                                    const vatValue = totals?.vatTotals[vat] || 0;
                                                                    return vatValue == 0 ? null : (
                                                                        <div key={vat} className={styles.vatTotalRow}>
                                                                            <p>{`${vat} %:`}</p>
                                                                            <p>{`${currencyFormatter.format(vatValue)}`}</p>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <h3>{this.tr('Total')}</h3>
                                                    <p>{currencyFormatter.format(totals?.total || 0)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <Popover
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    transformOrigin={{ horizontal: 'right', vertical: -8 }}
                                    open={!!columnSettingsAnchor}
                                    anchorEl={columnSettingsAnchor}
                                    onClose={this.closeColumnSettings}
                                >
                                    <div className={styles.viewOptions}>
                                        <div className={styles.viewOptionRow}>
                                            <div>
                                                <h2>{this.tr('Print')}</h2>
                                            </div>
                                            <div>
                                                <h2>{this.tr('View')}</h2>
                                            </div>
                                            <div>
                                                <h2>{this.tr('Name')}</h2>
                                            </div>
                                        </div>
                                        {listColumns
                                            .filter((c) => !c.hideFromPrintOptions && !c.hidden)
                                            .map((column) => {
                                                const hidden = column.visible == false;
                                                const printCheckbox = (
                                                    <Checkbox
                                                        className={`${column.alwaysHiddenFromPrint ? styles.alwaysHidden : ''} ${!column.hiddenFromPrint && !hidden ? styles.active : ''}`}
                                                        disabled={hidden || column.alwaysHiddenFromPrint}
                                                        onChange={(e) => this.onColumnPrintVisibilityChange(column.field, e.target.checked)}
                                                        checked={!column.hiddenFromPrint && !hidden}
                                                    />
                                                );
                                                return (
                                                    <div className={styles.viewOptionRow}>
                                                        <Tooltip title={hidden ? this.tr('The column needs to be visible in order to show it in print.') : ''}>
                                                            <div>{printCheckbox}</div>
                                                        </Tooltip>
                                                        <div>
                                                            <Checkbox
                                                                className={!hidden ? styles.active : ''}
                                                                onChange={(e) => this.onColumnVisibilityChange(column.field, e.target.checked)}
                                                                checked={!hidden}
                                                            />
                                                        </div>
                                                        <div>
                                                            <p>{column.header}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        <div className={styles.viewOptionRow}>
                                            <Tooltip title={grandTotalHidden ? this.tr('Grand total needs to be visible in order to show it in print.') : ''}>
                                                <div>
                                                    {
                                                        <Checkbox
                                                            className={`${!grandTotalPrintHidden && !grandTotalHidden ? styles.active : ''}`}
                                                            disabled={grandTotalHidden}
                                                            onChange={this.onGrandTotalPrintVisibilityChange}
                                                            checked={!grandTotalPrintHidden && !grandTotalHidden}
                                                        />
                                                    }
                                                </div>
                                            </Tooltip>
                                            <div>
                                                <Checkbox className={!grandTotalHidden ? styles.active : ''} onChange={this.onGrandTotalVisibilityChange} checked={!grandTotalHidden} />
                                            </div>
                                            <div>
                                                <p>{this.tr('Grand total')}</p>
                                            </div>
                                        </div>
                                        {this.context.addons && this.context.addons.quoterow_show_only_quantity_in_print &&
                                            <>
                                            <Divider />
                                            <div className={styles.viewOptionRow}>
                                                <div className={styles.oneCheckBox}>
                                                    <Checkbox
                                                        className={`${columnValuesPrintHidden ? styles.active : ''}`}
                                                        disabled={false}
                                                        onChange={(e) => this.onColumnPrintVisibilityChange('columnValuesHidden', !e.target.checked)}
                                                        checked={columnValuesPrintHidden}
                                                    />
                                                </div>
                                                <div>
                                                    <p>{this.tr('Show only amount-column in print rows')}</p>
                                                </div>
                                            </div>
                                            </>
                                        }
                                    </div>
                                </Popover>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
                <FieldEditSlider
                    onSave={editSliderData?.onSave}
                    item={editSliderData?.item}
                    fields={editSliderData?.fields}
                    title={this.tr('New visiting address')}
                    open={!!editSliderData}
                    onClose={this.closeEditSlider}
                />
            </div>
        );
    }
}

export default Quote;
