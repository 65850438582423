import TaimerComponent from '../../TaimerComponent';

import Error from '@mui/icons-material/Error';
import WarningRounded from '@mui/icons-material/WarningRounded';
import styles from './ErrorsList.module.scss';

export interface data {
    header?: string;
    message?: string;
}

export interface ErrorsListProps {
    data: data[];
    type?: string;
    className?: string;
    hideHeader?: boolean;
    shownRowsAmount?: number;
    typeClass?: string;
}

interface State {
    showAllErrors: boolean;
}

class ErrorsList extends TaimerComponent<ErrorsListProps, State> {
    constructor(props, context) {
        super(props, context, "dialogs/elements/ErrorsList");

        this.state = {
            showAllErrors: false
        };
    }

    renderErrorRow = (row) => {
        const { type, hideHeader } = this.props;

        return (
            <li className={styles.row}>
                {type == "warning" ? <WarningRounded /> : <Error />}
                <div className={styles.texts}>              
                    {!hideHeader && <span className={styles.header}>{row.header}</span>}
                    <span className={styles.message}>{row.message}</span>
                </div>  
            </li>
        )
    }

    renderShowAllErrorsRow = (amount) => {
        return (
            <li className={styles.row}>
                <Error />                 
                <span className={styles.header}>{"+ " + amount + " " + this.tr("more")}</span>
                <span className={styles.link} onClick={() => this.setState({ showAllErrors: true })}>{this.tr("Show all")}</span>
            </li>
        )
    }
    
    render() { 
        const { data, type, className, shownRowsAmount, typeClass } = this.props;
        const { showAllErrors } = this.state;
        const SHOWN_ROWS_AMOUNT = shownRowsAmount || 3;

        const shownErrors = showAllErrors ? data : data.slice(0, SHOWN_ROWS_AMOUNT);
        const restErrorsCount = data.length - shownErrors.length;

        return (
            <div className={`${styles.ErrorsListContainer} ${className ? className : ""}`}>
                <ul className={`${styles.errorList} ${type == "warning" ? styles.warning : ""} ${typeClass ? styles[typeClass] : ""}`}>
                    {shownErrors.map(i => {
                        return this.renderErrorRow(i);
                    })}
                    {!showAllErrors && restErrorsCount > 0 && this.renderShowAllErrorsRow(restErrorsCount)}
                </ul>
            </div>
        )
    }
}

export default ErrorsList;