import React, { ReactNode, useEffect, useState } from 'react';
import moment from 'moment/min/moment-with-locales';
import { isEqual } from 'lodash';
import TaimerComponent from '../../../TaimerComponent';
import DataList from '../../../general/DataList';
import { AddContact } from '../../../general/no-options/AddItemComponents';
import { ProposalContentSection, Person } from '../types';
import styles from '../ProposalEditor.module.css';
import { DateRangePopper } from '../../../general/react-date-range/src/components/DateRangePicker';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from '../../../invoices/Logo';
import DataHandler from '../../../general/DataHandler';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

interface PersonBlockProps {
    title: string;
    dataListPlaceholder: any;
    users: Person[];
    onUserSelected: (user: Person, addContact?: boolean) => void;
    onPhoneChanged: (phone: string) => void;
    onEmailChanged: (email: string) => void;
    selected?: string;
    disabled?: boolean;
    customPhone?: string;
    customMail?: string;
    phonePlaceholder: string;
    emailPlaceholder: string;
    company: string;
    account: string;
    noOptions?: React.ReactNode;
    overrideValue?: any;
}

const PersonBlock = (props: PersonBlockProps) => {
    const { title, dataListPlaceholder, users, noOptions, company, account, phonePlaceholder, emailPlaceholder, selected, customPhone, customMail, onUserSelected, onPhoneChanged, onEmailChanged, disabled, overrideValue } = props;
    const [value, setValue] = useState<Person | undefined>(undefined);
    useEffect(() => {
        const newValue = users.find((u) => u.id == selected);
        setValue(newValue);
    }, [selected, users]);
    const shownValue = value || overrideValue;
    return (
        <div className={styles.personBlock}>
            <h2>{title}</h2>
            <DataList
                /*@ts-ignore */
                isDisabled={disabled}
                menuListMaxHeight={200}
                menuWidth={300}
                value={shownValue}
                label={dataListPlaceholder}
                noOptions={noOptions}
                name="contact"
                company={company}
                account={account}
                companies_id={company}
                customers_id={account}
                options={users}
                onChange={onUserSelected}
                onItemCreated={(contact) => onUserSelected(contact, true)}
                className={styles.personDataList}
                shownCount={20}
            />
            {<p>{shownValue?.label || '-'}</p>}
            <div className={styles.labels}>
                {shownValue && <input placeholder={phonePlaceholder} onChange={(e) => onPhoneChanged(e.target.value)} value={customPhone || shownValue.phone || ''} />}
                {shownValue && <input placeholder={emailPlaceholder} onChange={(e) => onEmailChanged(e.target.value)} value={customMail || shownValue.email || ''} />}
            </div>
        </div>
    );
};

interface Props {
    section: ProposalContentSection;
    sectionIndex: number;
    company: string;
    onToggleHidden: (sectionIndex: number, itemKey: string) => void;
    employees: Person[];
    contacts: Person[];
    editProposalValue: (sectionIndex: number, key: string | string[], value: any) => void;
    checkPrivilege?: (group: string, right: string, company: string) => boolean;
    templateMode?: boolean;
    quoteId: string;
    loadingBGImage: boolean;
    readMode: boolean;
    printLanguage?: string;
    printDateFormat?: string;
    account: any;
    onAddContact: (contact: Person) => void
    onLogoLoaded?: () => void;
}

interface State {
    datePickerAnchor?: ReactNode;
    datePickerMode: 'daterange' | 'date';
    datePickerKey?: 'sent' | 'validUntil';
    loadedBGImage?: any;
    loadingBGImage: boolean;
}

interface WithHighlightProps {
    hidden?: boolean;
    children: ReactNode;
    itemKey: string;
    onToggleHidden: (itemKey: string) => void;
    templateMode?: boolean;
}

const WithHighLight = (props: WithHighlightProps) => {
    const { hidden, children, onToggleHidden, itemKey, templateMode } = props;
    return (
        <div className={styles.editHighlight}>
            <div className={hidden ? styles.hidden : ''}>{children}</div>
            {!templateMode && <button onClick={() => onToggleHidden(itemKey)}>{hidden ? <VisibilityOff /> : <Visibility />}</button>}
        </div>
    );
};

export default class CoverPage extends TaimerComponent<Props, State> {
    _itemKeys: { titles: string; timeline: string; sent: string; validUntil: string };
    constructor(props, context) {
        super(props, context, 'projects/proposal/sections/CoverPage');
        this._itemKeys = {
            titles: 'titles',
            timeline: 'timeline',
            sent: 'sent',
            validUntil: 'validUntil',
        };
        this.state = {
            datePickerMode: 'daterange',
            loadingBGImage: false,
        };
    }

    componentDidMount = () => {
        this._getBGFile();
    };

    shouldComponentUpdate = (oldProps: Props, oldState) => {
        return (
            !isEqual(oldProps.section.content, this.props.section.content) ||
            !isEqual(oldProps.section.hiddenBlocks, this.props.section.hiddenBlocks) ||
            !isEqual(oldProps.employees, this.props.employees) ||
            !isEqual(oldProps.contacts, this.props.contacts) ||
            oldProps.sectionIndex != this.props.sectionIndex ||
            oldProps.section.backgroundImage != this.props.section.backgroundImage ||
            oldState.datePickerAnchor != this.state.datePickerAnchor ||
            oldState.loadedBGImage != this.state.loadedBGImage ||
            oldState.loadingBGImage != this.state.loadingBGImage ||
            oldProps.loadingBGImage != this.props.loadingBGImage
        );
    };

    componentDidUpdate = (oldProps) => {
        if (!isEqual(oldProps.section.backgroundImage, this.props.section.backgroundImage)) {
            this._getBGFile();
        }
        if (oldProps.loadingBGImage != this.props.loadingBGImage) {
            this.setState({ loadingBGImage: this.props.loadingBGImage });
        }
    };

    onLogoLoaded = () => {
        this.props.onLogoLoaded && this.props.onLogoLoaded();
    }

    _getBGFile = () => {
        const { section, quoteId } = this.props;
        if (section.backgroundImage?.filename) {
            this.setState({ loadingBGImage: true }, () => {
                DataHandler.get({ url: `projects/proposals/${quoteId}/attachments`, filename: section.backgroundImage?.filename })
                    .then((response) => {
                        const loadedBGImage = response.attachment;
                        this.setState({ loadedBGImage, loadingBGImage: false });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ loadingBGImage: false });
                    });
            });
        }
    };

    _formatBackendDate = (date) => {
        const { templateMode } = this.props;
        if (templateMode) return '_';
        const dateFormat = this.props.printDateFormat;
        if (!date || date == '0000-00-00') {
            return '_';
        }
        return moment(date).format(dateFormat);
    };

    handleDatePickerBlur = () => {
        this.setState({ datePickerAnchor: undefined });
    };

    _showDateRangePicker = (e) => {
        this.setState({ datePickerAnchor: e.currentTarget, datePickerMode: 'daterange' });
    };

    _showDatePicker = (e, datePickerKey: 'sent' | 'validUntil') => {
        this.setState({ datePickerAnchor: e.currentTarget, datePickerMode: 'date', datePickerKey });
    };

    onDateRangeChanged = (value) => {
        const { datePickerMode } = this.state;
        const { editProposalValue, sectionIndex } = this.props;
        if (datePickerMode == 'date' && this.state.datePickerKey) {
            const key = this.state.datePickerKey;
            const date = value;
            editProposalValue(sectionIndex, key, moment(date).format('YYYY-MM-DD'));
        } else {
            const { startDate, endDate } = value.selection;
            editProposalValue(sectionIndex, ['startDate', 'endDate'], { startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') });
        }
    };

    _getDateRangeForPicker = () => {
        const {
            section: { content },
        } = this.props;
        let startDate = new Date();
        let endDate = new Date();
        if (content.startDate && content.startDate != '0000-00-00') {
            startDate = moment(content.startDate).toDate();
        }
        if (content.endDate && content.endDate != '0000-00-00') {
            endDate = moment(content.endDate).toDate();
        }
        return [
            {
                startDate,
                endDate,
                key: 'selection',
            },
        ];
    };

    _getDateForPicker = () => {
        const {
            section: { content },
        } = this.props;
        let date = new Date();
        if (this.state.datePickerKey && content[this.state.datePickerKey] && content[this.state.datePickerKey] != '0000-00-00') {
            date = moment(content[this.state.datePickerKey]).toDate();
        }
        return date;
    };

    getTranslation = (value) => {
        const { printLanguage } = this.props;
        return this.beTr(value, printLanguage || 'en', {}, 'general/backendTranslations/Proposal');
    };

    onInputKeyDown = (e) => {
        if (e.key == 'Enter') {
            document.execCommand('insertLineBreak');
            e.preventDefault();
        }
    };

    render() {
        const { datePickerMode, loadedBGImage, loadingBGImage } = this.state;
        const { employees, contacts, editProposalValue, section, sectionIndex, onToggleHidden, company, account, templateMode, readMode } = this.props;
        const { content, hiddenBlocks = [] } = section;
        return (
            <div className={styles.page}>
                {section.backgroundImage && (
                    <img
                        src={section.backgroundImage.filename ? loadedBGImage || section.backgroundImage.localFile : section.backgroundImage}
                        className={`${styles.backgroundImage} ${loadingBGImage ? styles.loading : ''}`}
                    />
                )}
                <div className={`${styles.coverPage} ${readMode ? styles.readOnly : ''}`}>
                    <div>
                        <div className={styles.logoContainer}>
                            <Logo editMode={true} company={company} base64Encode={true} onLogoLoaded={this.onLogoLoaded} shrinkLogo />
                        </div>
                    </div>
                    <div>
                        <WithHighLight
                            templateMode={templateMode}
                            hidden={hiddenBlocks.indexOf(this._itemKeys.titles) != -1}
                            itemKey={this._itemKeys.titles}
                            onToggleHidden={(itemKey) => onToggleHidden(sectionIndex, itemKey)}
                        >
                            <div className={styles.titleSection}>
                                <span
                                    onKeyDown={this.onInputKeyDown}
                                    onBlur={(e) => editProposalValue(sectionIndex, 'title', e.currentTarget.textContent)}
                                    contentEditable={true}
                                    spellCheck={false}
                                    className={styles.title}
                                >
                                    {content.title}
                                </span>
                                <span
                                    onKeyDown={this.onInputKeyDown}
                                    onBlur={(e) => editProposalValue(sectionIndex, 'subtitle', e.currentTarget.textContent)}
                                    contentEditable={true}
                                    spellCheck={false}
                                    placeholder={this.tr('Add optional description')}
                                    className={styles.subtitle}
                                >
                                    {content.subtitle}
                                </span>
                            </div>
                        </WithHighLight>
                    </div>
                    <div>
                        <WithHighLight
                            templateMode={templateMode}
                            hidden={hiddenBlocks.indexOf(this._itemKeys.timeline) != -1}
                            itemKey={this._itemKeys.timeline}
                            onToggleHidden={(itemKey) => onToggleHidden(sectionIndex, itemKey)}
                        >
                            <p onClick={!templateMode ? this._showDateRangePicker : undefined}>
                                <strong>{`${this.getTranslation('Project timeline')}: ${this._formatBackendDate(content.startDate)} - ${this._formatBackendDate(content.endDate)}`}</strong>
                            </p>
                        </WithHighLight>
                        <WithHighLight
                            templateMode={templateMode}
                            hidden={hiddenBlocks.indexOf(this._itemKeys.sent) != -1}
                            itemKey={this._itemKeys.sent}
                            onToggleHidden={(itemKey) => onToggleHidden(sectionIndex, itemKey)}
                        >
                            <p onClick={!templateMode ? (e) => this._showDatePicker(e, 'sent') : undefined}>{`${this.getTranslation('Sent')}: ${this._formatBackendDate(content.sent)}`}</p>
                        </WithHighLight>
                        <WithHighLight
                            templateMode={templateMode}
                            hidden={hiddenBlocks.indexOf(this._itemKeys.validUntil) != -1}
                            itemKey={this._itemKeys.validUntil}
                            onToggleHidden={(itemKey) => onToggleHidden(sectionIndex, itemKey)}
                        >
                            <p onClick={!templateMode ? (e) => this._showDatePicker(e, 'validUntil') : undefined}>{`${this.getTranslation('Valid until')}: ${this._formatBackendDate(
                                content.validUntil
                            )}`}</p>
                        </WithHighLight>
                    </div>
                    <div className={styles.personSection}>
                        <PersonBlock
                            title={this.getTranslation('Created by:')}
                            dataListPlaceholder={this.tr('Select user')}
                            selected={content.createdBy}
                            overrideValue={content.overrideSender}
                            users={employees}
                            customPhone={content.customCreatorPhone}
                            customMail={content.customCreatorMail}
                            phonePlaceholder={this.tr('Phone')}
                            emailPlaceholder={this.tr('Email')}
                            disabled={templateMode}
                            company={company}
                            account={account}
                            onUserSelected={(user) => editProposalValue(sectionIndex, 'createdBy', user.id)}
                            onPhoneChanged={(phone) => editProposalValue(sectionIndex, 'customCreatorPhone', phone)}
                            onEmailChanged={(email) => editProposalValue(sectionIndex, 'customCreatorMail', email)}
                        />
                        <PersonBlock
                            title={this.getTranslation('Prepared for:')}
                            dataListPlaceholder={this.tr('Select user')}
                            selected={content.preparedFor}
                            overrideValue={content.overrideReceiver}
                            customPhone={content.customPreparedForPhone}
                            customMail={content.customPreparedForMail}
                            phonePlaceholder={this.tr('Phone')}
                            emailPlaceholder={this.tr('Email')}
                            company={company}
                            account={account}
                            noOptions={AddContact}
                            users={contacts}
                            disabled={templateMode}
                            onUserSelected={(user, addContact = false) => {
                                if (addContact) this.props.onAddContact(user);
                                editProposalValue(sectionIndex, 'preparedFor', user.id);
                            }}
                            onPhoneChanged={(phone) => editProposalValue(sectionIndex, 'customPreparedForPhone', phone)}
                            onEmailChanged={(email) => editProposalValue(sectionIndex, 'customPreparedForMail', email)}
                        />
                    </div>
                    <DateRangePopper
                        ranges={this._getDateRangeForPicker()}
                        date={this._getDateForPicker()}
                        mode={datePickerMode}
                        onChange={this.onDateRangeChanged}
                        open={!!this.state.datePickerAnchor}
                        handleBlur={this.handleDatePickerBlur}
                        anchorEl={this.state.datePickerAnchor}
                    />
                    {loadingBGImage && <Loading className={styles.loader} />}
                </div>
            </div>
        );
    }
}
