import moment from 'moment/min/moment-with-locales';
import Utils from '../general/Utils';
import { 
    divideDateTimeRangeIntoDays,
    createDateObject,
    to24HourTime
} from "../general/DateTimeUtils";
import {
    nmultiply,
    multiply,
    ndivide,
    divide
} from "../general/MathUtils";

import {
    DailyAllowance
} from "./ExpenseView";

type DayTuple = [full: number, half: number];

function calculateAllowanceDays(startTime: string, endTime: string): number {
    startTime                    = startTime.replace(".", ":");
    endTime                      = endTime.replace(".", ":");
    const start: number          = createDateObject(startTime).getTime() / 1000;
    const end: number            = createDateObject(endTime).getTime() / 1000;
    const hours: number          = (end - start) / 3600;
    const days: number           = Math.floor(hours / 24);
    const remainingHours: number = hours - (days * 24);
    let total: number            = days;

    total += getAllowanceFactorByHours(remainingHours, days > 0);

    return total;
}

function getAllowanceFactorByHours(hours: number, afterFullDay: boolean): number {
    if(hours > 24 || hours < 0) {
        throw new Error("The 'hours' parameter must be less than 24 and greater than 0.");
    }

    const upperLimit = afterFullDay ? 6 : 10;
    const lowerLimit = afterFullDay ? 2 : 6;

    if(hours > upperLimit) {
        return 1;
    }

    return hours > lowerLimit
        || (afterFullDay && hours >= lowerLimit)
        ? 0.5
        : 0;
}

function getFullDaysAndHalfDays(days: number): DayTuple {
    console.assert(days % 0.5 === 0);

    const full: number = Math.floor(days);
    const half: number = (days - full) / 0.5;

    return [full, half];
}

function fixTime(time: string): string {
    return time 
        ? time.replace(".", ":")
        : "";
}

function calculateDayMealDeductions(
    startDateTime: string, 
    endDateTime: string, 
    mealAmount: number,
    fullRate: number,
    partRate: number,
    index: number
): {
    total: number;
    withoutMeals: number;
    deduction: number;
} {
    const totals: {
        total: number;
        withoutMeals: number;
        deduction: number;
    } = {
        total: 0,
        withoutMeals: 0,
        deduction: 0
    };

    const sD = createDateObject(startDateTime);
    const eD = createDateObject(endDateTime);

    // Convert to UTC 0 to avoid daylight saving time issues. Hours can be 23 or 25 hours if daylight saving time changes between start and end date.
    const sDZeroUTF = moment(sD).utcOffset(0, true);
    const eDZeroUTF = moment(eD).utcOffset(0, true);

    const diffHours: number = moment(eDZeroUTF).diff(moment(sDZeroUTF), "hours");
    const diffMinutes: number = moment(eDZeroUTF).diff(moment(sDZeroUTF), "minutes") % 60;
    const hours: number       = diffHours + (diffMinutes / 60);
    const factor: number      = getAllowanceFactorByHours(hours, index > 0);

    if(factor === 0) {
        return totals;
    }

    const totalFactor: number = (factor === 1 && mealAmount === 2) || (factor === 0.5 && mealAmount > 0)
        ? 0.5
        : 1;

    totals.withoutMeals = factor === 1 ? fullRate : partRate;
    totals.total        = totals.withoutMeals !== undefined
        ? totals.withoutMeals * totalFactor
        : 0;

    totals.deduction = totals.total - totals.withoutMeals;

    return totals;
}

function calculateFreeMealDeductionFinland(
    meals: { [key: string]: number },
    fullRate: number, 
    partRate: number, 
    startTime: string,
    endTime: string
): { 
    total: number; 
    withoutMeals: number; 
    deduction: number; 
} {
    const range: string[]                       = divideDateTimeRangeIntoDays(startTime, endTime)
    const times: [start: string, end: string][] = Utils.intRange(0, range.length - 2)
        .map((i: number): [start: string, end: string] => [range[i], range[i+1]]);

    const totals: {
        total: number;
        withoutMeals: number;
        deduction: number;
    } = {
        total: 0,
        withoutMeals: 0,
        deduction: 0
    };

    times.map((e: [start: string, end: string], index: number): {
        total: number;
        withoutMeals: number;
        deduction: number;
    } => {
        const mealAmount: number = meals[moment(createDateObject(e[0])).format("YYYY-MM-DD")];

        return calculateDayMealDeductions(e[0], e[1], mealAmount, fullRate, partRate, index);
    }).forEach((t: {
        total: number;
        withoutMeals: number;
        deduction: number;
    }) => {
        totals.total        += t.total;
        totals.withoutMeals += t.withoutMeals;
        totals.deduction    += t.deduction;
    });

    return totals;
}

// Works for the rates defined for expenses only.
// DO NOT use for general currency conversion.
//
// original = the original amount of X currency we want to transform to another currency.
// foreignInOriginal = how many units of the original currency X the "foreign" currency is.
function calculateByCurrencyRate(
    original: number, 
    foreignInOriginal: number,
    roundTo = 6
): number {
    if(foreignInOriginal <= 0) {
        throw Error("Invalid rate");
    }

    return divide(original, foreignInOriginal)
        .round(roundTo)
        .toNumber();
}

// Works for the rates defined for expenses only.
// DO NOT use for general currency conversion.
function calculateByCurrencyRateInverse(
    original: number, 
    foreignInOriginal: number,
    roundTo = 6
): number {
    if(foreignInOriginal <= 0) {
        throw Error("Invalid rate");
    }

    return multiply(original, foreignInOriginal)
        .round(roundTo)
        .toNumber();
}

// TODO: Move into some other utility module,
// it doesn't really belong here.
function mapFieldsWithType<Entity>(obj: any, map: { [key: string]: string; }): Entity {
    const ent = {} as Entity;

    Object.keys(map)
        .filter((key: string) => obj.hasOwnProperty(key))
        .forEach((f: string) => ent[map[f]] = obj[f]);

    return ent;
}

// TODO: Move into some other utility module,
// it doesn't really belong here.
function flipObject<T>(object: any): T {
    const newObj: T = {} as T;

    for(const key in object) {
        newObj[object[key]] = key;
    }

    return newObj;
}

interface DailyAllowanceTimeBundle {
    start: number;
    startStr: string;
    end: number;
    endStr: string;
    endOfYear: number;
}

function getDailyAllowanceTimeBundle(item: DailyAllowance): DailyAllowanceTimeBundle {
    const startTime = to24HourTime(item.startTime);
    const endTime = to24HourTime(item.endTime);
    const itemStartStr = `${item.startDate}T${startTime}`;

    return {
        start    : createDateObject(`${item.startDate}T${startTime}`).getTime(),
        startStr : itemStartStr,
        end      : createDateObject(`${item.endDate}T${endTime}`).getTime(),
        endStr   : `${item.endDate}T${endTime}`,
        endOfYear: createDateObject(`${moment(itemStartStr).format("YYYY")}-12-31T23:59:59`).getTime(),
    }
}

export {
    calculateAllowanceDays,
    getFullDaysAndHalfDays,
    getAllowanceFactorByHours,
    fixTime,
    mapFieldsWithType,
    flipObject,
    calculateFreeMealDeductionFinland,
    calculateDayMealDeductions,
    calculateByCurrencyRate,
    calculateByCurrencyRateInverse,
    getDailyAllowanceTimeBundle,
    type DayTuple
}
