import replace from "string-replace-to-array";
import { Emoji } from "emoji-mart";
import React from "react";
import _ from "lodash";

import ColorHash from 'color-hash';

import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';

import { 
  roundToFixed
} from "./general/MathUtils";

export const colorHash = new ColorHash();

export const toTitleCase = (string) => {
  if (!string) return "";
  const formatted = string.toLowerCase();
  return formatted.charAt(0).toUpperCase() + formatted.slice(1);
};

export function formatHoursAndMinutes(h, m, allowEmpty = false) {
  return (
    (h > 0 ? `${h} h ` : "") +
    (m > 0 || (!h && !allowEmpty) ? `${m} m` : "").trim()
  );
}

export function formatHours(hours, allowEmpty = false) {
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);

  return (
    (h > 0 ? `${h} h ` : "") +
    (m > 0 || (!h && !allowEmpty) ? `${m} m` : "").trim()
  );
}

export function prefixLink(link) {
  if (!link || !link.startsWith)
      return link;
    
  if (link.startsWith('http://') || link.startsWith('https://'))
    return link;

  return `http://${link}`;
}

const emojiRegex = /(:([+-\d\w]+(::skin-tone-\d)?):)/g;
const linkEmojiRegex = /((:([+-\d\w]+(::skin-tone-\d)?):)|((http|https):\/\/([^\s'"]+)))/g;

export function renderWithEmoji(text, size = 32, enableEmbeds = true) {
  let i = 0;
  function replaceEmoji(match, emoji) {
    if (match.startsWith("http://") || match.startsWith("https://")) {
      const url = new URL(match);

      if (
        enableEmbeds &&
        (url.host === "www.youtube.com" ||
          url.host === "youtube.com" ||
          url.host === "www.youtu.be" ||
          url.host === "youtu.be")
      ) {
        let id = false;
        const time = url.searchParams.get("t") || 0;

        if (url.pathname.startsWith("/watch")) {
          id = url.searchParams.get("v");
        } else {
          id = url.pathname;
        }

        if (id) {
          return (
            <iframe
              className="youtube-embed"
              src={`https://www.youtube-nocookie.com/embed/${id}?${
                time ? `start=${time}` : ""
              }`}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          );
        }
      }

      return (
        <a href={match} target="_blank" rel="noreferrer">
          {match}
        </a>
      );
    }

    return (
      <Emoji
        key={`${i++}-${emoji}`}
        title={emoji}
        tooltip
        emoji={emoji}
        set="twitter"
        size={size}
      />
    );
  }

  return replace(text, linkEmojiRegex, replaceEmoji);
}

export function filterByText(data = [], search = "", fields = []) {
  if (!search) return data;

  const filtered = [];

  // By default search all fields
  if (fields.length === 0) {
    const first = _.first(data);
    fields = _.keys(first);
  }

  search = search.toLowerCase();

  _.forEach(data, value => {
    let found = false;

    fields.forEach(f => {
      const val = String(value[f]).toLowerCase();

      if (val.indexOf(search) > -1) found = true;
    });

    if (found) filtered.push(value);
  });

  return filtered;
}

export function objectMap(object, mapFn) {
  if (!object) return [];

  return Object.keys(object).reduce(function(result, key) {
    result.push(mapFn(object[key]));
    return result;
  }, []);
}

export function convertToObject(array, func, numeric) {
  const obj = {};

  if (array)
    array.forEach(function(v) {
      if (func) func(v);

      obj[v.id] = v;
    });

  return obj;
}

export const convertDateFormat = (format, reverse = false) => {
  if (!format) return undefined;
  if (reverse) {
      return format.replace('DD', '%d').replace('MM', '%m').replace('YYYY', '%Y');
  }
  return format.replace('%d', 'DD').replace('%m', 'MM').replace('%Y', 'YYYY');
};

export function getElementOffset(el) {
  let _x = 0;
  let _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

export function timeOfDay(date) {
  const midnight = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0
  );

  return date.getTime() - midnight.getTime();
}

export function shadeColor(color, percent) {
  const c = typeof color === "object" ? color : parseHexColor(color);

  if (c.r === 255 && c.g === 255 && c.b === 255) percent = -Math.abs(percent);

  let R = parseInt((c.r * (100 + percent)) / 100, 10);
  let G = parseInt((c.g * (100 + percent)) / 100, 10);
  let B = parseInt((c.b * (100 + percent)) / 100, 10);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  return colorToHex({ r: R, g: G, b: B });
}

export const stringToColour = str => {
  let hash = 0;
  if (!str)
    str = "";
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (var i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const hexColorWithOpacity = (hex, opacity = 0.6) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      `,${opacity})`
    );
  }
  return hex;
};

export function textColorOnBG(color) {
  const c = parseHexColor(color);

  if (0.213 * c.r + 0.715 * c.g + 0.072 * c.b < 0.5) return "text-light";
  else return "text-dark";
}

export function parseHexColor(color) {
  if (typeof color === "object") return color;

  color = color.replace(/#/g, "");
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  return { r, g, b };
}

export function colorToHex(color) {
  if (typeof color === "string") return color;

  const r = color.r.toString(16).padStart(2, "0");
  const g = color.g.toString(16).padStart(2, "0");
  const b = color.b.toString(16).padStart(2, "0");

  return "#" + r + g + b;
}

export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export function getTextWidthInElement(text, el) {
  return getTextWidth(text, window.getComputedStyle(el).font);
}

export function randomColor() {
  const colorArray = [
    red["900"],
    red["700"],
    red["500"],
    red["300"],
    red["100"],
    pink["900"],
    pink["700"],
    pink["500"],
    pink["300"],
    pink["100"],
    purple["900"],
    purple["700"],
    purple["500"],
    purple["300"],
    purple["100"],
    deepPurple["900"],
    deepPurple["700"],
    deepPurple["500"],
    deepPurple["300"],
    deepPurple["100"],
    indigo["900"],
    indigo["700"],
    indigo["500"],
    indigo["300"],
    indigo["100"],
    blue["900"],
    blue["700"],
    blue["500"],
    blue["300"],
    blue["100"],
    lightBlue["900"],
    lightBlue["700"],
    lightBlue["500"],
    lightBlue["300"],
    lightBlue["100"],
    cyan["900"],
    cyan["700"],
    cyan["500"],
    cyan["300"],
    cyan["100"],
    teal["900"],
    teal["700"],
    teal["500"],
    teal["300"],
    teal["100"],
    "#194D33",
    green["700"],
    green["500"],
    green["300"],
    green["100"],
    lightGreen["900"],
    lightGreen["700"],
    lightGreen["500"],
    lightGreen["300"],
    lightGreen["100"],
    lime["900"],
    lime["700"],
    lime["500"],
    lime["300"],
    lime["100"],
    yellow["900"],
    yellow["700"],
    yellow["500"],
    yellow["300"],
    yellow["100"],
    amber["900"],
    amber["700"],
    amber["500"],
    amber["300"],
    amber["100"],
    orange["900"],
    orange["700"],
    orange["500"],
    orange["300"],
    orange["100"],
    deepOrange["900"],
    deepOrange["700"],
    deepOrange["500"],
    deepOrange["300"],
    deepOrange["100"],
    brown["900"],
    brown["700"],
    brown["500"],
    brown["300"],
    brown["100"],
    blueGrey["900"],
    blueGrey["700"],
    blueGrey["500"],
    blueGrey["300"],
    blueGrey["100"],
    "#525252",
    "#969696",
    "#D9D9D9"
  ];

  return colorArray[Math.floor(Math.random() * colorArray.length)];
}

export function formatInputNumber(value, type) {
  if (value == undefined) return value;
  const num = Number(String(value).replace(",",".").replace(/\s+/g, ''));
  if (!num && num !== 0)
    return value;

  if (type === "hours") {
    return String(roundToFixed(num, 2)).replace(",",".");
  } else if (type == 'no-zero-decimals') {
    return String(Math.round((num || 0) * 100) / 100).replace(".",",");
  } else if (type == 'no-format') {
    return num;
  } else
    return String(roundToFixed(num, 2)).replace(".",",");
}

export function checkStringRestrictedChars(chekcString, restrictedChars) {
  const matcher = new RegExp("[" + restrictedChars + "+]");

  if (matcher.test(chekcString)) {
      return true;
  } else {
      return false;
  }
}

export function isCompanyUsingInvoiceCountryCode(addons, company) {
  return (addons.nav && addons.nav.used_by_companies.indexOf(company) > -1);
}


export function validateVatNumber(vat, countryCode) {
  const validators = {
      AT: "(AT)U[0-9]{8}",
      BE: "(BE)[0-9]{10}",
      BG: "(BG)[0-9]{9,10}",
      HR: "(HR)[0-9]{11}",
      CY: "(CY)[0-9]{8}[A-Z]",
      CZ: "(CZ)[0-9]{8,10}",
      DE: "(DE)[0-9]{9}",
      DK: "(DK)[0-9]{8}",
      EE: "(EE)[0-9]{9}",
      GR: "(EL|GR)[0-9]{9}",
      ES: "(ES)[0-9A-Z][0-9]{7}[0-9A-Z]",
      FI: "(FI[0-9]{8}|[0-9]{7}-[0-9])",
      FR: "(FR)[0-9A-Z]{2}[0-9]{9}",
      GB: "(GB|XI)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})",
      HU: "(HU)[0-9]{8}",
      IE: "IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])",
      IT: "(IT)[0-9]{11}",
      LT: "(LT)([0-9]{9}|[0-9]{12})",
      LU: "(LU)[0-9]{8}",
      LV: "(LV)[0-9]{11}",
      MT: "(MT)[0-9]{8}",
      NL: "(NL)[0-9]{9}B[0-9]{2}",
      PL: "(PL)[0-9]{10}",
      PT: "(PT)[0-9]{9}",
      RO: "(RO)[0-9]{2,10}",
      SE: "(SE)[0-9]{12}",
      SI: "(SI)[0-9]{8}",
      SK: "(SK)[0-9]{10}"
  }

  const formats = {
    AT: 'ATU12345678',
    BE: 'BE1234567890',
    BG: 'BG123456789, BG1234567890',
    HR: 'HR12345678901',
    CY: 'CY12345678X',
    CZ: 'CZ12345678, CZ123456789, CZ1234567890',
    DK: 'DK12345678',
    EE: 'EE123456789',
    FI: 'FI12345678, 1234567-8',
    FR: 'FR12345678901, FRX1234567890, FR1X123456789, FRXX123456789',
    DE: 'DE123456789',
    GR: 'EL123456789, GR123456789',
    HU: 'HU12345678',
    IE: 'IE1234567X, IE1X23456X, IE1234567XX',
    IT: 'IT12345678901',
    LV: 'LV12345678901',
    LT: 'LT123456789, LT123456789012',
    LU: 'LU12345678',
    MT: 'MT12345678',
    NL: 'NL123456789B01',
    PL: 'PL1234567890',
    PT: 'PT123456789',
    RO: 'RO12, RO123, RO1234, RO12345, RO123456, RO1234567, RO12345678, RO123456789, RO1234567890',
    SK: 'SK1234567890',
    SI: 'SI12345678',
    ES: 'ESX12345678, ES12345678X, ESX1234567X',
    SE: 'SE123456789012',
    GB: "XI999999999, XI99999999999, XIXX999, GB999999999, GB999999999999, GBXX999"
  }

  if (!vat) {
    return {error: true, format: formats[countryCode] ? formats[countryCode] : false};
  }
  if (!countryCode) {
    countryCode = "";
  }

  countryCode = countryCode.toUpperCase();

  let validator = validators[countryCode];
  if (!validator)
    validator = "(" + countryCode + ")" + "[a-zA-Z0-9-.]+";

  const re = new RegExp(`^${validator}$`, "i") 
  const result = re.test(vat);
  if (!result)
    return {error: true, format: formats[countryCode] ? formats[countryCode] : false};
  else 
    return {error: false};
}

export function formatVatNumber(value) {
  if (!value)
    value = "";
  value = String(value).trim().replace(/—/g, "-");
  return value;
}

export function getColorArray() {
  return [
    ['#002B59', '#003A78', '#32649A', '#7EA4CD', '#CAE4FF', '#E6F3FE', '#F0F3F7'], // Heeros blue
    ['#00679F', '#007ABF', '#2F9ED1', '#00B8E4', '#80DBF1', '#CCF1FA', '#E5F8FC'], // Blue
    ['#094033', '#0A7662', '#1CA88A', '#20C6A1', '#79DDC7', '#C3F3E9', '#F1FFFC'], // Green
    ['#6A4B19', '#CC8A0B', '#E59E14', '#FFCC33', '#FFE590', '#FFFEC2', '#FFFAEC'], // Yellow
    ['#87005C', '#B90073', '#D2007E', '#EC008A', '#F580C4', '#FFCADD', '#FDE5F3'], // Pink
    ['#4E2D89', '#6F42C1', '#9472D0', '#B7A0E0', '#CCBCE9', '#E2D9F3', '#F2EEF9']  // Purple
  ];
}
