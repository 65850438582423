import React from 'react';
import TaimerComponent from '../TaimerComponent';
import SubtitleDialog from '../dialogs/SubtitleDialog';
import ErrorsList from './elements/ErrorsList';
import QuoteScheduledInvoiceDialogListRow from '../dialogs/QuoteScheduledInvoiceDialogListRow';
import List from '../list/List';
import { roundToFixedNumber, roundToDecimals, nmultiply, ndivide } from '../general/MathUtils';
import DataHandler from '../general/DataHandler';

import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Tooltip, Switch } from '@mui/material';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { format } from 'date-fns';

import './DialogList.scss';
import './QuoteScheduledInvoiceDialog.scss';

import { cloneDeep, uniqBy } from 'lodash';

interface Data {
    rows: any[];
    projects_id: string | number;
    company: string | number;
    currency: string;
    calculateRowValues: (row: any, field: string, quote: any, valueDecimalsAmount: number, totalsDecimalAmount: number) => any;
    convertToCompanyCurrency: (value: string|number) => number;
    saveFunc: () => void;
}

interface Props extends WithSnackbarProps {
    onClose: () => void;
    onDialogSave: (saveFunc: any, id: number) => void; 
    data: Data;
}

interface State {
  installments: any[];
  addRowsSeparately: boolean;
  loading: boolean;
  defaultVAT: number;
  invalidFields: string[];
  commonRowVat: number; // Common vat from all quote rows if they all have same vat %.
  useCommonVat: boolean; // Should commonRowVat be used (is same vat in every row).
}

class QuoteScheduledInvoiceDialog extends TaimerComponent<Props, State> {
    listColumns: any = [];
    list: any = undefined;
    lastNewId = -1;
    totalRowsSum = 0;
    totalRowsCurrencySum = 0;

    constructor(props, context) {
        super(props, context, "dialogs/QuoteScheduledInvoiceDialog");

        const commonColumnProps = {
            showMenu: false,
            resizeable: false,
            moveable: false,
            hideable: false,
            showResizeMarker: false
        };

        this.listColumns = [
            { field: 'hidden_to_focus', name: 'hidden_to_focus', header: "", width: 0, ...commonColumnProps }, // Hidden cell that gets focused. To prevent datecell initial focus and calendar open.
            { field: 'invoice_date', name: 'invoice_date', header: this.tr('Invoice date'), width: 90, ...commonColumnProps },
            { field: 'description', name: 'description', header: this.tr('Description'), width: 80, ...commonColumnProps },
            { field: 'total_no_vat', name: 'total_no_vat', header: this.tr('Total VAT 0 %'), width: 80, alignRight: true, ...commonColumnProps },
            { field: 'vat_percentage', name: 'vat_percentage', header: this.tr('VAT %'), width: 80, alignRight: true, ...commonColumnProps },
            { field: 'total', name: 'total', header: this.tr('Total'), width: 80, alignRight: true, ...commonColumnProps },
            { field: 'percentage', name: 'percentage', header: this.tr('Percentage'), width: 80, alignRight: true, ...commonColumnProps },
            { field: 'delete', name: 'delete', header: undefined, width: 20, ...commonColumnProps },
        ];

        this.state = {
            installments: [],
            addRowsSeparately: false,
            loading: false,
            defaultVAT: context.taimerAccount?.defaultVat || 0,
            invalidFields: [],
            commonRowVat: 0,
            useCommonVat: false
        };

        this.list = React.createRef();
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.getCommonVat();
        this.getFirstInstallment();
    }

    getCommonVat = () => {
        const { data: { rows } } = this.props;
        
        const uniqueVatRows = uniqBy(rows, "vat");
        if (uniqueVatRows.length == 1) {
            this.setState({ commonRowVat: uniqueVatRows[0].vat, useCommonVat: true });
        }
    }

    getVatPercent = () => {
        const { defaultVAT, addRowsSeparately, commonRowVat, useCommonVat } = this.state;
        return addRowsSeparately && useCommonVat ? commonRowVat : defaultVAT;
    }

    getFirstInstallment = () => {
        const { data: { rows } } = this.props;
        const vatPercent = this.state.defaultVAT;

        rows.forEach(r => {
            this.totalRowsCurrencySum += Number(r.currency_total_no_vat) || 0;
            this.totalRowsSum += Number(r.total_no_vat) || 0;
        });

        const installment: object = {
            id: this.lastNewId,
            invoice_date: format(new Date(), "YYYY-MM-DD"),
            description: this.tr("Installment") + " " + (this.lastNewId * -1),
            currency_total_no_vat: roundToFixedNumber(this.totalRowsCurrencySum, 2),
            currency_total: roundToFixedNumber(nmultiply(this.totalRowsCurrencySum, (1 + ndivide(vatPercent, 100))), 2),
            total_no_vat: roundToFixedNumber(this.totalRowsSum, 2),
            total: roundToFixedNumber(nmultiply(this.totalRowsSum, (1 + ndivide(vatPercent, 100))), 2),
            vat_percentage: vatPercent,
            percentage: 100
        };

        this.lastNewId--;
        this.addTotalsRow([installment]);
    }

    addInstallment = () => {
        const { installments } = this.state;

        installments.push({
            id: this.lastNewId,
            invoice_date: format(new Date(), "YYYY-MM-DD"),
            description: this.tr("Installment") + " " +  (this.lastNewId * -1),
            vat_percentage: this.getVatPercent(),
        });

        const newInstallments = this.updatePercentageToInstallments(installments);

        this.lastNewId--;
        this.addTotalsRow(newInstallments);
    }

    deleteInstallment = (id) => {
        const { installments, invalidFields } = this.state;
        const installmentIndex = installments.findIndex(i => i.id == id);
        installments.splice(installmentIndex, 1);

        invalidFields.forEach((inv, index) => {
            const invId = inv.split("_")[1];
            if (invId == id) {
                invalidFields.splice(index, 1);
            }
        })
        this.setState({ invalidFields });

        const newInstallments = this.updatePercentageToInstallments(installments);
        this.addTotalsRow(newInstallments);
    }

    editInstallment = (id, name, value) => {
        const { invalidFields } = this.state;
        const { data: { convertToCompanyCurrency } } = this.props;
        
        const installments = cloneDeep(this.state.installments);
        let inst = installments.find(i => i.id == id);
        let invalids = invalidFields.filter(i => i != name + "_" + id);

        const numericFields = ["total_no_vat", "percentage", "vat_percentage"];
        const requiredFields = ["invoice_date", "description"];

        if (numericFields.find(n => n == name)) {
            const origValue = value;
            value = Number(value?.replace(",", ".") || 0);
            if (!value && value !== 0) {
                inst[name] = origValue;
                invalids.push(name + "_" + id);
                this.setState({ invalidFields: invalids });
                this.addTotalsRow(installments);
                return;
            }
        }

        if (requiredFields.find(n => n == name)) {
            if (!value && value !== "0") {
                inst[name] = value;
                invalids.push(name + "_" + id);
                this.setState({ invalidFields: invalids });
                this.addTotalsRow(installments);
                return;
            }
        }

        if (name == "invoice_date" && value == "Invalid Date") {
            inst[name] = value;
            invalids.push(name + "_" + id);
            this.setState({ invalidFields: invalids });
            this.addTotalsRow(installments);
            return;
        }

        inst[name] = value;
        let updateValues = false;
        let companyValue = inst['total_no_vat'];
        let currencyValue = inst['currency_total_no_vat'];

        switch (name) {
            case "total_no_vat":
                // Value is inputted in quote's currency, so need to convert to company's currency.
                companyValue = convertToCompanyCurrency(value); 
                invalids = invalids.filter(i => i != "percentage_" + id);
                updateValues = true;
                break;
            case "percentage":
                // Calculate currency value first and then convert it to company's currency. Prevent rounding errors.
                currencyValue = roundToDecimals(nmultiply(
                    this.totalRowsCurrencySum, 
                    Number(value) != 100 
                        ? ndivide(Number(value), 100) 
                        : 1
                    ), 4);

                companyValue = convertToCompanyCurrency(currencyValue); 
                invalids = invalids.filter(i => i != "total_no_vat_" + id);
                updateValues = true;
                break;
            case "vat_percentage":
                updateValues = true;
                break;
        }

        const hasInvalidValues = invalids.find(i => i == "total_no_vat_" + id);
        if (updateValues && !hasInvalidValues) {
            inst = this.updateInstallmentValues(inst, companyValue, name);
        }

        this.setState({ invalidFields: invalids });
        this.addTotalsRow(installments);
    }

    updateInstallmentVats = () => {
        const vatPercent = this.getVatPercent();

        const installments = this.state.installments.map(i => {
            i.vat_percentage = vatPercent;
            i = this.updateInstallmentValues(i, i['total_no_vat'], "vat_percentage");
            return i;
        });

        this.addTotalsRow(installments);
    }

    updateInstallmentValues = (installment, companyValue, updatedKey) => {
        const { data: { calculateRowValues } } = this.props;

        const row = {
            value: Number(companyValue) || 0,
            vat: Number(installment["vat_percentage"]) || 0,
            quantity: 1
        }

        const rowValues = calculateRowValues(row, 'value', undefined, 4, 4);

        const valuesToUpdate = [
            'total',
            'total_no_vat',
            'currency_total',
            'currency_total_no_vat'
        ];

        valuesToUpdate.forEach(key => {
            // Don't update currency_total_no_vat when percentage changes. Caused rounding errors to 4 decimals if row had 2 decimal value.
            const noUpdate = installment == "vat_percentage" && key == "currency_total_no_vat";
            if (!noUpdate) {
                installment[key] = rowValues[key];
            }
        });

        installment['percentage'] = updatedKey == "percentage" || updatedKey == "vat_percentage"
            ? installment['percentage']
            : roundToDecimals(nmultiply(100, ndivide(installment["total_no_vat"], this.totalRowsSum)), 4);

        return installment;
    }

    getSplitPercentage = (installments) => {
        const installmentsAmount = installments.filter(i => i.type != "totals")?.length || 1;
        return roundToDecimals(Number(100 / installmentsAmount), 4);
    }

    updatePercentageToInstallments = (installments) => {
        const vatPercent = this.getVatPercent();        
        const percentage = this.getSplitPercentage(installments);

        const newInstallments = installments.map(i => {
            i.total_no_vat = roundToFixedNumber(nmultiply(this.totalRowsSum, (ndivide(percentage, 100))), 4);
            i.total = roundToFixedNumber(nmultiply(i.total_no_vat, (1 + ndivide(vatPercent, 100))), 4);
            i.currency_total_no_vat = roundToFixedNumber(nmultiply(this.totalRowsCurrencySum, (ndivide(percentage, 100))), 4);
            i.currency_total = roundToFixedNumber(nmultiply(i.currency_total_no_vat, (1 + ndivide(vatPercent, 100))), 4);
            i.percentage = percentage;
            return i;
        });

        return newInstallments;
    }

    addTotalsRow = (installments) => {
        let sum = 0;
        let vatSum = 0;
        let percentage = 0;
        
        const totalsRowIndex = installments.findIndex(i => i.type == "totals");
        if (totalsRowIndex > -1) {
            installments.splice(totalsRowIndex, 1); // Remove totals row.
        }

        installments.forEach(r => {
            sum += Number(r.currency_total_no_vat || 0) || 0;
            vatSum += Number(r.currency_total || 0) || 0;
            percentage += Number(r.percentage || 0) || 0;
        });

        installments.push({
            id: this.lastNewId - 1,
            type: "totals",
            invoice_date: this.tr("Total"),
            decription: "",
            currency_total_no_vat: sum,
            currency_total: vatSum,
            vat_percentage: "",
            percentage: roundToFixedNumber(percentage, 2)
        });
        this.setState({ installments }, () => {
            this.list?.current && this.list.current.resetStateData(() => this.scrollListToBottom());
        });
    }

    scrollListToBottom = () => {
        this.list?.current && this.list.current.setScrollTop(this.list.current.mainList?.current?.scrollHeight);
    }

    save = async () => {
        const { onDialogSave, enqueueSnackbar, data: { rows, projects_id, saveFunc } } = this.props;
        const { installments, addRowsSeparately, invalidFields, useCommonVat } = this.state;

        if (invalidFields.length > 0) {
            enqueueSnackbar(this.tr("Invalid data!"), {
                variant: "error",
            });
            return;
        }

        this.setState({ loading: true });

        const totalRow = installments.find(i => i.type == "totals");

        const data = {
            rows,
            installments: installments.filter(i => i.type != "totals"),
            totalRowsSum: this.totalRowsSum,
            addRowsSeparately,
            useRowVat: !useCommonVat,
            totalPercentage: totalRow.percentage
        }

        try {
            const results = await DataHandler.post({url: `projects/${projects_id}/quotes/scheduled_invoices`}, {...data});
            
            enqueueSnackbar(this.tr("Scheduled invoices created successfully!"), {
                variant: "success",
            });
            onDialogSave && onDialogSave(saveFunc, 0);
            this.setState({ loading: false });
        }
        catch (err: any) {
            let error = "";

            switch(err?.responseJSON?.error) {
                case "INCLUDES_ALREADY_INVOICED_ROWS":
                    error = this.tr("Already invoiced rows selected.");
                    break;
                case "INVALID_DATA_IN_INSTALLMENT":
                    error = this.tr("Invalid data in installments.");
                    break;
            }

            const msg = error
                ? this.tr("Failed to create scheduled invoices: ${error}", { error })
                : this.tr("Failed to create scheduled invoices!")

            enqueueSnackbar(msg, {
                variant: "error",
            });
            this.setState({ loading: false });
        }
    }

    renderContent = () => {
        const { installments, addRowsSeparately, invalidFields, useCommonVat } = this.state;
        const totalsRow = installments.find(i => i.type == "totals");

        return <div>
            {totalsRow?.percentage > 100 && 
                <ErrorsList 
                    type={"warning"}
                    hideHeader={true}
                    data={[{
                        header: "",
                        message: this.tr("The defined scheduled invoicing amount exceeds the sales quote total value.")
                    }]}
                    className="warning-text"
                /> 
            }
            <List
                className='dialogList'
                ref={this.list}
                fluid
                manualCreate={true}
                columns={this.listColumns}
                listRowType={QuoteScheduledInvoiceDialogListRow}
                noColorVariance
                noStateData
                ignoreRowPropsChange={false}
                data={installments}
                hideBottomMarker={true}
                rowHeight={26}
                reverseNewData={true}
                renderNewDataAtEnd={true}
                rowProps={{
                    onDelete: data => {
                        this.deleteInstallment(data.id);
                    },
                    onEdit: (id, name, value) => { 
                        this.editInstallment(id, name, value);
                    },
                    currency: this.props.data?.currency,
                    invalidFields,
                    vatEditable: !addRowsSeparately || useCommonVat
                }}
            />
            <div className={"addNewInstallment"} onClick={() => this.addInstallment()}>
                <AddCircleOutline className="plus" />
                <span>{this.tr("Add another installment")}</span>
            </div>
            <div className={"addRowsSeparately"}>
                <span>{this.tr("Show rows separately for each installment")}</span>
                <Tooltip title={this.tr("If activated, all installments will include all sales quote rows as separate scheduled invoices.")} classes={{ tooltip: 'darkblue-tooltip' }} placement="bottom" arrow>
                    <InfoOutlined className={"infoIcon"} />
                </Tooltip>
                <Switch 
                    className={"switch"}
                    color="primary" 
                    value={addRowsSeparately} 
                    checked={addRowsSeparately} 
                    onChange={() => this.setState({ addRowsSeparately: !addRowsSeparately }, () => this.updateInstallmentVats())} 
                /> 
            </div>
        </div>
    }

    render() {
        const { onClose } = this.props;
        const { loading, invalidFields } = this.state;

        return (
            <SubtitleDialog
                onDialogClose={() => {
                    onClose && onClose();
                }}
                onConfirm={() => {
                    this.save();
                }}
                title={this.tr("Create scheduled invoices")}
                subtitle={this.tr("Here you can create scheduled invoices directly from your sales quote content. Edit the rows below to suit your needs, and add more installments if needed.")}
                confirmButtonText={this.tr("Create scheduled invoices")}
                confirmButtonLoading={loading}
                confirmDisabled={invalidFields.length > 0}
                confirmDisabledTooltip={this.tr("Invalid data in installments!")}
                className='quoteScheduledInvoiceDialog'
            >
                {this.renderContent()}
            </SubtitleDialog>
        );
    }
}

export default withSnackbar(QuoteScheduledInvoiceDialog);
