import React, { MouseEventHandler, ReactNode, useState } from 'react';
import { Button, Tooltip, Popover, CircularProgress, MenuItem } from '@mui/material';
import AccessTime from '@mui/icons-material/AccessTime';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Settings from '@mui/icons-material/Settings';
import KeyboardArrowRightRounded from '@mui/icons-material/KeyboardArrowRightRounded';
import Add from '@mui/icons-material/Add';
import IconTabs from './IconTabs';
import colors from '../colors';
import Link from './Link';
import ContextMenu from './ContextMenu';

import './PageTopSection.css';
import InsightDropDown from '../dashboard/insights/InsightDropDown';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

interface PopoverData {
    class?: string;
    anchor?: any;
    component?: ReactNode | ((closeFunction: any) => ReactNode);
}

export interface Tab {
    /** Unique string for this tab. */
    key: string;
    /** Tab name, is shown in the tooltip on hover. */
    title: string;
    /** Link destination, if defined, tab will be treated as a link element. */
    href?: string;
    /** Action to call when tab is clicked. */
    action: MouseEventHandler<HTMLElement>;
    /** Icon to show inside tab component. */
    icon: ReactNode;
}

interface Summary {
    title: string;
    value: string | number;
    'data-testid'?: string;
}

export interface Subheader {
    header: string;
    action?: () => void;
    icon?: ReactNode;
}

interface ActionButtonItem {
    title: string;
    icon?: ReactNode | string;
    action?: () => void;
}

interface TopSectionContextMenuButton {
    title: string;
    isVisible?: boolean;
    items?: ActionButtonItem[];
}

interface TopSectionButton {
    /** A title that is shown as text in the button in mainButton, and as a tooltip in other buttons. */
    title: string;
    /** Called when button is clicked. */
    action?: () => void;
    /** If defined, makes the button work as a link. */
    url?: (() => object | string) | object | string;
    /** Determines if the button is shown or not. */
    isVisible?: boolean;
    /** Determines if a loading indicator should be shown. */
    loading?: boolean;
    disabled?: boolean;
    'data-testid'?: string;
}

interface TopSectionActionButton extends TopSectionButton {
    /** Background color of the button. */
    color?: string;
    noIcon?: boolean;
}

interface TopSectionBorderButton extends TopSectionButton {
    /** An icon shown inside the button. */
    icon?: ReactNode | string;
    /** Icon to show after text */
    iconAfter?: ReactNode | string;
    /** Content for Popover that gets shown on button click. */
    popoverComponent?: ReactNode;
    /** Classname for Popover component. */
    popoverClass?: string;
    disabled?: boolean;
    /**ClassName for button. */
    className?: string;
    text?: string;
}

interface TopSectionSettingsButton extends TopSectionBorderButton {
    /** An optional URL for settings view, works if action is not defined. */
    href?: string;
}

interface Props {
    /** Tabs to show. */
    tabs?: Tab[];
    /** Main header for the page. */
    header?: string;
    /** Key of the currently selected tab. */
    selected?: string;
    /** Shows loading indicator next to page header if needed. */
    loading?: boolean;
    /** Colored, large buttons for actions. */
    mainButtons?: TopSectionActionButton[];
    /** Contextmenu button with items */
    actionsButton?: TopSectionContextMenuButton;
    /** Additional small & bordered buttons with icon. */
    additionalButtons?: (TopSectionBorderButton | null | undefined | false)[];
    /** Data for settings button. */
    settingsButton?: TopSectionSettingsButton;
    /** Optional summary data to show next to the page header. */
    summaries?: (Summary | false | undefined)[];
    /** Determines if summary items should be stacked horizontally or vertically. */
    summaryDirection?: 'horizontal' | 'vertical';
    /** Options for view options button. */
    viewButton?: TopSectionBorderButton;
    /** Completely custom component for view options. */
    customViewButton?: ReactNode;
    /** Any extra components you wish to render, placed after main buttons. */
    extraComponents?: ReactNode[];
    /** A ref to the outermost container. */
    refProp?: string;
    /** Array of subheaders to show in the page header, divided with arrows. */
    subheaders?: Subheader[];
    /** A subtitle to show under the main page header. */
    subtitle?: string;
    /** An action that gets called when the main header is clicked. */
    onHeaderClick?: () => void;
    disabled?: boolean;
    text?: string;
    className?: string;
    viewModes?: any[];
    selectedViewMode?: string;
    viewModesTitle?: string;
}

const PageTopSection = (props: Props) => {
    const {
        tabs,
        header,
        selected,
        loading,
        mainButtons = [],
        additionalButtons = [],
        settingsButton,
        summaries = [],
        summaryDirection = 'horizontal',
        viewButton,
        customViewButton,
        extraComponents,
        refProp,
        subheaders,
        subtitle,
        disabled,
        onHeaderClick,
        text,
        className,
        viewModes,
        selectedViewMode,
        viewModesTitle,
        actionsButton
    } = props;

    const initialData: PopoverData = {};
    const [popoverData, setPopoverData] = useState(initialData);

    return (
        <div ref={refProp} id="page-top-section" className={`${!header ? 'summaryMode' : ''} ${className}`}>
            <div className="left-content">
                <div className="row">
                    {header && <h1 onClick={onHeaderClick && onHeaderClick} className={onHeaderClick && 'link'}>
                        {header}
                    </h1>}
                    {subheaders &&
                        subheaders.map((sh) => (
                            <div className="subheader">
                                <KeyboardArrowRightRounded className="arrow" />
                                {sh.icon && sh.icon}
                                <h1 onClick={sh.action && sh.action} className={sh.action && 'link'}>
                                    {sh.header}
                                </h1>
                            </div>
                        ))}
                    {loading && <Loading  />}
                    {//@ts-ignore
                    viewModes && viewModes.length > 0 && <InsightDropDown title={viewModesTitle} selected={selectedViewMode} tabs={viewModes} />}
                    <div className={'summaries' + ' ' + summaryDirection}>
                        {summaries.map(
                            (summary) =>
                                summary && (
                                    <div data-testid={summary['data-testid']}>
                                        {summary.title}: <strong>{summary.value}</strong>
                                    </div>
                                )
                        )}
                    </div>
                </div>
                {subtitle && <p>{subtitle}</p>}
            </div>
            <div className="right-content">
                {actionsButton && actionsButton.isVisible && (
                    <ContextMenu data-testid={actionsButton['data-testid']} variant="outlined" className="main-button actions-button" label={actionsButton.title} size="medium" placement={"bottom-end"}>
                        {actionsButton.items?.map(item => {
                            return <MenuItem onClick={item.action}>{item.icon && item.icon} {item.title}</MenuItem>
                        })}
                    </ContextMenu>
                )}
                {mainButtons &&
                    mainButtons
                        .filter((btn) => btn.isVisible)
                        .map((button) => {
                            if (button.url) {
                                return (
                                    <Link url={button.url}>
                                        <Button data-testid={button['data-testid']} disabled={button.disabled} className="main-button" style={{ backgroundColor: button.color || colors.greenish_cyan }}>
                                            {!button.noIcon && <Add className="icon" />}
                                            {button.title}
                                        </Button>
                                    </Link>
                                );
                            } else {
                                return (
                                    <Button data-testid={button['data-testid']} disabled={button.disabled} className="main-button" style={{ backgroundColor: button.color || colors.greenish_cyan }} onClick={button.action}>
                                        {!button.noIcon && <Add className="icon" />}
                                        {button.title}
                                    </Button>
                                );
                            }
                        })}
                {extraComponents && <div className="extras">{extraComponents}</div>}
                <div className="additional-btns">
                    {additionalButtons
                        .filter((btn) => btn)
                        .map(
                            (btn) =>
                                btn && (
                                    <Tooltip placement="bottom" classes={{ tooltip: 'darkblue-tooltip' }} title={btn.title}>
                                        <div
                                            data-testid={btn['data-testid']}
                                            onClick={(e) => {
                                                if (btn.disabled || disabled) return;
                                                !btn.loading &&
                                                    (btn.popoverComponent
                                                        ? setPopoverData({
                                                              component: btn.popoverComponent,
                                                              anchor: e.target,
                                                              class: btn.popoverClass,
                                                          })
                                                        : btn.action?.());
                                            }}
                                            className={`btn ${btn.disabled || disabled ? 'disabled' : ''} ${btn.className}`}
                                        >
                                            {btn.loading ? <CircularProgress color="primary" disableShrink size={20} /> : btn.icon ?? <AccessTime />}
                                            {btn.text && <span>{btn.text.toUpperCase()}</span>}
                                            {btn.iconAfter}
                                        </div>
                                    </Tooltip>
                                )
                        )}
                </div>
                {tabs && <IconTabs disabled={disabled} selected={selected} tabs={tabs} />}
                {(viewButton || customViewButton) &&
                    (customViewButton || (
                        <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={viewButton?.title || ''}>
                            <div
                                onClick={(e) => {
                                    if (disabled) return;
                   
                                    viewButton && viewButton.popoverComponent
                                        ? setPopoverData({
                                              component: viewButton.popoverComponent,
                                              anchor: e.target,
                                              class: viewButton.popoverClass,
                                          })
                                        : viewButton?.action?.();
                                }}
                                className="btn view-btn"
                            >
                                <RemoveRedEye />
                                <ArrowDropDown className="small" />
                            </div>
                        </Tooltip>
                    ))}
                {settingsButton && settingsButton.isVisible && (
                    <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={settingsButton.title}>
                        <div>
                            <Link
                                href={settingsButton.href}
                                onClick={(e) => {
                                    settingsButton.popoverComponent
                                        ? setPopoverData({
                                            component: settingsButton.popoverComponent,
                                            anchor: e.target,
                                            class: settingsButton.popoverClass,
                                        })
                                        : settingsButton.action?.();
                                }}
                                className="btn"
                            >
                                <Settings />
                            </Link>
                        </div>
                    </Tooltip>
                )}
            </div>
            {popoverData.anchor && (
                <Popover
                    onClose={() => setPopoverData({})}
                    className={popoverData.class}
                    anchorEl={popoverData.anchor}
                    open={Boolean(popoverData.anchor)}
                    anchorOrigin={{ vertical: 45, horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {typeof popoverData.component == 'function' ? popoverData.component(() => setPopoverData({})) : popoverData.component}
                </Popover>
            )}
        </div>
    );
};

export default PageTopSection;
