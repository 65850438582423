import React from 'react';
import TreeDropdown from "../general/TreeDropdown";
import DataHandler from "../general/DataHandler";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual"; 
import { makeMapOfPrimitives } from "../list/ListUtils";
import styles from "./ProjectTreeDropdown.module.scss";

class ProjectTreeDropdown extends React.Component {
    static defaultProps = {
        className: "full",
        customerId: undefined,
        data: undefined,
        disabled: false,
        disableBeforeInit: false,
        handleBackspace: true,
        noSearch: false,
        onlyOwnProjects: false,
        onSelect: () => {},
        onInitialSelect: () => {},
        projectId: undefined,
        parentKey: "parentid",
        treeDropdownProps: {},
        treeOnly: false,
        showWholeTrees: undefined,
        value: undefined,
        disableAutoSelect: false,
        clearOnEmpty: false,
        filterProjects: [],
        filterOutProjects: [],
        queryParameters: {},
        route: undefined
    };


    constructor(props) {
        super(props);

        this.jqXhr     = undefined;
        this.initDone  = false;
        this.lastValue = "";

        this.state = {
            projects: [],
            newProject: false,
            initDone: false,
        };

        this.fetchProjects      = this.fetchProjects.bind(this);
        this.middleWareFunction = this.middleWareFunction.bind(this);
        this.setDefaultValue    = this.setDefaultValue.bind(this);
        this.inputRef           = React.createRef();
    }


    componentDidUpdate(prevProps, prevState) {
        const { props } = this;

        if(props.onlyOwnProjects !== prevProps.onlyOwnProjects) {
            this.fetchProjects();
        } else if(!isEqual(props.queryParameters, prevProps.queryParameters)) {
            if(!("includeCurrent" in props.queryParameters) 
                || (("includeCurrent" in props.queryParameters) 
                    && props.queryParameters.includeCurrent !== undefined 
                    && !prevProps.queryParameters.includeCurrent)) {
                this.fetchProjects();
            }
        } else if(props.customerId !== prevProps.customerId) {
            this.fetchProjects();
        }
        if ((!isEqual(this.state.projects, prevState.projects) || this.props.value != prevProps.value) && this.props.value > 0)
            this.handleInitialSelect(this.state.projects.find(p => p.id == this.props.value));
    }


    componentDidMount() {
        if (!("includeCurrent" in this.props.queryParameters) || this.props.queryParameters.includeCurrent !== undefined) {
            this.fetchProjects();
        }
    }

    handleInitialSelect = (project) => {
        this.props.onInitialSelect(project);
    }

    async fetchProjects(value = undefined, params = {}) {
        value = value === undefined
            ? this.lastValue
            : value;

        params.treeOnly = Boolean(this.props.treeOnly);
        const qParams   = { ...params, ...this.props.queryParameters };

        qParams.projectId      = this.props.projectId;
        qParams.customerId     = this.props.customerId;
        qParams.onlyOwn        = this.props.onlyOwnProjects;
        qParams.freetext       = value;
        qParams.showWholeTrees = this.props.showWholeTrees === undefined 
            ? 
            "true"
            :
            String(this.props.showWholeTrees);
        
        // TODO: see if the showWholeTrees param matter anywhere now

        value = encodeURIComponent(value);

        if(this.jqXhr !== undefined) {
            this.jqXhr.abort();
        }
        /*if (this.props.queryParameters.includeCurrent !== undefined && this.props.queryParameters.includeCurrent < 0) {
            return;
        }*/

        const url = this.props.route === undefined
            ?
            "projects/dropdown"
            :
            this.props.route
            ;
        this.jqXhr = DataHandler.get({ url, ...qParams });
       
        this.jqXhr.done(projects => {
            this.props.onProjectsFetched && this.props.onProjectsFetched(projects);
            this.jqXhr = undefined;

            // This is commented out because we still want to rerender and trigger TreeDropdown's
            // render when we refetch data, even if the data didn't change, since
            // TreeDropdown highlights the matches in the data we pass to it.
            // If this causes unwanted behavior, uncomment it.
            // if(isEqual(cloneDeep(projects).map(p => {
                // delete p.best_match;

                // return p;
            // }), curProjects))
                // return;

            if(this.props.filterProjects.length > 0) {
                const map = makeMapOfPrimitives(this.props.filterProjects);
                projects  = projects.filter(p => !map[p.id]);
            } 

            if(this.props.filterOutProjects.length > 0) {
                const map = makeMapOfPrimitives(this.props.filterOutProjects);
                projects  = projects.filter(p => !map[p.id]);
            } 
            
            const emptySelection = {
                id: "0",
                customer: "0",
                name: "",
                label: "",
                disabled: false
            };
               
            if (this.props.addNoneOption && projects.length > 0) { 
                projects.unshift(emptySelection);
            }

            const projectsContainsSelectedValue = projects.findIndex(p => p.id == this.props.value) != -1;

            //set state only if another query is not coming right after
            if (this.jqXhr == undefined) {
                this.setState({ projects }, () => {
                    if (this.state.initDone && !projectsContainsSelectedValue && this.props.dontAllowNonExistentValues) {
                        this.props.onSelect(projects[0] || emptySelection);
                        return;
                    }

                    if(this.state.initDone || this.props.value !== undefined) {
                        this.initDone = true;

                        // Tämä sotku on kyllä siivottava mitä ihmettä
                        if(!this.state.initDone && Array.isArray(projects) && projects.length > 0) {
                            this.setState({ initDone: true });
                        }
                        
                        return;
                    } 
                        
                    if(!this.props.disableAutoSelect && Array.isArray(projects) && projects.length > 0) {
                        this.props.onSelect(projects[0]);
                    }

                    this.initDone = true;
                    
                    if(!this.state.initDone && Array.isArray(projects) && projects.length > 0) {
                        this.setState({ initDone: true });
                    }
                });
                
                return projects;
            }
        });
    }

    getProjects(){
        return this.state.projects;
    }

    middleWareFunction(response){
        let { projects } = this.state;
        const { noOptionsMessageProps: {onChange} } = this.props;
        projects.push(response);
        this.setState({projects: projects, newProject: true}, () => onChange(response));
    }

    async setDefaultValue(){
        if(this.state.newProject)
            return;
        const projects = await this.fetchProjects();
        const { onSelect } = this.props;
        let e = {data: projects[0]}
        onSelect && onSelect(e);
    }

    handleOnChange = (value, source) => {
        const { clearOnEmpty } = this.props;

        if(clearOnEmpty && ((source === "keyUp" && value.trim() === ""))) {
            this.props.onSelect({});
        }

        if(this.props.noSearch) {
            return;
        }

        if(source == "keyUp") {
            this.lastValue = value;

            this.fetchProjects(value);
        }
    };

    renderRowValue = (data) => {
        const color = data.color?.length === 6 ? data.color + '0' : data.color;
        return (
            <div className={styles.row}>
                <div className={styles.color} style={{ backgroundColor: color }} />
                <div className={styles.info}>
                    <div className={styles.customer}>
                        {data.customer_name}
                    </div>
                    <div className={styles.project}>
                        {data.project_name}
                    </div>
                </div>
            </div>
        );
    }

    defineTitle = (data) => {
        return data.project_name?.length > 70 
            ? data.project_name?.trim() 
            : undefined;
    }

    render() {
        const { name, error, filterIds, noDisabledValues, clearOnEmpty } = this.props;

        return (
            <TreeDropdown
                customRenderer={this.renderRowValue}
                title={this.defineTitle}
                activateBestMatch={true}
                bestMatchKey={"best_match"}
                noDisabledValues={noDisabledValues}
                disabled={this.props.disabled || (this.props.disableBeforeInit && !this.state.initDone)}
                deferOpenOnEmptyValueUntilNewData={false}
                data={this.props.data ? this.props.data : (Array.isArray(this.state.projects) ? (filterIds ? this.state.projects.map(p => ({...p, disabled: p.disabled || filterIds.findIndex(fI => fI == p.id) != -1})) : this.state.projects) : [])}
                disableValueReset={true}
                noData={!this.props.data ? true : undefined}
                error={error}
                name={name}
                debounceOnChange={true}
                parentKey={this.props.parentKey}
                inputRef={this.inputRef}
                handleBackspace={this.props.handleBackspace}
                highlightSelectedValue={true}
                openOnMount={this.props.openOnMount}
                removeRowPadding={true}
                // onFocus={() => this.inputRef.current.ref.current.select()}
                /*onChange={value => {
                    if(this.props.noSearch) {
                        return;
                    }

                    //this.fetchProjects(value);
                }}*/
                onChange={this.handleOnChange}
                onSelect={value => {
                    this.props.onSelect(value);
                }}
                className={this.props.className}
                label={this.props.label}
                // displayValue={(this.props.customer && this.props.project) ? `${this.props.customer.name} / ${this.props.project.name }` : undefined}
                value={this.props.value}
                filterOutParentSelfAndChildrenFor={this.props.filterOutParentSelfAndChildrenFor}
                noOptionsMessage={this.props.noOptionsMessage}
                noOptionsClassName={this.props.noOptionsClassName}
                noOptionsMessageProps={this.props.noOptionsMessageProps ? {...this.props.noOptionsMessageProps, onChange: this.middleWareFunction, setDefaultValue: this.setDefaultValue} : undefined}
                treeDropdownClassName="projectTreeDropdown"
                {...this.props.treeDropdownProps} 
            /> 
        );
    }
}

export default ProjectTreeDropdown;
