import PrintDisabled from '@mui/icons-material/PrintDisabled';
import { Tooltip } from '@mui/material';
import clone from "lodash/clone";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import React from 'react';
import LazyLoad from 'react-lazyload';
import TaimerComponent from "../TaimerComponent";
import FocusGroup from "../general/FocusGroup";


class ListRow extends TaimerComponent {
    static defaultProps = {
        listRef: undefined,
        recursionLevel: 0,
        fixFluidOverflow: false,
        columnOrder: [],
        commonCellProps: {},
        children: [],
        attributes: {},
        data: {},
        disableInitialFocus: false,
        editMode: "one_cell", // Not to be confused with inEditMode.
        updateSummaryOnSetData: false,
        updateSummary: () => {},
        noStateData: false,
        hierarchyNode: {},
        ignoreRowPropsChange: false,
        isChild: false,
        top: 0,
        rowHeight: 44,
        rowHeightFix: false,
        idType: "number",
        rowKey: "id",
        rowType: "default",
        virtualized: false,
        treeMetaData: {},
        treeMetaDataMap: {},
        horizontalVirtualization: false,
        noColorVariance: false,
        rowDraggingEnabled: true,
        rowProps: {
            onCheck: () => {},
            onDelete: () => {},
            onUpdate: () => {},
            onCreate: () => {}
        }
    };


    constructor(props, subtypeState = {}, rowTypeConfig = {}, namespace = null) {
        if(namespace) {
            super(props, null, namespace);
        } else { 
            super(props);
        }

        this.cells                = false;
        this.ref                  = React.createRef();
        this.rowRef               = React.createRef();
        this.focusGroup           = React.createRef();
        this.childRefs            = undefined;
        this.rowOrderingIndicator = React.createRef();

        this.cancel               = () => {};

        this.currentTop = 0;

        // Aaagghhhhhh
        const rowTypeConfigWhitelist = ["childRowType"];

        for(let i in rowTypeConfig)
            if(rowTypeConfigWhitelist.indexOf(i) > -1)
                this[i] = rowTypeConfig[i];

        this.state = Object.assign({ 
            data: this.props.noStateData ? undefined : props.data, 
            invalids: [], 
            expanded: true, 
            editMode: false,
            checked: false
        }, subtypeState);

        this.moveRow                = this.moveRow.bind(this);
        this.defineClassName        = this.defineClassName.bind(this);
        this.defineCells            = this.defineCells.bind(this);
        this.wrapRow                = this.wrapRow.bind(this);
        this.canExitEditMode        = this.canExitEditMode.bind(this);
        this.createChildren         = this.createChildren.bind(this);
        this.getChildReferences     = this.getChildReferences.bind(this);
        this.getCellRef             = this.getCellRef.bind(this);
        this.getData                = this.getData.bind(this);
        this.setData                = this.setData.bind(this);
        this.cellEdited             = this.cellEdited.bind(this);
        this.getRowCallback         = this.getRowCallback.bind(this);
        this.toggleExpand           = this.toggleExpand.bind(this);
        this.isChecked              = this.isChecked.bind(this);
        this.check                  = this.check.bind(this);
        this.checkAll               = this.checkAll.bind(this);
        this.delete                 = this.delete.bind(this);
        this.update                 = this.update.bind(this);
        this.create                 = this.create.bind(this);
        this.setInvalidFields       = this.setInvalidFields.bind(this);
        this.clearInvalidFields     = this.clearInvalidFields.bind(this);
        this.onCtrlS                = this.onCtrlS.bind(this);
        this.rowHoverEnter          = this.rowHoverEnter.bind(this);
        this.rowHoverExit           = this.rowHoverExit.bind(this);
        this.rowClick               = this.rowClick.bind(this);
        this.startDrag              = this.startDrag.bind(this);
        this.handleDragStart        = this.handleDragStart.bind(this);
        this.handleDragEnter        = this.handleDragEnter.bind(this);
        this._handleDragOver        = this._handleDragOver.bind(this);        
        this.handleDragOver         = this.handleDragOver.bind(this);
        this.handleDragEnd          = this.handleDragEnd.bind(this);
        this.handleMouseUp          = this.handleMouseUp.bind(this);
        this.showRowOrderIndicator  = this.showRowOrderIndicator.bind(this);
        this.hideRowOrderIndicators = this.hideRowOrderIndicators.bind(this);
        this.setEditMode            = this.setEditMode.bind(this);
        this.enterEditMode          = this.enterEditMode.bind(this);
        this.exitEditMode           = this.exitEditMode.bind(this);
        this.exitEditModeSilently   = this.exitEditModeSilently.bind(this);
        this.draggingStarted        = false;
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.props.noStateData || isEqual(prevProps.data, this.props.data)) {
            return;
        }

        this.setState({ data: this.props.data });
    }


    componentDidMount() {
        super.componentDidMount();

        if(this.props.virtualized) {
            this.moveRow(this.props.top);

            // This happens after the first render.
            // Before this, this.cells = false, and
            // defineCells is called once in render,
            // in case props.virtualized.
            this.cells = this.defineCells();
        }
    }


    shouldComponentUpdate(nextProps, nextState) {
        if(!this.props.ignoreRowPropsChange) {
            return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);        
        }

        const { rowProps, ...rest } = this.props;
        const { rowProps: nextRowProps, ...nextRest } = nextProps;

        return !isEqual(rest, nextRest) || !isEqual(this.state, nextState);
    }


    // TODO: Move to PropsOnlyListRow or something or will this row also be useamsdsoöknfg
    // argh
    moveRow(top) {
        // 
        this.currentTop            = top;
        this.ref.current.style.top = `${top}px`;
    }


    createChildren(children, rowType = undefined, childRowProps = {}) {
        if(children.length === 0 || children === undefined || children === false)
            return false;

        if(this.childRefs === undefined)
            this.childRefs = children.map(c => React.createRef());

        const childrenHidden = this.state.expanded === false || (this.props.hasOwnProperty("hidden") && this.props.hidden === true);
        const { 
            listRowTypeMap, 
            listRowTypeKey, 
            listRowType, 
            columnConfigs, 
            columnWidthMaps, 
            columnOrders,
            columnConfig,
            columnOrder,
            columnWidths,
            columnWidthMap,
        } = this.props;

        // Maybe it's easier to just pass all the props of the parent to the children, 
        // and just modify those that are meant for the child specifically..............
        return children.map((child, index) => {
            let colConfig =  columnConfig;
            let colWidths =  columnWidthMap;
            let colOrder  =  columnOrder;

            let ListElementComponent;

            // Type priority: 1 - argument passed to createChildren, 2 - type passed in the constructor's rowTypeConfig, 3 - listRowTypeMap, 4 - ListRow.
            if(rowType)
                ListElementComponent = rowType;
            else if(this.childRowType !== undefined)
                ListElementComponent = this.childRowType;
            else if(listRowTypeMap !== undefined && child.data.hasOwnProperty(listRowTypeKey) && listRowTypeMap.hasOwnProperty(child.data[listRowTypeKey])) {
                ListElementComponent = listRowTypeMap[child.data[listRowTypeKey]];

                colConfig            = columnConfigs.hasOwnProperty(child.data[listRowTypeKey]) ? columnConfigs[child.data[listRowTypeKey]] : columnConfig;
                colWidths            = columnWidthMaps.hasOwnProperty(child.data[listRowTypeKey]) ? columnWidthMaps[child.data[listRowTypeKey]] : columnWidthMap;
                colOrder             = columnOrders.hasOwnProperty(child.data[listRowTypeKey]) ? columnOrders[child.data[listRowTypeKey]] : columnOrder;
            } else if(listRowType !== undefined)
                ListElementComponent = listRowType;
            else 
                ListElementComponent = ListRow;

            if(!ListElementComponent)
                throw new Error("Child row's type in ListRow.createChildren is undefined.");

            const combinedProps = Object.assign({}, child, {
                key:              child.data.id,
                listRef: 		  this.props.listRef,
                ref: 			  this.childRefs[index],
                hidden: 		  childrenHidden, 
                recursionLevel:   this.props.recursionLevel + 1, 
                columnOrder: 	  colOrder, 
                columnWidths: 	  this.props.columnWidths, 
                columnMap:        this.props.columnMap,
                columnWidthMap:   colWidths,
                columnConfig:     colConfig,
                columnConfigs:    columnConfigs,
                columnWidthMaps:  columnWidthMaps,
                columnOrders:     columnOrders,
                fluid:            this.props.fluid,
                sharedData: 	  this.props.sharedData,
                handler: 		  this.props.handler,
                rowHeight:        this.props.rowHeight,
                rowHeightFix:     this.props.rowHeightFix,
                parentProps: 	  this.props,
                noStateData:      this.props.noStateData,
                usesState:        this.props.usesState,
                manualCreate:     this.props.manualCreate,
                rowConfiguration: this.props.rowConfiguration,
                virtualized:      this.props.virtualized,
                rowProps: 		  this.props.rowProps,
                rowCallbacks:     this.props.rowCallbacks,
                rowKey:           this.props.rowKey,
                isChild: 		  true,
                isLastChild: 	  index === children.length - 1,
                rowDraggingEnabled: this.props.rowDraggingEnabled,
                // TODO: Move this to PropsOnlyListRow somehow until all list rows can use PropsOnlyListRow.
                checked:          this.props.checkedRows ? this.props.checkedRows[child.data.id] || false : undefined,
                childrenVisible:  this.props.childrenVisibleRows ? this.props.childrenVisibleRows[child.data.id] || false : undefined,
                checkedRows:      this.props.checkedRows ? this.props.checkedRows : undefined,
                childrenVisibleRows: this.props.childrenVisibleRows ? this.props.childrenVisibleRows : undefined,
                flips:            this.props.flips ? this.props.flips : undefined,
                flip:             this.props.flips[child.data[this.props.rowKey]],
                treeMetaDataMap:  this.props.treeMetaDataMap,
                treeMetaData:     this.props.treeMetaDataMap[child.data[this.props.rowKey]]
            });

            return <ListElementComponent {...combinedProps} />;
        });
    }


    getChildReferences() {
        return this.childRefs;
    }


    getCellRef(name) {
        return this.focusGroup.current.getCellRefByName(name);
    }


    getData() {
        return !this.props.noStateData ? this.state.data : this.props.data;
    }


    setData(a, v, cb = () => {}) {
        let data = clone(this.state.data);

        if(typeof a === "object") {
            for(let i in a)
                data[i] = a[i];
        } else if(typeof a === "string") {
            data[a] = v;
        }

        this.setState({ data }, () => cb(data));

        return data;
    }



    cellEdited(name, value) {
        this.setData(name, value, data => {
            if(this.state.data.id < 0)
                return;

            this.update();
        });
    }


    getRowCallback(name) {
        if(this.props.hasOwnProperty("rowProps") && this.props.rowProps.hasOwnProperty(name) && typeof this.props.rowProps[name] === "function") {
            return this.props.rowProps[name];
        }

        if(this.props.hasOwnProperty("rowCallbacks") && this.props.rowCallbacks.hasOwnProperty(name) && typeof this.props.rowCallbacks[name] === "function") {
            return this.props.rowCallbacks[name];        
        }

        return () => {};
    }


    toggleExpand() {
        this.setState({ expanded: !this.state.expanded });
    }


    isChecked() {
        return this.state.checked;
    }


    // TODO: Is this used anywhere?
    check(check = undefined, fromCheckAll = false) {
        const checked = check !== undefined ? check : !this.state.checked;

        (this.props.rowProps.hasOwnProperty("onCheck")) && (this.props.rowProps.onCheck(checked, this.state.data, fromCheckAll));

        this.setState({ checked: checked });
    }


    // TODO: Is this used anywhere?
    checkAll(check = undefined) {
        const checked = check !== undefined ? check : !this.state.checked;

        this.check(checked, true);

        if(this.childRefs === undefined || this.childRefs.length === 0)
            return;

        for(let ref of this.childRefs) {
            if(ref.current === null || ref.current === undefined)
                return;

            ref.current.checkAll(checked);
        }
    }


    delete() {
        return this.getRowCallback("onDelete")(cloneDeep(this.props.noStateData ? this.props.data : this.state.data), this, this.props.listRef);
    }


    update(data = false) {
        return this.getRowCallback("onUpdate")(cloneDeep(data !== false ? data : (this.props.noStateData ? this.props.data : this.state.data)), this, this.props.listRef);
    }


    create(data = false) {
        return this.getRowCallback("onCreate")(cloneDeep(data !== false ? data : (this.props.noStateData ? this.props.data : this.state.data)), this, this.props.listRef);
    }


    // TODO: Is this used anywhere?
    setInvalidFields(invalids = []) {
        if(typeof invalids === "string")
            invalids = [invalids];

        this.setState({ invalids: invalids });

        this.forceUpdate();
    }


    clearInvalidFields() {
        this.setInvalidFields();
    }


    onCtrlS() {
        this.state.data.id < 0 ? this.create() : this.update();
    }


    // Override this in your implementation of ListRow if you use the parent's render method.
    defineClassName() {
        return "";
    }


    // Override this in your implementation of ListRow if you use the parent's render method.
    defineCells() {
        return {};
    }


    wrapRow(content, data, attributes) {
        return content;
    }


    canExitEditMode() {
        return true;
    }


    rowClick() {
        return this.getRowCallback("onClickRow")(this.props.noStateData ? this.props.data : this.state.data);
    }


    rowHoverEnter(e) {
        const { rowKey } = this.props;

        return this.getRowCallback("onMouseEnter")((this.props.data || {})?.[rowKey] || 0, e, this.props.data);
    }


    rowHoverExit(e) {
        const { rowKey } = this.props;

        return this.getRowCallback("onMouseLeave")(e, (this.props.data || {})?.[rowKey] || 0, this.props.data);
    }


    startDrag() {
        this.draggingStarted = true;
        this.forceUpdate();
        this.props.listRef.startRowDrag(this.props);
    }


    // This is a fix for Firefox.
    handleDragStart(e) {
        if(!this.props.listRef.isDragging()) {
            return;
        }

        if (this.props.onDragStart) {
            this.props.onDragStart(e);
        }

        e.dataTransfer.setData('text/plain', 'placeholder');  
    }


    handleDragEnter(e) {
        if(!this.props.listRef.isDragging())
            return;

        e.preventDefault();
    }


    _handleDragOver(e) {
        if(!this.props.listRef.isDragging())
            return;

        e.dataTransfer.dropEffect = "none"; 

        e.persist();
        e.preventDefault();

        // Always hide other indicators.
        this.hideRowOrderIndicators();

        this.handleDragOver();

        return false;
    }


    handleDragOver() {
        if(this.props.listRef.dragDropIsAllowed(this.props)) {
            this.props.listRef.setCurrentlyDraggingOver(this.props);             

            this.showRowOrderIndicator();
        } else {
            this.props.listRef.setCurrentlyDraggingOverNotAllowed();
        }
    }


    handleDragEnd(e) {
        this.draggingStarted = false;

        this.forceUpdate();

        if(!this.props.listRef.isDragging())
            return;

        e.persist();
        e.preventDefault();

        this.props.listRef.endRowDrag();

        this.hideRowOrderIndicators();

        return false;
    }


    handleMouseUp(e) {
        if(this.draggingStarted) {
            this.handleDragEnd(e);
        }
    }


    showRowOrderIndicator() {
        if(!this.props.listRef.isDragging())
            return;

        this.rowOrderingIndicator.current.classList.add("visible");
    }


    hideRowOrderIndicators() {
        // All indicators might seem much, but really only one of them can be visible at once,
        // unless the user has magically started using two mouse pointers.
        const allIndicators = document.getElementsByClassName("rowOrderingIndicator");

        Array.from(allIndicators).forEach(i => i.classList.remove("visible"));
    }

    setEditMode(params) {
        const { 
            bool, 
            callback = () => {},
            canceled = false,
            runCallbacks = true
        } = params;

        const { 
            listRef,
            data,
            rowKey
        } = this.props;

        const otherIds = listRef
            .getData()
            .map(d => d[rowKey])
            .filter(id => id !== data[rowKey]);

        const attributeMap = listRef.getAttributes();
        const open         = listRef
            .getData()
            .filter(d => attributeMap?.[d[rowKey]]?.rowInEditMode || false)
            .map(d => d[rowKey])

        const nonCloseableRows = open
            .map(id => listRef.getRow(id))
            .filter(row => !row.canExitEditMode());

        if(nonCloseableRows.length > 0 && !canceled) {
            return;
        }

        listRef.batchEditAttributes({ rowInEditMode: bool }, data[rowKey])
            .edit({ rowInEditMode: false }, otherIds)
            .commit()
            .then(() => {
                if(!bool) {
                    return;
                }

                listRef.runOnEnterEditMode(data[rowKey]);
            })
            .then(callback);

        if(!bool && canceled) {
            return;
        } else if(!bool && open.indexOf(data[rowKey]) > -1) {
            listRef.runOnExitEditMode(data[rowKey]);

            return runCallbacks
                ? this.update()
                : new Promise((resolve) => resolve);
        }

        this.cancel = (callback = () => {}) => {
            this.exitEditMode(() => {
                this.props.listRef
                    .editData(data, data[rowKey])
                    .then(() => {
                        this.cancel = () => {};
                        callback();
                    });

            }, true);
        };

        return this.cancel;
    }


    enterEditMode(callback = () => {}) {
        return this.setEditMode({ 
            bool: true, 
            callback: callback 
        });
    }


    exitEditMode(callback = () => {}, canceled = false) {
        return this.setEditMode({ 
            bool: false, 
            callback: callback, 
            canceled: canceled 
        });
    }


    exitEditModeSilently(callback = () => {}) {
        return this.setEditMode({ 
            bool: false, 
            callback: callback, 
            runCallbacks: false
        });
    }


    // TODO:
    // Subtype for ListRow that implements simple row definition, 
    // so the logic doesn't need to be here.
    // This class should be abstract and call it's concrete subtype's implemented functions.


    render() {
        const id = this.props.noStateData 
            ? this.props.data[this.props.rowKey] 
            : this.state.data[this.props.rowKey];
        const { 
            virtualizationStartColumn, 
            virtualizationColumnAmount,
            virtualized,
            horizontalVirtualization,
            hidden,
            listRef,
            fixFluidOverflow,
            fluid,
            flex,
            rowHeight,
            editMode,
            data,
            attributes
        } = this.props;
        const cells = virtualized && this.cells !== false
            ? this.cells 
            : this.defineCells();
        const className      = [
            "listElement row", 
            hidden ? "hidden" : "", 
            id < 0 ? "new" : "", 
            (flex || fluid) ? "flex" : "", 
            fixFluidOverflow ? "fixFluidOverflow" : "",
            this.defineClassName()
        ].join(" ");
        const childClassName = ["listElement listElementChild", this.props.childrenVisible ? "visible" : "hidden"].join(" ");
        const childRows      = this.props.children.length > 0 ? this.createChildren(this.props.children) : [];

        const heights = rowHeight && (virtualized || this.props.rowHeightFix)
            ? 
            { height: parseInt(rowHeight) + "px", lineHeight: parseInt(rowHeight) + "px" } 
            : 
            {};

        const spacerWidth = virtualized 
            ? this.props.columnOrder
            .slice(0, virtualizationStartColumn)
            .map(c => parseFloat(this.props.columnConfig[c].width)) 
            .reduce((acc, cur) => acc + cur, 0)
            : 0;

        const virtualizeHorizontally = virtualized && horizontalVirtualization;
        const sliceParameters        = virtualizeHorizontally
            ? [
                virtualizationStartColumn, 
                virtualizationStartColumn + virtualizationColumnAmount
            ]
            : [];


        const dataTestIdIndex = (this.props.rowIndex || Number(this.props.rowIndex) === 0) ? this.props.rowIndex : (Number(id < 0) ? id : this.props.data?.roworder);

        let content = (
            <div 
                ref={this.ref}
                className={`listElement 
                    ${!this.props.isChild && !this.props.noColorVariance ? "mainLevel" : ""} 
                    ${this.props.virtualized ? "virtualized" : ""} 
                    ${this.props.data?.hidden_for_print == 1 ? 'list-row-hidden-from-print' : ''}`} 
                style={{heights}}>
                <div 
                    ref={this.rowRef}
                    data-id={id}
                    className={className}
                    onClick={this.rowClick}
                    draggable={this.props.rowDraggingEnabled && this.draggingStarted}
                    //onDragStart={this.handleDragStart}
                    onDragEnter={this.props.rowDraggingEnabled ? this.handleDragEnter : null}
                    onDragOver={this.props.rowDraggingEnabled ? this._handleDragOver : null}
                    onDragEnd={this.props.rowDraggingEnabled ? this.handleDragEnd : null}
                    onMouseUp={this.handleMouseUp}
                    onMouseEnter={this.rowHoverEnter}
                    onMouseLeave={this.rowHoverExit}
                    style={heights}>
                    <div 
                        className="virtualization-spacer" 
                        style={{ 
                            display: virtualizeHorizontally 
                                ? "block" 
                                : "none",
                            width: spacerWidth, 
                            height: rowHeight 
                        }}></div>
                    {this.props.rowDraggingEnabled && (
                        <div 
                            ref={this.rowOrderingIndicator}
                            className="rowOrderingIndicator">
                        </div>
                    )}
                    {this.props.renderIndicators ? this.props.renderIndicators(this.props.data) : this.props.showHiddenFromPrintIndicator && this.props.data?.hidden_for_print == 1 && 
                            (<Tooltip title={this.tr("Hidden from print")}><div className="list-row-hidden-from-print-indicator"><PrintDisabled /></div></Tooltip>)}
                    <FocusGroup
                        ref={this.focusGroup}
                        focusOnInit={this.props.disableInitialFocus ? false : id < 0} 
                        columnOrder={this.props.columnOrder}
                        onCtrlS={() => this.onCtrlS()}>
                        {this.props.columnOrder
                                .filter(columnName => !this.props.columnConfig[columnName].hidden)
                                .slice(...sliceParameters)
                                .map(columnName => {
                                    const cell 			= cells[columnName];
                                    const listCellProps = cell && cell.props.listCellProps;

                                    if(!cell) {
                                        console.warn(`Missing cell definition for ${columnName}.`);
                                        return this.props.noElementWithMissingDefinition ? null : (<div>&nbsp;</div>);
                                    }

                                    return React.cloneElement(cell, {
                                        key: columnName,
                                        width: cell.props.overrideWidth !== undefined 
                                            ? cell.props.overrideWidth 
                                            : this.props.columnConfig[columnName].width,
                                        rowRef: this.rowRef,
                                        hiddenFromPrint: !this.props.columnConfig[columnName]?.alwaysHiddenFromPrint && this.props.columnConfig[columnName]?.hiddenFromPrint,
                                        ...this.props.commonCellProps,
                                        listCellProps: {
                                            listRef: listRef,
                                            rowRef: this,
                                            inEditMode: id < 0 || (attributes?.rowInEditMode || false),
                                            inCreateMode: id < 0,
                                            showErrorBorder: this.state.invalids.indexOf(columnName) > - 1,
                                            'data-testid': `${columnName}_${dataTestIdIndex}`,
                                            editMode: editMode,
                                            hiddenFromPrint: !this.props.columnConfig[columnName]?.alwaysHiddenFromPrint && this.props.columnConfig[columnName]?.hiddenFromPrint,
                                            ...listCellProps 
                                        }
                                    });
                                })}
                    </FocusGroup>
                </div>
                {childRows !== false && <div className={childClassName}>{childRows}</div>}
            </div>
        );

        content = this.wrapRow(content, data, attributes);

        // TODO: Refactor
        return (this.props.useLazyLoad && !this.props.data?.isNew) 
            ? (<LazyLoad
                key={id}
                height={this.props.rowHeight}
                offset={100}>
                {content}
            </LazyLoad>)
            : content;
    }
}

export default ListRow;
