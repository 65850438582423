import React from "react";
import { Dialog, Button, Chip, Radio } from "@mui/material";
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import Clear from '@mui/icons-material/Clear';
import DataList from "../../general/DataList";
import OutlinedField from "../../general/OutlinedField";
import SupplierSelect from '../../general/SupplierSelect';
import Checkbox from "../../general/Checkbox";
import TaimerComponent from "../../TaimerComponent";
import colors from "../../colors";

import "./ProductCatalogDialogs.css";
import DataHandler from "../../general/DataHandler";
import { AddAccount } from '../../general/no-options/AddItemComponents';
import { DialogHeader } from "../../dialogs/DialogComponents";

export const CatalogDialog = (props) => {
  const { header, warning, children, buttons, onClose } = props;
  return (
    <Dialog
      PaperProps={{ className: "product-catalog-dialog-container" }}
      className="product-catalog-dialog"
      open={true}
    >
      <DialogHeader title={header} onClose={onClose} showWarningIcon={warning} />
      {children}
      {buttons && (
        <div className="bottom-bar">
          {buttons.map((btn) => (
            <Button disabled={btn.disabled} onClick={btn.onClick} style={{ ...btn.style, opacity: btn.disabled ? 0.5 : 1 }}>
              {btn.title}
            </Button>
          ))}
        </div>
      )}
    </Dialog>
  );
};

export class AddCatalogDialog extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/dialogs/AddCatalogDialog");

    this.input = React.createRef();
    this.state = {
      newCatalogName: this.props.catalog ? this.props.catalog.name : "",
      selectedOption: null
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
    setTimeout(() => {
      this.input.current && this.input.current.focus();
    }, 500);
  };

  render() {
    const { onClose, onSave, catalog, header, label, options, optionsTitle, newTitle, optionsLabel } = this.props;
    const { newCatalogName, selectedOption } = this.state;
    return (
      <CatalogDialog
        onClose={onClose}
        buttons={[
          {
            title: this.tr("Cancel"),
            onClick: onClose,
            style: {
              backgroundColor: "white",
              color: colors.steel,
            },
          },
          {
            title: this.tr("Save"),
            disabled: !newCatalogName && !selectedOption,
            onClick: () =>
              onSave({ ...this.props.catalog, name: selectedOption ? selectedOption.label : newCatalogName, update: !!selectedOption}),
            style: {
              backgroundColor: colors.deep_sky_blue,
              color: "white",
            },
          },
        ]}
        header={header || (catalog ? `${this.tr("Edit Catalog")} - ${catalog.name}` : this.tr("Add Catalog"))}
      >
        <div className="dialog-content">
          <div>
            {options && options.length > 1 && <div>
              {optionsTitle && <h2>{optionsTitle}</h2>}
              <DataList
              label={optionsLabel}
              name="id"
              value={selectedOption}
              options={(options || [])}
              onChange={(selectedOption) => this.setState({ selectedOption: selectedOption.id == "-1" ? null : selectedOption })}
              className="catalog-data-list"
              shownCount={20}
            />
            </div>}
            {newTitle && <h2>{newTitle}</h2>}
            <OutlinedField
              ref={this.input}
              label={
                label || (catalog ? this.tr("Rename Catalog") : this.tr("Name Catalog"))
              }
              disabled={!!selectedOption}
              name="name"
              value={newCatalogName}
              fullWidth={true}
              onKeyUp={(e) => {
                this.setState({ newCatalogName: e.target.value });
              }}
            />
          </div>
        </div>
      </CatalogDialog>
    );
  }
}

export class AddToCatalogDialog extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/dialogs/AddToCatalogDialog");

    this.state = {
      addNew: false,
      newCatalogName: "",
      selectedCatalogs: [],
      catalogs: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
    this._getCatalogs();
  };

  _getCatalogs = () => {
    DataHandler.post({
      url: `products/company/${this.props.company}/catalog`,
    }, { all: true })
      .done((res) => {
        this.setState({ catalogs: res.catalogs || [], loading: false });
      })
      .fail((error) => {
        console.log(error);
      });
  };

  _addToCatalog = () => {
    const { selectedCatalogs, newCatalogName } = this.state;
    const { onSave } = this.props;
    if (newCatalogName) {
      onSave([{ id: -1, name: newCatalogName }], this.props.products, this.props.company);
    } else if (selectedCatalogs.length > 0) {
      onSave(selectedCatalogs, this.props.products, this.props.company);
    }
  };

  _addSelectedCatalog = (catalog) => {
    const selectedCatalogs = [...this.state.selectedCatalogs];
    selectedCatalogs.push(catalog);
    this.setState({ selectedCatalogs });
  };

  _removeSelectedCatalog = (e) => {
    const selectedCatalogs = [...this.state.selectedCatalogs];
    const index = selectedCatalogs.findIndex(
      (a) => a.id == e.currentTarget.parentElement.id
    );
    if (index != -1) {
      selectedCatalogs.splice(index, 1);
    }
    this.setState({ selectedCatalogs });
  };

  render() {
    const { onClose, products } = this.props;
    const {
      addNew,
      newCatalogName,
      selectedCatalogs,
      catalogs,
      loading,
    } = this.state;
    return (
      <CatalogDialog
        onClose={onClose}
        buttons={[
          {
            title: this.tr("Cancel"),
            onClick: onClose,
            style: {
              backgroundColor: "white",
              color: colors.steel,
            },
          },
          {
            title: this.tr("Save"),
            disabled: (addNew && !newCatalogName) || (!addNew && selectedCatalogs.length == 0),
            onClick: this._addToCatalog,
            style: {
              backgroundColor: colors.deep_sky_blue,
              color: "white",
            },
          },
        ]}
        header={(products.length > 1 || !!products[0]?.name) ? `${this.tr("Add to Catalog")} - ${
          products.length > 1
            ? products.length + " " + this.tr("products")
            : products[0].name
        }` : this.tr("Add to Catalog")}
      >
        <div className="dialog-content">
          <DataList
            label={
              loading
                ? this.tr("Loading Catalogs...")
                : this.tr("Choose Existing Catalog")
            }
            isDisabled={loading || addNew}
            name="id"
            options={(catalogs || [])
              .filter(
                (catalog) =>
                  selectedCatalogs.findIndex((c) => c.id == catalog.id) == -1
              )
              .map((c) => ({ ...c, label: c.name }))}
            onChange={this._addSelectedCatalog}
            className="catalog-data-list"
            shownCount={20}
          />
          <div className="selected-tags">
            {selectedCatalogs.map((row) => (
              <Chip
                id={row.id}
                key={row.id}
                label={row.name}
                className="square"
                onDelete={this._removeSelectedCatalog}
                deleteIcon={<Clear />}
              />
            ))}
          </div>
          <div
            onClick={() => this.setState({ addNew: true })}
            className={`icon-btn ${addNew ? "disabled" : ""}`}
          >
            <AddCircleOutline className="icon" />
            {this.tr("Add New Catalog")}
          </div>
          {addNew && (
            <div className="add-new">
              <OutlinedField
                label={this.tr("Name Catalog")}
                name="name"
                value={newCatalogName}
                fullWidth={true}
                onKeyUp={(e) => {
                  this.setState({ newCatalogName: e.target.value });
                }}
              />
              <div
                onClick={() => this.setState({ addNew: false })}
                className="icon-btn red"
              >
                <CancelOutlined className="icon" />
                {this.tr("Discard")}
              </div>
            </div>
          )}
        </div>
      </CatalogDialog>
    );
  }
}

export class LinkCatalogToAccountDialog extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/dialogs/LinkCatalogToAccountDialog");

    this.state = {
      selectedAccounts: [],
      accounts: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
    this._getAccounts();
  };

  _getAccounts = () => {
    DataHandler.get({
      url: `subjects/accounts/${this.context.userObject.companies_id}`,
    }).done((accounts) => {
      this.setState({ accounts, loading: false });
    });
  };

  _linkToAccount = () => {
    const { selectedAccounts } = this.state;
    const { onSave, catalog } = this.props;
    if (selectedAccounts.length) {
      onSave(selectedAccounts, catalog);
    }
  };

  _addSelectedAccount = (account) => {
    const selectedAccounts = [...this.state.selectedAccounts];
    selectedAccounts.push(account);
    this.setState({ selectedAccounts });
  };

  _removeSelectedAccount = (e) => {
    const selectedAccounts = [...this.state.selectedAccounts];
    const index = selectedAccounts.findIndex(
      (a) => a.id == e.currentTarget.parentElement.id
    );
    if (index != -1) {
      selectedAccounts.splice(index, 1);
    }
    this.setState({ selectedAccounts });
  };

  render() {
    const { onClose, catalog } = this.props;
    const { selectedAccounts, accounts, loading } = this.state;
    return (
      <CatalogDialog
        onClose={onClose}
        buttons={[
          {
            title: this.tr("Cancel"),
            onClick: onClose,
            style: {
              backgroundColor: "white",
              color: colors.steel,
            },
          },
          {
            title: this.tr("Save"),
            onClick: this._linkToAccount,
            style: {
              backgroundColor: colors.deep_sky_blue,
              color: "white",
            },
          },
        ]}
        header={`${this.tr("Link to Account")} - ${catalog.name}`}
      >
        <div className="dialog-content">
        <p className="desc">{`${this.tr(
            "Remember that when linking this catalog to an account, it will replace the account's current selected catalog (if any)."
          )}`}</p>
          <DataList
            label={
              loading
                ? this.tr("Loading Accounts...")
                : this.tr("Choose Account")
            }
            isDisabled={loading}
            name="id"
            options={(accounts || []).filter(
              (a) =>
                catalog.customers.findIndex((c) => c.customer_id == a.id) ==
                  -1 && selectedAccounts.findIndex((c) => c.id == a.id) == -1
            )}
            onChange={this._addSelectedAccount}
            className="catalog-data-list"
            shownCount={20}
          />
          <div className="selected-tags">
            {selectedAccounts.map((row) => (
              <Chip
                id={row.id}
                key={row.id}
                label={row.name}
                className="square"
                onDelete={this._removeSelectedAccount}
                deleteIcon={<Clear />}
              />
            ))}
          </div>
        </div>
      </CatalogDialog>
    );
  }
}

export class ArchiveCatalogDialog extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/dialogs/ArchiveCatalogDialog");

    this.state = {
      selectedCatalog: null,
      selectNew: false,
      catalogs: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
    this._getCatalogs();
  };

  _getCatalogs = () => {
    DataHandler.post({
      url: `products/company/${this.context.userObject.companies_id}/catalog`,
    }, { all: true })
      .done((res) => {
        this.setState({ catalogs: res.catalogs || [], loading: false });
      })
      .fail((error) => {
        console.log(error);
      });
  };

  _archiveCatalog = () => {
    const { selectedCatalog } = this.state;
    const { onSave, catalog } = this.props;
    onSave(catalog, selectedCatalog);
  };

  render() {
    const { onClose, catalog } = this.props;
    const { selectedCatalog, catalogs, loading, selectNew } = this.state;
    return (
      <CatalogDialog
        onClose={onClose}
        buttons={[
          {
            title: this.tr("Cancel"),
            onClick: onClose,
            style: {
              backgroundColor: "white",
              color: colors.steel,
            },
          },
          {
            title: this.tr("Archive"),
            onClick: this._archiveCatalog,
            style: {
              backgroundColor: colors.faded_red,
              color: "white",
            },
          },
        ]}
        header={this.tr("Are you sure?")}
        warning={true}
      >
        <div className="dialog-content">
          <p className="desc">{`${this.tr(
            "Are you sure you want to archive this catalog"
          )} (${catalog.name})?`}</p>
          {(catalog.customers || []).length > 0 && (
            <>
              <div className="radio-row">
                <Radio
                  color="primary"
                  checked={!selectNew}
                  onClick={(event) => {
                    this.setState({ selectNew: event.target.value == 1 });
                  }}
                  value={!selectNew}
                  name="only_archive"
                />
                <p>{this.tr("Remove catalog from accounts")}</p>
              </div>
              <div className="radio-row">
                <Radio
                  color="primary"
                  checked={selectNew}
                  onClick={(event) => {
                    this.setState({ selectNew: event.target.value != 1 });
                  }}
                  value={selectNew}
                  name="select_new"
                />
                <p>{this.tr("Choose new catalog and link to accounts")}</p>
              </div>
              <DataList
                label={
                  loading
                    ? this.tr("Loading Catalogs...")
                    : this.tr("Choose New Catalog")
                }
                isDisabled={loading || !selectNew}
                value={selectedCatalog}
                name="id"
                options={(catalogs || [])
                  .filter((c) => c.status == 1 && c.id != catalog.id)
                  .map((c) => ({ ...c, label: c.name }))}
                onChange={(obj) => this.setState({ selectedCatalog: obj })}
                className="catalog-data-list"
                shownCount={20}
              />
            </>
          )}
        </div>
      </CatalogDialog>
    );
  }
}


//Customer delete dialog

export class AccountDeleteDialog extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/dialogs/AccountDeleteDialog");

    this.state = {
      selectNewAccount: false,
      suppliers: [],
      addresses: [],
      selectedSupplier: false,
      selectedAddress: false,
      invalidField: "",
      company_id: this.props.data.companies_id || this.context.userObject.companies_id,
      showArchivedAccounts: true
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
    
    if( this.props.data.productAmount > 0) {
      DataHandler.get({url: `subjects/suppliers/${this.state.company_id}`, get_customers: 1}).done(response => {
        this.setState({ suppliers: response });
      });
    }
  };

  validateSupplierAddress = () => {
    const data = {
      customer: this.state.selectedSupplier,
      address: this.state.selectedAddress
    }

    if (!data.customer) {
      this.setState({ invalidField: "customerName" })
      return;
    }
    else if (!data.address) {

      this.setState({ invalidField: "address" })
      return;
    }

    this.setState({ invalidField: "" })
    this.props.onConfirm(this.props.data, {supplier: data.customer.id, address: data.address.id})
  }

  supplierChanged = (val, isNew) => {
    if (isNew) 
      this.setState({ selectedSupplier: val, invalidField: "", suppliers: this.state.suppliers.concat(val) });
    else
      this.setState({ selectedSupplier: val, invalidField: "" });

    DataHandler.get({ url: `subjects/billing_addresses_by_company/${this.state.company_id}/${val.id}` }).done(response => {
      const addresses = response.map(e => {
        return { ...e, label: e.address }
      });

      this.setState({ addresses });
      if (addresses.length > 0)
        this.setState({ selectedAddress: addresses[0] });
      else
        this.setState({ selectedAddress: false });
    });
  }

  render() {
    const { onClose, onConfirm, data, action } = this.props;
    const { selectNewAccount, showArchivedAccounts } = this.state;

    const relationConfirmationText = `Are you sure you want to ${action === "delete" ? "delete" : "archive"} relation`;
    return (
      <CatalogDialog
        onClose={onClose}
        buttons={[
          {
            title: this.tr("Cancel").toUpperCase(),
            onClick: onClose,
            style: {
              backgroundColor: "white",
              color: colors.steel,
            },
          },
          {
            title: action === "delete" ? this.tr("Delete").toUpperCase() : this.tr("Archive").toUpperCase(),
            onClick: () => {
              if (this.state.selectNewAccount)
                this.validateSupplierAddress()
              else 
                onConfirm(data, false)
            },
            style: {
              backgroundColor: colors.faded_red,
              color: "white",
            },
          },
        ]}
        header={action === "delete" ? this.tr("Delete") + " " + this.tr(data.name ? "account?" : "relation?") : this.tr("Archive") + " " + this.tr(data.name ? "account?" : "relation?")}
        warning={true}
      >
        <div className="dialog-content">
          <p className="desc">
          {!data.name ? this.tr(relationConfirmationText + " " + "\"${relation}\" for company  \"${company_name}\"?", {relation: data.type, company_name: data.company_name })
          : 
            action === "delete" 
              ? this.tr("Are you sure you want to delete account \"${account}\"?", {account: `${data.name} (${this.context.addons.custom_customer_id && this.context.addons.custom_customer_id.used_by_companies.indexOf(data.companies_id) > -1 ? data.customer_id : (data.orig_id ? data.orig_id : data.id)})`})
              : this.tr("Are you sure you want to archive account \"${account}\"?", {account: `${data.name} (${this.context.addons.custom_customer_id && this.context.addons.custom_customer_id.used_by_companies.indexOf(data.companies_id) > -1 ? data.customer_id : (data.orig_id ? data.orig_id : data.id)})`})
            } 
            {data.productAmount > 0 && " " + this.tr("This account is connected to ${productAmount} products as supplier", {productAmount: data.productAmount})}
          </p>

          {data.productAmount > 0 && (
            <>
              <div className="radio-row">
                <Radio
                  color="primary"
                  checked={!selectNewAccount}
                  onClick={(event) => {
                    this.setState({ selectNewAccount: event.target.value == 1 });
                  }}
                  value={!selectNewAccount}
                  name="delete_account"
                />
                <p>{action === "delete" ? this.tr("Delete") + " " + this.tr("account and delete account’s data.") : this.tr("Archive") + " " + this.tr("account and account’s data.")}</p>
              </div>
              <div className="radio-row">
                <Radio
                  color="primary"
                  checked={selectNewAccount}
                  onClick={(event) => {
                    this.setState({ selectNewAccount: event.target.value != 1 });
                  }}
                  value={selectNewAccount}
                  name="select_new_account"
                />
                <p>{action === "delete" ? this.tr("Delete") + " " + this.tr("account and set different supplier for product(s)") : this.tr("Archive") + " " + this.tr("account and set different supplier for product(s)")}</p>
              </div>
              <SupplierSelect
                label={this.tr("Choose Supplier...")}
                name="id"
                className={`catalog-data-list ${this.state.invalidField === "customerName" ? "account-supplier-error-field" : ""}`}
                options={this.state.suppliers}
                value={this.state.selectedSupplier}
                supplierCreated={(val) => this.supplierChanged(val, true)}
                shownCount={20}
                company={this.state.company_id}
                showArchivedAccounts={showArchivedAccounts}
                isDisabled={!selectNewAccount} 
                accountUpdated={(supplier) => {
                  this.props.enqueueSnackbar && this.props.enqueueSnackbar(this.tr("Account ${account} updated successfully!", {account: supplier.name}), {
                      variant: "success"
                  });
                  this.supplierChanged(supplier);
                }}  
                onChange={(val) => this.supplierChanged(val)}
              />
              {selectNewAccount && <Checkbox
                name="showArchived"
                checked={showArchivedAccounts}
                onChange={() =>
                  this.setState({ showArchivedAccounts: !showArchivedAccounts })
                }
                label={this.tr("Show archived accounts in supplier search")}
              />}
              {this.state.selectedSupplier &&
                <DataList
                  label={this.tr("Choose address...")}
                  name="id"
                  className={`catalog-data-list ${this.state.invalidField === "address" ? "account-supplier-error-field" : ""}`}
                  options={this.state.addresses}
                  value={this.state.selectedAddress}
                  onChange={(val) => {
                    this.setState({ selectedAddress: val, invalidField: "" });
                  }}
                />
              }
            </>
          )}
        </div>
      </CatalogDialog>
    );
  }
}


//User Activate / Deactivate / Reset password dialog
export class UserDialog extends TaimerComponent {
  constructor(props, context) {
    super(props, context, "list/dialogs/UserDialog");

  }

  componentDidMount = () => {
    super.componentDidMount();
  };

  render() {
    const { onClose, onConfirm, data, action } = this.props;
    return (
      <CatalogDialog
        onClose={onClose}
        buttons={[
          {
            title: this.tr("Cancel"),
            onClick: onClose,
            style: {
              backgroundColor: "white",
              color: colors.steel,
            },
          },
           {
            title: action === "Activate" ? this.tr("Activate").toUpperCase() : ( action === "Deactivate" ? this.tr("Deactivate").toUpperCase() : this.tr("Reset password").toUpperCase()),
            onClick: () => {onConfirm(data)},
            style: {
              backgroundColor: colors.faded_red,
              color: "white",
            },
          }
        ]}
        header={action === "Activate" ? this.tr("Activate user?") : ( action === "Deactivate" ? this.tr("Deactivate user?") : this.tr("Reset password ?")) }
        warning={true}
      >
        <div className="dialog-content">
          {action === "Deactivate" &&  <p className="desc">{`${this.tr("Are you sure you want to Deactivate the user")} ${data.firstname} ${data.lastname}?`}</p>}
          {action === "Activate" &&  <p className="desc">{`${this.tr("Are you sure you want to Activate the user" )} ${data.firstname} ${data.lastname}?`}</p>}
          {action === "Reset_password" &&  <p className="desc">{`${this.tr("Are you sure you want to Reset password for the user")} ${data.firstname} ${data.lastname}?`}</p>}
        </div>
      </CatalogDialog>
    );
  }
}