import React from 'react';
import { clone, cloneDeep } from 'lodash';
import isEqual from "lodash/isEqual";
import TaimerComponent from '../TaimerComponent';
import ToggleableContainerList from '../general/ToggleableContainerList';
import SliderFieldGroup from '../general/SliderFieldGroup';
import FieldEditSlider from './../general/FieldEditSlider';
import StatusTag from '../general/StatusTag';
import { SettingsContext } from '../SettingsContext';
import cardStyles from '../general/styles/CardStyles.module.scss';
import { AddProject } from './../general/no-options/AddItemComponents';
import tabStyles from './ExpenseDetailsTab.module.scss';
import TaimerAvatar from '../general/TaimerAvatar';
import ButtonSelector from '../general/ButtonSelector';
import Dialog from '../dialogs/mass_operations/CoreDialog';
import fieldEditSliderStyles from "../general/FieldEditSlider.module.scss";
import { 
    SliderList,
    SliderListRowMenuItem,
    SliderListRowMenu,
    SliderListProps, 
    SliderRowField,
    ColumnObject
} from '../general/SliderList';
import sliderListStyles from "../general/SliderList.module.scss";
import DataHandler from '../general/DataHandler';
import OverlayImage from "../general/OverlayImage";
import ProjectTreeDropdown from "../projects/ProjectTreeDropdown";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import LoaderButton from './../general/LoaderButton';
import { EditableField } from "../general/FieldEditSlider";
import { 
    UploadResponse,
    uploadFiles
} from "../general/FileUtils";
import { 
    createOptions
} from "../general/TreeSelect";
import SimpleTable from "./../general/SimpleTable";
import Avatar from "@mui/material/Avatar";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DirectionsCar  from '@mui/icons-material/DirectionsCar';
import Event from '@mui/icons-material/Event';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import AttachFile from '@mui/icons-material/AttachFile';

import { 
    calculateAllowanceDays,
    getFullDaysAndHalfDays,
    fixTime,
    DayTuple,
    mapFieldsWithType,
    flipObject,
    calculateFreeMealDeductionFinland,
    calculateDayMealDeductions,
    calculateByCurrencyRate,
    getDailyAllowanceTimeBundle
} from "./ExpenseUtils";
import {
    isImageFileType,
    downloadAttachment
} from "./../general/FileUtils";
import { 
    divideDateTimeRangeIntoDays,
    isValidDateString,
    to24HourTime,
    dateTimeTo24HourDateTime,
    dateTimeTo12HourDateTime,
    is24HourTime,
    to12HourTime,
} from "./../general/DateTimeUtils";
import Utils from "./../general/Utils";
import { 
    sum, 
    reduceSum,
    roundToDecimals,
    divide,
    nplus,
    nminus,
    nmultiply,
    calculate,
} from "./../general/MathUtils";
import colors from '../colors';
import { 
    ExpenseDetails, 
    Expense,
    ExpenseType,
    DataSelectEntry,
    DropdownData,
    MiscData,
    MileageRate,
    MileageAllowance,
    DailyAllowance,
    DailyRate,
    Project,
    TemporaryAttachmentsBundle,
    ExpenseFile,
    QuoteRow,
    MILEAGE_FIELD_MAP,
    DAILY_FIELD_MAP,
    EXPENSE_FIELD_MAP
} from "./ExpenseView";
import moment from 'moment/min/moment-with-locales';
import { isNaN } from 'lodash';
import { ReactComponent as Loading } from "src/dashboard/insights/img/loading.svg";

function ensureNumber(x: any): number {
    x = typeof(x) === "string" 
        ? x.replaceAll(",", ".")
        : x;

    const n = Number(x);

    return !isNaN(n) ? n : 0;
}

function handleNumberInput(x: any): number {
    return roundToDecimals(ensureNumber(x), 2);
}

interface SimpleAttachmentTableProps {
    rows: { [key: string]: string }[];
    showAttachment: (attachment: any) => void;
    downloadAttachment: (attachment: any) => void;
    onPromptDeletion: (attachment, index: number) => void;
}

class SimpleAttachmentTable extends TaimerComponent<SimpleAttachmentTableProps> {
    constructor(props) {
        super(props, null, "expenses/SimpleAttachmentTable");
    }

    promptDeletion = (attachment) => {
        const index: number = this.props.rows.findIndex(a => a === attachment);

        if(index === -1) {
            // TODO: Snackbar or something.
            return;
        }

        this.props.onPromptDeletion(
            attachment, 
            // The index only matters when the attachment 
            // is one that hasn't been uplaoded yet, so
            // don't take already-saved attachments
            // into account.
            index - this.props.rows.filter(r => r?.id).length
        );
    }

    render() {
        const { 
            rows 
        }: { 
            rows: { [key: string]: string }[] 
        } = this.props;

        return (
            <SimpleTable 
                columnStyles={{
                    delete: { textAlign: "right" },
                    view: { textAlign: "right" },
                }}
                rows={rows.filter(r => !r?.deleted).map((attachment, index: number) => {
                    return {
                        name: attachment.name,
                        view: <button
                            className={tabStyles.linkLikeButton}
                            onClick={() => {
                                isImageFileType(attachment.type)
                                    ? this.props.showAttachment(attachment)
                                    : this.props.downloadAttachment(attachment)
                            }}>
                            {this.tr(
                                isImageFileType(attachment.type) 
                                    ? "View" 
                                    : "Download"
                            )}
                        </button>,
                        delete: <DeleteIcon 
                            className={tabStyles.pointer}
                            onClick={() => this.promptDeletion(attachment)}
                        />
                    };
 
                })}
            />
        );
    }
}

interface AttachmentIndicatorProps {
    attachments: { [key: string]: string }[] | File[];
    showAttachment: (attachment: any) => void;
    downloadAttachment: (attachment: any) => void;
}

class AttachmentIndicator extends TaimerComponent<AttachmentIndicatorProps> {
    constructor(props) {
        super(props, null, "expenses/AttachmentIndicator");
    }

    handleAttachmentOpenText = (filetype: string): string => {
        return isImageFileType(filetype)
            ? this.tr("View")
            : this.tr("Download");
    }

    handleClick = (attachment) => {
        if(!attachment.id) {
            attachment.url = URL.createObjectURL(attachment);
        }

        isImageFileType(attachment.type)
            ? this.props.showAttachment(attachment)
            : this.props.downloadAttachment(attachment);
    }

    render() {
        const length: number           = this.props?.attachments?.length ?? 0;
        const icon: JSX.Element | null = length > 0
            ? <AttachFileIcon
        className={tabStyles.attachmentIcon} />
            : null
        const lengthStr: string = length > 1 
            ? `x${length}`
            : ``;

        if(length === 0) {
            return null;
        }

        return (
            <Tooltip
                placement="top"
                title={<ul className={tabStyles.attachmentTooltipList}>
                    {this.props.attachments.map(attachment => {
                        return (
                            <li>
                                <span>
                                    {attachment.filename ?? attachment.name}
                                </span>
                                <span>
                                    <button
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            event.preventDefault();

                                            this.handleClick(attachment);
                                        }}
                                        className={tabStyles.linkLikeButton}>
                                        {this.handleAttachmentOpenText(attachment.type)}
                                    </button>
                                </span>
                            </li>
                        );
                    })}
                </ul>}
                arrow={true}>
                <div>
                    {icon}
                    <span className={tabStyles.attachmentIconText}>
                        {lengthStr}
                    </span>
                </div>
            </Tooltip>
        );
    }
}

interface TreeIndicatorProps {
    last: boolean;
}

class TreeIndicator extends React.Component<TreeIndicatorProps> {
    render() {
        return (
            <svg width="24" height="34" style={{ fill: "#ccc" }} xmlns="http://www.w3.org/2000/svg">
                <rect width="1" height="18" x="8" y="2" />
                <rect width="10" height="1" x="8" y="20" />
            </svg>
        );
    }
}

// TODO: ???
type Section = {
    key: string;
    title: string;
    initiallyOpen?: boolean;
};

interface DailyAllowanceMealSelectorProps {
    dailyRates: DailyRate[];
    allowance: DailyAllowance;
    onChange?: (meals: { [key: string]: number; }) => void;
    presentCurrency: (amount: number | string, options) => string;
}

interface DailyAllowanceMealSelectorState {
    allowance: DailyAllowance;
}

class DailyAllowanceMealSelector extends TaimerComponent<DailyAllowanceMealSelectorProps, DailyAllowanceMealSelectorState> {
    static contextType  = SettingsContext;

    constructor(props, context) {
        super(props, context, "expenses/ExpenseDetailsTab");

        this.state = {
            allowance: props.allowance
        };
    }

    componentDidUpdate(prevProps: DailyAllowanceMealSelectorProps) {
        if(!isEqual(prevProps.allowance, this.props.allowance)) {
            this.setState({ allowance: this.props.allowance });
        }
    }

    handleMealAmountChange = (dailyAllowanceId: number, mealDate: string, mealAmount: number): void => {
        const allowance: DailyAllowance = cloneDeep(this.state.allowance);

        allowance.meals           = allowance.meals ?? {};
        allowance.meals[mealDate] = mealAmount;

        this.setState({ 
            allowance: allowance 
        });
    }

    handleSave = () => {
        if(!this.props.onChange) {
            return;
        }
        
        this.props.onChange(this.state.allowance.meals);
    }

    handleCancel = () => {
        this.context.functions.closeSlider();
    }

    humanizeHoursAndMinutes = (hours: number, minutes: number): string => {
        return [
            hours > 0 ? `${hours} ${this.tr("hours")}` : null,
            minutes > 0 ? `${minutes} ${this.tr("minutes")}` : null,
        ].filter(d => d).join(" ");
    }

    renderDay = (startDateTime: string, endDateTime: string, index: number): JSX.Element => {
        const allowance: DailyAllowance = this.state.allowance;

        const transformDateTime: (t: string) => string = (t: string) => {
            return Number(this.context.userObject.clock_format) === 0
                ? dateTimeTo12HourDateTime(t)
                : dateTimeTo24HourDateTime(t);
        };

        // Wau.
        // @ts-ignore
        const allowanceId: number               = allowance.allowanceId?.id || allowance.allowanceId;
        const meals: { [date: string]: number } = allowance.meals;
        const dailyRates: DailyRate[]           = this.props.dailyRates;
        const startDate: string                 = moment(startDateTime).format("YYYY-MM-DD");
        const mealAmount: number                = meals?.[startDate] || 0;
        const start: string                     = transformDateTime(moment(startDateTime).format(this.context.userObject.datetimeFormat));
        const end: string                       = transformDateTime(moment(endDateTime).format(this.context.userObject.datetimeFormat));
        const span                              = `${start} - ${end}`;
        const diffHours: number                 = moment(endDateTime).diff(moment(startDateTime), "hours");
        const diffMinutes: number               = moment(endDateTime).diff(moment(startDateTime), "minutes") % 60;
        const diffString: string                = this.humanizeHoursAndMinutes(diffHours, diffMinutes);
        const dayStr                            = `${index + 1}. ${this.tr("travel day")} (${diffString})`;
        const rate: DailyRate | undefined       = dailyRates.find((dr: DailyRate) => dr.value === allowanceId);

        const totals: {
            total: number;
            withoutMeals: number;
            deduction: number;
        } = calculateDayMealDeductions(
            startDateTime,
            endDateTime,
            mealAmount,
            rate?.rate ?? 0,
            rate?.partRate ?? 0,
            index
        );

        return (
            <>
                <div className={tabStyles.dayContainer}>
                    <div className={tabStyles.sidePaddingContainer}>
                        <div className={tabStyles.mealDateContainer}>
                            <h2 className={tabStyles.dayOrderAndLength}>{dayStr}</h2>
                            <h2 className={tabStyles.mealDate}>{span}</h2>
                        </div>
                        <p className={tabStyles.mealTotal}>{this.props.presentCurrency(
                            totals.total, 
                            {
                                maximumFractionDigits: 3 
                            }
                        )}</p>
                    </div>
                    <div className={tabStyles.mealSeparator}></div>
                    <div className={tabStyles.mealGrid}>
                        <div className={tabStyles.mealText}>
                            <p>{this.tr("Free meals")}:</p>
                        </div>

                        <div className={tabStyles.mealButtons}>
                            <ButtonSelector<number>
                                onClick={(q: number) => this.handleMealAmountChange(
                                    allowance.id, startDate, q
                                )}
                                value={mealAmount}
                                options={[0, 1, 2]}
                                buttonClassName={tabStyles.mealButton} />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    render() {
        const { 
            startDate,
            endDate,
        }: {
            startDate: string;
            endDate: string;
        } = this.state.allowance;

        // TODO: fix the time format stuff at the source, where ever that is.
        const startTime: string = fixTime(this.props.allowance.startTime);
        const endTime: string   = fixTime(this.props.allowance.endTime);
        const st                = `${startDate} ${startTime}`;
        const et                = `${endDate} ${endTime}`;
        const dayAmount: number = calculateAllowanceDays(st, et);
        const range: string[]   = divideDateTimeRangeIntoDays(st, et);

        const times: [start: string, end: string][] = range.length > 0 
            ? Utils.intRange(0, range.length - 2)
                .map((i: number): [start: string, end: string] => [range[i], range[i+1]])
            : [];

        const dailyRate: DailyRate | undefined = this.props.dailyRates
            .find((dr: DailyRate) => {
                const id: number = typeof(this.props.allowance.allowanceId) === "object"
                    ? this.props.allowance?.allowanceId['id']
                    : this.props.allowance.allowanceId;

                return dr.value === id;
            });

        if(!dailyRate) {
            // TODO:
            return null;
        }

        const totals: { [key: string]: number } = calculateFreeMealDeductionFinland(
            this.state.allowance.meals ?? {},
            dailyRate?.rate,
            dailyRate?.partRate,
            st,
            et
        );

        const presentCurrency: (n: number) => string = (n: number) => {
            return this.props.presentCurrency(n, {});
        };

        return (
            <div className={[fieldEditSliderStyles.fieldEditSlider, tabStyles.mealFieldEditSlider].join(" ")}>
                <div className={fieldEditSliderStyles.fields} id={tabStyles.mealFieldEditSliderFields}>
                    {times.map((t: [start: string, end: string], i: number): JSX.Element => {
                        return this.renderDay(t[0], t[1], i);
                    })}
                </div>
                <div className={tabStyles.mealFieldEditSliderSummary}>
                    <div className={tabStyles.col}>
                        <div className={tabStyles.row}>
                            {this.tr("Allowance")}:<strong> {dailyRate.label}</strong>
                        </div>
                        <div className={tabStyles.row}>
                            {this.tr("Days")}:<strong> {dayAmount}</strong>
                        </div>
                    </div>
                    <div className={[tabStyles.col, tabStyles.alignRight].join(" ")}>
                        <div className={tabStyles.row}>
                            {this.tr("Total deductions")}:<strong> {presentCurrency(totals.deduction)}</strong>
                        </div>
                        <div className={tabStyles.row}>
                            {this.tr("Total after meals")}:<strong> {presentCurrency(totals.total)}</strong>
                        </div>
                    </div>
                </div>
                <div className={fieldEditSliderStyles.actions}>
                    <Button 
                        onClick={this.handleCancel}
                        variant="text" 
                        size="large" 
                        data-testid="meal-selector-cancel-button">
                        {this.tr('Cancel')}
                    </Button>
                    <LoaderButton 
                        text={this.tr('Save')}
                        onClick={this.handleSave}
                        size="large" 
                        color="primary"
                        data-testid="meal-selector-save-button" />
                </div>
            </div>
        );
    }
}

interface ExpenseDetailsTabProps {
    data: ExpenseDetails;
    dropdownData: DropdownData;
    miscData: MiscData;
    attachments: any[];
    stateLog: any[];
    initialFetchDone: boolean;
    onDetailsEdit: (details: ExpenseDetails) => void;
    refresh: () => void;
    expenseType: string;
    countryCode: string;
    enqueueSnackbar: Function;
    // onAddAttachments?: (attachments: any[]) => void;
    // onAttachmentDelete?: (attachment: any) => void;
    onTemporaryAttachmentsChange?: (attachments: TemporaryAttachmentsBundle) => void;
    onDetailsScroll?: (scroll: number) => void;
    onTotalsChange?: (totals: any) => void;
    temporaryAttachmentsBundle?: TemporaryAttachmentsBundle;
}

interface ExpenseDetailsTabState {
    subtotal: number;
    vatTotal: number;
    total: number;
    attachmentOpen: boolean;
    attachmentURI: string;
    tempProjectId: string | number | undefined;
    fieldVisibilityRules: { [key: string]: boolean };
    tempAttachments: TemporaryAttachmentsBundle;
    attachmentsMarkedForDeletion: string[];
}

// TODO: Prop and state types.
class ExpenseDetailsTab extends TaimerComponent<ExpenseDetailsTabProps, ExpenseDetailsTabState> {
    static contextType = SettingsContext;

    private detailsSections: Section[];
    private projectTreeRef: React.RefObject<ProjectTreeDropdown> = React.createRef<ProjectTreeDropdown>();
    private detailsSliderRef: React.RefObject<SliderFieldGroup> = React.createRef<SliderFieldGroup>();
    private statusMap: { [key: number]: {
        text: string;
        color: string;
    } };
    private lastModifiedExpenseField: string | undefined;

    constructor(props, context) {
        super(props, context, "expenses/ExpenseDetailsTab");

        this.state = {
            subtotal: 0,
            vatTotal: 0,
            total: 0,
            attachmentOpen: false,
            attachmentURI: "",
            tempProjectId: undefined,
            fieldVisibilityRules: {},
            tempAttachments: {
                mileage_allowance: {},
                additional_allowance: {},
                daily_allowance: {},
                other_allowance: {},
                purchase_expense: {},
                general: {}
            },
            attachmentsMarkedForDeletion: []
        };

        this.detailsSections = [
            {
                key: "details",
                title: this.tr(this.props.expenseType === "te" 
                    ? "Travel expense details"
                    : "Purchase expense details"),
                initiallyOpen: true,
            },
            {
                key: "state_log",
                title: this.tr("Status log"),
                initiallyOpen: true,
            },
        ];

        this.statusMap = {
            0: { text: this.tr("Draft"), color: "#6B7897" },
            1: { text: this.tr("Waiting"), color: "#ffb822" },
            2: { text: this.tr("Approved"), color: colors.greenish_cyan },
            3: { text: this.tr("Declined"), color: "#f7548f" },
            4: { text: this.tr("Archived"), color: "#716aca" },
        };
    }

    componentDidUpdate(prevProps: ExpenseDetailsTabProps, prevState: ExpenseDetailsTabState) {
        if(!isEqual(prevProps.data, this.props.data)
            || !isEqual(prevProps.dropdownData, this.props.dropdownData)) {
                this.tallyUpExpenses(this.props.data);

            this.setProjectSelection(this.props.data.projectId);
            this.updateFieldVisibilityRules();
        }

        if(!isEqual(prevState.tempAttachments, this.state.tempAttachments)) {
            this.handleAttachmentsChange();
        }
    }

    componentDidMount() {
        this.tallyUpExpenses(this.props.data);
        this.setProjectSelection(this.props.data.projectId);
        this.updateFieldVisibilityRules();
    }

    updateFieldVisibilityRules = () => {
        this.setState({
            fieldVisibilityRules: {
                accountingProductId: this.props.expenseType === "te" 
                    && this.context.addons?.procountor?.used_by_companies?.indexOf(this.props.data.companyId) > -1,
                accountingAccountId: this.context.addons?.fortnox?.used_by_companies?.indexOf(this.props.data.companyId) > -1,
                jobtypeId: Boolean(this.context.addons?.nav),
                additionalPassengerCount: this.netvisorIsOn(),
                ratioId: this.context.addons?.netvisor?.used_by_companies?.indexOf(this.props.data.companyId) > -1,
                mealAmount: this.props.countryCode === "FI"
            }
        });
    }

    netvisorIsOn = (): boolean => {
        return this.context.addons?.netvisor?.used_by_companies?.indexOf(this.props.data.companyId) > -1;
    }

    getDetailsSections = (): Section[] => {
        return this.detailsSections.filter((section: Section) => {
            return section.key !== "state_log"
                || (this.props.stateLog && this.props.stateLog.length > 0);
        });
    }

    onDetailsEdit = (details: ExpenseDetails) => {
        details = {
            ...details,
            projectId: this.state.tempProjectId ?? details.projectId
        };

        const clone: ExpenseDetails = cloneDeep(details);

        this.props.onDetailsEdit(clone);
        this.tallyUpExpenses(clone);
        this.setProjectSelection(undefined);
    }

    setProjectSelection = (projectId: string | number | undefined) => {
        this.setState({ 
            tempProjectId: projectId
        });
    }

    tallyUpExpenses = (details: ExpenseDetails) => {
        let vat      = 0;
        let subtotal = 0;
        let total    = 0;

        details.dailyRows?.forEach((d): void => {
            const dAllowance: DailyRate | undefined = this.props.dropdownData?.dailyRates.find(rate => {
                return rate.value === d.allowanceId;
            });

            if(!dAllowance) {
                return;
            }

            // @ts-ignore
            const t = parseFloat(d.total ?? 0);

            subtotal = nplus(subtotal, t);
            total    = nplus(total, t);
        });

        details.mileageRows?.forEach((d: MileageAllowance): void => {
            const pool: MileageRate[] = d?.isAdditional 
                ? this.props.dropdownData?.additionalRates 
                : this.props.dropdownData?.mileageRates; 

            const mileage: MileageRate | undefined = pool.find(rate => {
                return rate.value === d.mileageId;
            });

            if(!mileage) {
                return;
            }

            const netvisorRate: number = this.netvisorIsOn() && d?.additionalPassengerCount > 0 
                ? this.props.miscData?.netvisorRate ?? 0
                : 0;

            const normal: number = nmultiply(d.mileage, mileage.rate);
            const netvisorAdditional: number = nmultiply(
                Number(d?.additionalPassengerCount ?? 0), 
                netvisorRate,
                d.mileage
            );

            const mileageTotal: number = nplus(normal, netvisorAdditional);

            subtotal = nplus(subtotal, mileageTotal);
            total    = nplus(total, mileageTotal);
        });

        details?.expenseRows?.forEach(er => {
            subtotal = nplus(subtotal, roundToDecimals(er.sum, 2));
            total    = nplus(total, roundToDecimals(er.total, 2));
            vat      = nplus(vat, nminus(er?.totalWithVat ?? er.total, er?.totalWithoutVat ?? er.sum));
        });

        const totals = {
            subtotal: subtotal,
            total: total,
            // I see no reason not to.
            vatTotal: total - subtotal
            // vatTotal: vat,
        }

        this.setState({ ...totals }, () => this.props.onTotalsChange && this.props.onTotalsChange(totals));
    }

    renderDetailsTopSection = (): JSX.Element => {
        const {
            data
        }: { 
            data: ExpenseDetails 
        } = this.props;

        const {
            companyTotalWithoutVat,
            companyTotal,
            companyVat
        }: {
            companyTotalWithoutVat?: number | undefined;
            companyTotal?: number | undefined;
            companyVat?: number | undefined;
        } = data;

        const { functions: { urlify } } = this.context;

        const {
            subtotal,
            vatTotal,
            total,
        }: {
            subtotal: number;
            vatTotal: number;
            total: number;
        } = this.state;

        const rate: number                   = data?.currencyRate ?? 1;
        const showInvoiceability: boolean    = [2, 4].indexOf(Number(data.state)) > -1;
        const showInCompanyCurrency: boolean = this.props.expenseType === "pe" 
            && data.notInCompanyCurrency;

        const title           = this.tr(this.props.expenseType === "te" 
            ? "Travel expense" 
            : "Purchase expense");
        const status          = this.statusMap[Number(data.state)];
        const presentCurrency = (amount: number | string) => {
            return this.context.functions.presentCurrency(amount, data.currency);
        };
        const presentInCompanyCurrency = (amount: number | string) => {
            return this.props.expenseType === "pe" 
                ? this.context.functions.presentCurrency(amount, data?.companyCurrency ?? null)
                : presentCurrency(amount);
        };

        const invoicedStatuses: {
            [key: number]: {
                text: string;
                color: string;
            }
        } = {
            1: {
                text: this.tr("Not invoiced"),
                color: "#f97fab"
            },
            2: {
                text: this.tr("Partially invoiced"),
                color: "#f7bc59"
            },
            3: {
                text: this.tr("Invoiced"),
                color: "#6f42c1"
            },
            4: {
                text: this.tr("Non-billable"),
                color: "#757ba6"
            },
        };

        // TODO:
        return (
            <div data-testid="expense-details-top-section" className={cardStyles.detailsTopSection}>
                <div className={`${cardStyles.topRow} ${tabStyles.detailsTopSectionTopRow}`}>
                    <div className={cardStyles.tags}>
                        <StatusTag text={status?.text} color={status?.color} />
                    </div>
                    {showInvoiceability && <div className={cardStyles.tags}>
                        <StatusTag 
                            text={invoicedStatuses[data.invoiceabilityStatus].text} 
                            color={invoicedStatuses[data.invoiceabilityStatus].color} />
                    </div>}
                </div>
                <div className={cardStyles.titleRow}>
                    {Number(data.userId) > -1 && <TaimerAvatar 
                        size="large" 
                        id={data.userId} 
                        name={data.userFullName} />}
                    <div className={`${cardStyles.titles} ${!data.id ? cardStyles.noTopMargin : ''}`}>
                        <h1>{`${title} #${data.id}`}</h1>
                        <p><a id={tabStyles.userLink} href={urlify({module: 'users', action: 'view', id: data.userId, company: data.companyId})}>{data.userFullName}</a></p>
                        <p>{data.description}</p>
                    </div>
                </div>
                <div id={tabStyles.detailsNumberContainer} className={cardStyles.titleRow}>
                    <p data-testid="expense-details-subtotal" className={tabStyles.titleRowParagraph}>
                        {this.tr("Subtotal")}: <strong>{`${presentCurrency(subtotal ?? 0)}`}</strong>
                        {showInCompanyCurrency && ` (${presentInCompanyCurrency(companyTotalWithoutVat ?? 0)})`}
                    </p>
                    <p data-testid="expense-details-vat" className={tabStyles.titleRowParagraph}>
                        {this.tr("VAT")}: <strong>{`${presentCurrency(vatTotal ?? 0)}`}</strong>
                        {showInCompanyCurrency && ` (${presentInCompanyCurrency(companyVat ?? 0)})`}
                    </p>
                    <p data-testid="expense-details-total" className={tabStyles.titleRowParagraph}>
                        {this.tr("Total")}: <strong>{`${presentCurrency(total ?? 0)}`}</strong>
                        {showInCompanyCurrency && ` (${presentInCompanyCurrency(companyTotal ?? 0)})`}
                    </p>
                </div>
            </div>
        );
    }

    onDetailsScroll = (e): void => {
        this.props?.onDetailsScroll?.(e.currentTarget.scrollTop);
    }

    handleAttachmentsChange = () => {
        if(!this.props.onTemporaryAttachmentsChange) {
            return;
        }

        this.props.onTemporaryAttachmentsChange(
            cloneDeep(this.state.tempAttachments)
        );
    }

    addTemporaryAttachments = (
        files: ExpenseFile[], 
        key: string, 
        entityId: string | number
    ): void => {
        const { 
            attachmentMaxSize 
        }: {
            attachmentMaxSize: number
        } = this.context.taimerAccount;

        const size: number = files
            .map((file: ExpenseFile) => file.size)
            .reduce((acc: number, cur: number) => acc + cur);

        if(this.getTemporaryAttachmentsSize() + size > attachmentMaxSize) {
            const msg = "The total size of your attachments is too large";

            this.props.enqueueSnackbar(this.tr(msg), {
                variant: "error"
            });

            return;
        }

        const tempAttachments: { 
            [key: string | number]: ExpenseFile[] 
        } = cloneDeep(this.state.tempAttachments[key]);

        if(!tempAttachments[entityId]) {
            tempAttachments[entityId] = [];
        }

        tempAttachments[entityId] = [
            ...tempAttachments[entityId],
            ...files
        ];

        this.setState({
            tempAttachments: {
                ...this.state.tempAttachments,
                [key]: tempAttachments
            }
        });
    }

    setTemporaryAttachments = (
        files: ExpenseFile[], 
        key: string, 
        entityId: number | string,
        callback: () => void = () => null
    ): void => {
        const attachments: { 
            [key: string | number]: ExpenseFile[] 
        } = cloneDeep(this.state.tempAttachments[key]);

        attachments[entityId] = files;

        this.setState({
            tempAttachments: {
                ...this.state.tempAttachments,
                [key]: attachments
            }
        }, callback);
    }

    getTemporaryAttachments = (key: string, entityId: string | number | undefined = undefined): ExpenseFile[] => {
        if(entityId !== undefined) {
            return this.state.tempAttachments?.[key]?.[entityId] ?? [];
        }

        // If entityId is undefined, the client wants all 
        // the possible attachments for this row type.
        return Object.keys(this.state.tempAttachments[key])
            .map((id: string | number): ExpenseFile[] => this.state.tempAttachments[key][id])
            .flat();
    }

    getTemporaryAttachmentsSize = (): number => {
        const {
            tempAttachments 
        }: {
            tempAttachments: TemporaryAttachmentsBundle;
        } = this.state;

        type BundlePart = { [key: string | number]: ExpenseFile[] };

        const allFiles: ExpenseFile[] = Object.keys(tempAttachments)
            .map((k: string): BundlePart => tempAttachments[k])
            .map((p: BundlePart): ExpenseFile[][] => {
                return Object.keys(p).map((k: string | number) => p[k]);
            }).flat().flat();

        return allFiles.map((f: ExpenseFile): number => {
            return f.size;
        }).reduce(reduceSum, 0);
    }

    clearTemporaryAttachments = (
        key: string, 
        entityId: number | string | undefined = undefined
    ): void => {
        let tempAttachments: {
            [key: string | number]: ExpenseFile[]  
        } = cloneDeep(this.state.tempAttachments[key]);

        if(!entityId) {
            tempAttachments = {};
        } else {
            delete tempAttachments[entityId];
        }

        this.setState({
            tempAttachments: {
                ...this.state.tempAttachments,
                [key]: tempAttachments
            }
        });
    }

    // TODO: Refactor the whole index thing.
    // They're objects, so you can just compare them to 
    // find out which one was deleted.
    markTemporaryAttachmentDeleted = (
        key: string, 
        entityId: string | number,
        index: number
    ): void => {
        const tempAttachments: ExpenseFile[] = cloneDeep(this.state.tempAttachments[key][entityId])
            .map((file: ExpenseFile, fIndex: number) => {
                file.deleted = fIndex === index
                    ? true
                    : file.deleted;

                return file;
            })

        this.setTemporaryAttachments(tempAttachments, key, entityId);
    }

    deleteTemporaryAttachment = (key: string, entityId: number | string, index: number): void => {
        const tempAttachments: ExpenseFile[] = cloneDeep(this.state.tempAttachments[key][entityId])
            .filter((file: ExpenseFile, fIndex: number) => index !== fIndex);

        this.setTemporaryAttachments(tempAttachments, key, entityId);
    }

    deleteTemporaryAttachments = (key: string, entityId: number | string): void => {
        this.setTemporaryAttachments([], key, entityId);
    }

    uploadTemporaryAttachments = async (key: string, rowIdUI: number, rowIdDB: number): Promise<UploadResponse> => {
        const endPart: string = this.props.expenseType === "te" 
            ? "/traveling"
            : "";

        const r: UploadResponse = await uploadFiles(
            this.getTemporaryAttachments(key, rowIdUI)
                .filter((f: ExpenseFile) => !f.deleted),
            {
                url: `expenses/${this.props.data.id}/attachments${endPart}`,
                row_id: rowIdDB,
                row_type: key
            },
            await DataHandler.get({ url: `attachments/max_size` })
        );

        // Give the slider some time to close before
        // clearing the attachments. Prevents the just added
        // attachments from disappearing from the attachment
        // list before the slider has been closed.
        setTimeout(() => this.clearTemporaryAttachments(key, rowIdDB), 500);

        return r;
    }

    markAttachmentForDeletion = (attachmentId: string): void => {
        this.setState({
            attachmentsMarkedForDeletion: [
                ...this.state.attachmentsMarkedForDeletion,
                attachmentId
            ]
        });
    }

    clearAttachmentsMarkedForDeletion = (): void => {
        this.setState({
            attachmentsMarkedForDeletion: [],
        });
    }

    deleteAttachmentsMarkedForDeletion = async (): Promise<void> => {
        for(const id of this.state.attachmentsMarkedForDeletion) {
            await DataHandler.delete({ url: `attachments/${id}` });
        }

        this.clearAttachmentsMarkedForDeletion();
    }

    transformFilesForSimpleTable = (files: ExpenseFile[]): {
        name: string;
        type: string;
        url: string;
    }[] => {
        return files.map((file: ExpenseFile) => {
            return {
                name: file.name,
                type: file.type,
                url: URL.createObjectURL(file),
                deleted: file.deleted,
                saved: file.saved
            }
        });
    }

    showDeletionDialog = (callback: () => void): void => {
        this.context.functions.showDialog(<Dialog 
            dialogType={"delete"}
            dialogProps={{
                onConfirm: () => {
                    callback();
                    this.context.functions.closeDialog();
                },
                header: this.tr("Delete row?"),
                onCloseClick: () => this.context.functions.closeDialog(),
                onCancel: () => this.context.functions.closeDialog(),
                warning: () => this.tr("Are you sure you want to remove this row?")
            }}
        />);
    }

    showAttachmentDeletionDialog = (attachment, onConfirm?: (boolean) => void): void => {
        this._showAttachmentDeletionDialog(async (confirmed: boolean) => {
            if(onConfirm !== undefined) {
                onConfirm(confirmed);

                return;
            }

            if(!confirmed) {
                return;
            }

            this.handleGeneralAttachmentDeletion(attachment);
            this.context.functions.closeDialog();
        });
    }

    protected handleGeneralAttachmentDeletion(attachment) {
        if (attachment?.locationtype === 'googledrive') {
            const { enqueueSnackbar } = this.props;
                DataHandler.post({ url: 'drive/google/deletefile' }, { file_id: attachment.fileId })
                    .done(response => {
                        if (!response.error) {
                            enqueueSnackbar(this.tr('Attachment removed succesfully!'), {
                                variant: "success"
                            });
                        } else {
                            console.error(response.error);
                        }

                        this.props.refresh();
                    })
                    .fail(err => {
                        console.log(err);
                    });
            return;
        }

        DataHandler.delete({ url: `attachments/${attachment.id}` })
            .done(this.props.refresh())
    }

    _showAttachmentDeletionDialog = (onConfirm: (boolean) => void) => {
        this.context.functions.showDialog(<Dialog 
            dialogType={"delete"}
            dialogProps={{
                header: this.tr("Delete attachment?"),
                warning: () => this.tr("Are you sure you want to remove this attachment?"),
                onConfirm: () => onConfirm(true),
                onCloseClick: () => {
                    onConfirm(false);
                    this.context.functions.closeDialog();
                },
                onCancel: () => {
                    onConfirm(false);
                    this.context.functions.closeDialog();
                },
            }}
        />);
        
    }

    getAttachmentDeletionFunction = (key: string, entityId: number | string): (attachment, index: number) => void => {
        return (attachment, index: number) => {
            this.showAttachmentDeletionDialog(
                attachment,
                attachment?.id ?? false
                    ? (confirmed: boolean) => {
                        if(!confirmed) {
                            return;
                        }

                        this.markAttachmentForDeletion(attachment.id);

                        this.context.functions.closeDialog();
                    }
                    : (confirmed: boolean) => {
                        if(!confirmed) {
                            return;
                        }

                        this.markTemporaryAttachmentDeleted(
                            key, 
                            entityId,
                            index
                        );

                        this.context.functions.closeDialog();
                    }
            );
        }
    }

    filterOutDeletedAttachments = (attachments): any[] => {
        return attachments.filter(attachment => {
            return this.state.attachmentsMarkedForDeletion.indexOf(attachment.id) === -1;
        });
    }

    getExpense = (): ExpenseDetails => {
        return this.props.data;
    }

    getMileageAllowanceSliderRowFields = (isAdditional = false): SliderRowField[] => {
        const {
            dropdownData
        }: {
            dropdownData: DropdownData;
        } = this.props;

        return [
            {
                key: "mileageId",
                title: !isAdditional 
                    ? this.tr("Allowance")
                    : this.tr("Additional allowance"),
                type: "select",
                getOptions: (item, options): MileageRate[] => {
                    return this.getEligibleRates<MileageRate>(
                        // data, // Can't use the captured (closure) value here,
                        // so we have to do it like this:
                        this.props.data,
                        // Voi lollero.
                        !item?.isAdditional
                            ? options
                            : this.props.dropdownData.additionalRates,
                        item?.id > 0 
                            ? item?.mileageId ?? undefined
                            : undefined
                    );
                },
                options: !isAdditional 
                    ? dropdownData.mileageRates 
                    : dropdownData.additionalRates,
                selectFirstAutomatically: true,
                autoFocus: (item) => (item?.id ?? -1) < 0,
                required: true,
                setOtherValuesWithSelection: (item, value) => {
                    const integration_product = value?.integration_product || 0;
                    const accountingProduct = dropdownData?.accountingProducts?.find(a => a.id == integration_product);
                    
                    return {
                        accountingProductId: accountingProduct?.id || 0
                    }
                }
            },
            {
                key: "description",
                title: this.tr("Description"),
                type: "text",
                additionalProps: {
                    selectOnFocus: true
                }
            },
            {
                key: "additionalPassengerCount",
                title: this.tr("Additional passengers"),
                type: "numeric",
                validation: "numericNonNegative",
                additionalProps: {
                    selectOnFocus: true
                }
            },
            {
                key: "jobtypeId",
                title: this.tr("Jobtype"),
                type: "select",
                options: dropdownData.jobtypes,
                selectFirstAutomatically: true,
            },
            {
                key: "accountingProductId",
                title: this.tr("Accounting product"),
                type: "select",
                options: dropdownData.accountingProducts,
                getDefaultValue: (item) => {
                    const integration_product = item?.mileageId?.integration_product || 0;
                    const accountingProduct = dropdownData?.accountingProducts?.find(a => a.id == integration_product);
                    return accountingProduct?.id?.toString() || "0";
                },
            },
            {
                key: "accountingAccountId",
                title: this.tr("Accounting account"),
                type: "select",
                options: dropdownData.accountingAccounts,
                selectFirstAutomatically: true,
            },
            {
                key: "mileage",
                title: this.tr("Distance"),
                type: "number",
                required: true,
                validation: "numericNonNegative",
                additionalProps: {
                    selectOnFocus: true,
                    maximumFractionDigits: 0,
                    equalPropValueFix: true
                }
            },
        ].filter(field => this.state.fieldVisibilityRules?.[field.key] ?? true);
    }

    getSaveRow = (type, row) => {
        let saveRow = row;

        switch (type) {
            case "expense":
                saveRow = this.getExpenseSaveRow(row);
                break;
            case "mileage":
                if(saveRow.isAdditional)
                    saveRow = this.getAdditionalMileageSaveRow(row, row.mileageAllowanceId);
                else
                    saveRow = this.getMileageSaveRow(row);
                break;
            case "daily":
                saveRow = this.getDailySaveRow(row);
                break;
            default:
                saveRow = this.getExpenseSaveRow(row);
        }
        return saveRow;
    }

    getExpenseSaveRow = (row) => {
        const { data } = this.props;
        const flipped: { [key: string]: string } = flipObject<{ [key: string]: string }>(EXPENSE_FIELD_MAP);
        const other: any                         = mapFieldsWithType<any>(row, flipped);
        other.expenses_id                        = data.id;
        return other;
    }

    getMileageSaveRow = (row) => {
        const { data } = this.props;
        const flipped: { [key: string]: string } = flipObject<{ [key: string]: string }>(MILEAGE_FIELD_MAP);
        const mileage: any                       = mapFieldsWithType<any>(row, flipped);
        mileage.expenses_id                      = data.id;
        mileage.is_additional_row                = row.isAdditional ? 1 : 0;
        return mileage;
    }

    getAdditionalMileageSaveRow = (row, itemId) => {
        const { data }                           = this.props;
        const flipped: { [key: string]: string } = flipObject<{ [key: string]: string }>(MILEAGE_FIELD_MAP);
        const fAdditional: any                   = mapFieldsWithType<any>(row, flipped);

        fAdditional.expenses_id          = data.id;
        fAdditional.mileage_allowance_id = itemId;
        fAdditional.rate                 = row?.mileageId?.rate;
        fAdditional.is_additional_row    = 1;
        fAdditional.target_id            = typeof(fAdditional.target_id) === "object"
            ? fAdditional.target_id.value
            : fAdditional.target_id;

        if(typeof (fAdditional.accounting_accounts_id) === "object") {
            fAdditional.accounting_accounts_id = fAdditional.accounting_accounts_id.value;
        }

        if(typeof (fAdditional.accounting_products_id) === "object") {
            fAdditional.accounting_products_id = fAdditional.accounting_products_id.value;
        }

        return fAdditional;
    }

    getDailySaveRow = (row) => {
        const { data } = this.props;

        const flipped: { [key: string]: string }  = flipObject<{ [key: string]: string }>(DAILY_FIELD_MAP);
        const daily: any = mapFieldsWithType<any>(row, flipped);

        row.startTime     = to24HourTime(row.startTime);
        row.endTime       = to24HourTime(row.endTime);
        daily.start_time  = `${row.startDate} ${row.startTime}`;
        daily.end_time    = `${row.endDate} ${row.endTime}`;
        daily.expenses_id = data.id;
        daily.days        = calculateAllowanceDays(daily.start_time, daily.end_time);

        return daily;
    }

    addRateToRows = (rows: MileageAllowance[]): MileageAllowance[] => {
        const { 
            dropdownData 
        } = this.props;

        return clone(rows).map(r => {
            const mId = Number(typeof(r.mileageId) !== "object"
                ? r.mileageId
                // Whoops.
                // @ts-ignore
                : r.mileageId.value);

            const isAdditional: boolean = r?.isAdditional ?? false;

            const mRate: MileageRate | undefined = (
                !isAdditional 
                    ? (dropdownData?.mileageRates ?? [])
                    : (dropdownData?.additionalRates ?? [])
            ).find(rate => rate.value === mId);

            if(!mRate) {
                return r;
            }

            r.rate = mRate.rate;

            return r;
        });
    }

    protected userCanEdit(): boolean {
        const { 
            checkPrivilege
        }: {
            checkPrivilege: (m: string, p: string, c: number | string) => boolean
        } = this.context.functions;

        const { userObject } = this.context;
        const {
            data
        }: {
            data: ExpenseDetails
        } = this.props;
        
        return (!data.billed && checkPrivilege("worktrips", "modify_all", data.companyId))
            || (Number(data.state) < 2
                && Number(data.userId) === Number(userObject.usersId)
                && checkPrivilege("worktrips", "write", data.companyId));
    }

    protected getMileageAllowanceListProps(): SliderListProps {
        const {
            data,
            dropdownData,
        }: {
            data: ExpenseDetails;
            dropdownData: DropdownData;
        } = this.props;

        const presentCurrency = (amount: number | string) => {
            return this.context.functions.presentCurrency(amount, data.currency);
        };

        return {
            editable: (row: MileageAllowance): boolean => {
                return this.userCanEdit() && row.billId === 0;
            },
            columnsToHideWhenNotEditable: ["menu", "delete"],
            propControlledRows: true,
            itemPropUpdateRelevantFields: ["attachments"],
            summaryRowPresentation: (rows: MileageAllowance[]): { [key: string]: JSX.Element | string; } => {
                const mileage: number = rows
                    .filter((r: MileageAllowance) => !r.isAdditional)
                    .map((r: MileageAllowance) => Number(r.mileage))
                    .reduce((a, c) => a + c);

                // TODO: The company's unit instead of km.
                const mileageStr = `${mileage} km`;

                // If the expense hasn't been saved yet, the rows will not have
                // the rate attribute, so we need to add it "manually" in addRateToRows.
                const total: number = (Number(data?.id) > 0 ? rows : this.addRateToRows(rows))
                    .map((r: MileageAllowance) => {
                        // Some of the calculations here are duplicated in 
                        // tallyUpExpenses. Didn't have the time to write a 
                        // function for this yet.
                        const mileage              = Number(r.mileage);
                        const normal: number       = nmultiply(mileage, r.rate);
                        const netvisorRate: number = this.netvisorIsOn() && r?.additionalPassengerCount > 0 
                            ? this.props.miscData?.netvisorRate ?? 0
                            : 0;

                        const netvisorAdditional: number = nmultiply(
                            r?.additionalPassengerCount ?? 0, 
                            netvisorRate,
                            mileage
                        );

                        return nplus(normal, netvisorAdditional);
                    })
                    .reduce((a, c) => a + c);

                return {
                    mileageId: <strong>{this.tr("Total")}</strong>,
                    mileage: <strong>{mileageStr}</strong>,
                    total: <strong>{presentCurrency(total)}</strong>
                };
            },
            rowPresentation: (row: MileageAllowance, list: SliderList): { [key: string]: JSX.Element | string | null } => {
                const isAdditional: boolean = row.isAdditional;
                const mileagePool           = !isAdditional
                    ? dropdownData?.mileageRates || []
                    : dropdownData?.additionalRates || [];

                const originalRate: number = mileagePool.find(m => m.value === row.mileageId)?.rate ?? 0;
                const netvisorRate: number = this.netvisorIsOn() && row?.additionalPassengerCount > 0 
                    ? this.props.miscData?.netvisorRate ?? 0
                    : 0;

                // Rate for displaying purposes.
                const rate: number = nplus(
                    originalRate, 
                    nmultiply(netvisorRate, row?.additionalPassengerCount ?? 0)
                );
                const rateStr      = String(rate);
                const mileageLabel = mileagePool.find(m => m.value === row.mileageId)?.label ?? "" 
                const mileagePart  = (<>
                    <div><strong>{mileageLabel}</strong></div>
                    <div>{`${presentCurrency(rateStr)} / km`}</div>
                </>);

                const mileageCell = !isAdditional 
                    ? mileagePart
                    : (
                    <>
                        <div className={tabStyles.additionalCellLeft}>
                            <TreeIndicator last={false} />
                        </div>
                        <div className={tabStyles.additionalCellRight}>
                            {mileagePart}
                        </div>
                    </>
                );

                let total: number = nmultiply(
                    row.mileage, 
                    originalRate
                );

                if(!isAdditional && this.netvisorIsOn()) {
                    total = nplus(
                        total, 
                        nmultiply(
                            row.mileage,
                            row?.additionalPassengerCount ?? 0, 
                            netvisorRate
                        )
                    );
                }

                return {
                    menu: <SliderListRowMenu 
                        items={[
                            { icon: <EditIcon />, text: this.tr("Edit"), onClick: () => list.showRowSlider(row) },
                            { 
                                icon: <PersonAddIcon />, text: this.tr("Add an additional mileage cost"), 
                                visible: !row?.isAdditional && !this.netvisorIsOn(),
                                onClick: () => this.addAdditionalMileageRow(row) 
                            },
                            { icon: <DeleteIcon />, text: this.tr("Delete"), variant: "delete", onClick: () => list.deleteRow(row) },
                        ]}
                    />,
                    mileageId: mileageCell,
                    mileage: `${row.mileage} km`,
                    additionalPassengerCount: String(row?.additionalPassengerCount ?? ""),
                    accountingAccountId: dropdownData?.accountingAccounts?.find(m => m.value === Number(row.accountingAccountId))?.label ?? "",
                    accountingProductId: dropdownData?.accountingProducts?.find(m => m.value === Number(row.accountingProductId))?.label ?? "",
                    attachments: <AttachmentIndicator 
                        showAttachment={this.showAttachment}
                        downloadAttachment={this.downloadAttachment}
                        attachments={row.attachments}
                    />,
                    jobtypeId: dropdownData?.jobtypes?.find(m => m.value === row.jobtypeId)?.label ?? "",
                    total: `${presentCurrency(total)}`,
                    delete: <DeleteIcon />
                };
            },
            fieldEditHandlers: {
                mileageId: (mileageId, row) => {
                    return (typeof(mileageId) === "object") 
                        ? mileageId.value 
                        : mileageId;
                },
                jobtypeId: (jobtypeId, row) => {
                    return row.jobtypeId?.id ?? row.jobtypeId;
                },
                accountingAccountId: (account, row) => (account?.value || account?.value === 0) ? account?.value : account,
                accountingProductId: (product, row) => (product?.value || product?.value === 0) ? product?.value : product,
            },
            onItemSave: async (row: MileageAllowance) => {
                const response = await DataHandler.post(
                    { url: `expenses/${row.id}/mileage_row` }, 
                    this.getMileageSaveRow(row)
                );

                await this.uploadTemporaryAttachments(
                    !row.isAdditional 
                        ? "mileage_allowance"
                        : "additional_allowance",
                    row.id,
                    !row.isAdditional 
                        ? response.data.id
                        : response.data.id.substr(1)
                );

                await this.deleteAttachmentsMarkedForDeletion();

                this.props.refresh();
            },
            onDeleteRow: (mileage: MileageAllowance) => {
                this.showDeletionDialog(async () => {
                    !mileage.isAdditional
                        ? await DataHandler.delete({ url: `expenses/${mileage.id}/mileage_row` }, mileage)
                        : await DataHandler.delete({ url: `expenses/${mileage.id}/additional_row` }, mileage);

                    this.props.refresh();
                });
            },
            onSliderClose: (item: MileageAllowance, reason: string) => {
                if(reason !== "cancel" && reason !== "backdropClick") {
                    return null;
                }

                this.clearTemporaryAttachments(!item.isAdditional 
                    ? "mileage_allowance" 
                    : "additional_allowance", 
                item.id);

                this.clearAttachmentsMarkedForDeletion();
            },
            minimumWidth: 1000,
            // Ignored because TypeScript doesn't understand the
            // "right" value for the align attribute is one of the values
            // defined for the unity: 
            // "left" | "right" | "justify" | "center" | "inherit" | undefined
            // @ts-ignore
            listColumns: [
                { id: "menu", label: "", width: "40px", noClick: true },
                { id: "mileageId", label: this.tr("Allowance"), width: "100%" },
                { id: "description", label: this.tr("Description"), width: "200px" },
                { id: "additionalPassengerCount", label: this.tr("Additional passengers"), width: "80px" },
                { id: "jobtypeId", label: this.tr("Jobtype"), width: "80px" },
                { id: "accountingProductId", label: this.tr("Accounting product"), width: "80px" },
                { id: "accountingAccountId", label: this.tr("Accounting account"), width: "80px" },
                { id: "mileage", label: this.tr("Distance"), width: "100px", align: "right" },
                { id: "total", label: this.tr("Total"), width: "100px", align: "right" },
                { id: "attachments", label: "", width: "30px", align: "center" },
                { id: "delete", label: "", width: "30px", align: "center", cellClickHandler: (row: any, list: SliderList) => {
                    list.deleteRow(row);
                }}
            ].filter((field): boolean => this.state.fieldVisibilityRules?.[field.id] ?? true),
            icons: { mainIcon: DirectionsCar },
            menuItems: this.userCanEdit() 
                ? [
                    { label: this.tr("Add row"), onClick: (list) => list.addRow() },
                ] 
                : [],
            rows: data.mileageRows,
            sliderRowFields: (item) => {
                return this.getMileageAllowanceSliderRowFields(item?.isAdditional ?? false);
            },
            texts: (item) => {
                const typeStr = !item?.isAdditional ? "Mileage" : "Additional";

                return {
                    mainHeader: this.tr(`${typeStr} allowances`),
                    sliderHeader: this.tr(`${typeStr.toLowerCase()} allowance`),
                } ;
            },
            className: tabStyles.sliderList,
            additionalContent: (item: MileageAllowance, slider: FieldEditSlider): JSX.Element => {
                if(!item || (item?.isAdditional ?? false)) {
                    return <></>;
                }

                const key: string = !item.isAdditional 
                    ? "mileage_allowance"
                    : "additional_allowance";

                return (
                    <>
                        <h3>{this.tr("Attachments")}</h3>
                        <SimpleAttachmentTable 
                            showAttachment={this.showAttachment}
                            downloadAttachment={this.downloadAttachment}
                            onPromptDeletion={this.getAttachmentDeletionFunction(key, item.id)}
                            rows={this.filterOutDeletedAttachments(item?.attachments ?? [])
                                .concat(this.transformFilesForSimpleTable(this.getTemporaryAttachments(key, item.id)))}
                        />
                        <button 
                            className={tabStyles.linkLikeButton} 
                            onClick={() => {
                                this.context.functions.promptMultipleFileSelection((files: File[]) => {
                                    this.addTemporaryAttachments(
                                        files.map((f: File): ExpenseFile => {
                                            const ef: ExpenseFile = new ExpenseFile(f);

                                            ef.setExpenseRowType(key);

                                            return ef;
                                        }), 
                                        key, 
                                        item.id
                                    );

                                    return true;
                                });
                            }}>
                            {this.tr("Add an attachment")}
                        </button>
                    </>
                );
            },
        };
    }

    addAdditionalMileageRow = (item: MileageAllowance) => {
        // Prevent any potential by-reference bugs.
        const clonedItem: MileageAllowance = cloneDeep(item);

        clonedItem.isAdditional = true;

        this.context.functions.showSlider(
            <FieldEditSlider 
                open={true}
                fields={this.getMileageAllowanceSliderRowFields(true)}
                onClose={this.context.functions.closeSlider}
                onSave={async (additional: MileageAllowance) => {
                    const fAdditional = this.getAdditionalMileageSaveRow(additional, clonedItem.id);

                    await DataHandler.post(
                        { url: `expenses/${fAdditional.id}/mileage_row` }, 
                        fAdditional
                    );

                    this.props.refresh();
                    this.context.functions.closeSlider();
                }}
                title={this.tr("Add additional row")}
                item={{
                    mileageId: this.getEligibleRates<MileageRate>(
                        this.props.data,
                        this.props.dropdownData.additionalRates,
                    )?.[0],
                    mileage: clonedItem.mileage,
                }}
                useAnimatedSlider={true}
            />
        );
    }

    verbalizeFullDaysAndHalfDays = (dayT: DayTuple): (string | null)[] => {
        const [f, h]: [number, number] = dayT;

        const hd = this.tr("half day");
        const fd = f < 2 
            ? this.tr("full day") 
            : this.tr("full days");
        const d  = this.tr("days");

        if(f === 0 && h === 0) {
            return [`0 ${d}`];
        }

        return [
            f > 0 ? `${f} ${fd}` : null,
            h > 0 ? `${h} ${hd}` : null
        ].filter(s => s);
    }

    editMeals = (item, slider): void => {
        const hasMeals: boolean = Object.keys(item?.meals ?? {})
            .filter((d: string) => item?.meals[d] > 0).length > 0;

        this.context.functions.showSliderContent(
            this.tr(hasMeals ? "Edit meals" : "Add meals"),
            <DailyAllowanceMealSelector 
                allowance={item} 
                dailyRates={this.props.dropdownData.dailyRates}
                presentCurrency={(amount: number | string, options = {}) => {
                    return this.context.functions.presentCurrency(amount, this.props.data.currency, options);
                }}
                onChange={async ( meals: { [key: string]: number; }) => {
                    // Edited by reference.
                    // TODO: Handle this differently.
                    item.meals = meals;

                    this.context.functions.closeSlider();

                    // Bummer.
                    slider.forceUpdate();
                }}
            />
        );
    }

    calculateTotalsForRows = (rows: any[]): any[] => {
        const { 
            dropdownData
        } = this.props;

        return clone(rows).map(row => {
            const startNoFormat = `${row.startDate} ${row.startTime}`;
            const endNoFormat   = `${row.endDate} ${row.endTime}`;
            const allowance: DailyRate | undefined  = dropdownData?.dailyRates
                ?.find(m => Number(m.value) === Number(row.allowanceId)) ?? undefined;

            const total: {
                total: number;
                withoutMeals: number;
                deduction: number
            } = calculateFreeMealDeductionFinland(
                row?.meals ?? {},
                allowance?.rate ?? 0,
                allowance?.partRate ?? 0,
                startNoFormat,
                endNoFormat
            );

            row.totalMeals = Object.keys(row?.meals ?? {})
                .map((d: string): number => row?.meals?.[d])
                .reduce(reduceSum, 0);

            row.total              = total.total;
            row.mealDeductionTotal = total.deduction;
            row.days               = calculateAllowanceDays(startNoFormat, endNoFormat);

            return row;
        });
    }

    protected getDailyAllowanceListProps(): SliderListProps {
        const {
            data,
            dropdownData,
        }: {
            data: ExpenseDetails;
            dropdownData: DropdownData;
        } = this.props;

        const { 
            userObject: {
                dateFormat
            }
        } = this.context;

        const presentCurrency = (amount: number | string) => {
            return this.context.functions.presentCurrency(amount, data.currency);
        };

        const allowanceIdTooltipFunction: (item: any) => string | undefined = (item): string | undefined => {
            const showTooltip: boolean = (Object.keys(item?.meals || {})
                .map((k: string) => item?.meals[k])
                .reduce((cur: number, acc: number): number => cur + acc, 0) || 0) > 0;

             return showTooltip 
                 ? this.tr("Free meals are only allowed for Finnish daily allowances. Remove the meals to edit the allowance.")
                 : undefined
        }

        return {
            editable: (row: DailyAllowance): boolean => {
                return this.userCanEdit() && row.billId === 0;
            },
            columnsToHideWhenNotEditable: ["menu", "delete"],
            propControlledRows: true,
            itemPropUpdateRelevantFields: ["attachments"],
            summaryRowPresentation: (rows): { [key: string]: JSX.Element | string; } => {
                rows                                 = Number(data?.id) > 0 ? rows : this.calculateTotalsForRows(rows);
                const total: number                  = sum(rows.map(r => Number(r.total)));
                const mealsTotal: number             = rows.map(r => r.totalMeals).reduce(reduceSum, 0);
                const totalDeductions: number        = rows.map(r => r.mealDeductionTotal).reduce(reduceSum, 0);
                const fullAndHalfDaysTotal: number[] = rows
                    .map(r => r.days)
                    .map(days => getFullDaysAndHalfDays(days))
                    .reduce((a, c) => [a[0] + c[0], a[1] + c[1]], [0, 0]);

                const full: JSX.Element = fullAndHalfDaysTotal[0] > 0
                    ? (<div><strong>{`${fullAndHalfDaysTotal[0]} ${this.tr(fullAndHalfDaysTotal[0] > 1 ? "full days" : "full day")}`}</strong></div>)
                    : (<></>);
                const half: JSX.Element = fullAndHalfDaysTotal[1] > 0
                    ? (<div><strong>{`${fullAndHalfDaysTotal[1]} ${this.tr(fullAndHalfDaysTotal[1] > 1 ? "half days" : "half day")}`}</strong></div>)
                    : (<></>);

                return {
                    allowanceId: <strong>{this.tr("Total")}</strong>,
                    days: (
                        <>
                            {full}
                            {half}
                        </>
                    ),
                    mealAmount: (
                        <>
                            <div><strong>{`${mealsTotal} ${this.tr("added")}`}</strong></div>
                            <div><strong>{presentCurrency(totalDeductions)}</strong></div>
                        </>
                    ),
                    total: <strong>{presentCurrency(total)}</strong>,
                };
            },
            rowPresentation: (row, list: SliderList): { [key: string]: JSX.Element | string | null; } => {
                const uses12: boolean   = Number(this.context.userObject.clock_format) === 0;
                const startTime: string = uses12 && is24HourTime(row.startTime) 
                    ? to12HourTime(row.startTime) 
                    : row.startTime;
                const endTime: string = uses12 && is24HourTime(row.endTime) 
                    ? to12HourTime(row.endTime) 
                    : row.endTime;

                const startNoFormat                     = `${row.startDate} ${row.startTime}`;
                const endNoFormat                       = `${row.endDate} ${row.endTime}`;
                const start                             = `${moment(row.startDate).format(dateFormat)} ${startTime}`;
                const end                               = `${moment(row.endDate).format(dateFormat)} ${endTime}`;
                const dayT: DayTuple                    = getFullDaysAndHalfDays(calculateAllowanceDays(startNoFormat, endNoFormat));
                const dayS: (string | null)[]           = this.verbalizeFullDaysAndHalfDays(dayT);
                const allowance: DailyRate | undefined  = dropdownData?.dailyRates?.find(m => Number(m.value) === Number(row.allowanceId)) ?? undefined;
                const days: string | JSX.Element | null = dayS.length === 1
                        ? dayS[0]
                        : (<><div>{dayS[0]}</div><div>{dayS[1]}</div></>);

                const rate: number     = allowance?.rate ?? 0;
                const partRate: number = allowance?.partRate ?? 0;

                const total: {
                    total: number;
                    withoutMeals: number;
                    deduction: number
                } = calculateFreeMealDeductionFinland(
                    row?.meals ?? {},
                    rate,
                    partRate,
                    startNoFormat,
                    endNoFormat
                );

                const totalP   = row?.total ?? total?.total ?? 0;
                const hasMeals = row?.totalMeals > 0;

                return {
                    menu: <SliderListRowMenu 
                        items={[
                            { icon: <EditIcon />, text: this.tr("Edit"), onClick: () => list.showRowSlider(row) },
                            { icon: <DeleteIcon />, text: this.tr("Delete"), variant: "delete", onClick: () => list.deleteRow(row) },
                        ]}
                    />,
                    allowanceId: (
                        <>
                            <div><strong>{allowance?.label ?? ""}</strong></div>
                            <div>{`${presentCurrency(rate)} / ${presentCurrency(partRate)}`}</div>
                        </>
                    ),
                    timespan: (<><div>{`${start} -`}</div><div>{end}</div></>),
                    days: days,
                    total: `${presentCurrency(totalP)}`, 
                    jobtypeId: dropdownData?.jobtypes?.find(m => m.value === row.jobtypeId)?.label ?? "",
                    accountingAccountId: dropdownData?.accountingAccounts?.find(m => m.value === Number(row.accountingAccountId))?.label ?? "",
                    accountingProductId: dropdownData?.accountingProducts?.find(m => m.value === Number(row.accountingProductId))?.label ?? "",
                    attachments: <AttachmentIndicator 
                        showAttachment={this.showAttachment}
                        downloadAttachment={this.downloadAttachment}
                        attachments={row.attachments}
                    />,
                    mealAmount: hasMeals ? (
                        <>
                            <div style={{ textAlign: "right" }}><strong>{`${row?.totalMeals ?? 0} ${this.tr("added")}`}</strong></div>
                            <div style={{ textAlign: "right" }}>{presentCurrency(row?.mealDeductionTotal ?? 0)}</div>
                        </>
                    ) : null,
                    delete: <DeleteIcon />
                };
            },
            fieldEditSliderProps: {
                checkValidityOfAllFieldsOnChange: true
            },
            fieldEditHandlers: {
                allowanceId: (allowance, row) => {
                    return typeof(allowance) === "object"
                        ? allowance.value
                        : allowance;
                },
                jobtypeId: (jobtypeId, row) => row.jobtypeId?.id ?? row.jobtypeId,
                accountingAccountId: (account, row) => (account?.value || account?.value === 0) ? account?.value : account,
                accountingProductId: (product, row) => (product?.value || product?.value === 0) ? product?.value : product,
                startTime: (time, row) => to24HourTime(time ?? "07:00"),
                endTime: (time, row) => to24HourTime(time ?? "07:00"),
            },
            onItemSave: async (row: DailyAllowance) => {
                const daily    = this.getDailySaveRow(row);
                const response = await DataHandler.post(
                    { url: `expenses/${row.id}/daily_row` }, 
                    daily
                );

                await this.uploadTemporaryAttachments(
                    "daily_allowance", 
                    row.id,
                    response.data.id
                );

                await this.deleteAttachmentsMarkedForDeletion();

                this.props.refresh();
            },
            onDeleteRow: (daily: DailyAllowance) => {
                this.showDeletionDialog(async () => {
                    await DataHandler.delete({ url: `expenses/${daily.id}/daily_row` }, daily)

                    this.props.refresh();
                });
            },
            onSliderClose: (item: DailyAllowance, reason: string) => {
                if(reason !== "cancel" && reason !== "backdropClick") {
                    return null;
                }

                this.clearTemporaryAttachments("daily_allowance", item.id);
                this.clearAttachmentsMarkedForDeletion();
            },
            minimumWidth: 1000,
            // @ts-ignore
            listColumns: [
                { id: "menu", label: "", width: "40px", noClick: true },
                { id: "allowanceId", label: this.tr("Allowance"), width: "100%" },
                { id: "timespan", label: this.tr("Timespan"), width: "110px" },
                { id: "jobtypeId", label: this.tr("Jobtype"), width: "80px" },
                { id: "accountingProductId", label: this.tr("Accounting product"), width: "80px" },
                { id: "accountingAccountId", label: this.tr("Accounting account"), width: "80px" },
                { id: "days", label: this.tr("Days"), width: "120px", align: "right" },
                { id: "mealAmount", label: this.tr("Free meals"), width: "110px", align: "right" },
                { id: "total", label: this.tr("Total"), width: "100px", align: "right" },
                { id: "attachments", label: "", width: "30px", align: "center" },
                { id: "delete", label: "", width: "30px", align: "center", cellClickHandler: (row: any, list: SliderList) => {
                    list.deleteRow(row);
                }}
            ].filter((field): boolean => this.state.fieldVisibilityRules?.[field.id] ?? true),
            icons: { mainIcon: Event  },
            menuItems: this.userCanEdit() 
                ? [
                    { label: this.tr("Add row"), onClick: (list) => list.addRow({
                        startDate: data?.startDate,
                        startTime: data?.startTime,
                        endDate: data?.endDate,
                        endTime: data?.endTime,
                    }) }
                ]
                : [],
            rows: data?.dailyRows ?? [],
            sliderRowFields: (row: any): SliderRowField[] => {
                return [
                    {
                        key: "allowanceId",
                        title: this.tr("Allowance"),
                        type: "select",
                        selectFirstAutomatically: true,
                        tooltip: allowanceIdTooltipFunction,
                        fieldTooltip: allowanceIdTooltipFunction,
                        getOptions: (item, options): DailyRate[] => {
                            return this.getEligibleRates<DailyRate>(
                                // data, // Can't use the captured (closure) value here,
                                // so we have to do it like this:
                                this.props.data,
                                dropdownData.dailyRates, 
                                item?.id > 0 
                                    ? item?.allowanceId ?? undefined
                                    : undefined
                            );
                        }, 
                        // ???
                        options: dropdownData.dailyRates,
                        required: true,
                        autoFocus: (item) => item?.id < 0,
                        disabled: (item): boolean => {
                            return (Object.keys(item?.meals || {})
                                .map((k: string) => item?.meals[k])
                                .reduce((cur: number, acc: number): number => cur + acc, 0) || 0) > 0;
                        },
                        setOtherValuesWithSelection: (item, value) => {
                            const integration_product = value?.integration_product || 0;
                            const accountingProduct = dropdownData?.accountingProducts?.find(a => a.id == integration_product);

                            return {
                                accountingProductId: accountingProduct?.id || 0
                            }
                        }
                    },
                    {
                        key: "jobtypeId",
                        title: this.tr("Jobtype"),
                        type: "select",
                        options: dropdownData.jobtypes,
                        selectFirstAutomatically: true,
                    },
                    {
                        key: "accountingProductId",
                        title: this.tr("Accounting product"),
                        type: "select",
                        options: dropdownData.accountingProducts,
                        getDefaultValue: (item) => {
                            const integration_product = item?.allowanceId?.integration_product || 0;
                            const accountingProduct = dropdownData?.accountingProducts?.find(a => a.id == integration_product);
                            return accountingProduct?.id?.toString() || "0";
                        },
                    },
                    {
                        key: "accountingAccountId",
                        title: this.tr("Accounting account"),
                        type: "select",
                        options: dropdownData.accountingAccounts,
                        selectFirstAutomatically: true,
                    },
                    {
                        key: "startDate",
                        title: this.tr("Start date"),
                        type: "date",
                        required: true,
                        defaultValue: data?.startDate,
                        checkValidity: (item) => {
                            const expense: ExpenseDetails = this.getExpense()

                            return item.startDate <= item.endDate
                                && item.startDate >= (expense?.startDate ?? "0000-00-00");
                        }
                    },
                    {
                        key: "startTime",
                        title: this.tr("Start time"),
                        type: "time",
                        defaultValue: data?.startTime,
                        required: true,
                        checkValidity: (item) => {
                            const expense: ExpenseDetails = this.getExpense()
                            const {
                                start,
                                end,
                            }: {
                                start: number;
                                end: number;
                            } = getDailyAllowanceTimeBundle(item);

                            return ((item.startDate === item.endDate && start <= end)
                                || item.startDate < item.endDate)
                                && start >= (new Date(`${expense.startDate}T${to24HourTime(expense.startTime ?? "")}`).getTime());
                        }
                    },
                    {
                        key: "endDate",
                        title: this.tr("End date"),
                        type: "date",
                        required: true,
                        defaultValue: data?.endDate,
                        checkValidity: (item) => {
                            const expense: ExpenseDetails = this.getExpense()
                            const {
                                end,
                                startStr,
                                endStr,
                                endOfYear
                            }: {
                                end: number;
                                startStr: string;
                                endStr: string;
                                endOfYear: number;
                            } = getDailyAllowanceTimeBundle(item);

                            return item.startDate <= item.endDate
                                && item.endDate <= (expense?.endDate ?? "9999-12-31")
                                && calculateAllowanceDays(startStr, endStr) > 0
                                && end <= endOfYear;
                        }
                    },
                    {
                        key: "endTime",
                        title: this.tr("End time"),
                        type: "time",
                        defaultValue: data?.endTime,
                        required: true,
                        checkValidity: (item: DailyAllowance) => {
                            const expense: ExpenseDetails = this.getExpense();
                            const {
                                start,
                                end,
                                startStr,
                                endStr,
                                endOfYear
                            }: {
                                start: number;
                                end: number;
                                startStr: string;
                                endStr: string;
                                endOfYear: number;
                            } = getDailyAllowanceTimeBundle(item);

                            return ((item.startDate === item.endDate && start <= end)
                                || item.startDate < item.endDate)
                                && end <= (new Date(`${expense.endDate}T${to24HourTime(expense.endTime ?? "")}`).getTime())
                                && calculateAllowanceDays(startStr, endStr) > 0
                                && end <= endOfYear;
                        },
                        errorMessage: (item: DailyAllowance): React.ReactNode => {
                            if(!item) {
                                return null;
                            }

                            const {
                                start,
                                end,
                                startStr,
                                endStr,
                                endOfYear
                            }: {
                                start: number;
                                end: number;
                                startStr: string;
                                endStr: string;
                                endOfYear: number;
                            } = getDailyAllowanceTimeBundle(item);

                            // Needs to be done like this, because the function would
                            // otherwise capture props.data by value.
                            const expense: ExpenseDetails = this.getExpense();
                            const expenseStart            = new Date(`${expense.startDate}T${to24HourTime(expense.startTime ?? "")}`).getTime();
                            const expenseEnd              = new Date(`${expense.endDate}T${to24HourTime(expense.endTime ?? "")}`).getTime();

                            const messages: { [key: string]: boolean } = {
                                [this.tr("The start and end times of the allowance need to be between the start and end times of the expense")]:
                                   !(end <= expenseEnd && start >= expenseStart),
                                [this.tr("The end time of of the allowance needs to be after its start time")]:
                                   !(start <= end),
                                [this.tr("The duration of the allowance must be over six hours")]:
                                    calculateAllowanceDays(startStr, endStr) === 0,
                                [this.tr("The allowance can't span past the end of the year")]:
                                    !(end <= endOfYear)
                            };

                            return (
                                <>
                                    {Object.keys(messages)
                                        .filter((k: string) => messages[k])
                                        .map((message: string) => <p>{message}</p>)
                                    }
                                </>
                            );
                        },
                    },
                ].filter((field): boolean => this.state.fieldVisibilityRules?.[field.key] ?? true)
            },
            texts: {
                mainHeader: this.tr("Daily allowances"),
                sliderHeader: this.tr("daily allowance")
            },
            className: tabStyles.sliderList,
            additionalContent: (item: DailyAllowance, slider: FieldEditSlider) => {
                if(!item) {
                    return <></>;
                }

                const allowanceId = typeof(item.allowanceId) === "object"
                    // @ts-ignore
                    ? item.allowanceId.value
                    : item.allowanceId;

                const rate: DailyRate | undefined = dropdownData.dailyRates
                    .find((dr: DailyRate) => (dr?.value || null) === allowanceId);

                // Mangle the datetimes into a form SAFARI understands.
                const sdt = `${item.startDate}T${to24HourTime(fixTime(item.startTime))}`;
                const edt = `${item.endDate}T${to24HourTime(fixTime(item.endTime))}`;

                /**
                 * Updated in TR-3358: Support free meal deductions in foreign daily allowances.
                 * Decided to have it work the same way for foreign as for finnish allowances.
                 * So meal selection is always shown for finnish companies.
                 */
                    // const mealRelevantLabels: string[] = [
                    //     "suomi",
                    //     "finland",
                    //     "kotimaa",
                    //     "hemland",
                    //     "domestic",
                    //     "homeland"
                    // ];

                    // const matchingMealLabel: boolean = !rate 
                    //     ? false 
                    //     : mealRelevantLabels.find((l: string) => rate?.label.toLowerCase().indexOf(l) > -1) !== undefined;

                    // If the company isn't based in Finland or the trip didn't target
                    // Finland, don't show the meal editing button or other meal stuff.
                    // const hideMeals = !rate 
                    //     || this.props.countryCode !== "FI" 
                    //     || !matchingMealLabel
                    //     || (!isValidDateString(sdt) || !isValidDateString(edt));

                const hideMeals = !rate 
                    || this.props.countryCode !== "FI" 
                    || !isValidDateString(sdt) 
                    || !isValidDateString(edt);

                const dates: string[] = divideDateTimeRangeIntoDays(sdt, edt)
                    .map((d: string) => {
                        return moment(d).format("YYYY-MM-DD")
                    });

                dates.pop();

                const datesWithMeals: string[] = dates
                    .filter((date: string) => Boolean(item.meals?.[date]));

                return (
                    <>
                        {!hideMeals && <>
                            <h3>{this.tr("Meals")}</h3>
                            {datesWithMeals
                                .map((date: string) => {
                                    const dateStr: string = moment(date).format(this.context.userObject.dateFormat);

                                    return <p className={tabStyles.mealP}>{dateStr}: <strong>{item.meals?.[date] || 0} {this.tr("free meal(s)")}</strong></p>
                                })}
                            <button
                                className={tabStyles.linkLikeButton} 
                                onClick={() => {
                                    this.editMeals(item, slider);
                                }}>{this.tr(datesWithMeals.length > 0 ? "Edit meals" : "Add meals")}</button>
                        </>}

                        <h3>{this.tr("Attachments")}</h3>
                        <SimpleAttachmentTable 
                            showAttachment={this.showAttachment}
                            downloadAttachment={this.downloadAttachment}
                            onPromptDeletion={this.getAttachmentDeletionFunction("daily_allowance", item.id)}
                            rows={this.filterOutDeletedAttachments(item?.attachments ?? [])
                                .concat(this.transformFilesForSimpleTable(this.getTemporaryAttachments("daily_allowance", item.id)))
                            }
                            />
                        <button 
                            className={tabStyles.linkLikeButton} 
                            onClick={() => {
                                this.context.functions.promptMultipleFileSelection((files: File[]) => {
                                    this.addTemporaryAttachments(
                                        files.map((f: File): ExpenseFile => {
                                            const ef: ExpenseFile = new ExpenseFile(f);

                                            ef.setExpenseRowType("daily_allowance");

                                            return ef;
                                        }), 
                                        "daily_allowance", 
                                        item.id
                                    );

                                    return true;
                                });
                            }}>
                            {this.tr("Add an attachment")}
                        </button>
                    </>
                );
            }
        }
    }

    protected presentCurrencyWithRate(x: number): string {
        return this.context.functions.presentCurrency(
            this.calcUsingRate(x),
            this.props.data.currency
        );
    }

    protected calcUsingRate(x: number): number {
        return calculateByCurrencyRate(
            x, 
            this.props.data.currencyRate ?? 1, 
            2
        );
    }

    protected getExpenseListProps(): SliderListProps {
        const {
            data,
            dropdownData,
        }: {
            data: ExpenseDetails;
            dropdownData: DropdownData;
        } = this.props;

        const presentCurrency = (amount: number | string) => {
            return this.context.functions.presentCurrency(amount, data.currency);
        };

        return {
            editable: (row: Expense): boolean => {
                return this.userCanEdit() && row.billId === 0;
            },
            columnsToHideWhenNotEditable: ["menu", "delete"],
            propControlledRows: true,
            itemPropUpdateRelevantFields: ["attachments"],
            summaryRowPresentation: (rows): { [key: string]: JSX.Element | string; } => {
                const subTotal: number   = sum(rows.map((r: Expense): number => roundToDecimals(Number(r.sum), 2)));
                const totalTotal: number = sum(rows.map((r: Expense): number => roundToDecimals(Number(r.total), 2)));
                const vatTotal: number   = totalTotal - subTotal;
                // const vatTotal: number   = sum(rows.map((r: Expense): number => {
                    // totalWithVat and totalWithoutVat aren't set in wizard mode.
                    // return roundToDecimals(Number(r.totalWithVat ?? r.total) - Number(r.totalWithoutVat ?? r.sum), 2) 
                // }));

                return {
                    description: <strong>{this.tr("Total")}</strong>,
                    sum: <strong>{presentCurrency(subTotal)}</strong>,
                    total: <strong>{presentCurrency(totalTotal)}</strong>,
                    vat: <strong>{presentCurrency(vatTotal)}</strong>,
                };
            },
            rowPresentation: (row, list: SliderList): { [key: string]: JSX.Element | string; } => {
                return {
                    menu: <SliderListRowMenu 
                        items={[
                            { icon: <EditIcon />, text: this.tr("Edit"), onClick: () => list.showRowSlider(row) },
                            { icon: <DeleteIcon />, text: this.tr("Delete"), variant: "delete", onClick: () => list.deleteRow(row) },
                        ]}
                    />,
                    sum: presentCurrency(row.sum),
                    total: presentCurrency(row.total),
                    type: dropdownData?.expenseTypes?.find(m => m.value === row.type)?.label ?? "",
                    jobtypeId: dropdownData?.jobtypes?.find(m => m.value === row.jobtypeId)?.label ?? "",
                    ratioId: dropdownData?.ratios?.find(m => m.value === row.ratioId)?.label ?? "",
                    accountingAccountId: dropdownData?.accountingAccounts?.find(m => m.value === Number(row.accountingAccountId))?.label ?? "",
                    accountingProductId: dropdownData?.accountingProducts?.find(m => m.value === Number(row.accountingProductId))?.label ?? "",
                    attachments: <AttachmentIndicator 
                        showAttachment={this.showAttachment}
                        downloadAttachment={this.downloadAttachment}
                        attachments={row.attachments}
                    />,
                    vat: `${Number(row.vat).toFixed(2)} %`,
                    delete: <DeleteIcon />
                };
            },
            fieldEditHandlers: {
                // @ts-ignore
                jobtypeId: (jobtype, row) => jobtype?.id ?? jobtype,
                ratioId: (ratio, row) => ratio?.id ?? ratio,
                type: (type, row) => type?.id ?? type,
                accountingAccountId: (account, row) => (account?.value || account?.value === 0) ? account?.value : account,
                accountingProductId: (product, row) => (product?.value || product?.value === 0) ? product?.value : product,
                // TODO: wautsi wau
                // total: (_, row) => calculate(ensureNumber(row.vat)).div(100).plus(1).times(ensureNumber(row.sum)).toNumber(),
            },
            onItemSave: async (row: Expense) => {
                // Sometimes an event listener or something somewhere
                // isn't triggered correctly, if a field isn't unfocused
                // before the user closes the slider,
                // so we need to make sure the subtotal is calculated
                // at some point.
                row.sum = roundToDecimals(Number(row.total) / (Number(row.vat) / 100 + 1), 2);

                const rowType: string = this.props.expenseType === "te"
                    ? "other_row"
                    : "expense_row";

                const other    = this.getExpenseSaveRow(row);
                const response = await DataHandler.post(
                    { url: `expenses/${row.id}/${rowType}` }, 
                    other
                );

                const attachmentType: string = this.props.expenseType === "te"
                    ? "other_allowance"
                    : "purchase_expense";

                await this.uploadTemporaryAttachments(
                    attachmentType,
                    row.id,
                    response.data.id
                );

                await this.deleteAttachmentsMarkedForDeletion();

                this.props.refresh();
            },
            onDeleteRow: (expenseRow: Expense) => {
                this.showDeletionDialog(async () => {
                    const rowType: string = this.props.expenseType === "te"
                        ? "other_row"
                        : "expense_row";

                    await DataHandler.delete({ url: `expenses/${expenseRow.id}/${rowType}` }, expenseRow)

                    this.props.refresh();
                });
            },
            onSliderClose: (item: Expense, reason: string) => {
                this.lastModifiedExpenseField = undefined;

                if(reason !== "cancel" && reason !== "backdropClick") {
                    return null;
                }

                this.clearTemporaryAttachments(
                    this.props.expenseType === "te" 
                        ? "other_allowance" 
                        : "purchase_expense", 
                    item.id
                );

                this.clearAttachmentsMarkedForDeletion();
            },
            minimumWidth: 1000,
            // @ts-ignore
            listColumns: [
                { id: "menu", label: "", width: "40px", noClick: true },
                { id: "description", label: this.tr("Description"), width: "100%" },
                { 
                    id: "type", 
                    label: this.props.expenseType === "te" 
                        ? this.tr("Allowance type")
                        : this.tr("Expense type"), 
                    width: "120px" 
                },
                { id: "jobtypeId", label: this.tr("Jobtype"), width: "75px" },
                { id: "ratioId", label: this.tr("Netvisor ratio"), width: "80px" },
                { id: "accountingProductId", label: this.tr("Accounting product"), width: "80px" },
                { id: "accountingAccountId", label: this.tr("Accounting account"), width: "80px" },
                { id: "sum", label: this.tr("Subtotal"), width: "100px", align: "right" },
                { id: "vat", label: this.tr("VAT %"), width: "50px", align: "right" },
                { id: "total", label: this.tr("Total"), width: "100px", align: "right" },
                { id: "attachments", label: "", width: "30px", align: "center" },
                { id: "delete", label: "", width: "30px", align: "center", cellClickHandler: (row: any, list: SliderList) => {
                    list.deleteRow(row);
                }}
            ].filter((field): boolean => this.state.fieldVisibilityRules?.[field.id] ?? true),
            icons: { mainIcon: MonetizationOn },
            menuItems: this.userCanEdit() 
                ? [
                    { label: this.tr("Add row"), onClick: list => {
                        const vat: number = dropdownData.expenseTypes.filter(et => !et.deleted)?.[0]?.vat ?? 0;

                        list.addRow({
                            vat: !isNaN(vat) ? vat : 0
                        }) 
                    } }
                ] 
                : [],
            rows: data.expenseRows,
            sliderRowFields: [
                {
                    key: "description",
                    title: this.tr("Description"),
                    type: "text",
                    autoFocus: (item) => item?.id < 0,
                    additionalProps: {
                        selectOnFocus: true
                    }
                },
                {
                    key: "type",
                    title: this.tr("Expense type"),
                    type: "select",
                    selectFirstAutomatically: true,
                    options: dropdownData.expenseTypes.filter(et => !et.deleted),
                    setOtherValuesWithSelection: (item, value) => {
                        const f: ExpenseType | undefined = dropdownData.expenseTypes
                            .find((et) => et.value === value.id);

                        if(!f) {
                            return {};
                        }

                        const vat: number = isNaN(f.vat) ? 0 : f.vat;
                        const sum: number = roundToDecimals(divide(item?.total ?? 0, calculate(vat).div(100).plus(1)).toNumber(), 2)

                        return {
                            vat: vat,
                            sum: sum
                        };
                    }
                },
                {
                    key: "jobtypeId",
                    title: this.tr("Jobtype"),
                    type: "select",
                    options: dropdownData.jobtypes,
                    selectFirstAutomatically: true,
                },
                {
                    key: "ratioId",
                    title: this.tr("Netvisor ratio"),
                    type: "select",
                    selectFirstAutomatically: true,
                    options: dropdownData.ratios,
                    getOptions: (item, options) => {
                        return options?.filter(o => {
                            return !o.deleted || item?.ratioId === o.value;
                        });
                    }
                },
                {
                    key: "accountingProductId",
                    title: this.tr("Product"),
                    type: "select",
                    options: dropdownData.accountingProducts,
                    selectFirstAutomatically: true,
                },
                {
                    key: "accountingAccountId",
                    title: this.tr("Account"),
                    type: "select",
                    options: dropdownData.accountingAccounts,
                    selectFirstAutomatically: true,
                },
                {
                    key: "sum",
                    title: this.tr("Subtotal"),
                    type: "number",
                    formatValue: (value: string) => roundToDecimals(Number(value ?? 0), 2).toFixed(2),
                    disabled: true,
                },
                {
                    key: "vat",
                    title: this.tr("VAT %"),
                    type: "number",
                    handleValueAfterEdit: handleNumberInput,
                    formatValue: (value: string) => !isNaN(Number(value)) 
                        ? ensureNumber(value).toFixed(2) 
                        : value, 
                    setOtherValuesWithSelection: (item, value) => {
                        value = Number(value);
                        value = !isNaN(value) ? value : 0;

                        return {
                            sum: divide(item?.total ?? 0, calculate(value).div(100).plus(1)).toNumber()
                        };
                    },
                    checkValidity: (item: Expense): boolean => {
                        if(!item) {
                            return true;
                        }

                        const vat = Number(item.vat);

                        return !isNaN(vat) && 0 <= vat && vat <= 100;
                    },
                    errorMessage: (item: Expense): React.ReactNode => {
                        const vat = Number(item?.vat ?? 0);

                        if(!isNaN(vat) && 0 <= vat && vat <= 100) {
                            return null;
                        }

                        return (
                            <p>{this.tr("The VAT-% must be a number between 0 and 100")}</p>
                        );
                    },
                    required: true,
                    additionalProps: {
                        selectOnFocus: true,
                        maximumFractionDigits: 2,
                        equalPropValueFix: true
                    }
                },
                {
                    key: "total",
                    title: this.tr("Total"),
                    type: "number",
                    formatValue: (value: string) => roundToDecimals(Number(value ?? 0), 2).toFixed(2), 
                    handleValueAfterEdit: handleNumberInput,
                    validation: "numeric",
                    setOtherValuesWithSelection: (item, value) => {
                        const vat: number = calculate(ensureNumber(item?.vat ?? 0)).div(100).plus(1).toNumber();

                        return {
                            sum: roundToDecimals(divide(value, vat).toNumber(), 2)
                        };
                    },
                    afterEdit: () => {
                        this.lastModifiedExpenseField = "total";
                    },
                    required: true,
                    additionalProps: {
                        selectOnFocus: true,
                        maximumFractionDigits: 2,
                        equalPropValueFix: true
                    }
                },
            ].filter((field): boolean => this.state.fieldVisibilityRules?.[field.key] ?? true),
            additionalContent: (item: Expense, slider: FieldEditSlider): JSX.Element => {
                if(!item) {
                    return <></>;
                }

                const attachmentType: string = this.props.expenseType === "te"
                    ? "other_allowance"
                    : "purchase_expense";

                return (
                    <>
                        <h3>{this.tr("Attachments")}</h3>
                        <SimpleAttachmentTable 
                            showAttachment={this.showAttachment}
                            downloadAttachment={this.downloadAttachment}
                            onPromptDeletion={this.getAttachmentDeletionFunction(attachmentType, item.id)}
                            rows={this.filterOutDeletedAttachments(item?.attachments ?? [])
                                .concat(this.transformFilesForSimpleTable(this.getTemporaryAttachments(attachmentType, item.id)))
                            }
                        />
                        <button 
                            className={tabStyles.linkLikeButton} 
                            onClick={() => {
                                this.context.functions.promptMultipleFileSelection((files: File[]) => {
                                    this.addTemporaryAttachments(
                                        files.map((f: File): ExpenseFile => {
                                            const ef: ExpenseFile = new ExpenseFile(f);

                                            ef.setExpenseRowType(attachmentType);

                                            return ef;
                                        }), 
                                        attachmentType, 
                                        item.id
                                    );

                                    return true;
                                });
                            }}>
                            {this.tr("Add an attachment")}
                        </button>
                    </>
                );
            },
            texts: {
                mainHeader: this.props.expenseType === "te"
                    ? this.tr("Other allowances")
                    : this.tr("Expenses"),
                sliderHeader: this.props.expenseType === "te" 
                    ? this.tr("other allowance")
                    : this.tr("expense")
            },
            className: tabStyles.sliderList
        };
    }

    showAttachment = (attachment: any) => {
        const bt = sessionStorage.taimerToken 
            ? sessionStorage.taimerToken
            : localStorage.taimerToken;

        if (attachment?.locationtype === 'googledrive'){
            window.open(attachment?.file_url, "_blank");
            return;
        }


        const prefix: string = window.location.href.indexOf(":8080") > -1 
            ? "react/api" 
            : "/react/api";

        this.setState({
            attachmentOpen: true,
            attachmentURI: attachment.url ? attachment.url : `${prefix}/attachment/${attachment.id}?_auth=${bt}`
        });
    }

    downloadAttachment = (attachment) => {
        if (attachment?.locationtype === 'googledrive'){
            window.open(attachment?.file_url, "_blank");
            return;
        }

        downloadAttachment(attachment.id, attachment.filename);
    }

    closeAttachment = () => {
        this.setState({
            attachmentOpen: false,
            attachmentURI: ""
        });
    }

    protected getAttachmentListProps(): SliderListProps {
        const {
            attachments,
        }: { 
            attachments: any[]; 
        } = this.props;

        const bt = sessionStorage.taimerToken 
            ? sessionStorage.taimerToken
            : localStorage.taimerToken;

        const prefix: string = window.location.href.indexOf(":8080") > -1 
            ? "react/api" 
            : "/react/api";

        return {
            propControlledRows: true,
            itemPropUpdateRelevantFields: ["attachments"],
            columnsToHideWhenNotEditable: ["delete"],
            editable: (attachment): boolean => {
                return this.userCanEdit() && 
                    (Number(attachment?.data?.deletable) === 1 || !attachment.hasOwnProperty("data"))
            },
            rowPresentation: (row: any, list: SliderList): { [key: string]: JSX.Element | string; } => {
                const size: string     = (row.filesize/1024).toFixed(2);
                const sizeStr          = `${size} ${this.tr("KB")}`;
                const isImage: boolean = isImageFileType(row?.type || row?.file?.type);
                const thumbnailProps   = {
                    src: isImage
                        ? row.url ? row.url : `${prefix}/attachment/${row.thumb_id ?? row.id}?_auth=${bt}`
                        : undefined,
                    children: isImage
                        ? undefined
                        : <AttachFileIcon 
                            className={tabStyles.attachFileIcon}
                        />
                };

                const sliderListRowMenuItems: SliderListRowMenuItem[] = [
                    {
                        icon: <VisibilityIcon />, 
                        text: this.tr(isImage ? "View" : "Download"), 
                        onClick: () => {
                            isImage 
                                ? this.showAttachment(row) 
                                : this.downloadAttachment(row);
                        } 
                    },
                ];

                if(this.userCanEdit() 
                   && (Number(row?.data?.deletable) === 1 || !row.hasOwnProperty("data"))) {
                    sliderListRowMenuItems.push({ 
                        icon: <DeleteIcon />, 
                        text: this.tr("Delete"), 
                        variant: "delete", 
                        onClick: () => list.deleteRow(row) 
                    });
                }

                return {
                    menu: <SliderListRowMenu 
                        items={sliderListRowMenuItems}
                    />,
                    thumbnail: <div style={{ textAlign: "center" }}>
                        <Avatar 
                            variant="rounded"
                            className={tabStyles.avatar}
                            {...thumbnailProps}
                        />
                    </div>,
                    filename: (<>
                        <div><strong>{row.filename}</strong></div>
                        <div>{sizeStr}</div>
                    </>),
                    view: <button 
                        className={sliderListStyles.blueTextButton}>
                        {this.tr(isImage ? "View" : "Download")}
                    </button>,
                    delete: <DeleteIcon />
                };
            },
            onDeleteRow: (row): void => {
                this.showAttachmentDeletionDialog(row);
            },
            minimumWidth: 1000,
            // @ts-ignore
            listColumns: [
                { id: "menu", label: "", width: "40px", noClick: true },
                { id: "thumbnail", label: "", width: "40px", cellClickHandler: (row: any) => {
                    isImageFileType(row.type || row.file?.type)
                        ? this.showAttachment(row)
                        : this.downloadAttachment(row);
                } },
                { id: "filename", label: "", width: "100%", noClick: true },
                { id: "view", label: "", width: "50px", align: "right", cellClickHandler: (row: any) => {
                    isImageFileType(row.type || row.file?.type)
                        ? this.showAttachment(row)
                        : this.downloadAttachment(row);
                } },
                { id: "delete", label: "", width: "30px", align: "center", cellClickHandler: (row: any, list: SliderList) => {
                    list.deleteRow(row);
                }}
            ],
            icons: { mainIcon: AttachFile },
            rows: attachments,
            customMenuItem: this.userCanEdit()
                ? <>
                    <button onClick={async () => {
                        await this.promptGeneralAttachmentSelection();

                        this.props.refresh();
                    }}>{this.tr("Add an attachment")}</button>
                </>
                : <></>,
            texts: {
                mainHeader: this.tr("Attachments"),
                sliderHeader: this.tr("attachment")
            },
            className: [
                tabStyles.sliderList, 
                tabStyles.attachmentSliderList
            ].join(" "),
        };
    }

    promptGeneralAttachmentSelection = () => {
        const endPart: string = this.props.expenseType === "te" 
            ? "/traveling"
            : "";

        return this.context.functions.promptMultipleUpload({
            url: `expenses/${this.props.data.id}/attachments${endPart}`
        });
    }

    handleProjectCreation = (e) => {
        this.setState({
            tempProjectId: e.id
        }, () => this.projectTreeRef?.current?.fetchProjects());
    }

    projectHasQuotes = (projectId: string | number): boolean => {
        const project: Project | undefined = this.props?.dropdownData?.projects
            ?.find((p: Project): boolean => {
                return Number(projectId) === Number(p.value);
            });

        return project?.hasQuotes ?? false;
    }

    renderProjectTreeDropdown  = () => {
        return <ProjectTreeDropdown
            ref={this.projectTreeRef}
            disabled={!this.userCanEdit() || this.props.data.atLeastOneRowInvoiced}
            label={this.tr("Project")}
            showWholeTrees={true}
            treeDropdownProps={{ 
                growOptionContainer: true, 
                showTooltip: true, 
                resetOnClickaway: true, 
                emptyValueOnClick: false,
                usePopper: true
            }}
            value={this.state.tempProjectId}
            // disabled={!rowProps.editable || atLeastOneRowInvoiced}
            disableAutoSelect
            queryParameters={{ 
                right: 'read',
                company: this.props.data.companyId,
                includeCurrent: this.props?.data?.projectId ?? undefined
            }}
            // error={selectionErrors.customers_id || selectionErrors.projects_id}
            noOptionsMessage={AddProject}
            noOptionsMessageProps={{
                selectProps: {
                    onItemCreated: this.handleProjectCreation,
                }
            }}
            onSelect={e => {
                this.setProjectSelection(e.id);
            }}
            clearOnEmpty
        />
    }

    renderContentForDetailsSection = (section: string): JSX.Element => {
        switch(section) {
            case "details":
                return this.renderDetailsSection();
            case "state_log":
                return this.renderStateLog();
            default:
                return <></>;
        }
    }

    filterDetailFields = (field: EditableField | any): boolean => {
        if(!this?.props?.expenseType) {
            return false;
        }

        const fields: { [key: string]: string[] } = {
            // Travel expense.
            te: [
                "companyName",
                "accountName",
                "projectId",
                "quoteId",
                "quoteRowId",
                "description",
                "route",
                "startDate",
                "startTime",
                "endDate",
                "endTime",
                "billCustomer"
            ],
            // Purchase expense.
            pe: [
                "companyName",
                "accountName",
                "projectId",
                "quoteId",
                "quoteRowId",
                "paymentTypeId",
                "description",
                "expenseDate",
                "currency",
                "currencyRate",
                "billCustomer"
            ]
        };

        const vFields: string[] = fields[this.props.expenseType ?? "te"];

        return vFields.indexOf(field.key) > -1;
    }

    renderDetailsSection(): JSX.Element {
        const {
            data,
            dropdownData
        }: {
            data: ExpenseDetails;
            dropdownData: { [key: string]: DataSelectEntry[] };
        } = this.props;

        const quoteRowTree = {};

        this.props.dropdownData?.quoteRows?.forEach((qr: QuoteRow) => {
            if(!quoteRowTree[qr.quoteId]) {
                quoteRowTree[qr.quoteId] = [];
            }

            quoteRowTree[qr.quoteId].push(qr);
        });

        Object.keys(quoteRowTree).forEach((k: string) => {
            quoteRowTree[k] = createOptions(
                quoteRowTree[k],
                "value",
                "headerId"
            );
        });

        return (
            <SliderFieldGroup
                testKey='expense_details'
                ref={this.detailsSliderRef}
                editingDisabled={!this.userCanEdit()}
                items={[data]}
                editButtonLocation="top"
                fieldEditSliderProps={{
                    checkValidityOfAllFieldsOnChange: true
                }}
                onItemSaved={this.onDetailsEdit}
                onCloseSlider={(sliderRef: FieldEditSlider, _?, reason?: string) => {
                    if(reason !== "cancel" && reason !== undefined) {
                        return;
                    }

                    // TODO: Cancel the setProjectSelection and slider.setFieldValue.
                    this.setProjectSelection(this.props.data.projectId);
                    sliderRef?.setFieldValue("projectId", this.props.data.projectId);
                }}
                sliderFieldFilter={(f: EditableField): boolean => f.key !== "accountName"}
                fields={[
                    {
                        key: "companyName",
                        title: this.tr("Company"),
                        disabled: true,
                        tooltip: this.tr("You can only add travel expenses for your own company"),
                        isHidden: () => !this.context.taimerAccount.isMulticompany
                        // fieldTooltip: this.tr("You can only add travel expenses for your own company")
                    },
                    {
                        key: "accountName",
                        title: this.tr("Account"),
                        formURL: () => `index.html?module=customers&action=view&id=${this.props?.data?.accountId}`,
                    },
                    {
                        key: "projectId",
                        formatValue: (_: string) => `${this.props?.data?.projectName} (${this.props?.data?.projectNumber})`,
                        formURL: () => `index.html?module=projects&action=view&id=${this.props?.data?.projectId}`,
                        title: this.tr("Project"),
                        required: true,
                        setOtherValuesWithSelection: (item: ExpenseDetails, value: number) => {
                            const values: { [key: string]: any } = {};
                            const clonedItem: ExpenseDetails     = cloneDeep(item);

                            if(this.projectHasQuotes(value)) {
                                values.quoteId = Number(this.props.dropdownData.quotes
                                    .find((q: DataSelectEntry) => Number(q.parentIdentifier) === Number(value))?.value) || null;

                                values.quoteRowId = Number(this.props.dropdownData.quoteRows
                                   .find((qr: DataSelectEntry) => Number(qr.parentIdentifier) === values.quoteId)?.value) || null;
                            }

                            clonedItem.projectId = value;
                            values.billCustomer  = this.expenseCanBeBilled(clonedItem);

                            return values;
                        },
                        customComponent: this.renderProjectTreeDropdown,
                        customComponentMediator: (slider: FieldEditSlider) => {
                            return {
                                onSelect: e => {
                                    this.setProjectSelection(e.id);
                                    slider.setFieldValue("projectId", e.id);
                                }
                            };
                        }
                    },
                    {
                        key: "quoteId",
                        title: this.tr("Quote"),
                        type: "data_select",
                        options: dropdownData?.quotes,
                        filterBy: "projectId",
                        filterByKey: "parentIdentifier",
                        setOtherValuesWithSelection: (item, value) => {
                            const quoteRow: DataSelectEntry | undefined = dropdownData.quoteRows
                                .find((qr: DataSelectEntry) => qr.parentIdentifier === value);

                            return {
                                quoteRowId: quoteRow?.value ?? null
                            };
                        },
                        isHidden: (item) => !this.projectHasQuotes(item.projectId)
                            || !this.props.data.extendedCostTargeting 
                    },
                    {
                        key: "quoteRowId",
                        title: this.tr("Quote row"),
                        type: "treeselect", 
                        options: quoteRowTree,
                        getOptions: (item: ExpenseDetails, options) => {
                            return item && item.quoteId
                                ? options?.[item.quoteId] ?? []
                                : [];
                        },
                        isHidden: (item) => !this.projectHasQuotes(item.projectId)
                            || !this.props.data.extendedCostTargeting 
                    },
                    {
                        key: "paymentTypeId",
                        title: this.tr("Payment type"),
                        type: "data_select",
                        options: dropdownData.payment_types,
                        required: true,
                    },
                    {
                        key: "description",
                        title: this.props.expenseType === "te"
                            ? this.tr("Description")
                            : this.tr("Purpose of expense"),
                        additionalProps: {
                            selectOnFocus: true
                        }
                    },
                    {
                        key: "route",
                        title: this.tr("Route"),
                        additionalProps: {
                            selectOnFocus: true
                        }
                    },
                    {
                        key: "expenseDate",
                        title: this.tr("Date"),
                        type: "date",
                        required: true,
                    },
                    {
                        key: "startDate",
                        title: this.tr("Start date"),
                        type: "date",
                        required: true,
                        checkValidity: (expense: ExpenseDetails): boolean => {
                            return this.neededRatesExist(expense)
                                && this.expenseDatesAreValid(expense);
                        },
                        setOtherValuesWithSelection: (item, value) => {
                            const billable: boolean = this.expenseCanBeBilled(item);

                            return {
                                billCustomer: !billable ? false : item.billCustomer
                            }
                        }
                    },
                    {
                        key: "startTime",
                        title: this.tr("Start time"),
                        type: "time",
                        required: true,
                        checkValidity: (expense: ExpenseDetails) => {
                            return this.expenseDatesAreValid(expense);
                        }
                    },
                    {
                        key: "endDate",
                        title: this.tr("End date"),
                        type: "date",
                        required: true,
                        checkValidity: (expense: ExpenseDetails) => {
                            return this.expenseDatesAreValid(expense);
                        }
                    },
                    {
                        key: "endTime",
                        title: this.tr("End time"),
                        type: "time",
                        required: true,
                        checkValidity: (expense: ExpenseDetails) => {
                            return this.expenseDatesAreValid(expense);
                        },
                        errorMessage: (expense: ExpenseDetails): React.ReactNode => {
                            if(!expense) {
                                return null;
                            }

                            const messages: { [key: string]: boolean } = {
                                [this.tr("Either no daily allowances or no mileage allowances exist for the selected year")]:
                                    !this.neededRatesExist(expense),
                                [this.tr("The expense's starting time can not be after its ending time and the expense can't span past the end of the year")]: 
                                    !this.expenseDatesAreValid(expense),
                            };

                            return (
                                <>
                                    {Object.keys(messages)
                                        .filter((k: string) => messages[k])
                                        .map((message: string) => <p>{message}</p>)
                                    }
                                </>
                            );
                        },
                    },
                    {
                        key: "currency",
                        title: this.tr("Expense's currency"),
                        type: "data_select",
                        options: dropdownData.currencies,
                        setOtherValuesWithSelection: (item, value) => {
                            const currency = dropdownData
                                .currencies
                                .find(c => c.id === value);

                            return !currency 
                                ? {} 
                                : {
                                    // TODO: Fix at the source.
                                    currencyRate: Number(String(currency.value).replace(",", "."))
                                };
                        }
                    },
                    {
                        key: "currencyRate",
                        title: this.tr("Rate"),
                        formatValue: (value: string): string => Number(value).toFixed(6),
                        disabled: true
                    },
                    {
                        key: "billCustomer",
                        title: this.tr("Bill customer"),
                        type: "checkbox",
                        disabled: (item): boolean => {
                            return this.props.data.atLeastOneRowInvoiced
                                || !this.expenseCanBeBilled(item);
                        }
                    },
                ].filter(this.filterDetailFields)}
            />
        );
    }

    protected expenseCanBeBilled(e: ExpenseDetails): boolean {
        if(!e) {
            return false;
        }

        const project: Project | undefined = this.props.dropdownData.projects
            .find((p: Project) => p.value === String(e.projectId));

        return project !== undefined
            && project.expensesBillable 
            && (e?.startDate ?? "0000-00-00") >= project.chargeTravelingAfter;
    }


    protected getEligibleRates<T extends (MileageRate | DailyRate)>(
        expense: ExpenseDetails,
        pool: T[], 
        includeId: number | undefined = undefined
    ): T[] {
        return pool.filter((rate: T) => {
            return Number(includeId) === rate.value
                || (!rate.deleted
                    && rate.startDate <= (expense?.startDate ?? "0000-00-00")
                    && rate.endDate >= (expense?.endDate ?? "0000-00-00"));
        });
    }

    protected neededRatesExist(expense: ExpenseDetails, dropdownData: DropdownData = this.props.dropdownData): boolean {
        const { 
            mileageRates,
            dailyRates
        }: {
            mileageRates: MileageRate[];
            dailyRates: DailyRate[];
        } = dropdownData;

        const mr: MileageRate[] = this.getEligibleRates<MileageRate>(expense, mileageRates);
        const dr: DailyRate[]   = this.getEligibleRates<DailyRate>(expense, dailyRates);

        return mr.length > 0 || dr.length > 0;
    }

    protected expenseDatesAreValid(expense: ExpenseDetails): boolean {
        const endOfYear  = `${moment(`${expense.startDate} ${expense.startTime}`).format("YYYY")}-12-31 23:59:59`;
        const expenseEnd = `${expense.endDate} ${expense.endTime}`;

        // The expense's start datetime needs to be before its end,
        // and the expense can't span past the changing of the year.
        return `${expense.startDate} ${expense.startTime}` <= `${expense.endDate} ${expense.endTime}`
            && (expenseEnd <= endOfYear);
    }

    renderStateLog = (): JSX.Element => {
        const { 
            userObject: {
                // dateFormat,
                datetimeFormat
            }
        } = this.context;

        const stateLog: any[] = this.props.stateLog.map(item => {
            item.datetimeString = moment(item.datetime).format(datetimeFormat.replace("LT", "LTS"));
            item.statusText     = this.statusMap?.[item.value]?.text;

            return item;
        });

        return (
            <SliderFieldGroup
                items={stateLog}
                editingDisabled={true}
                showAll={true}
                fields={[
                    {
                        key: "statusText",
                        title: this.tr("Status"),
                    },
                    {
                        key: "datetimeString",
                        title: this.tr("Changed on"),
                    },
                    {
                        key: "user",
                        title: this.tr("Changed by"),
                    },
                ]} />
        );
    }

    renderPurchaseExpenseLists = () => {
        return <SliderList data-testid="expense" {...this.getExpenseListProps()} />;
    }

    renderTravelExpenseLists = () => {
        return (
            <>
                {<SliderList data-testid="mileage" {...this.getMileageAllowanceListProps()} />}
                {<SliderList data-testid="daily" {...this.getDailyAllowanceListProps()} />}
                {<SliderList data-testid="expense" {...this.getExpenseListProps()} />}
            </>
        );
    }

    renderRowLists = () => {
        return this.props.expenseType === "pe"
            ? this.renderPurchaseExpenseLists()
            : this.renderTravelExpenseLists();
    }

    protected renderAttachmentList() {
        return <SliderList data-testid="attachments" {...this.getAttachmentListProps()} />;
    }

    render() {
        return (
            <div id={tabStyles.details}>
                {!this.props.initialFetchDone && (
                    <div className={tabStyles.savingOverlay}>
                        <Loading  />
                    </div>
                )}
                <div className={`${cardStyles.detailsBox}`} onScroll={this.onDetailsScroll}>
                    {this.renderDetailsTopSection()}
                    <div className={tabStyles.detailsSections}>
                        <ToggleableContainerList 
                            sections={this.getDetailsSections()} 
                            renderContentForSection={this.renderContentForDetailsSection} />
                    </div>
                </div>
                <div className={[cardStyles.mainContent, tabStyles.mainContent].join(" ")}>
                    {this.renderRowLists()}
                    {this.renderAttachmentList()}
                </div>
                <OverlayImage
                    active={this.state.attachmentOpen}
                    uri={this.state.attachmentURI}
                    onClose={this.closeAttachment}
                />
                {/*tabs.filter(t => !t.hidden).length > 0 && <div className={cardStyles.mainContent}>
                    <WithTabs offsetTop={10} tabs={tabs} selectedTab={this.state.selectedTab} noUpdateView onTabClick={(tab) => this.setState({ selectedTab: tab.id })}>
                        {(selectedTab) => (React.cloneElement(this.tabContent[selectedTab], {
                            masterState: this.state,
                            masterProps: {...this.props, fetchData: this.fetchData},
                        }))}
                    </WithTabs>
                </div>*/}
            </div>
        );
    }
}

class ExpenseDetailsTabWizardMode extends ExpenseDetailsTab {
    componentDidMount() {
        super.componentDidMount();

        if(this.props.temporaryAttachmentsBundle === undefined) {
            return;
        }

        this.setState({
            tempAttachments: this.props.temporaryAttachmentsBundle
        });
    }

    componentDidUpdate(
        prevProps: ExpenseDetailsTabProps, 
        prevState: ExpenseDetailsTabState
    ) {
        super.componentDidUpdate(prevProps, prevState);

        if(this.props.temporaryAttachmentsBundle !== undefined
           && !isEqual(prevProps.temporaryAttachmentsBundle, this.props.temporaryAttachmentsBundle)) {
               this.setState({
                   tempAttachments: this.props.temporaryAttachmentsBundle
               });
        }
    } 

    editNewRow = (row, type) => {
        const { data } = this.props;
        const d = cloneDeep(data);
        const rows =  d[type + "Rows"] || [];
        const rowExists = rows.find(r => r.id == row.id);

        row.jobtypeId           = Number(row.jobtypeId) || 0;
        row.accountingAccountId = Number(row.accountingAccountId) || 0;
        row.accountingProductId = Number(row.accountingProductId) || 0;
        row.ratioId             = Number(row.ratioId) || 0;

        if (!rowExists) {
            if (row.isAdditional) {
                const reverseRows = cloneDeep(rows).reverse();
                // Get last additional row for mileage row or mileage row if no additional rows exist.
                const indexRow = reverseRows.find(r => r.id == row.mileageAllowanceId || r.mileageAllowanceId == row.mileageAllowanceId) || 0;
                // Insert new additional row to correct place in rows.
                const mileageIndex = rows.findIndex(r => r.id == indexRow.id);
                d[type + "Rows"].splice(mileageIndex + 1, 0, row);
            }
            else {
                d[type + "Rows"] = rows.concat(row);
            }
        } 
        else {
            d[type + "Rows"] = rows.map(r => {
                return (r.id === row.id) 
                    ? row 
                    : r;
            })
        }

        // (y)
        setTimeout(() => this.onDetailsEdit(d));
    }

    deleteNewRow = (row, type) => {
        const { data } = this.props;
        const d = cloneDeep(data);
        d[type + "Rows"] = (d[type + "Rows"] || []).filter(r => r.id != row.id);
        this.onDetailsEdit(d);
    }

    protected handleGeneralAttachmentDeletion(attachment) {
        const index: number = this.getTemporaryAttachments("general", -1)
            .findIndex((f: ExpenseFile) => attachment.file === f);

        this.deleteTemporaryAttachment("general", -1, index);
    }

    markAttachmentsSaved = (
        key: string, 
        entityId: number | string, 
        callback: () => void
    ): void => {
        this.setTemporaryAttachments(
            this.getTemporaryAttachments(key, entityId)
            .map((f: ExpenseFile): ExpenseFile => {
                f.saved = true;

                return f;
            }), 
            key, 
            entityId,
            callback
        );
    }

    clearNonSavedEntityAttachments = (
        key: string, 
        entityId: number | string
    ): void => {
        this.setTemporaryAttachments(
            (cloneDeep(this.state.tempAttachments[key])?.[entityId] ?? [])
                .filter((f: ExpenseFile) => f.saved),
            key,
            entityId
        );
    }

    clearCanceledAttachmentDeletions = (): void => {
        const attachments: TemporaryAttachmentsBundle = cloneDeep(this.state.tempAttachments);

        Object.keys(attachments).forEach((k: string) => {
            Object.keys(attachments[k]).forEach((id: number | string) => {
                attachments[k][id] = attachments[k][id].map((f: ExpenseFile) => {
                    f.deleted = false;

                    return f;
                });
            });
        });

        this.setState({
            tempAttachments: attachments
        });
    }

    deleteDeletedAttachments = (key: string, id: number | string): void => {
        this.setTemporaryAttachments(
            cloneDeep(this.state.tempAttachments[key][id])
                .filter((f: ExpenseFile) => !f.deleted),
            key,
            id
        );
    }

    protected getMileageAllowanceListProps(): SliderListProps {
        const props: SliderListProps = super.getMileageAllowanceListProps();

        props.onItemSave = (row: MileageAllowance) => {
            row.saved = true;

            const key: string = !row.isAdditional
                ? "mileage_allowance"
                : "additional_allowance";

            this.markAttachmentsSaved(
                key, 
                row.id,
                () => this.deleteDeletedAttachments(key, row.id)
             );

            setTimeout(() => this.editNewRow(row, "mileage"), 500);
        }

        props.onDeleteRow = (mileage: MileageAllowance) => {
            this.showDeletionDialog(() => {
                this.deleteNewRow(mileage, "mileage");

                this.deleteTemporaryAttachments(
                    !mileage.isAdditional 
                        ? "mileage_allowance"
                        : "additional_allowance",
                    mileage.id
                );
            });
        }

        const originalRowPresentationFunction: (row, list: SliderList) => { 
            [key: string]: JSX.Element | string | null; 
        } = props.rowPresentation;

        props.rowPresentation = (row, list: SliderList): { [key: string]: JSX.Element | string | null; } => {
            const rp: { [key: string]: JSX.Element | string | null; } = originalRowPresentationFunction(row, list);

            rp.attachments = <AttachmentIndicator 
                showAttachment={this.showAttachment}
                downloadAttachment={this.downloadAttachment}
                attachments={this.state.tempAttachments.mileage_allowance[row.id]} />;

            return rp;
        }

        props.onSliderClose = (item: MileageAllowance, reason: string) => {
            if(reason !== "cancel" && reason !== "backdropClick") {
                return null;
            }
           
            this.clearNonSavedEntityAttachments(
                !item.isAdditional
                    ? "mileage_allowance"
                    : "additional_allowance", 
                item.id
            );

            this.clearCanceledAttachmentDeletions();
        };

        props.editable = true;

        return props;
    }

    addAdditionalMileageRow = (item: MileageAllowance) => {
        const { data } = this.props;

        let newId = -1;
        data.mileageRows?.forEach(r => {
            if (Number(r.id) <= newId) {
                newId = Number(r.id) - 1;
            }
        });

        const mileageId = this.getEligibleRates<MileageRate>(
            this.props.data,
            this.props.dropdownData.additionalRates,
        )?.[0]
        const integration_product = mileageId?.integration_product || 0;
        const accountingProduct = this.props.dropdownData?.accountingProducts?.find(a => a.id == integration_product);

        this.context.functions.showSlider(
            <FieldEditSlider 
                open={true}
                fields={this.getMileageAllowanceSliderRowFields(true)}
                onClose={this.context.functions.closeSlider}
                onSave={async (additional: MileageAllowance) => {
                    const fAdditional = this.getAdditionalMileageSaveRow(additional, item.id);

                    additional.isAdditional       = true;
                    additional.mileageId          = fAdditional.target_id;
                    additional.mileageAllowanceId = item.id;
                    additional.accountingAccountId = fAdditional.accounting_accounts_id;
                    additional.accountingProductId = fAdditional.accounting_products_id;

                    this.editNewRow(additional, "mileage");
                    this.context.functions.closeSlider();
                }}
                title={this.tr("Add additional row")}
                item={{
                    id: newId,
                    mileageId: mileageId,
                    mileage: item.mileage,
                    accountingAccountId: 0,
                    accountingProductId: accountingProduct?.id || 0,
                    jobtypeId: 0
                }}
                useAnimatedSlider={true}
            />
        );
    }

    protected getDailyAllowanceListProps(): SliderListProps {
        const props: SliderListProps = super.getDailyAllowanceListProps();

        props.onItemSave = (row: DailyAllowance) => {
            this.markAttachmentsSaved(
                "daily_allowance", 
                row.id,
                () => this.deleteDeletedAttachments("daily_allowance", row.id)
            );
            setTimeout(() => this.editNewRow(row, "daily"), 500);
        }

        props.onDeleteRow = (row: DailyAllowance) => {
            this.showDeletionDialog(() => {
                this.deleteNewRow(row, "daily");
                this.deleteTemporaryAttachments("daily_allowance", row.id);
            });
        }

        delete props?.fieldEditHandlers?.startTime;
        delete props?.fieldEditHandlers?.endTime;

        const originalRowPresentationFunction: (row, list: SliderList) => { 
            [key: string]: JSX.Element | string | null; 
        } = props.rowPresentation;

        props.rowPresentation = (row, list: SliderList): { [key: string]: JSX.Element | string | null; } => {
            const rp: { [key: string]: JSX.Element | string | null; } = originalRowPresentationFunction(row, list);

            rp.attachments = <AttachmentIndicator 
                showAttachment={this.showAttachment}
                downloadAttachment={this.downloadAttachment}
                attachments={this.state.tempAttachments.daily_allowance[row.id]} />;

            return rp;
        }

        props.onSliderClose = (item: DailyAllowance, reason: string) => {
            if(reason !== "cancel" && reason !== "backdropClick") {
                return null;
            }

            this.clearNonSavedEntityAttachments(
                "daily_allowance",
                item.id
            );

            this.clearCanceledAttachmentDeletions();
        };

        props.editable = true;

        return props;
    }

    protected getExpenseListProps(): SliderListProps {
        const props: SliderListProps = super.getExpenseListProps();
        const attachmentKey: string  = this.props.expenseType === "te"
            ? "other_allowance"
            : "purchase_expense";

        props.onItemSave = (row: Expense) => {
            this.markAttachmentsSaved(
                attachmentKey, 
                row.id,
                () => this.deleteDeletedAttachments(attachmentKey, row.id)
            );
            setTimeout(() => this.editNewRow(row, "expense"), 500);
        }

        props.onDeleteRow = (other: Expense) => {
            this.showDeletionDialog(() => {
                this.deleteNewRow(other, "expense");
                this.deleteTemporaryAttachments(attachmentKey, other.id);
            });
        }

        const originalRowPresentationFunction: (row, list: SliderList) => { 
            [key: string]: JSX.Element | string | null; 
        } = props.rowPresentation;

        props.rowPresentation = (row, list: SliderList): { [key: string]: JSX.Element | string | null; } => {
            const rp: { [key: string]: JSX.Element | string | null; } = originalRowPresentationFunction(row, list);

            rp.attachments = <AttachmentIndicator 
                showAttachment={this.showAttachment}
                downloadAttachment={this.downloadAttachment}
                attachments={this.state.tempAttachments[attachmentKey][row.id]} />;

            return rp;
        }

        props.onSliderClose = (item: Expense, reason: string) => {
            if(reason !== "cancel" && reason !== "backdropClick") {
                return null;
            }

            this.clearNonSavedEntityAttachments(
                attachmentKey,
                item.id
            );

            this.clearCanceledAttachmentDeletions();
        };

        props.editable = true;

        return props;
    }

    protected getAttachmentListProps(): SliderListProps {
        const props: SliderListProps = super.getAttachmentListProps();

        props.rows = this.getTemporaryAttachments("mileage_allowance").concat(
            this.getTemporaryAttachments("daily_allowance"),
            this.getTemporaryAttachments("other_allowance"),
            this.getTemporaryAttachments("purchase_expense"),
            this.getTemporaryAttachments("general"))
            .filter((f: ExpenseFile) => f.saved)
            .map((f: ExpenseFile) => {
                return {
                    file: f, 
                    filename: f.name, 
                    filesize: f.size, 
                    url: URL.createObjectURL(f),
                };
            });

        props.onDeleteRow = (row): void => {
            this.showAttachmentDeletionDialog(row, (confirmed: boolean): void => {
                if(!confirmed) {
                    return;
                }

                const index: number = this.getTemporaryAttachments(row.file.getExpenseRowType(), -1)
                    .findIndex((f: ExpenseFile) => row.file === f);

                if(index === -1) {
                    // TODO: snackbar or something.
                    return;
                }

                this.deleteTemporaryAttachment(
                    row.file.getExpenseRowType(), 
                    -1, 
                    index
                );

                this.context.functions.closeDialog();
            });
        }

        props.editable = true;

        return props;
    }

    downloadAttachment = (attachment) => {
        window.open(attachment.url, '_blank')?.focus();
    }

    promptGeneralAttachmentSelection = () => {
        return this.context.functions.promptMultipleFileSelection((files: File[]) => {
            this.addTemporaryAttachments(
                files.map((f: File): ExpenseFile => {
                    const fs: ExpenseFile = new ExpenseFile(f);

                    fs.saved = true;
                    fs.setExpenseRowType("general");

                    return fs;
                }), 
                "general",
                -1
            );

            return true;
        });
    }

    getEligibleRates = <T extends (MileageRate | DailyRate)>(
        expense: ExpenseDetails,
        pool: T[], 
        includeId: number | undefined = undefined
    ): T[] => {
        return super.getEligibleRates(expense, pool, includeId);
    }

    neededRatesExist = (expense: ExpenseDetails, dropdownData: DropdownData = this.props.dropdownData): boolean => {
        return super.neededRatesExist(expense, dropdownData);
    }

    render() {
        return (
            <div>
                <div 
                    className={`${cardStyles.mainContent} ${cardStyles.fullWidth} ${cardStyles.fullHeight}`}
                    id={tabStyles.createRowsMainContent}>
                    {this.renderRowLists()}
                    {this.renderAttachmentList()}
                </div>
                <OverlayImage
                    active={this.state.attachmentOpen}
                    uri={this.state.attachmentURI}
                    onClose={this.closeAttachment}
                />
            </div>
        );
    }
}

class ExpenseDetailsTabPreviewMode extends ExpenseDetailsTabWizardMode {
    protected getMileageAllowanceListProps(): SliderListProps {
        const props: SliderListProps = super.getMileageAllowanceListProps();

        props.editable  = false;
        props.className = `${props.className} ${tabStyles.previewMode}`;
        props.menuItems = [];

        return props;
    }

    protected getDailyAllowanceListProps(): SliderListProps {
        const props: SliderListProps = super.getDailyAllowanceListProps();

        props.editable  = false;
        props.className = `${props.className} ${tabStyles.previewMode}`;
        props.menuItems = [];

        return props;
    }

    protected getExpenseListProps(): SliderListProps {
        const props: SliderListProps = super.getExpenseListProps();

        props.editable  = false;
        props.className = `${props.className} ${tabStyles.previewMode}`;
        props.menuItems = [];

        return props;
    }

    protected getAttachmentListProps(): SliderListProps {
        const props: SliderListProps = super.getAttachmentListProps();

        props.className   = `${props.className} ${tabStyles.previewMode}`;
        props.listColumns = props.listColumns.filter((c: ColumnObject) => {
            return c.id !== "menu" && c.id !== "delete";
        });

        props.menuItems = [];
        props.customMenuItem = <></>;

        return props;
    }

    protected renderAttachmentList() {
        return this.props.attachments.length > 0
            ? super.renderAttachmentList()
            : <></>;
    }

    renderPurchaseExpenseLists = () => {
        const { 
            data 
        } = this.props;

        return ((data.expenseRows?.length ?? 0) > 0)
            ? <SliderList {...this.getExpenseListProps()} /> 
            : <></>;
    }

    renderTravelExpenseLists = () => {
        const { 
            data 
        } = this.props;

        return (
            <>
                {((data.mileageRows?.length ?? 0) > 0) && <SliderList {...this.getMileageAllowanceListProps()} />}
                {((data.dailyRows?.length ?? 0) > 0) && <SliderList {...this.getDailyAllowanceListProps()} />}
                {((data.expenseRows?.length ?? 0) > 0) &&<SliderList {...this.getExpenseListProps()} />}
            </>
        );
    }
}

class ExpenseDetailsTabNoRenderMode extends ExpenseDetailsTabWizardMode {
    renderAttachmentList = () => {
        return <></>;
    }

    renderPurchaseExpenseLists = () => {
        return <></>;
    }

    renderTravelExpenseLists = () => {
        return <></>;
    }
}


export default ExpenseDetailsTab;
export {
    ExpenseDetailsTabWizardMode,
    ExpenseDetailsTabPreviewMode,
    ExpenseDetailsTabNoRenderMode,
    type ExpenseDetailsTabProps,
    type TemporaryAttachmentsBundle
}
